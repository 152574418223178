import React, { useState, useRef } from "react"

import {
    EuiForm,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiButton,
    EuiText,
    EuiLink,
    EuiSpacer,
    EuiFieldText,
    EuiDescriptionList,
    EuiDescriptionListProps,
} from "@equipmentshare/ds2"

import {
    useGetEquipmentServiceManuals,
    useCreateEquipmentServiceManual,
    ServiceManual,
    EquipmentServiceManual,
} from "../../../services/Skunkworks/Generated"
import { MakePicker } from "../../Pickers"
import { ModelMultiSelect, YearMultiSelect, MultiSelectHandle, Option } from "../../MultiSelectPickers"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"

export type EquipmentDataFormProps = {
    onSave?: () => void
    onCancel: () => void
    document: ServiceManual
}

export const EquipmentDataForm: React.FC<EquipmentDataFormProps> = ({ onSave, onCancel, document }) => {
    const [makeId, setMakeId] = React.useState<number | null>(null)
    const [modelOptions, setModelOptions] = useState<Option[]>([])
    const [serialStart, setSerialStart] = useState<string>("")
    const [serialEnd, setSerialEnd] = useState<string>("")
    const [yearOptions, setYearOptions] = useState<Option[]>([])

    const { addSuccessToast, addErrorToast } = useToasts()
    const modelMultiSelectRef = useRef<MultiSelectHandle>(null)
    const yearMultiSelectRef = useRef<MultiSelectHandle>(null)

    const {
        data: equipmentServiceManualsResponse,
        isLoading: isLoadingEquipmentServiceManuals,
        refetch: refetchEquipmentServiceManuals,
    } = useGetEquipmentServiceManuals(
        { service_manual_id: document.service_manual_id, sort_by: "equipment_service_manual_id", sort_order: "desc" },
        {
            query: {
                onError: (err) => addErrorToast({ text: formatException(err) }),
            },
        }
    )

    const { mutateAsync: createEquipmentServiceManual, isLoading: isCreatingEquipmentServiceManual } =
        useCreateEquipmentServiceManual({
            mutation: {
                onSuccess: () => addSuccessToast({ text: "Equipment Reference(s) Successfully Created" }),
            },
        })

    const buildSerialString = (esm: EquipmentServiceManual) => {
        if (esm.serial_start && esm.serial_end) {
            return `${esm.serial_start} - ${esm.serial_end}`
        }

        if (esm.serial_start) {
            return esm.serial_start
        }

        if (esm.serial_end) {
            return esm.serial_end
        }

        return ""
    }

    const equipmentServiceManuals = equipmentServiceManualsResponse?.equipment_service_manuals ?? []
    const previousClassifications: EuiDescriptionListProps["listItems"] = equipmentServiceManuals.map((esm) => {
        let titleText: string[] = [`${esm.equipment_make_name}`]

        if (!!esm.equipment_model_name) {
            titleText.push(`${esm.equipment_model_name}`)
        }

        return {
            title: titleText.join(", "),
            description: (
                <>
                    <EuiText size="s">{buildSerialString(esm)}</EuiText>
                    <EuiText size="s">{(esm.years || []).sort().join(", ")}</EuiText>
                </>
            ),
        }
    })

    const resetForm = () => {
        modelMultiSelectRef.current?.clearValue()
        yearMultiSelectRef.current?.clearValue()
        setModelOptions([])
        setSerialStart("")
        setSerialEnd("")
        setYearOptions([])
    }

    const submitCreate = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!makeId) {
            addErrorToast({ text: "Please complete all required fields" })
            return
        }

        try {
            await createEquipmentServiceManual({
                data: {
                    service_manual_id: document.service_manual_id,
                    equipment_make_id: makeId,
                    equipment_model_ids: modelOptions.length > 0 ? modelOptions.map((m) => parseInt(m.key)) : undefined,
                    serial_start: serialStart ? serialStart : undefined,
                    serial_end: serialEnd ? serialEnd : undefined,
                    years: yearOptions.length > 0 ? yearOptions.map((y) => parseInt(y.key)) : undefined,
                },
            })

            refetchEquipmentServiceManuals()
            onSave && onSave()
            resetForm()
        } catch (err) {
            addErrorToast({ text: formatException(err) })
        }
    }

    return (
        <EuiForm component="form" onSubmit={submitCreate}>
            <EuiFormRow label="Document Title">
                <EuiText size="s">{document.file_name}</EuiText>
            </EuiFormRow>
            <EuiFormRow label="GDrive Link">
                <EuiLink href={`https://drive.google.com/file/d/${document.file_id}/view`} target="_blank" external>
                    Document Link
                </EuiLink>
            </EuiFormRow>
            <EuiFormRow>
                <MakePicker
                    placeholder="Search for Equipment Make"
                    onIdSelected={(id) => {
                        setMakeId(id)
                        modelMultiSelectRef.current?.clearValue()
                    }}
                    onCleared={() => {
                        setMakeId(null)
                        modelMultiSelectRef.current?.clearValue()
                    }}
                    selectedId={makeId}
                    isInvalid={!makeId}
                />
            </EuiFormRow>
            <EuiFormRow helpText={!makeId ? "Select a make to enable model selection" : undefined}>
                <ModelMultiSelect
                    placeholder="Search for Equipment Model(s)"
                    ref={modelMultiSelectRef}
                    selected={modelOptions}
                    onSelected={(selectedModels) => setModelOptions(selectedModels)}
                    isDisabled={!makeId}
                    context={{ makeId: makeId ?? undefined }}
                />
            </EuiFormRow>
            <EuiFormRow label="Serial Start">
                <EuiFieldText
                    placeholder="Enter Serial Start"
                    value={serialStart}
                    onChange={(e) => setSerialStart(e.target.value.trim())}
                />
            </EuiFormRow>
            <EuiFormRow label="Serial End">
                <EuiFieldText
                    placeholder="Enter Serial End"
                    value={serialEnd}
                    onChange={(e) => setSerialEnd(e.target.value.trim())}
                />
            </EuiFormRow>
            <EuiFormRow>
                <YearMultiSelect
                    ref={yearMultiSelectRef}
                    placeholder="Select Year(s)"
                    selected={yearOptions}
                    onSelected={(selectedYears) => setYearOptions(selectedYears)}
                />
            </EuiFormRow>
            <EuiSpacer size="xxl" />
            <EuiFormRow>
                <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiButton color="text" onClick={onCancel}>
                            Close
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill type="submit" isLoading={isCreatingEquipmentServiceManual}>
                            Submit
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiSpacer size="xxl" />
            <EuiFormRow label="Equipment References">
                <>
                    <EuiSpacer size="s" />
                    {previousClassifications.length > 0 ? (
                        <EuiDescriptionList gutterSize="m" compressed listItems={previousClassifications} />
                    ) : (
                        <EuiText size="s">
                            {isLoadingEquipmentServiceManuals ? "Loading..." : "No previous equipment references"}
                        </EuiText>
                    )}
                </>
            </EuiFormRow>
        </EuiForm>
    )
}

export default EquipmentDataForm
