import { useState } from "react"

import ViewModal from "./Modals/ViewModal"

import AssignmentFields from "./Modals/ModalFields/AssignmentFields"
import ScheduleFields from "./Modals/ModalFields/ScheduleFields"
import ProgramFields from "./Modals/ModalFields/ProgramFields"

import { useToasts } from "../../../hooks/useToasts"

import { verifyArgs, PromiseData } from "./HelperFunctions"

import {
    GetAssignmentsParams,
    GetPayoutProgramMasterAgreementsParams,
    GetPayoutProgramsParams,
    GetSchedulesParams,
    getAssignment,
    getAssignments,
    getPayoutProgramMasterAgreement,
    getPayoutProgramMasterAgreements,
    getPayoutProgram,
    getPayoutPrograms,
    getSchedule,
    getSchedules,
    Assignment,
    MasterAgreement,
    PayoutProgram,
    Schedule,
} from "../../../services/PayoutProgram/Generated"

import { Tab } from "../PayoutProgramConsole"

import {
    PayoutProgramPicker,
    PayoutSchedulePicker,
    MasterAgreementPicker,
    PayoutAssignmentPicker,
    CompanyPicker,
} from "../../../components/Pickers"

export type PromiseDataMult = (
    | Assignment
    | MasterAgreement
    | PayoutProgram
    | Schedule
)[]

type GetFunctions = (req: number) => Promise<PromiseData>
type GetMultFunctions<T extends Tab> = T extends Tab.ASSIGNMENTS
    ? (
          req: GetAssignmentsParams,
          signal?: AbortSignal
      ) => Promise<PromiseDataMult>
    : T extends Tab.MASTERAGREEMENTS
    ? (
          req: GetPayoutProgramMasterAgreementsParams,
          signal?: AbortSignal
      ) => Promise<PromiseDataMult>
    : T extends Tab.PROGRAMS
    ? (
          req: GetPayoutProgramsParams,
          signal?: AbortSignal
      ) => Promise<PromiseDataMult>
    : T extends Tab.SCHEDULES
    ? (
          req: GetSchedulesParams,
          signal?: AbortSignal
      ) => Promise<PromiseDataMult>
    : null

type RenderViewModalProps = {
    tabName: Tab
    closeModal: () => void
    onSubmit: (e: PromiseDataMult) => void
}

export default function RenderViewModal(props: RenderViewModalProps) {
    const [id, setId] = useState<number | null>(null)
    const [checked, setChecked] = useState(false)
    const { addErrorToast, addSuccessToast } = useToasts()
    // default values for when modal is displayed
    const [assignmentReq, setAssignmentReq] = useState<GetAssignmentsParams>({
        asset_id: undefined,
        payout_program_id: undefined,
        schedule_id: undefined,
        start_date: undefined,
        end_date: undefined,
        replaced_by_asset_id: undefined,
        replaced_or_removed_reason: undefined,
        active: undefined,
    })

    const [masterAgreeReq, setMasterAgreeReq] =
        useState<GetPayoutProgramMasterAgreementsParams>({
            company_id: undefined,
        })

    const [programReq, setProgramReq] = useState<GetPayoutProgramsParams>({
        name: undefined,
        payout_program_type_id: undefined,
    })

    const [scheduleReq, setScheduleReq] = useState<GetSchedulesParams>({
        name: undefined,
        master_agreement_id: undefined,
        payout_program_id: undefined,
    })

    const assignmentBody = (
        <>
            {checked ? (
                <PayoutAssignmentPicker onIdSelected={(e) => setId(e)} />
            ) : (
                <AssignmentFields
                    assignmentReq={assignmentReq}
                    setAssignmentReq={setAssignmentReq}
                />
            )}
        </>
    )

    const masterAgreeBody = (
        <>
            {checked ? (
                <MasterAgreementPicker onIdSelected={(e) => setId(e)} />
            ) : (
                <CompanyPicker
                    onIdSelected={(id) =>
                        setMasterAgreeReq({
                            ...masterAgreeReq,
                            company_id: id,
                        })
                    }
                />
            )}
        </>
    )

    const programBody = (
        <>
            {checked ? (
                <PayoutProgramPicker onIdSelected={(e) => setId(e)} />
            ) : (
                <ProgramFields
                    programReq={programReq}
                    setProgramReq={setProgramReq}
                />
            )}
        </>
    )

    const scheduleBody = (
        <>
            {checked ? (
                <PayoutSchedulePicker onIdSelected={(e) => setId(e)} />
            ) : (
                <ScheduleFields
                    scheduleReq={scheduleReq}
                    setScheduleReq={setScheduleReq}
                />
            )}
        </>
    )

    const getFunctions: Record<Tab, GetFunctions | null> = {
        [Tab.ASSIGNMENTS]: getAssignment,
        [Tab.MASTERAGREEMENTS]: getPayoutProgramMasterAgreement,
        [Tab.PROGRAMS]: getPayoutProgram,
        [Tab.SCHEDULES]: getSchedule,
        [Tab.ADMIN]: null,
    }

    const getMultFunctions: {
        [K in Tab]: GetMultFunctions<K>
    } = {
        [Tab.ASSIGNMENTS]: getAssignments,
        [Tab.MASTERAGREEMENTS]: getPayoutProgramMasterAgreements,
        [Tab.PROGRAMS]: getPayoutPrograms,
        [Tab.SCHEDULES]: getSchedules,
        [Tab.ADMIN]: null,
    }

    async function processCreation(
        tab: Tab,
        req:
            | GetAssignmentsParams
            | GetPayoutProgramMasterAgreementsParams
            | GetPayoutProgramsParams
            | GetSchedulesParams,
        successMessage: string,
        errorMessage?: string
    ) {
        const getFunc = getFunctions[tab]
        const getMultFunc = getMultFunctions[tab]

        if (!getFunc || !getMultFunc) {
            return
        }

        if (id) {
            try {
                const data = await getFunc(id)
                addSuccessToast({
                    text: successMessage,
                    toastLifeTimeMs: 10000,
                })
                props.onSubmit([data])
                props.closeModal()
            } catch (e) {
                addErrorToast({
                    text: errorMessage + e.message,
                    toastLifeTimeMs: 10000,
                })
            }
        } else {
            if (!verifyArgs(req)) {
                addErrorToast({
                    text: "Please fill in at least 1 field ",
                    toastLifeTimeMs: 5000,
                })
                return
            }

            try {
                const data = await getMultFunc(req)
                addSuccessToast({
                    text: successMessage,
                    toastLifeTimeMs: 10000,
                })
                props.onSubmit(data)
                props.closeModal()
            } catch (e) {
                addErrorToast({
                    text: errorMessage + e.message,
                    toastLifeTimeMs: 10000,
                })
            }
        }
    }

    async function handleView(
        tab: Tab,
        req:
            | GetAssignmentsParams
            | GetPayoutProgramMasterAgreementsParams
            | GetPayoutProgramsParams
            | GetSchedulesParams
    ) {
        await processCreation(tab, req, `Successfully retrieved ${tab}`)
    }

    switch (props.tabName) {
        case "Assignments":
            return (
                <ViewModal
                    modalTitle={props.tabName}
                    modalBody={assignmentBody}
                    onConfirm={() => handleView(props.tabName, assignmentReq)}
                    closeModal={props.closeModal}
                    checkedBool={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
            )
        case "Master Agreements":
            return (
                <ViewModal
                    modalTitle={props.tabName}
                    modalBody={masterAgreeBody}
                    onConfirm={() => handleView(props.tabName, masterAgreeReq)}
                    closeModal={props.closeModal}
                    checkedBool={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
            )
        case "Programs":
            return (
                <ViewModal
                    modalTitle={props.tabName}
                    modalBody={programBody}
                    onConfirm={() => handleView(props.tabName, programReq)}
                    closeModal={props.closeModal}
                    checkedBool={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
            )
        case "Schedules":
            return (
                <ViewModal
                    modalTitle={props.tabName}
                    modalBody={scheduleBody}
                    onConfirm={() => handleView(props.tabName, scheduleReq)}
                    closeModal={props.closeModal}
                    checkedBool={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                />
            )
        default:
            return <></>
    }
}
