import React, { useState, useRef } from "react"

import { EuiForm, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldSearch, EuiButton } from "@equipmentshare/ds2"

import { GetEquipmentServiceManualsParams } from "../../../services/Skunkworks/Generated"
import { MakePicker, AllModelsPicker, YearPicker, PickerHandle } from "../../Pickers"

const SEARCH_FIELD_STYLE: React.CSSProperties = { width: "250px" }

export type SearchCriteria = Omit<GetEquipmentServiceManualsParams, "offset" | "limit" | "sort_by" | "sort_order">

export type EquipmentReferenceSearchFormProps = {
    onSearch: (searchCriteria: SearchCriteria) => void
    initialSearchCriteria: SearchCriteria
    isLoading?: boolean
}

export const EquipmentReferenceSearchForm: React.FC<EquipmentReferenceSearchFormProps> = ({
    onSearch,
    initialSearchCriteria,
    isLoading,
}) => {
    const [makeId, setMakeId] = useState<SearchCriteria["equipment_make_id"]>(initialSearchCriteria.equipment_make_id)
    const [modelId, setModelId] = useState<SearchCriteria["equipment_model_id"]>(
        initialSearchCriteria.equipment_model_id
    )
    const [serialStart, setSerialStart] = useState<SearchCriteria["serial_start"]>(initialSearchCriteria.serial_start)
    const [serialEnd, setSerialEnd] = useState<SearchCriteria["serial_end"]>(initialSearchCriteria.serial_end)
    const [year, setYear] = useState<SearchCriteria["year"]>(initialSearchCriteria.year)
    const [expandedFiltersOpen, setExpandedFiltersOpen] = useState<boolean>(false)

    const modelPickerRef = useRef<PickerHandle>(null)

    const resetSearchCriteria = () => {
        setMakeId(initialSearchCriteria.equipment_make_id)
        setModelId(initialSearchCriteria.equipment_model_id)
        setSerialStart(initialSearchCriteria.serial_start)
        setSerialEnd(initialSearchCriteria.serial_end)
        setYear(initialSearchCriteria.year)
    }

    const submitSearch = (e: React.FormEvent) => {
        e.preventDefault()

        const searchCriteria = {
            equipment_make_id: makeId,
            equipment_model_id: modelId,
            serial_start: serialStart,
            serial_end: serialEnd,
            year,
        }

        const filteredSearchCriteria: SearchCriteria = Object.fromEntries(
            Object.entries(searchCriteria).filter(([_, v]) => !!v)
        )

        onSearch(filteredSearchCriteria)
    }

    return (
        <EuiForm component="form" onSubmit={submitSearch}>
            <EuiFlexGroup alignItems="flexEnd" gutterSize="m" wrap>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow>
                        <MakePicker
                            placeholder="Search for Equipment Make"
                            onIdSelected={(id) => {
                                setMakeId(id)
                                modelPickerRef.current?.clearValue()
                            }}
                            onCleared={() => {
                                setMakeId(null)
                                modelPickerRef.current?.clearValue()
                            }}
                            selectedId={makeId}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow>
                        <AllModelsPicker
                            placeholder={!makeId ? "Select Make to Enable" : "Search for Equipment Model(s)"}
                            ref={modelPickerRef}
                            selectedId={modelId}
                            onIdSelected={(id) => setModelId(id)}
                            onCleared={() => setModelId(null)}
                            isDisabled={!makeId}
                            context={{ makeId: makeId ?? undefined }}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                {expandedFiltersOpen && (
                    <>
                        <EuiFlexItem grow={false} style={{ ...SEARCH_FIELD_STYLE, width: "300px" }}>
                            <EuiFormRow label="Serial Start">
                                <EuiFieldSearch
                                    placeholder="Search by serial start"
                                    value={serialStart ?? ""}
                                    onChange={(e) => setSerialStart(e.target.value)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={{ ...SEARCH_FIELD_STYLE, width: "300px" }}>
                            <EuiFormRow label="Serial End">
                                <EuiFieldSearch
                                    placeholder="Search by serial end"
                                    value={serialEnd ?? ""}
                                    onChange={(e) => setSerialEnd(e.target.value)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow>
                                <YearPicker
                                    placeholder="Select Year(s)"
                                    selectedId={year}
                                    onIdSelected={(year) => setYear(year)}
                                    onCleared={() => setYear(null)}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </>
                )}
                <EuiFlexItem grow={false}>
                    <EuiButton color="text" onClick={() => setExpandedFiltersOpen(!expandedFiltersOpen)}>
                        {expandedFiltersOpen ? "Less Filters" : "More Filters"}
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton color="text" onClick={resetSearchCriteria}>
                        Reset Filters
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton type="submit" isLoading={isLoading}>
                        Search
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    )
}

export default EquipmentReferenceSearchForm
