import { FC, useState } from "react"
import moment, { Moment } from "moment"

import {
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiDatePicker,
    EuiDatePickerRange,
    EuiFormRow,
    EuiConfirmModal,
    EuiText,
} from "@equipmentshare/ds2"
import { DeleteBinLineIcon, SearchLineIcon, AddLineIcon } from "@equipmentshare/ds2-icons"

import { CustomerRebatesTable } from "./components/CustomerRebatesTable"
import { AddCustomerRebateFlyout } from "./components/AddCustomerRebateFlyout"
import { UpdateCustomerRebateFlyout } from "./components/UpdateCustomerRebateFlyout"
import Page from "../Page/Page"
import { RSPCompanyPicker } from "../Pickers"
import { useToasts } from "../../hooks/useToasts"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import { useGetRebateAgreements, RebateAgreement, useDeleteRebateAgreement } from "../../services/Skunkworks/Generated"
import { WRITE_REBATE_AGREEMENT_PERMISSION } from "../../Const"
import { formatException } from "../../Utils"

export const CustomerRebateConsole: FC = () => {
    const [customerRebateAgreements, setCustomerRebateAgreements] = useState<RebateAgreement[]>([])
    const [selectedRebateAgreements, setSelectedRebateAgreements] = useState<RebateAgreement[]>([])
    const [companyId, setCompanyId] = useState<number | null>(null)
    const [startDate, setStartDate] = useState<Moment | undefined>(undefined)
    const [endDate, setEndDate] = useState<Moment | undefined>(undefined)
    const [openAgreement, setOpenAgreement] = useState<RebateAgreement | null>(null)
    const [addAgreementFlyoutOpen, setAddAgreementFlyoutOpen] = useState<boolean>(false)
    const [loadingAgreements, setLoadingAgreements] = useState<boolean>(false)
    const [deleteAgreementModalOpen, setDeleteAgreementModalOpen] = useState<boolean>(false)
    const [deletingAgreements, setDeletingAgreement] = useState<boolean>(false)

    const { permissions: userPermissions } = useAuthenticationDetails()
    const { addSuccessToast, addErrorToast } = useToasts()

    const { mutateAsync: deleteAgreement } = useDeleteRebateAgreement()
    const { refetch: getRebateAgreements, isRefetchError } = useGetRebateAgreements(
        {
            company_id: companyId,
            start_date: !!startDate ? moment.utc(startDate).startOf("day").toISOString(true) : undefined,
            end_date: !!endDate ? moment.utc(endDate).endOf("day").toISOString(true) : undefined,
        },
        {
            query: {
                enabled: false,
                onError: (e) => {
                    addErrorToast({
                        text: formatException(e),
                        toastLifeTimeMs: 10000,
                    })
                },
            },
        }
    )

    const handleDeleteAgreements = async () => {
        setDeletingAgreement(true)

        const deletePromises: Promise<void>[] = []
        selectedRebateAgreements.forEach((selectedAgreement) =>
            deletePromises.push(deleteAgreement({ rebateAgreementId: selectedAgreement.rebate_agreement_id }))
        )

        const results = await Promise.allSettled(deletePromises)
        let failedResults: PromiseRejectedResult[] = []

        results.forEach((result) => {
            if (result.status === "rejected") {
                failedResults.push(result)
            }
        })

        await handleGetRebateAgreements()

        if (failedResults.length > 0) {
            const failedIds = failedResults.map((r) => parseInt(r.reason.config.url.split("/").pop()))
            const errorMessage = "Failed to delete the following rebate agreements: " + failedIds.join(", ")

            const remainingRebateAgreements = selectedRebateAgreements.filter((agreement) =>
                failedIds.includes(agreement.rebate_agreement_id)
            )
            setSelectedRebateAgreements(remainingRebateAgreements)

            addErrorToast({
                text: errorMessage,
                toastLifeTimeMs: 10000,
            })
        } else {
            setSelectedRebateAgreements([])
            addSuccessToast({ text: `Rebate agreements successfully deleted.`, toastLifeTimeMs: 5000 })
        }
        setDeletingAgreement(false)
        setDeleteAgreementModalOpen(false)
    }

    const handleGetRebateAgreements = async () => {
        if (!companyId) {
            return
        }

        setLoadingAgreements(true)

        const { data: rebateAgreements } = await getRebateAgreements()
        if (rebateAgreements) {
            setCustomerRebateAgreements(rebateAgreements)
        }

        setLoadingAgreements(false)
    }

    const handleUpdate = () => {
        handleGetRebateAgreements()
        setOpenAgreement(null)
    }

    return (
        <Page title="Customer Rebate Agreements">
            <EuiFlexGroup responsive={false} alignItems="flexEnd" justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiFlexGroup responsive={false} gutterSize="m" alignItems="flexEnd" justifyContent="flexStart">
                        <EuiFlexItem grow={false} style={{ minWidth: "400px" }}>
                            <RSPCompanyPicker onIdSelected={(e) => setCompanyId(e)} isInvalid={!companyId} />
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow label="Rebate Period Range">
                                <EuiDatePickerRange
                                    startDateControl={
                                        <EuiDatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date ?? undefined)}
                                            aria-label="Start Date"
                                            placeholder="Start Date"
                                            startDate={startDate}
                                            endDate={endDate}
                                            maxDate={endDate}
                                            isInvalid={startDate && endDate && startDate > endDate}
                                            adjustDateOnChange={false}
                                            fullWidth
                                        />
                                    }
                                    endDateControl={
                                        <EuiDatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date ?? undefined)}
                                            aria-label="End Date"
                                            placeholder="End Date"
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            isInvalid={endDate && startDate && endDate < startDate}
                                            adjustDateOnChange={false}
                                            fullWidth
                                        />
                                    }
                                    fullWidth
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButton
                                iconType={SearchLineIcon}
                                onClick={handleGetRebateAgreements}
                                isDisabled={!companyId}
                                isLoading={loadingAgreements}
                            >
                                Search
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiFlexItem>
                {userPermissions.includes(WRITE_REBATE_AGREEMENT_PERMISSION) && (
                    <EuiFlexItem grow={false}>
                        <EuiFlexGroup responsive={false} gutterSize="xs" alignItems="center">
                            <EuiFlexItem grow={false}>
                                <EuiButton iconType={AddLineIcon} onClick={() => setAddAgreementFlyoutOpen(true)}>
                                    New Rebate Agreement
                                </EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton
                                    iconType={DeleteBinLineIcon}
                                    onClick={() => setDeleteAgreementModalOpen(true)}
                                    isDisabled={!selectedRebateAgreements.length}
                                    color="danger"
                                    isLoading={deletingAgreements}
                                >
                                    Delete Selected
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
            <EuiSpacer size="m" />
            <CustomerRebatesTable
                items={customerRebateAgreements}
                loading={loadingAgreements}
                error={isRefetchError}
                selectable
                onSelectionChange={setSelectedRebateAgreements}
                onRebateSelect={setOpenAgreement}
            />
            {addAgreementFlyoutOpen && (
                <AddCustomerRebateFlyout
                    onClose={() => setAddAgreementFlyoutOpen(false)}
                    onCreate={handleGetRebateAgreements}
                />
            )}
            {!!openAgreement && (
                <UpdateCustomerRebateFlyout
                    originalRebateAgreement={openAgreement}
                    onClose={() => setOpenAgreement(null)}
                    onUpdate={handleUpdate}
                />
            )}
            {deleteAgreementModalOpen && (
                <EuiConfirmModal
                    title="Delete Rebate Agreements"
                    onCancel={() => setDeleteAgreementModalOpen(false)}
                    onConfirm={handleDeleteAgreements}
                    cancelButtonText="No, don't delete agreements"
                    confirmButtonText="Yes, delete agreements"
                    buttonColor="danger"
                    defaultFocusedButton="confirm"
                    isLoading={deletingAgreements}
                >
                    <EuiText>Are you sure you want to delete the selected customer rebate agreements?</EuiText>
                </EuiConfirmModal>
            )}
        </Page>
    )
}

export default CustomerRebateConsole
