export const parseNumbersFromText = (text: string): number[] => {
    let data: number[] = []
    if (text.includes("\n")) {
        data = text.split("\n").map((n) => parseInt(n))
    } else if (text.includes(",")) {
        data = text.split(",").map((n) => parseInt(n))
    } else {
        data = [parseInt(text)]
    }
    return data.filter((d) => !!d)
}
