import axios from "axios"
import config from "./config"

export interface Store {
    id: number
    name: string
}

export interface PurchaseOrder {
    id: string
    purchaseOrderNumber: number
}

export interface StoreQuery {
    data: {
        inventoryStores: {
            edges: { node: Store }[]
        }
    }
}

export interface Vendor {
    id: number
    name: string
}

export interface VendorQuery {
    data: {
        vendors: {
            edges: { node: Store }[]
        }
    }
}

export interface CreatePurchaseOrderResult {
    data: {
        createPurchaseOrder: {
            purchaseOrder: PurchaseOrder | null
            success: boolean
            message: string
        }
    }
}

export const createPurchaseOrderMutation =
    () => `mutation CreatePurchaseOrder($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input) {
    purchaseOrder {
    purchaseOrderNumber
    id
    deliverToId
    storeId
    vendorId
    }
    success
    message
    }
}`

export const createPurchaseOrderVariables = (
    branchId: number,
    storeId: number,
    vendorId: number
) => ({
    input: {
        vendorId,
        storeId,
        deliverToId: branchId,
        requestingBranchId: branchId,
        reference: "Dummy Case PO",
        lineItems: [
            {
                itemId: "a1f96b33-58a4-4d29-b9da-9939227342a9",
                description: "Oil Filter",
                memo: "Dummy Line Item",
                quantity: 1,
                pricePerUnit: {
                    amount: "1.00",
                    currencyCode: "USD",
                },
            },
        ],
    },
})

export const storeQuery = (branchId: number) => `query {
    inventoryStores(branchIds: [${branchId}]) {
    edges {
    node {
    id
    name
    }
    }
    }
}`

export const vendorQuery = (search: string) => `query {
    vendors(search: "${search}") {
    edges {
    node {
    id
    name
    }
    }
    }
}`

export const COST_CAPTURE_AXIOS_INSTANCE = axios.create({
    baseURL: config.COST_CAPTURE_API_URL,
})

async function costCaptureGQLRequest(query: string, variables: Object = {}) {
    // don't need to pass in a url
    const response = await COST_CAPTURE_AXIOS_INSTANCE.request({
        method: "POST",
        data: {
            query,
            variables,
        },
        headers: {
            "Content-Type": "application/json",
        },
    })

    return response.data
}

export function createPurchaseOrder(
    branchId: number,
    storeId: number,
    vendorId: number
): Promise<CreatePurchaseOrderResult> {
    const mutation = createPurchaseOrderMutation()

    const variables = {
        input: {
            vendorId,
            storeId,
            deliverToId: branchId,
            requestingBranchId: branchId,
            reference: "Dummy Case PO",
            lineItems: [
                {
                    itemId: "a1f96b33-58a4-4d29-b9da-9939227342a9",
                    description: "Oil Filter",
                    memo: "Dummy Line Item",
                    quantity: 1,
                    pricePerUnit: {
                        amount: "1.00",
                        currencyCode: "USD",
                    },
                },
            ],
        },
    }

    return costCaptureGQLRequest(mutation, variables)
}

export async function getStores(branchId: number): Promise<StoreQuery> {
    const query = storeQuery(branchId)

    return costCaptureGQLRequest(query)
}

export async function getVendors(search: string): Promise<VendorQuery> {
    const query = vendorQuery(search)

    return costCaptureGQLRequest(query)
}
