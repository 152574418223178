import { useState } from "react"

import { EuiFieldText, EuiFormRow, EuiSpacer } from "@equipmentshare/ds2"
import PayoutModal from "./Modals/PayoutModal"

import AssignmentFields from "./Modals/ModalFields/AssignmentFields"
import ProgramFields from "./Modals/ModalFields/ProgramFields"
import ScheduleFields from "./Modals/ModalFields/ScheduleFields"

import { Tab } from "../PayoutProgramConsole"

import { useToasts } from "../../../hooks/useToasts"

import {
    CreateAssignmentRequest,
    CreateMasterAgreementRequest,
    CreatePayoutProgramRequest,
    CreateScheduleRequest,
    PayoutProgramBillingEnum,
    createAssignment,
    createPayoutProgramMasterAgreement,
    createPayoutProgram,
    createSchedule,
} from "../../../services/PayoutProgram/Generated"

import { CompanyPicker } from "../../../components/Pickers"

import { PromiseData } from "./HelperFunctions"

type CreateFunctions<T extends Tab> = T extends Tab.ASSIGNMENTS
    ? (
          req: CreateAssignmentRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.MASTERAGREEMENTS
    ? (
          req: CreateMasterAgreementRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.PROGRAMS
    ? (
          req: CreatePayoutProgramRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.SCHEDULES
    ? (req: CreateScheduleRequest, signal?: AbortSignal) => Promise<PromiseData>
    : null

type RenderCreateModalProps = {
    tabName: typeof Tab[keyof typeof Tab]
    closeModal: () => void
}

export default function RenderCreateModal(props: RenderCreateModalProps) {
    const { addErrorToast, addSuccessToast } = useToasts()
    const [inputError, setInputError] = useState(false)
    //request objects needed for creation
    // anything undefined is optional
    const [assignmentReq, setAssignmentReq] = useState<CreateAssignmentRequest>(
        {
            asset_id: 0,
            billing_type: PayoutProgramBillingEnum.DIRECT,
            end_date: undefined,
            payout_program_id: 0,
            replaced_by_asset_id: undefined,
            replaced_or_removed_reason: undefined,
            schedule_id: 0,
            start_date: "",
        }
    )

    const [masterAgreeReq, setMasterAgreeReq] =
        useState<CreateMasterAgreementRequest>({
            company_id: 0,
            master_agreement_no: "",
        })

    const [programReq, setProgramReq] = useState<CreatePayoutProgramRequest>({
        name: "",
        payout_program_type_id: 0,
    })

    const [scheduleReq, setScheduleReq] = useState<CreateScheduleRequest>({
        name: "",
        payout_percentage: 0.0,
        payout_program_id: 0,
        master_agreement_id: 0,
    })

    //verifies if any required field hasn't been set
    function verifyArgs(req: any) {
        for (const key in req) {
            if (req.hasOwnProperty(key)) {
                const val = req[key]
                if (val === 0 || val === "") {
                    return false
                }
            }
        }

        return true
    }

    //maps each tab to respective create method
    const createFunctions: { [K in Tab]: CreateFunctions<K> } = {
        [Tab.ASSIGNMENTS]: createAssignment,
        [Tab.MASTERAGREEMENTS]: createPayoutProgramMasterAgreement,
        [Tab.PROGRAMS]: createPayoutProgram,
        [Tab.SCHEDULES]: createSchedule,
        [Tab.ADMIN]: null,
    }

    async function processCreation(
        tab: Tab,
        req: any,
        successMessage: string,
        errorMessage?: string
    ) {
        const createFunc = createFunctions[tab]
        if (!createFunc) {
            return
        }
        if (!verifyArgs(req)) {
            setInputError(true)
            addErrorToast({
                text: "Please fill in all required fields ",
                toastLifeTimeMs: 5000,
            })
            return
        }

        try {
            await createFunc(req)
            addSuccessToast({ text: successMessage, toastLifeTimeMs: 10000 })
            props.closeModal()
            setInputError(false)
        } catch (e) {
            addErrorToast({
                text: errorMessage + e.message,
                toastLifeTimeMs: 10000,
            })
        }
    }

    async function handleCreate(
        tab: typeof Tab[keyof typeof Tab],
        req:
            | CreateAssignmentRequest
            | CreateMasterAgreementRequest
            | CreatePayoutProgramRequest
            | CreateScheduleRequest
    ) {
        const singTabName = tab.substring(0, tab.length - 1)
        await processCreation(tab, req, `${singTabName} successfully created`)
    }

    const assignmentBody = (
        <AssignmentFields
            assignmentReq={assignmentReq}
            setAssignmentReq={setAssignmentReq}
            hasErrors={inputError}
        />
    )

    const masterAgreeBody = (
        <>
            <CompanyPicker
                onIdSelected={(id) =>
                    id
                        ? setMasterAgreeReq({
                              ...masterAgreeReq,
                              company_id: id,
                          })
                        : setMasterAgreeReq({ ...masterAgreeReq })
                }
                hasErrors={inputError}
            />
            <EuiSpacer />
            <EuiFormRow label="Master Agreement Number" isInvalid={inputError}>
                <EuiFieldText
                    placeholder="Master Agreement Number"
                    onChange={(e) => {
                        setMasterAgreeReq({
                            ...masterAgreeReq,
                            master_agreement_no: e.target.value,
                        })
                    }}
                />
            </EuiFormRow>
        </>
    )

    const programBody = (
        <ProgramFields
            programReq={programReq}
            setProgramReq={setProgramReq}
            hasErrors={inputError}
        />
    )

    const scheduleBody = (
        <>
            <ScheduleFields
                scheduleReq={scheduleReq}
                setScheduleReq={setScheduleReq}
                hasErrors={inputError}
            />
            <EuiSpacer />
            <EuiFormRow
                label="Schedule Payout Percentage"
                isInvalid={inputError}
            >
                <EuiFieldText
                    placeholder="Enter Schedule Payout Percentage"
                    onChange={(e) =>
                        setScheduleReq({
                            ...scheduleReq,
                            payout_percentage: parseFloat(e.target.value),
                        })
                    }
                />
            </EuiFormRow>
        </>
    )

    switch (props.tabName) {
        case "Assignments":
            return (
                <PayoutModal
                    modalTitle={`Create ${props.tabName}`}
                    modalBody={assignmentBody}
                    onConfirm={() => handleCreate(props.tabName, assignmentReq)}
                    closeModal={props.closeModal}
                />
            )
        case "Master Agreements":
            return (
                <PayoutModal
                    modalTitle={`Create ${props.tabName}`}
                    modalBody={masterAgreeBody}
                    onConfirm={() =>
                        handleCreate(props.tabName, masterAgreeReq)
                    }
                    closeModal={props.closeModal}
                />
            )
        case "Programs":
            return (
                <PayoutModal
                    modalTitle={`Create ${props.tabName}`}
                    modalBody={programBody}
                    onConfirm={() => handleCreate(props.tabName, programReq)}
                    closeModal={props.closeModal}
                />
            )
        case "Schedules":
            return (
                <PayoutModal
                    modalTitle={`Create ${props.tabName}`}
                    modalBody={scheduleBody}
                    onConfirm={() => handleCreate(props.tabName, scheduleReq)}
                    closeModal={props.closeModal}
                />
            )
        default:
            return <></>
    }
}
