import { useEffect, useState, useRef } from "react"

import {
    EuiMarkdownFormat,
    EuiSpacer,
    EuiButton,
    EuiButtonGroup,
    EuiEmptyPrompt,
    EuiLink,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiConfirmModal,
    EuiAvatar,
    EuiTablePagination,
    EuiToolTip,
    EuiBasicTable,
    EuiTableSortingType,
    Criteria,
    EuiText,
    EuiContextMenuPanel,
    EuiContextMenuItem,
    EuiPopover,
    EuiIcon,
    EuiCallOut,
    EuiTitle,
    useGeneratedHtmlId,
} from "@equipmentshare/ds2"
import { ExternalLinkLineIcon, ArrowDownSLineIcon, AlertLineIcon, LifebuoyLineIcon } from "@equipmentshare/ds2-icons"

import { getAssetInfo } from "../../services/VehicleUsageTracker"
import {
    useGetTrips,
    useApproveTrips,
    useReportTrips,
    useGetQuarterlyCutoffDate,
    SuperTrip,
    SuperTripsPaginated,
    TripAndClassification,
} from "../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import Page from "../Page/Page"

import { useToasts } from "../../hooks/useToasts"
import ReportIssueComponent from "./ReportIssueComponent"
import moment from "moment-timezone"

export const dateTimeOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
}

export default function VehicleUsageTracker() {
    const { userId, userEmail, userName, accessToken } = useAuthenticationDetails()
    const [tripInfo, setTripInfo] = useState<SuperTripsPaginated | null>(null)
    const [confirming, setConfirming] = useState(false)
    const [classificationLookup, setClassificationLookup] = useState<{
        [key: string]: number
    }>({})
    const [updates, setUpdates] = useState<{ [key: string]: SuperTrip }>({})
    const [assetSelectValue, handleReportAssetSelection] = useState("")
    const [assetSelectOptions, setAssetSelectOptions] = useState([{ value: 0, text: "" }])
    const [assetOptionsLoading, setAssetOptionsLoading] = useState(false)
    const { addErrorToast, addSuccessToast, addWarningToast } = useToasts()
    const [loadingTableIndicator, setLoadingTableIndicator] = useState(false)
    const [tripLookup, setTripLookup] = useState<{ [key: string]: number[] }>({})
    const [reportIssueInfo, setReportIssueInfo] = useState("")
    const [isReportModalVisible, setIsReportModalVisible] = useState(false)
    const [limit, setLimit] = useState(10)
    const [pageIndex, setPageIndex] = useState(0)
    const [cursor, setCursor] = useState<number | null>(null)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(false)
    const [isDocMenuOpen, setDocMenuOpen] = useState(false)

    const [sortField, setSortField] = useState<keyof SuperTrip>("start_timestamp")
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")

    const [hauledTripDismissed, setHauledTripDismissed] = useState(false)

    const { super_trips = [], miles_info: { total_miles = 0, personal_miles = 0, business_miles = 0 } = {} } =
        tripInfo ?? {}

    const assetIds = super_trips.map((e) => e.asset_id)
    const distinctAssetIds = Array.from(new Set(assetIds))

    const { refetch: getTrips } = useGetTrips(
        {
            user_id: userId,
            cursor: cursor ?? 0,
            limit,
            desc,
        },
        { query: { enabled: false } }
    )
    const { mutateAsync: approveTrips } = useApproveTrips()
    const { mutateAsync: reportTrips } = useReportTrips()

    const { data: cutOffDateResponse } = useGetQuarterlyCutoffDate({ query: { enabled: !!accessToken } })

    // in EUI Tables, if you want to set the selection
    // programmatically you have to use a ref
    const tableRef = useRef()

    const docLinkMenuId = useGeneratedHtmlId({ prefix: "docLinkMenu" })

    const message = (
        <EuiEmptyPrompt
            title={<h3>No Trips </h3>}
            titleSize="xs"
            body="There are no trips associated with your user ID "
        />
    )

    const toggleButtons = [
        { id: "1", label: "Business" },
        { id: "2", label: "Personal" },
    ]

    const closeReportModal = () => {
        setIsReportModalVisible(false)
        setReportIssueInfo("")
    }

    const showModal = async () => {
        setIsReportModalVisible(true)
        setAssetOptionsLoading(true)

        if (distinctAssetIds.length > 1) {
            const assetInfoArray = []
            try {
                for (let i = 0; i < distinctAssetIds.length; i++) {
                    const assetInfo = await getAssetInfo(distinctAssetIds[i])

                    const assetInfoText1 = assetInfo["equipment_model"]
                        ? assetInfo["equipment_model"]["equipment_make"]["name"]
                        : assetInfo["name"]

                    const assetInfoText2 = assetInfo["equipment_model"] ? assetInfo["equipment_model"]["name"] : ""

                    assetInfo
                        ? assetInfoArray.push({
                              value: Number(assetInfo["tracker_id"]),
                              text: `${assetInfoText1} ${assetInfoText2}
                               - ${Number(assetInfo["tracker_id"])}`,
                          })
                        : assetInfoArray.push()
                }
                setAssetSelectOptions(assetInfoArray)
            } catch (error) {
                addErrorToast({ text: error.message })
            }
            setAssetOptionsLoading(false)
        }
    }

    const onReportModalValueChange = (value: any) => {
        setReportIssueInfo(value.target.value)
    }

    const reportTrackerProblem = async (assetId: number) => {
        const assetInfo = await getAssetInfo(assetId)
        if (assetInfo) {
            try {
                await reportTrips({
                    data: {
                        user_name: userName,
                        user_email: userEmail,
                        tracker_id: assetInfo["tracker_id"],
                        asset_id: assetId,
                        report_info: reportIssueInfo,
                    },
                })
            } catch (error) {
                addErrorToast({ text: error.message })
            }
        }
    }

    const handleReportTrackerSubmit = async () => {
        if (reportIssueInfo.length > 0) {
            try {
                if (distinctAssetIds.length === 1) {
                    reportTrackerProblem(distinctAssetIds[0])
                } else if (distinctAssetIds.length > 1) {
                    reportTrackerProblem(Number(assetSelectValue))
                } else {
                    await reportTrips({
                        data: {
                            user_name: userName,
                            user_email: userEmail,
                            tracker_id: 0,
                            asset_id: 0,
                            report_info: reportIssueInfo,
                        },
                    })
                }
                addSuccessToast({ text: "Report Submitted!" })
            } catch (error) {
                addErrorToast({ text: error.message })
            } finally {
                closeReportModal()
            }
        }
    }

    let modal

    if (isReportModalVisible) {
        modal = (
            <ReportIssueComponent
                onClose={closeReportModal}
                reportIssueInfo={reportIssueInfo}
                onReasonChange={onReportModalValueChange}
                distinctAssetIds={distinctAssetIds}
                assetSelectValue={assetSelectValue}
                assetSelectOptions={assetSelectOptions}
                onAssetSelect={handleReportAssetSelection}
                onSubmit={handleReportTrackerSubmit}
                isLoading={assetOptionsLoading}
            />
        )
    }

    async function getTripInfo() {
        if (userId !== 0) {
            setLoadingTableIndicator(true)
            const results = await getTrips()
            const tripData = results.data ?? null
            setTripInfo(tripData)
            setTotal(tripData?.total ?? 0)
            setUpdates({})
            setClassificationLookup({})
            setTripLookup({})
            setLoadingTableIndicator(false)
        }
    }

    useEffect(() => {
        setCursor(pageIndex * limit)
    }, [pageIndex, limit])

    useEffect(() => {
        setDesc(sortDirection === "desc")
    }, [sortDirection])

    useEffect(() => {
        getTripInfo()
        // eslint-disable-next-line
    }, [accessToken, userId, cursor, desc, limit])

    const tryForceSelection = (trips: SuperTrip[]) => {
        if (tableRef && tableRef.current) {
            ;(tableRef.current as any).setSelection(trips)
        }
    }

    // programmatically set the selection when the values inside `data` change
    useEffect(() => {
        tryForceSelection(super_trips.filter((trip) => trip.super_trip_id in updates))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updates, tripInfo])

    useEffect(() => {
        if (super_trips.length > 0) {
            const warningText =
                "Trips calculated as hauled will be marked with a warning symbol, this usually indicates an issue with the tracker device itself"

            const hauledTrips = super_trips.filter((e) => e.trip_type_id === 3)
            if (hauledTrips.length && !hauledTripDismissed) {
                addWarningToast({
                    title: "Hauled Trips Detected",
                    text: warningText,
                })
                setHauledTripDismissed(true)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tripInfo])

    const setLookupMaps = (superTrips: SuperTrip[]) => {
        setUpdates(
            superTrips.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.super_trip_id]: curr,
                }),
                {}
            )
        )
        setClassificationLookup(
            superTrips.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.super_trip_id]: classificationLookup[curr.super_trip_id] || curr.trip_classification_type_id,
                }),
                classificationLookup
            )
        )
        setTripLookup(
            superTrips.reduce(
                (prev, curr) => ({
                    ...prev,
                    [curr.super_trip_id]: tripLookup[curr.super_trip_id] || curr.trips,
                }),
                {}
            )
        )
    }

    const selectionValue = {
        selectable: () => true,
        selectableMessage: (selectable: any) => (!selectable ? "This information can't be selected" : ""),
        onSelectionChange: (rows: SuperTrip[]) => {
            // Since we have a `useEffect` that triggers selection changes,
            // we want to guard against an infinite update loop
            if (rows.length !== Object.keys(updates).length) {
                setLookupMaps(rows)
            }
        },
    }

    const handleApproveTrips = async () => {
        // building an array of TripAndClassification objects
        const tripClassifications = Object.entries(tripLookup).reduce<TripAndClassification[]>(
            (prev, [superTripId, tripIds]) => [
                ...prev,
                ...tripIds.map<TripAndClassification>((tripId) => ({
                    trip_id: tripId,
                    trip_classification_type_id: classificationLookup[superTripId],
                })),
            ],
            []
        )
        if (tripClassifications.length > 0) {
            try {
                await approveTrips({
                    data: {
                        approvals: tripClassifications,
                    },
                })
                addSuccessToast({ text: "Trips Approved!" })
                getTripInfo()
            } catch (error) {
                addErrorToast({ text: error.message })
            }
        } else {
            addErrorToast({ text: "Error: No trips were modified" })
        }
    }

    const rowActions = [
        {
            render: (tripData: SuperTrip) => {
                return (
                    <>
                        {tripData.trip_type_id === 3 && (
                            <EuiToolTip position="top" content={"Detected as a Hauled Trip!"}>
                                <EuiAvatar
                                    data-testid={`${tripData.super_trip_id}-hauled-icon`}
                                    size="s"
                                    name="small"
                                    iconType="alert"
                                    style={{ marginRight: 10 }}
                                />
                            </EuiToolTip>
                        )}
                        <EuiButtonGroup
                            legend="Business or Personal Buttons"
                            options={toggleButtons}
                            idSelected={`${
                                classificationLookup[tripData.super_trip_id] || tripData.trip_classification_type_id
                            }`}
                            onChange={(id: string) => {
                                setClassificationLookup({
                                    ...classificationLookup,
                                    [tripData.super_trip_id]: parseInt(id) as 1 | 2,
                                })
                            }}
                            color="primary"
                        />
                    </>
                )
            },
        },
    ]

    const columns = [
        {
            field: "start_timestamp",
            name: "Start Date",
            sortable: true,
            truncateText: true,
            render: (value: string) => new Date(value).toLocaleString(undefined, dateTimeOptions),
        },
        {
            field: "end_timestamp",
            name: "End Date",
            truncateText: true,
            render: (value: string) => new Date(value).toLocaleString(undefined, dateTimeOptions),
        },
        {
            field: "start_address_street",
            name: "Starting Address",
            render: (_: any, row: SuperTrip) => (
                <EuiLink
                    href={`https://www.google.com/maps/search/${row.start_address_street} ${row.start_address_city} ${row.start_address_state_abbreviation}`}
                    target="_blank"
                >
                    {row.start_address_street}
                </EuiLink>
            ),
            sortable: true,
            truncateText: true,
        },
        {
            field: "end_address_street",
            name: "Ending Address",
            render: (_: any, row: SuperTrip) => (
                <EuiLink
                    href={`https://www.google.com/maps/search/${row.end_address_street} ${row.end_address_city} ${row.end_address_state_abbreviation}`}
                    target="_blank"
                >
                    {row.end_address_street}
                </EuiLink>
            ),
            sortable: true,
            truncateText: true,
        },
        {
            field: "trip_distance_miles",
            name: "Total Miles",
            render: (miles: any) => `${miles.toFixed(2)}`,
            sortable: true,
        },
        {
            field: "trip_classification_type_id",
            name: "Taxability",
            actions: rowActions,
        },
    ]

    function changeSelectedTrips(type: number) {
        const newClassifications: { [key: string]: number } = {}
        for (const superTripId in updates) {
            newClassifications[superTripId] = type
        }
        setClassificationLookup({
            ...classificationLookup,
            ...newClassifications,
        })
    }

    function showQuarterlyDeadlineWarning() {
        if (!cutOffDateResponse) {
            return null
        }

        const now = moment()
        const hardCutOffDate = moment(cutOffDateResponse?.hard_quarter_deadline).tz("America/Chicago")

        const daysTillDeadline = hardCutOffDate.diff(now, "days")
        const durationUntilDeadline = moment.duration(hardCutOffDate.diff(now))

        const days = durationUntilDeadline.get("days")
        const hours = durationUntilDeadline.get("hours")
        const minutes = durationUntilDeadline.get("minutes")
        const cutOffDateText = hardCutOffDate.format("MMMM Do YYYY")

        let deadlineComponent: React.ReactNode = (
            <EuiTitle size="xs" data-testid="weekly-review-message">
                <h3>All trips should be reviewed and approved on a weekly basis</h3>
            </EuiTitle>
        )

        const getUnitDisplay = (value: number, unit: string) => (value === 1 ? `${value} ${unit}` : `${value} ${unit}s`)

        if (durationUntilDeadline.asMilliseconds() >= 0) {
            if (daysTillDeadline < 1) {
                const bannerText =
                    `${getUnitDisplay(hours, "hour")} and ${getUnitDisplay(minutes, "minute")}` +
                    ` until quarterly submission deadline on ${cutOffDateText}`

                deadlineComponent = (
                    <EuiCallOut
                        title={bannerText}
                        size="s"
                        color="danger"
                        iconType={AlertLineIcon}
                        data-testid="review-danger-callout"
                    />
                )
            } else if (daysTillDeadline < 10) {
                const bannerText =
                    `${getUnitDisplay(days, "day")}, ${getUnitDisplay(hours, "hour")},` +
                    ` and ${getUnitDisplay(minutes, "minute")} until quarterly submission deadline on ${cutOffDateText}`

                deadlineComponent = (
                    <EuiCallOut
                        title={bannerText}
                        size="s"
                        color="warning"
                        iconType={LifebuoyLineIcon}
                        data-testid="review-warning-callout"
                    />
                )
            }
        }

        return (
            <>
                {deadlineComponent}
                <EuiSpacer />
            </>
        )
    }

    function renderConfirmModal() {
        let numBusiness = 0
        let numPersonal = 0
        for (const superTrip of Object.values(updates)) {
            const type = classificationLookup[superTrip.super_trip_id] || superTrip.trip_classification_type_id
            if (type === 1) {
                numBusiness += 1
            } else {
                numPersonal += 1
            }
        }

        return (
            <EuiConfirmModal
                title="Confirm Approval"
                onCancel={() => setConfirming(false)}
                onConfirm={() => {
                    setConfirming(false)
                    handleApproveTrips()
                }}
                cancelButtonText="No, don't do it"
                confirmButtonText="Yes, do it"
                buttonColor="primary"
                defaultFocusedButton="confirm"
            >
                <p>
                    Approving {numBusiness + numPersonal} trips: {numBusiness} as Business and {numPersonal} as Personal
                </p>
            </EuiConfirmModal>
        )
    }

    const totalMilesText = `Total Miles: \`${total_miles.toFixed(2)}\``
    const personalMilesText = `Personal Miles: \`${personal_miles.toFixed(2)}\``
    const businessMilesText = `Business Miles: \`${business_miles.toFixed(2)}\``

    const onTableChange = ({ sort }: Criteria<SuperTrip>) => {
        if (sort) {
            setSortField(sort.field)
            setSortDirection(sort.direction)
        }
    }

    const sortingOption: EuiTableSortingType<SuperTrip> = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    }

    const renderDocLinkMenu = () => {
        const button = (
            <EuiButtonEmpty
                size="s"
                iconType={ArrowDownSLineIcon}
                iconSide="right"
                onClick={() => setDocMenuOpen(!isDocMenuOpen)}
                color="primary"
                contentProps={{ style: { alignItems: "flex-end" } }}
            >
                Learn more
            </EuiButtonEmpty>
        )

        const menuItems = [
            <EuiContextMenuItem
                key="usageGuide"
                href="https://drive.google.com/file/d/163nTsXjPjxX3PT6ez7OKrlEKj35GTpbV/view"
                target="blank"
            >
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiText size="s">Usage Tool Reference Guide</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiIcon type={ExternalLinkLineIcon} />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="faq"
                href="https://drive.google.com/file/d/1Hg3oYvsFZseSdQVco2dFoPuqBCFe-E25/view"
                target="blank"
            >
                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                    <EuiFlexItem grow={false}>
                        <EuiText size="s">Taxability FAQs</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiIcon type={ExternalLinkLineIcon} />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiContextMenuItem>,
        ]

        return (
            <EuiPopover
                id={docLinkMenuId}
                button={button}
                isOpen={isDocMenuOpen}
                closePopover={() => setDocMenuOpen(false)}
                panelPaddingSize="none"
                anchorPosition="downLeft"
            >
                <EuiContextMenuPanel size="s" items={menuItems} />
            </EuiPopover>
        )
    }

    return (
        <Page
            title={
                <EuiFlexGroup gutterSize="s">
                    <EuiFlexItem grow={false}>Vehicle Usage Tracker</EuiFlexItem>
                    <EuiFlexItem grow={false}>{renderDocLinkMenu()}</EuiFlexItem>
                </EuiFlexGroup>
            }
        >
            {confirming && renderConfirmModal()}

            {showQuarterlyDeadlineWarning()}

            <EuiMarkdownFormat>{`${totalMilesText} ${personalMilesText} ${businessMilesText}`}</EuiMarkdownFormat>
            <EuiSpacer />
            <EuiButton onClick={showModal} style={{ float: "right" }}>
                Report Issues
            </EuiButton>
            {modal}
            {super_trips.length > 0 && (
                <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                        <EuiButton onClick={() => setConfirming(true)} isDisabled={Object.keys(updates).length === 0}>
                            Approve Selected
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            onClick={() => changeSelectedTrips(1)}
                            isDisabled={Object.keys(updates).length === 0}
                        >
                            Change Selected to Business
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            onClick={() => changeSelectedTrips(2)}
                            isDisabled={Object.keys(updates).length === 0}
                        >
                            Change Selected to Personal
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
            )}

            <EuiSpacer />

            <EuiBasicTable<SuperTrip>
                ref={tableRef as any}
                itemId={"super_trip_id"}
                items={super_trips}
                noItemsMessage={message}
                loading={loadingTableIndicator}
                hasActions={true}
                columns={columns}
                selection={selectionValue}
                isSelectable={true}
                sorting={sortingOption}
                onChange={onTableChange}
            />
            <EuiSpacer size="m" />
            <EuiTablePagination
                itemsPerPage={limit}
                itemsPerPageOptions={[10, 25, 50, 100]}
                onChangeItemsPerPage={setLimit}
                onChangePage={(pageIndex) => setPageIndex(pageIndex)}
                pageCount={Math.ceil(total / limit)}
                activePage={pageIndex}
            />

            <EuiSpacer />

            <EuiMarkdownFormat>{`Notes on default classification: Vehicle mileage beginning or ending within one mile of your personal residence is deemed personal by default and subject to modification.`}</EuiMarkdownFormat>

            <EuiSpacer />

            <EuiMarkdownFormat>{`Mileage incurred on a Saturday or Sunday is assumed to be personal, but is subject to modification.`}</EuiMarkdownFormat>

            <EuiSpacer />

            <EuiMarkdownFormat>{`Please report any changes in:`}</EuiMarkdownFormat>
            <EuiMarkdownFormat>{`- **personal residence** to \`payroll@equipmentshare.com\` `}</EuiMarkdownFormat>
            <EuiMarkdownFormat>{`- **business vehicles** to \`vehicle-help@equipmentshare.com\` `}</EuiMarkdownFormat>
        </Page>
    )
}
