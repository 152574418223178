import { useState } from "react"

import {
    EuiBasicTable,
    EuiButton,
    EuiButtonEmpty,
    EuiConfirmModal,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
    EuiText,
} from "@equipmentshare/ds2"

import Page from "../Page/Page"
import { CompanyPicker } from "../Pickers"
import { getProviderById } from "../../services/Discounts"
import {
    Discount,
    UpdateDiscountRequest,
    CreateDiscountRequest,
    useGetDiscounts,
    useDeleteDiscount,
    useCreateDiscount,
    useUpdateDiscount,
} from "../../services/Skunkworks/Generated"
import { useToasts } from "../../hooks/useToasts"
import { DiscountFlyout } from "./DiscountFlyout"

export const COST_BASED = 1
export const LIST_BASED = 2

export function formatDiscount(discountTypeId: number, modifier: number) {
    const text = discountTypeId === COST_BASED ? "above cost" : "below list"
    const percent =
        discountTypeId === COST_BASED
            ? `${Math.round(modifier * 100) - 100}`
            : `${100 - Math.round(modifier * 100)}`
    return `${percent}% ${text} price`
}

export function Discounts() {
    const [companyId, setCompanyId] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [discounts, setDiscounts] = useState<Discount[] | null>(null)
    const [providers, setProviders] = useState<{ [key: number]: string }>({})
    const [isDeleting, setIsDeleting] = useState(false)
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)
    const [selectedDiscount, setSelectedDiscount] = useState<Discount | null>(
        null
    )
    const [discountToDelete, setDiscountToDelete] = useState<Discount | null>(
        null
    )
    const { addSuccessToast, addErrorToast } = useToasts()

    const { refetch: getDiscounts } = useGetDiscounts(
        { company_id: companyId! },
        { query: { enabled: false } }
    )
    const { mutateAsync: createDiscount } = useCreateDiscount()
    const { mutateAsync: deleteDiscount } = useDeleteDiscount()
    const { mutateAsync: updateDiscount } = useUpdateDiscount()

    async function handleGetDiscounts() {
        if (!companyId) return null

        try {
            setIsLoading(true)
            const { data: discounts } = await getDiscounts()
            if (discounts) {
                setDiscounts(discounts)
                loadProviders(
                    Array.from(new Set(discounts.map((d) => d.provider_id)))
                )
            }
        } catch (e: any) {
            addErrorToast({ text: e.message })
        } finally {
            setIsLoading(false)
        }
    }

    async function loadProviders(providerIds: number[]) {
        Promise.all(providerIds.map((id) => getProviderById(id))).then(
            (data) => {
                setProviders(
                    data.reduce(
                        (prev, curr) => ({
                            ...prev,
                            [curr.providerId]: curr.name,
                        }),
                        {}
                    )
                )
            }
        )
    }

    const actions = [
        {
            render: (discount: Discount) => (
                <EuiButtonEmpty
                    onClick={() => {
                        setIsFlyoutOpen(true)
                        setSelectedDiscount(discount)
                    }}
                >
                    Update
                </EuiButtonEmpty>
            ),
        },
        {
            render: (discount: Discount) => {
                const disable =
                    isDeleting &&
                    discount.discount_id === discountToDelete?.discount_id
                return (
                    <EuiButtonEmpty
                        onClick={() => setDiscountToDelete(discount)}
                        color="danger"
                        disabled={disable}
                        isLoading={disable}
                    >
                        Delete
                    </EuiButtonEmpty>
                )
            },
        },
    ]

    const columns = [
        {
            field: "discount_id",
            name: "Discount Id",
        },
        {
            field: "provider_id",
            name: "Provider",
            render: (providerId: number) => providers[providerId] || providerId,
        },
        {
            render: (discount: Discount) =>
                formatDiscount(
                    discount.discount_type_id,
                    Number(discount.modifier)
                ),
            name: "Discount",
        },
        {
            field: "date_created",
            name: "Date",
            render: (date: string) => new Date(date).toLocaleString(),
        },
        {
            name: "Actions",
            actions,
        },
    ]

    function renderDiscounts() {
        if (!discounts) return null

        if (discounts.length) {
            return (
                <EuiBasicTable<Discount>
                    items={discounts}
                    itemId="discount_id"
                    columns={columns}
                />
            )
        }

        if (isLoading) {
            return <div>Loading...</div>
        }

        return (
            <EuiText>
                <p>Could not find discounts.</p>
            </EuiText>
        )
    }

    async function handleDeleteDiscount() {
        if (!discountToDelete) return null

        setIsDeleting(true)
        setDiscountToDelete(null)
        try {
            await deleteDiscount({ discountId: discountToDelete.discount_id })
            addSuccessToast({ text: "Discount Deleted!" })
            handleGetDiscounts()
        } catch (e: any) {
            addErrorToast({ text: e.message })
        } finally {
            setIsDeleting(false)
        }
    }

    function confirmDeleteModal() {
        if (!discountToDelete) return null

        const { discount_id, date_created, discount_type_id, modifier } =
            discountToDelete
        const date = new Date(date_created).toLocaleString()
        const summary = formatDiscount(discount_type_id, Number(modifier))
        return (
            <EuiConfirmModal
                title="Delete Discount"
                onCancel={() => setDiscountToDelete(null)}
                onConfirm={handleDeleteDiscount}
                cancelButtonText="No, don't do it"
                confirmButtonText="Yes, delete it"
                buttonColor="danger"
                defaultFocusedButton="confirm"
            >
                <p>
                    Are you sure you want to delete the discount
                    {discount_id} ({summary}) created on {date}?
                </p>
            </EuiConfirmModal>
        )
    }

    function renderFlyout() {
        if (!isFlyoutOpen) return null

        const closeFlyout = () => {
            setIsFlyoutOpen(false)
            setSelectedDiscount(null)
        }

        const initialValues = selectedDiscount || { company_id: companyId! }
        const createFn = (data: CreateDiscountRequest) =>
            createDiscount({ data })
        const updateFn = (data: UpdateDiscountRequest) =>
            updateDiscount({ discountId: selectedDiscount!.discount_id, data })

        return (
            <DiscountFlyout
                onClose={closeFlyout}
                onSaved={() => {
                    closeFlyout()
                    addSuccessToast({ text: "Discount Created/Updated" })
                    handleGetDiscounts()
                }}
                initialValues={initialValues}
                updateFn={updateFn}
                createFn={createFn}
            />
        )
    }

    return (
        <Page title="Discount Management">
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false} style={{ minWidth: "300px" }}>
                    <CompanyPicker
                        onIdSelected={setCompanyId}
                        onCleared={() => setCompanyId(null)}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="ㅤ">
                        <EuiButton
                            disabled={isLoading || !companyId}
                            isLoading={isLoading}
                            onClick={handleGetDiscounts}
                        >
                            Get Discounts
                        </EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="l" />
            <EuiButton
                onClick={() => setIsFlyoutOpen(true)}
                disabled={!companyId}
            >
                Add Discount
            </EuiButton>

            <EuiSpacer size="m" />

            {renderDiscounts()}
            {renderFlyout()}
            {confirmDeleteModal()}
        </Page>
    )
}
