import React from "react"

import { useToasts } from "../../hooks/useToasts"
import { DiscountForm } from "./DiscountForm"
import {
    Discount,
    UpdateDiscountRequest,
    CreateDiscountRequest,
} from "../../services/Skunkworks/Generated"

function isCreate(
    values: UpdateDiscountRequest | CreateDiscountRequest
): values is CreateDiscountRequest {
    return !!(
        values.company_id !== undefined &&
        values.discount_type_id !== undefined &&
        values.modifier !== undefined &&
        values.provider_id !== undefined
    )
}

interface DiscountFlyoutProps {
    onClose: () => void
    onSaved: () => void
    initialValues: Discount | { company_id: number }
    updateFn: (data: UpdateDiscountRequest) => Promise<Discount>
    createFn: (data: CreateDiscountRequest) => Promise<Discount>
}

export function DiscountFlyout(props: DiscountFlyoutProps) {
    const [isSaving, setIsSaving] = React.useState(false)
    const { addErrorToast } = useToasts()

    async function handleSave(
        values: UpdateDiscountRequest | CreateDiscountRequest
    ) {
        try {
            setIsSaving(true)
            if (isCreate(values)) {
                await props.createFn(values)
            } else {
                await props.updateFn(values)
            }
            setIsSaving(false)
            props.onSaved()
        } catch (e) {
            setIsSaving(false)
            addErrorToast({
                text: e.message,
                title: "Error updating discount",
            })
        }
    }

    return (
        <DiscountForm
            onCloseClicked={props.onClose}
            onSavedClicked={handleSave}
            initialValues={props.initialValues}
            isSaving={isSaving}
        />
    )
}
