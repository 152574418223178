import { useQueries } from "react-query"

import { mapListsByIndex } from "../Utils"
import useEsApiService from "./useEsApiService"

function useBatchClassNames(classIds: number[]) {
    const esApiService = useEsApiService()
    const queries = useQueries(
        classIds.map((classId) => ({
            queryKey: ["classIdToName", classId],
            queryFn: () => esApiService.getClassNameById(classId),
        }))
    )
    return mapListsByIndex(classIds, queries)
}

export default useBatchClassNames
