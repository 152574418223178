import React from "react"

import {
    EuiButton,
    EuiTextArea,
    EuiSpacer,
    EuiText,
    EuiCheckbox,
    EuiTitle,
} from "@equipmentshare/ds2"

import { Job } from "./Job"
import { updateAssetCompanyOwner } from "../../services/Misc"
import CenteredContent from "../CenteredContent"
import { CompanyPicker } from "../Pickers"
import { parseNumbersFromText } from "./Utils"

export interface AssetCompanyUpdateJobProposal {
    assetIds: number[]
    companyId: number
    removeFromFleet: boolean
}

const getDescription = (
    proposal: AssetCompanyUpdateJobProposal,
    assetId: number
): string => {
    const base = `Updating Asset ID #${assetId} to Company ID #${proposal.companyId}`
    const extra = "also removing it from fleet"
    return proposal.removeFromFleet ? `${base} ${extra}` : base
}

const AssetCompanyOwnerUpdateJob: Job<AssetCompanyUpdateJobProposal> = {
    options: process.env.NODE_ENV === "test" ? {} : { rateLimit: 3 },
    gen: (proposal) =>
        proposal.assetIds.map((assetId) => ({
            description: getDescription(proposal, assetId),
            fn: () =>
                updateAssetCompanyOwner(assetId, {
                    companyId: proposal.companyId,
                    removeFromRentalFleet: proposal.removeFromFleet,
                }),
        })),
    Configure: (props) => {
        const [text, setText] = React.useState("")
        const [removeFromFleet, setRemoveFromFleet] = React.useState(false)
        const [companyId, setCompanyId] = React.useState<number | null>(null)

        const handleSubmit = () => {
            if (!companyId) {
                alert("Need to choose a company.")
                return
            }
            const parsedAssetIds = parseNumbersFromText(text)
            if (!parsedAssetIds || !parsedAssetIds.every((n) => !!n)) {
                alert(
                    "Enter valid asset IDs. Separate with a new line or commas."
                )
                return
            }

            props.onSubmit({
                assetIds: parsedAssetIds,
                companyId: companyId,
                removeFromFleet,
            })
        }

        return (
            <>
                <EuiTitle size="m">
                    <h2>Change Asset Company Owner</h2>
                </EuiTitle>
                <EuiSpacer size="m" />
                <CompanyPicker onIdSelected={setCompanyId} />
                <EuiSpacer size="m" />
                <EuiCheckbox
                    id="remove-from-fleet-checkbox"
                    label="Remove from Rental Fleet"
                    checked={removeFromFleet}
                    onChange={() => setRemoveFromFleet(!removeFromFleet)}
                />
                <EuiSpacer size="m" />
                <EuiTextArea
                    fullWidth={true}
                    placeholder="Enter asset IDs separated by comma or new line"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <EuiSpacer size="m" />
                <EuiButton isDisabled={!text} onClick={handleSubmit}>
                    Next
                </EuiButton>
            </>
        )
    },
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    You are planning on changing the company id to{" "}
                    {props.proposal.companyId} for{" "}
                    {props.proposal.assetIds.length} assets.
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

export default AssetCompanyOwnerUpdateJob
