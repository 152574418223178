import { useState } from "react"

import PayoutModal from "./Modals/PayoutModal"

import {
    PayoutProgramPicker,
    PayoutAssignmentPicker,
    MasterAgreementPicker,
    PayoutSchedulePicker,
} from "../../../components/Pickers"

import { Tab } from "../PayoutProgramConsole"

import { useToasts } from "../../../hooks/useToasts"

import {
    deleteAssignment,
    deletePayoutProgramMasterAgreement,
    deletePayoutProgram,
    deleteSchedule,
} from "../../../services/PayoutProgram/Generated"

type DeleteFunction = (req: number) => Promise<void>

type RenderDeleteModalProps = {
    tabName: typeof Tab[keyof typeof Tab]
    closeModal: () => void
}

export default function RenderDeleteModal(props: RenderDeleteModalProps) {
    const [id, setId] = useState<number | null>(0)
    const { addErrorToast, addSuccessToast } = useToasts()

    const assignmentBody = (
        <PayoutAssignmentPicker onIdSelected={(e) => setId(e)} />
    )

    const masterAgreeBody = (
        <MasterAgreementPicker onIdSelected={(e) => setId(e)} />
    )

    const programBody = <PayoutProgramPicker onIdSelected={(e) => setId(e)} />

    const scheduleBody = <PayoutSchedulePicker onIdSelected={(e) => setId(e)} />

    const deleteFunctions: Record<
        typeof Tab[keyof typeof Tab],
        DeleteFunction | null
    > = {
        [Tab.ASSIGNMENTS]: deleteAssignment,
        [Tab.MASTERAGREEMENTS]: deletePayoutProgramMasterAgreement,
        [Tab.PROGRAMS]: deletePayoutProgram,
        [Tab.SCHEDULES]: deleteSchedule,
        [Tab.ADMIN]: null,
    }

    //handles deletion of element
    async function handleConfirm(id: number | null) {
        const deleteFunc = deleteFunctions[props.tabName]
        if (!deleteFunc) {
            return
        }
        if (!id) {
            addErrorToast({
                text: "Please input an ID",
                toastLifeTimeMs: 10000,
            })
            return
        }
        const singularTabName = props.tabName.substring(
            0,
            props.tabName.length - 1
        )
        try {
            await deleteFunc(id)
            addSuccessToast({
                text: `${singularTabName} successfully deleted `,
                toastLifeTimeMs: 10000,
            })

            props.closeModal()
        } catch (e) {
            addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
        }
    }

    switch (props.tabName) {
        case "Assignments":
            return (
                <PayoutModal
                    modalTitle={`Delete ${props.tabName}`}
                    modalBody={assignmentBody}
                    onConfirm={() => handleConfirm(id)}
                    closeModal={props.closeModal}
                />
            )
        case "Master Agreements":
            return (
                <PayoutModal
                    modalTitle={`Delete ${props.tabName}`}
                    modalBody={masterAgreeBody}
                    onConfirm={() => handleConfirm(id)}
                    closeModal={props.closeModal}
                />
            )
        case "Programs":
            return (
                <PayoutModal
                    modalTitle={`Delete ${props.tabName}`}
                    modalBody={programBody}
                    onConfirm={() => handleConfirm(id)}
                    closeModal={props.closeModal}
                />
            )
        case "Schedules":
            return (
                <PayoutModal
                    modalTitle={`Delete ${props.tabName}`}
                    modalBody={scheduleBody}
                    onConfirm={() => handleConfirm(id)}
                    closeModal={props.closeModal}
                />
            )

        default:
            return <></>
    }
}
