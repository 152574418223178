import { QueryClient, QueryClientProvider } from "react-query"

export const queryClient = new QueryClient()

function ReactQueryProvider({ children }: any) {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

export default ReactQueryProvider
