import { useLocation, useNavigate } from "react-router-dom"
import { EuiHeaderLink, EuiFlexItem } from "@equipmentshare/ds2"
import styled from "styled-components"

type MenuItemProps = {
    title: string
    path: string
    isMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const StyledEuiHeaderLink = styled(EuiHeaderLink)`
    width: 100%;
    height: 25px;

    .euiButtonEmpty__content {
        text-align: left !important;
        display: block;
    }
`

export default function MenuItem(props: MenuItemProps) {
    const location = useLocation()
    const navigate = useNavigate()

    function handleClick() {
        navigate(props.path)
        props.isMenuOpen(false)
    }

    return (
        <EuiFlexItem grow={false}>
            <StyledEuiHeaderLink
                onClick={handleClick}
                isActive={location.pathname === props.path}
                size="s"
            >
                {props.title}
            </StyledEuiHeaderLink>
        </EuiFlexItem>
    )
}
