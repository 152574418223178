import { useState } from "react"
import {
    EuiBasicTable,
    EuiButton,
    EuiLink,
    EuiModal,
    EuiModalBody,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiSpacer,
} from "@equipmentshare/ds2"

import {
    CompetitorClass,
    CompetitorQuote,
    CompetitorQuoteDetail,
    ESQuote,
    useGetEsQuotes,
} from "../../services/Skunkworks/Generated"
import { usdFormatter } from "../../Utils"
import useAllCategoryNames from "../../hooks/useAllCategoryNames"
import useBatchClassNames from "../../hooks/useBatchClassNames"
import useBatchClassRates from "../../hooks/useBatchClassRates"
import config from "../../services/config"
import CreateESQuoteInput from "./CreateESQuoteInput"

interface CompetitorQuoteDetailsModalProps {
    quote: CompetitorQuote
    classIds: number[]
    onClose: () => void
}

function CompetitorQuoteDetailsModal(props: CompetitorQuoteDetailsModalProps) {
    const { quote, classIds, onClose } = props
    const categoryIdToName = useAllCategoryNames()
    const classIdToName = useBatchClassNames(classIds)
    const classIdToRate = useBatchClassRates(quote.market_id!, classIds)
    const renderUsd = { render: usdFormatter.format }
    const [makeESQuote, setMakeESQuote] = useState(false)
    const { data: esQuotes, refetch: getESQuotes } = useGetEsQuotes(
        quote.competitor_quote_id,
        {
            query: {
                enabled: false,
            },
        }
    )

    const handleClose = () => {
        setMakeESQuote(false)
        onClose()
    }

    const activeItemCols = [
        {
            field: "competitor_class",
            name: "Competitor Class",
            render: ({
                description,
                es_category_id,
                es_class_id,
            }: CompetitorClass) => {
                const cat = `ES Category: ${categoryIdToName.data?.[es_category_id]}`
                const cls = es_class_id
                    ? `ES Class: ${classIdToName[es_class_id].data}`
                    : "ES Class: N/A"
                return (
                    <>
                        {description}
                        <br />
                        {cat}
                        <br />
                        {cls}
                    </>
                )
            },
        },
        { field: "quantity", name: "Quantity" },
        { field: "price_per_day", name: "Price Per Day", ...renderUsd },
        { field: "price_per_week", name: "Price Per Week", ...renderUsd },
        { field: "price_per_month", name: "Price Per Month", ...renderUsd },
    ]

    const esQuotesCols = [
        {
            field: "quote",
            name: "ES Quote",
            render: (quote: string) => (
                <EuiLink href={`${config.QUOTES_URL}/${quote}`} target="_blank">
                    Link
                </EuiLink>
            ),
        },
        {
            field: "date_created",
            name: "Date Created",
            render: (date_created: string) =>
                new Date(date_created).toLocaleDateString(),
        },
    ]

    return (
        <EuiModal onClose={handleClose}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Details</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
                <EuiBasicTable<CompetitorQuoteDetail>
                    tableLayout="auto"
                    items={quote.details}
                    itemId="competitor_quote_detail_id"
                    columns={activeItemCols}
                />
                {quote.doc_type_id > 1 && (
                    <>
                        <EuiSpacer size="m" />
                        <EuiButton
                            onClick={() => {
                                setMakeESQuote(!makeESQuote)
                            }}
                        >
                            Make ES Quote
                        </EuiButton>
                        {makeESQuote && (
                            <>
                                <EuiSpacer size="m" />
                                <CreateESQuoteInput
                                    competitorQuote={quote}
                                    classIdToName={classIdToName}
                                    classIdToRate={classIdToRate}
                                    onClose={() => {
                                        setMakeESQuote(false)
                                    }}
                                    onCreated={() => {
                                        getESQuotes()
                                    }}
                                />
                            </>
                        )}
                        <EuiSpacer size="m" />
                        <EuiBasicTable<ESQuote>
                            tableLayout="auto"
                            items={esQuotes || quote.es_quotes}
                            itemId="es_quote_id"
                            columns={esQuotesCols}
                        />
                    </>
                )}
            </EuiModalBody>
        </EuiModal>
    )
}

export default CompetitorQuoteDetailsModal
