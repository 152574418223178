import { useState } from "react"

import { EuiFlexGroup, EuiFlexItem } from "@equipmentshare/ds2"

import {
    useGetServiceManualsAssetStatus,
    GetServiceManualsAssetStatusParams,
} from "../../../services/Skunkworks/Generated"
import { DocumentStatusSearchForm, SearchCriteria } from "./DocumentStatusSearchForm"
import { DocumentStatusTable, PAGE_SIZE_OPTIONS, PageCriteria } from "./DocumentStatusTable"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"

const INITIAL_SEARCH_CRITERIA = {
    equipment_class_name: null,
    equipment_make_name: null,
    equipment_model_name: null,
    has_operators_manual: null,
    has_parts_manual: null,
    has_service_manual: null,
    has_warranty_manual: null,
    has_other_manual: null,
}

const INITIAL_PAGE_CRITERIA: PageCriteria = {
    pageSize: PAGE_SIZE_OPTIONS[0],
    pageIndex: 0,
    sortField: "asset_count",
    sortDirection: "desc",
}

export const DocumentStatus = () => {
    const { addErrorToast } = useToasts()
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(INITIAL_SEARCH_CRITERIA)
    const [pageCriteria, setPageCriteria] = useState<PageCriteria>(INITIAL_PAGE_CRITERIA)

    const searchParams: GetServiceManualsAssetStatusParams = {
        ...searchCriteria,
        sort_by: pageCriteria.sortField,
        sort_order: pageCriteria.sortDirection,
        limit: pageCriteria.pageSize,
        offset: pageCriteria.pageIndex * pageCriteria.pageSize,
    }

    const {
        data: getServiceManualsAssetStatusResponse = { service_manuals_asset_status: [], total_count: 0 },
        isLoading,
    } = useGetServiceManualsAssetStatus(searchParams, {
        query: {
            refetchOnWindowFocus: false,
            onError: (e) => addErrorToast({ text: formatException(e) }),
        },
    })

    const handleSearch = (newSearchCriteria: SearchCriteria) => {
        setPageCriteria({ ...pageCriteria, pageIndex: 0 })
        setSearchCriteria(newSearchCriteria)
    }

    return (
        <>
            <EuiFlexGroup direction="column">
                <EuiFlexItem>
                    <DocumentStatusSearchForm
                        onSearch={handleSearch}
                        initialSearchCriteria={INITIAL_SEARCH_CRITERIA}
                        isLoading={isLoading}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <DocumentStatusTable
                        documentStatuses={getServiceManualsAssetStatusResponse.service_manuals_asset_status ?? []}
                        totalDocumentStatusesCount={getServiceManualsAssetStatusResponse.total_count}
                        pageCriteria={pageCriteria}
                        onPageCriteriaChange={setPageCriteria}
                        isLoading={isLoading}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}
