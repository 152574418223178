import React, { useState } from "react"

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiSpacer,
} from "@equipmentshare/ds2"

import {
    BranchPicker,
    CompanyPicker,
    CompetitorPicker,
    SalespersonPicker,
} from "../Pickers"
import Page from "../Page/Page"
import CompetitorQuotesTable from "./CompetitorQuotesTable"
import { useQueryCompetitorQuotes } from "../../services/Skunkworks/Generated"
import CreateCompetitorQuoteFlyout from "./CreateCompetitorQuoteFlyout"

function CompetitorQuotes() {
    const [enabled, setEnabled] = useState(false)
    const [createFlyoutOpen, setCreateFlyoutOpen] = useState(false)
    const [competitorId, setCompetitorId] = useState<number | null>(null)
    const [salespersonId, setSalespersonId] = useState<number | null>(null)
    const [marketId, setMarketId] = useState<number | null>(null)
    const [companyId, setCompanyId] = useState<number | null>(null)

    const { data, refetch, isLoading } = useQueryCompetitorQuotes(
        {
            competitor_id: competitorId || undefined,
            salesperson_id: salespersonId || undefined,
            market_id: marketId || undefined,
            company_id: companyId || undefined,
        },
        { query: { enabled } }
    )

    function handleSearchClicked() {
        setEnabled(true)
        refetch()
    }

    function handleCreateClicked() {
        setCreateFlyoutOpen(true)
    }

    function handleCreated() {
        setCreateFlyoutOpen(false)
        refetch()
    }
    return (
        <Page title="Quote Generator">
            {createFlyoutOpen && (
                <CreateCompetitorQuoteFlyout
                    onClose={() => setCreateFlyoutOpen(false)}
                    onCreated={handleCreated}
                />
            )}
            <EuiFlexGroup>
                <EuiFlexItem>
                    <CompetitorPicker onIdSelected={setCompetitorId} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <SalespersonPicker onIdSelected={setSalespersonId} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <BranchPicker onIdSelected={setMarketId} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <CompanyPicker onIdSelected={setCompanyId} />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="ㅤ">
                        <EuiButton
                            onClick={handleSearchClicked}
                            isLoading={isLoading}
                            disabled={isLoading}
                        >
                            Search
                        </EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="ㅤ">
                        <EuiButton onClick={handleCreateClicked}>
                            Create
                        </EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            {data && (
                <CompetitorQuotesTable data={data} onItemDeleted={refetch} />
            )}
        </Page>
    )
}

export default CompetitorQuotes
