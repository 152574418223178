import { useRef, useState } from "react"
import {
    EuiBasicTable,
    EuiButtonIcon,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
} from "@equipmentshare/ds2"

import { PickerHandle } from "../Pickers"
import UsdInput from "../UsdInput"
import { genGuid } from "../../Utils"
import CompetitorClassPicker from "./CompetitorClassPicker"
import { CompetitorClass } from "../../services/Skunkworks/Generated"

export interface NewDetailRow {
    id: string
    competitorClass: CompetitorClass
    quantity: number
    pricePerDay: string
    pricePerWeek: string
    pricePerMonth: string
}

interface CompetitorQuotesDetailsInputProps {
    onDetailsUpdated: (details: NewDetailRow[]) => void
    competitorId: number
    competitorDetails?: NewDetailRow[]
}

function CompetitorQuotesDetailsInput(
    props: CompetitorQuotesDetailsInputProps
) {
    const [competitorClass, setCompetitorClass] =
        useState<CompetitorClass | null>(null)
    const [quantity, setQuantity] = useState(1)
    const [pricePerDay, setPricePerDay] = useState("")
    const [pricePerWeek, setPricePerWeek] = useState("")
    const [pricePerMonth, setPricePerMonth] = useState("")
    const [details, setDetails] = useState<NewDetailRow[]>(
        props.competitorDetails ?? []
    )
    const competitorClassPickerRef = useRef<PickerHandle>(null)

    const isNewRowValid = !!(
        competitorClass &&
        quantity &&
        pricePerDay &&
        pricePerWeek &&
        pricePerMonth
    )

    function handleNewRowCreated() {
        if (!isNewRowValid) return
        const id = genGuid()
        const detail = {
            id,
            competitorClass: competitorClass!,
            quantity,
            pricePerDay,
            pricePerWeek,
            pricePerMonth,
        }
        const newDetails = [...details, detail]
        setDetails(newDetails)
        props.onDetailsUpdated(newDetails)
        handleReset()
    }

    function handleReset() {
        setCompetitorClass(null)
        setQuantity(1)
        setPricePerDay("")
        setPricePerWeek("")
        setPricePerMonth("")
        competitorClassPickerRef?.current?.clearValue()
    }

    const actions = [
        {
            render: (row: NewDetailRow) => {
                return (
                    <EuiButtonIcon
                        onClick={() => {
                            setDetails(details.filter((d) => d.id !== row.id))
                        }}
                        iconType="cross"
                        aria-label="remove"
                    />
                )
            },
        },
    ]

    let cols: any[] = [
        {
            name: "Competitor Class",
            width: "240",
            field: "competitorClass",
            footer: (
                <CompetitorClassPicker
                    ref={competitorClassPickerRef}
                    competitorId={props.competitorId}
                    onSelected={setCompetitorClass}
                />
            ),
            render: (option: CompetitorClass) => (
                <div>{option.description}</div>
            ),
        },
        {
            name: "Quantity",
            field: "quantity",
            footer: (
                <EuiFieldNumber
                    value={quantity}
                    min={0}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                />
            ),
            width: "80",
        },
        {
            name: "Price Per Day",
            field: "pricePerDay",
            render: (val: string) => "$" + val,
            footer: <UsdInput value={pricePerDay} onChange={setPricePerDay} />,
            width: "120",
        },
        {
            name: "Price Per Week",
            field: "pricePerWeek",
            render: (val: string) => "$" + val,
            footer: (
                <UsdInput value={pricePerWeek} onChange={setPricePerWeek} />
            ),
            width: "120",
        },
        {
            name: "Price Per Month",
            field: "pricePerMonth",
            render: (val: string) => "$" + val,
            footer: (
                <UsdInput value={pricePerMonth} onChange={setPricePerMonth} />
            ),
            width: "120",
        },
        {
            // footer only column
            // ideally these would go beneath the actions, but actions can't have footers apparently
            name: "",
            field: "",
            render: () => "",
            width: "60",
            footer: (
                <EuiFlexGroup gutterSize="xs">
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            size="m"
                            display="base"
                            iconType="check"
                            aria-label="add"
                            disabled={!isNewRowValid}
                            onClick={handleNewRowCreated}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            size="m"
                            display="base"
                            aria-label="clear"
                            iconType="trash"
                            color="danger"
                            onClick={handleReset}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            ),
        },
        { name: "Actions", actions },
    ]

    if (props.competitorDetails) {
        const newCols = cols.map((col) => ({
            ...col,
            footer: undefined,
        }))
        cols = [...newCols.slice(0, newCols.length - 1)]
    }

    return (
        <EuiBasicTable<NewDetailRow>
            tableLayout="auto"
            items={details}
            columns={cols}
        />
    )
}

export default CompetitorQuotesDetailsInput
