import { useToastStore, ToastWithoutId } from "../providers/ToastProvider"
import { genGuid } from "../Utils"

const defaultErrorToast: ToastWithoutId = {
    text: "There was an error",
    title: "Error",
    iconType: "alert",
    color: "danger",
    toastLifeTimeMs: 10000,
}

const defaultSuccessToast: ToastWithoutId = {
    text: "Success!",
    title: "Success",
    iconType: "check",
    color: "success",
}

const defaultWarningToast: ToastWithoutId = {
    text: "Warning",
    title: "Warning",
    iconType: "warning",
    color: "warning",
}

export function useToasts() {
    const { state, setState } = useToastStore()

    const genAddToast =
        (defaults: Partial<ToastWithoutId> = {}) =>
        (toast: ToastWithoutId) => {
            const newToast = { ...defaults, ...toast, id: genGuid() }
            setState({ toasts: [...state.toasts, newToast] })
        }

    const addToast = genAddToast()

    // also provide various convenience methods with reasonable defaults
    const addSuccessToast = genAddToast(defaultSuccessToast)
    const addErrorToast = genAddToast(defaultErrorToast)
    const addWarningToast = genAddToast(defaultWarningToast)

    const removeAllToasts = () => {
        setState({ toasts: [] })
    }

    return {
        addToast,
        removeAllToasts,
        addSuccessToast,
        addErrorToast,
        addWarningToast,
    }
}
