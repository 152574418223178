import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiText,
} from "@equipmentshare/ds2"
import { BA_MAP_UPLOAD_PERMISSION } from "../../Const"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"

import MapsAutocomplete from "./MapsAutocomplete"

interface OverlayProps {
    map: google.maps.Map
    date: Date | null
    isLoading?: boolean
    onNewPlaceSelected: (place: google.maps.places.PlaceResult | null) => void
    onUploadClicked: () => void
}

function Overlay(props: OverlayProps) {
    const { permissions } = useAuthenticationDetails()

    const text = props.isLoading
        ? "Loading map data....."
        : props.date
        ? "Loaded Map: " + props.date.toLocaleDateString()
        : "No map data found...."

    return (
        <EuiPanel paddingSize="s" style={{ marginTop: "10px" }}>
            <EuiFlexGroup>
                <EuiFlexItem style={{ minWidth: "300px" }}>
                    <MapsAutocomplete onChange={props.onNewPlaceSelected} />
                </EuiFlexItem>
                {text ? (
                    <EuiFlexItem grow={false}>
                        <EuiText textAlign="center">
                            <p
                                style={{
                                    marginBottom: 0,
                                    height: "40px",
                                    lineHeight: "40px",
                                }}
                            >
                                {text}
                            </p>
                        </EuiText>
                    </EuiFlexItem>
                ) : null}
                {permissions.includes(BA_MAP_UPLOAD_PERMISSION) && (
                    <EuiFlexItem>
                        <EuiButton onClick={props.onUploadClicked}>
                            Upload New Data
                        </EuiButton>
                    </EuiFlexItem>
                )}
            </EuiFlexGroup>
        </EuiPanel>
    )
}

export default Overlay
