import { getOrElseW } from "fp-ts/lib/Either"
import { pipe } from "fp-ts/lib/function"
import { failure } from "io-ts/PathReporter"
import * as t from "io-ts"

/**
 * We gotta be picky about the types here because this data gets uploaded directly to s3 because of
 * its size. Therefore we want to do as much as we can to validate the shape of the data before upload.
 */

const latLng = t.tuple([t.number, t.number])
const latLngWeight = t.tuple([t.number, t.number, t.number])

const _BaMapData = t.type({
    date: t.string,
    es_locations: t.array(latLng),
    prospective_locations: t.union([t.array(latLng), t.undefined]),
    heatmap_data: t.array(latLngWeight),
})

export type BaMapData = t.TypeOf<typeof _BaMapData>

export function decodeData(data: unknown): BaMapData {
    // wrap up this FP code so you don't have to know it outside of this fn
    // ensures that the unknown data is the shape of _BaMapData else it
    // throws an error
    return pipe(
        _BaMapData.decode(data),
        getOrElseW((errors) => {
            throw new Error(failure(errors).join("\n"))
        })
    )
}

export const shapeDescription = `
interface Data {
    // ISO formatted date string
    date: string
	
    // i.e. [
        [29.38,-92.002], 
        [29.76,-95.10]
    ]
    es_locations: number[][]

    // i.e. [
        [31.38,-91.002], 
        [30.76,-92.10]
    ]
    prospective_locations?: number[][]

    // i.e. [
        [29.38,-92.002, 5], 
        [29.7,-95.10, 10]
    ]
    // 3rd number is the weight
	heatmap_data: number[][] 
}
`
