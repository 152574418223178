import { useAuth0 } from "@auth0/auth0-react"

import {
    EuiHeader,
    EuiHeaderLinks,
    EuiHeaderLogo,
    EuiHeaderSectionItem,
    EuiHeaderSection,
    EuiPopover,
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiFlexGroup,
} from "@equipmentshare/ds2"

import MenuItem from "./MenuItem"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import Avatar from "./avatar"
import Help from "./help"
import {
    DISCOUNTS_MANAGER_PERMISSION,
    FLEET_ASSIGNMENT_PERMISSION,
    FLEET_BATCH_GROUP,
    CASE_TOOL_GROUP,
    FLEET_ENABLE_GROUP,
    VIEW_REBATE_AGREEMENT_PERMISSION,
} from "../../Const"
import { useState } from "react"

export namespace SecurityLevel {
    export const ADMIN = "1"
    export const OWNER = "2"
    export const PENDING = "3"
    export const SALESPERSON = "4"
    export const MANAGER = "5"
    export const OPERATIONS = "6"
    export const DRIVER = "7"

    export const allLevels = [ADMIN, OWNER, PENDING, SALESPERSON, MANAGER, OPERATIONS, DRIVER]

    export const nonAdminLevels = SecurityLevel.allLevels.filter((l) => l !== SecurityLevel.ADMIN)
}

export default function Menu() {
    const { user, logout } = useAuth0()
    const { securityLevel, permissions, groups } = useAuthenticationDetails()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    function handleLogout() {
        logout({ returnTo: window.location.origin })
    }

    const button = (
        <EuiHeaderSectionItemButton
            aria-expanded={isPopoverOpen}
            aria-haspopup="true"
            onClick={() => {
                setIsPopoverOpen(!isPopoverOpen)
            }}
            aria-label="Open menu"
        >
            <EuiIcon type="apps" size="m" />
        </EuiHeaderSectionItemButton>
    )

    return (
        <EuiHeader>
            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <EuiHeaderLogo iconType="documents">Internal Tools</EuiHeaderLogo>
                </EuiHeaderSectionItem>

                <EuiHeaderSectionItem>
                    <EuiPopover
                        button={button}
                        isOpen={isPopoverOpen}
                        anchorPosition="downRight"
                        closePopover={() => {
                            setIsPopoverOpen(false)
                        }}
                    >
                        <EuiHeaderLinks popoverBreakpoints="none">
                            <EuiFlexGroup direction="column" gutterSize="xs">
                                {SecurityLevel.ADMIN === securityLevel && (
                                    <>
                                        <MenuItem
                                            title="Invoice Download"
                                            path="/invoices"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem
                                            title="Phone System"
                                            path="/phone-system"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem
                                            title="Equipment Reference Catalogue"
                                            path="/equipment-reference-catalogue"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem
                                            title="Quote Generator"
                                            path="/quote-generator"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem title="BA Map" path="/ba-map" isMenuOpen={setIsPopoverOpen} />
                                        <MenuItem
                                            title="Expense Tracker Console"
                                            path="/expense-admin-console"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem
                                            title="Payout Program Console"
                                            path="/payout-admin-console"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                        <MenuItem
                                            title="Commission Override DSM Review"
                                            path="/commission-override/dsm-review"
                                            isMenuOpen={setIsPopoverOpen}
                                        />
                                    </>
                                )}
                                {groups.includes(FLEET_BATCH_GROUP) && (
                                    <MenuItem
                                        title="Fleet Batch Tools"
                                        path="/fleet-batch-tools"
                                        isMenuOpen={setIsPopoverOpen}
                                    />
                                )}
                                {groups.includes(FLEET_ENABLE_GROUP) && (
                                    <MenuItem
                                        title="Fleet Track Enablement"
                                        path="/auto-fleet-track"
                                        isMenuOpen={setIsPopoverOpen}
                                    />
                                )}
                                {groups.includes(CASE_TOOL_GROUP) && (
                                    <MenuItem title="CASE Orders" path="/case-orders" isMenuOpen={setIsPopoverOpen} />
                                )}
                                <MenuItem
                                    title="Commission Override TAM Form"
                                    path="/commission-override/tam-form"
                                    isMenuOpen={setIsPopoverOpen}
                                />
                                {permissions.includes(VIEW_REBATE_AGREEMENT_PERMISSION) && (
                                    <MenuItem
                                        title="Customer Rebate Console"
                                        path="/customer-rebate-console"
                                        isMenuOpen={setIsPopoverOpen}
                                    />
                                )}
                                <MenuItem
                                    title="Vehicle Tracker"
                                    path="/vehicle-tracker"
                                    isMenuOpen={setIsPopoverOpen}
                                />
                                {permissions.includes(FLEET_ASSIGNMENT_PERMISSION) && (
                                    <MenuItem
                                        title="Driver Assignment"
                                        path="/assign-drivers"
                                        isMenuOpen={setIsPopoverOpen}
                                    />
                                )}
                                {permissions.includes(DISCOUNTS_MANAGER_PERMISSION) && (
                                    <MenuItem title="Discounts" path="/discounts" isMenuOpen={setIsPopoverOpen} />
                                )}
                            </EuiFlexGroup>
                        </EuiHeaderLinks>
                    </EuiPopover>
                </EuiHeaderSectionItem>
            </EuiHeaderSection>

            <EuiHeaderSection>
                <EuiHeaderSectionItem>
                    <Help name="help" />
                </EuiHeaderSectionItem>

                <EuiHeaderSectionItem>
                    <Avatar handleLogout={handleLogout} name={user?.name || ""} />
                </EuiHeaderSectionItem>
            </EuiHeaderSection>
        </EuiHeader>
    )
}
