import React, { useState } from "react"

import { EuiForm, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldSearch, EuiButton } from "@equipmentshare/ds2"

import { GetServiceManualsParams } from "../../../services/Skunkworks/Generated"
import { ServiceManualTypePicker } from "../../Pickers"
import { BooleanPicker } from "../BooleanPicker"

const SEARCH_FIELD_STYLE: React.CSSProperties = { width: "250px" }

export type SearchCriteria = Omit<GetServiceManualsParams, "offset" | "limit" | "sort_by" | "sort_order">

export type DocumentSearchFormProps = {
    onSearch: (searchCriteria: SearchCriteria) => void
    initialSearchCriteria: SearchCriteria
    isLoading?: boolean
}

export const DocumentSearchForm: React.FC<DocumentSearchFormProps> = ({
    onSearch,
    initialSearchCriteria,
    isLoading,
}) => {
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(initialSearchCriteria)
    const [expandedFiltersOpen, setExpandedFiltersOpen] = useState<boolean>(false)

    const submitSearch = (e: React.FormEvent) => {
        e.preventDefault()
        onSearch(searchCriteria)
    }

    return (
        <EuiForm component="form" onSubmit={submitSearch}>
            <EuiFlexGroup alignItems="flexEnd" gutterSize="m" wrap>
                <EuiFlexItem grow={false} style={{ ...SEARCH_FIELD_STYLE, width: "300px" }}>
                    <EuiFormRow label="Name or Title">
                        <EuiFieldSearch
                            placeholder="Search by document name or title"
                            value={searchCriteria.file_name ?? ""}
                            onChange={(e) => setSearchCriteria({ ...searchCriteria, file_name: e.target.value })}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ ...SEARCH_FIELD_STYLE, width: "300px" }}>
                    <EuiFormRow label="GDrive Path">
                        <EuiFieldSearch
                            placeholder="Search by document GDrive path"
                            value={searchCriteria.file_path ?? ""}
                            onChange={(e) => setSearchCriteria({ ...searchCriteria, file_path: e.target.value })}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ ...SEARCH_FIELD_STYLE, width: "300px" }}>
                    <EuiFormRow label="Document Format">
                        <EuiFieldSearch
                            placeholder="Search by document format"
                            value={searchCriteria.file_type ?? ""}
                            onChange={(e) => setSearchCriteria({ ...searchCriteria, file_type: e.target.value })}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow label="Status">
                        <BooleanPicker
                            value={searchCriteria.classified ?? null}
                            onChange={(v) => setSearchCriteria({ ...searchCriteria, classified: v ?? null })}
                            options={[
                                { value: true, label: "Classified" },
                                { value: false, label: "Unclassified" },
                            ]}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                {expandedFiltersOpen && (
                    <>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow>
                                <ServiceManualTypePicker
                                    placeholder="Select a Manual Type"
                                    onIdSelected={(id) =>
                                        setSearchCriteria({ ...searchCriteria, service_manual_type_id: id })
                                    }
                                    onCleared={() =>
                                        setSearchCriteria({ ...searchCriteria, service_manual_type_id: null })
                                    }
                                    selectedId={searchCriteria.service_manual_type_id}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow label="License">
                                <BooleanPicker
                                    value={searchCriteria.is_licensed ?? null}
                                    onChange={(v) => setSearchCriteria({ ...searchCriteria, is_licensed: v ?? null })}
                                    options={[
                                        { value: true, label: "Is Licensed" },
                                        { value: false, label: "Is Not Licensed" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow label="Notice">
                                <BooleanPicker
                                    value={searchCriteria.has_notice ?? null}
                                    onChange={(v) => setSearchCriteria({ ...searchCriteria, has_notice: v ?? null })}
                                    options={[
                                        { value: true, label: "Has Notice" },
                                        { value: false, label: "Does Not Have Notice" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow label="Restriction">
                                <BooleanPicker
                                    value={searchCriteria.has_restrictions ?? null}
                                    onChange={(v) =>
                                        setSearchCriteria({ ...searchCriteria, has_restrictions: v ?? null })
                                    }
                                    options={[
                                        { value: true, label: "Has Restrictions" },
                                        { value: false, label: "Does Not Have Restrictions" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow label="Exclude">
                                <BooleanPicker
                                    value={searchCriteria.exclude ?? null}
                                    onChange={(v) => setSearchCriteria({ ...searchCriteria, exclude: v ?? null })}
                                    options={[
                                        { value: true, label: "Is Excluded" },
                                        { value: false, label: "Is Not Excluded" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                            <EuiFormRow label="Needs Review">
                                <BooleanPicker
                                    value={searchCriteria.needs_expert_review ?? null}
                                    onChange={(v) =>
                                        setSearchCriteria({ ...searchCriteria, needs_expert_review: v ?? null })
                                    }
                                    options={[
                                        { value: true, label: "Needs Review" },
                                        { value: false, label: "Does Not Need Review" },
                                    ]}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </>
                )}
                <EuiFlexItem grow={false}>
                    <EuiButton color="text" onClick={() => setExpandedFiltersOpen(!expandedFiltersOpen)}>
                        {expandedFiltersOpen ? "Less Filters" : "More Filters"}
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton color="text" onClick={() => setSearchCriteria(initialSearchCriteria)}>
                        Reset Filters
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton type="submit" isLoading={isLoading}>
                        Search
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    )
}

export default DocumentSearchForm
