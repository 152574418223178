import React from "react"
import { EuiStepHorizontalProps } from "@elastic/eui/src/components/steps/step_horizontal"

import {
    EuiButton,
    EuiText,
    EuiSpacer,
    EuiStepsHorizontal,
    EuiConfirmModal,
} from "@equipmentshare/ds2"

import Page from "../Page/Page"
import TaskManager from "./TaskManager"
import { AllJobs, AllProposals } from "./Job"
import ExampleJob from "./ExampleJob"
import CenteredContent from "../CenteredContent"
import AssetCompanyOwnerUpdateJob from "./AssetCompanyUpdateJob"
import AssetRspMspUpdateJob from "./AssetRspMspUpdateJob"
import EnrollPayoutProgramJob from "./EnrollPayoutProgramJob"
import UnenrollPayoutProgramJob from "./UnenrollPayoutProgramJob"
import AssetModelUpdateJob from "./AssetModelUpdateJob"

export default function FleetBatchTools() {
    const [job, setJob] = React.useState<AllJobs | null>(null)
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [isDone, setIsDone] = React.useState(false)
    const [step, setStep] = React.useState(0)
    const [proposal, setProposal] = React.useState<AllProposals | null>(null)

    // NOTE: for batch, the token could expire in the time it's running those tasks
    // For now this is unhandled. In the future, you could just pass down a
    // function that retrieves the token, but it'd be even better if you could also
    // consolidate multiple token refreshes into 1 request (because of concurrency)

    const handleRestart = () => {
        setIsModalOpen(false)
        setStep(0)
        setProposal(null)
        setJob(null)
    }

    const handleSwitchStep = (next: number) => {
        // from step 3, you can only go to step 0
        if (step === 3 && next !== 0) return

        // if going back to beginning, show modal if far enough along
        if (next === 0 && step > 1 && !isDone) {
            setIsModalOpen(true)
            return
        }

        if (next === 0) {
            handleRestart()
        }
    }

    const horizontalSteps = [
        {
            title: "Pick Job",
            status: step === 0 ? "current" : "complete",
            onClick: () => handleSwitchStep(0),
        },
        {
            title: "Enter Data",
            status:
                step === 0 ? "disabled" : step === 1 ? "current" : "complete",
            onClick: () => handleSwitchStep(1),
        },
        {
            title: "Confirm",
            status: step < 2 ? "disabled" : step === 2 ? "current" : "complete",
            onClick: () => handleSwitchStep(0),
        },
        {
            title: "Process",
            status: step === 3 ? "current" : "disabled",
            onClick: () => null,
        },
    ] as Array<Omit<EuiStepHorizontalProps, "step">>

    const taskManager =
        step === 3 && job && proposal ? (
            <TaskManager
                options={job.options}
                tasks={job.gen(proposal as any)}
                onFinished={() => setIsDone(true)}
            />
        ) : null

    const makeJobSelectionHandler = (job: AllJobs) => () => {
        setJob(job)
        setStep(1)
    }

    const jobSelect =
        step === 0 ? (
            <CenteredContent>
                <EuiText>
                    <p>Choose the job that you'd like to run...</p>
                </EuiText>
                <EuiSpacer size="s" />
                <EuiButton onClick={makeJobSelectionHandler(ExampleJob)}>
                    Example Job
                </EuiButton>
                <EuiSpacer size="xs" />
                <EuiButton
                    onClick={makeJobSelectionHandler(
                        AssetCompanyOwnerUpdateJob
                    )}
                >
                    Change Asset Company Owner
                </EuiButton>
                <EuiSpacer size="xs" />
                <EuiButton
                    onClick={makeJobSelectionHandler(AssetRspMspUpdateJob)}
                >
                    Change Asset RSP / MSP
                </EuiButton>
                <EuiSpacer size="xs" />
                <EuiButton
                    onClick={makeJobSelectionHandler(EnrollPayoutProgramJob)}
                >
                    Enroll Payout Program
                </EuiButton>
                <EuiSpacer size="xs" />
                <EuiButton
                    onClick={makeJobSelectionHandler(UnenrollPayoutProgramJob)}
                >
                    Un-Enroll Payout Program
                </EuiButton>
                <EuiSpacer size="xs" />
                <EuiButton
                    onClick={makeJobSelectionHandler(AssetModelUpdateJob)}
                >
                    Update Model
                </EuiButton>
            </CenteredContent>
        ) : null

    const handleSubmitProposal = (proposal: AllProposals) => {
        setProposal(proposal)
        setStep(2)
    }

    const renderConfigureJob = () => {
        if (step !== 1 || !job) return null
        return <job.Configure onSubmit={handleSubmitProposal} />
    }

    const renderConfirm = () => {
        if (step === 2 && job && proposal) {
            const props = {
                onConfirm: () => {
                    setIsDone(false)
                    setStep(3)
                },
                proposal: proposal as any,
            }
            return <job.Confirm {...props} />
        }
    }

    const renderRestartModal = () => {
        if (isModalOpen) {
            return (
                <EuiConfirmModal
                    title="Start Over"
                    onCancel={() => setIsModalOpen(false)}
                    onConfirm={handleRestart}
                    cancelButtonText="No, don't do it"
                    confirmButtonText="Yes, do it"
                    buttonColor="danger"
                    defaultFocusedButton="confirm"
                >
                    <p>You're about to start over.</p>
                    <p>Are you sure you want to do this?</p>
                </EuiConfirmModal>
            )
        }
    }

    return (
        <Page title="Bulk Update Tool" restrictWidth="1000px">
            <EuiStepsHorizontal steps={horizontalSteps} />
            <EuiSpacer size="l" />
            {jobSelect}
            {taskManager}
            {renderConfigureJob()}
            {renderConfirm()}
            {renderRestartModal()}
            <EuiSpacer size="l" />
        </Page>
    )
}
