import { useEffect, useState } from "react"
import { createPortal } from "react-dom"

interface MapControlProps {
    map: google.maps.Map
    position: google.maps.ControlPosition
    children: any
}

function MapControl(props: MapControlProps) {
    const [controlDiv, setControlDiv] = useState<HTMLElement | null>(null)
    useEffect(() => {
        const div = document.createElement("div")
        props.map.controls[props.position].push(div)
        setControlDiv(div)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return controlDiv ? createPortal(props.children, controlDiv) : null
}

export default MapControl
