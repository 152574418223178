import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"

import config from "../services/config"

type AuthenticationDetails = {
    accessToken: string
    securityLevel: string
    userId: number
    userName: string
    userEmail: string
    permissions: string[]
    groups: string[]
}

export function useAuthenticationDetails(): AuthenticationDetails {
    const { getAccessTokenSilently, user } = useAuth0()
    const [authenticationDetails, setAuthenticationDetails] =
        useState<AuthenticationDetails>({
            accessToken: "",
            securityLevel: "",
            userName: "",
            userEmail: "",
            userId: 0,
            permissions: [],
            groups: [],
        })
    useEffect(() => {
        async function getAuthenticationDetails(): Promise<void> {
            const accessToken = await getAccessTokenSilently()
            const temp = JSON.parse(atob(accessToken.split(".")[1]))
            const details = Object.entries(temp).reduce(
                (prev, [key, value]) => {
                    if (key.includes("es_security_level_id")) {
                        return { ...prev, securityLevel: value }
                    } else if (key.includes("es_user_name")) {
                        return { ...prev, userName: value }
                    } else if (key.includes("es_user_email")) {
                        return { ...prev, userEmail: value }
                    } else if (key.includes("es_user_id")) {
                        return { ...prev, userId: parseInt(value as string) }
                    } else if (key === "permissions") {
                        return { ...prev, permissions: value }
                    } else {
                        return { ...prev }
                    }
                },
                { accessToken }
            ) as AuthenticationDetails
            if (user?.[`${config.API_URL}groups`]) {
                details.groups = user[`${config.API_URL}groups`]
            } else {
                details.groups = []
            }
            setAuthenticationDetails(details)
        }

        getAuthenticationDetails()
    }, [getAccessTokenSilently, user])
    return authenticationDetails
}
