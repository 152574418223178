import { EuiBasicTableColumn } from "@equipmentshare/ds2"
import { formatDate } from "../HelperFunctions"

export const assignColumns: Array<EuiBasicTableColumn<{}>> = [
    {
        field: "asset_id",
        name: "Asset Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "assignment_id",
        name: "Assignment Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "billing_type",
        name: "Billing Type",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
        render: (e: string | null) => (e ? e : ""),
    },
    {
        field: "payout_program_id",
        name: "Payout Program Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "start_date",
        name: "Start Date",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
        render: (e: string) => formatDate(e),
    },
    {
        field: "end_date",
        name: "End Date",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
        render: (e: string) => formatDate(e),
    },
]

export const masterAgreeColumns = [
    {
        field: "company.name",
        name: "Company Name",
        sortable: true,
        truncateText: true,
    },
    {
        field: "company_id",
        name: "Company Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "master_agreement_id",
        name: "Master Agreement Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "master_agreement_no",
        name: "Master Agreement No",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
]
export const programColumns = [
    {
        field: "name",
        name: "Program Name",
        sortable: true,
        truncateText: true,
    },
    {
        field: "payout_program_id",
        name: "Payout Program Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "payout_program_type_id",
        name: "Payout Program Type Id",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
]
export const scheduleColumns = [
    {
        field: "name",
        name: "Schedule Name",
        sortable: true,
        truncateText: true,
    },
    {
        field: "schedule_id",
        name: "Schedule ID",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "payout_percentage",
        name: "Payout Percentage",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
    {
        field: "payout_program_id",
        name: "Payout Program ID",
        truncateText: true,
        mobileOptions: {
            show: false,
        },
    },
]
