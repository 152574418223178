import { useRef } from "react"
import { EuiInMemoryTable, Search } from "@equipmentshare/ds2"

import {
    SubDepartment,
    ExpenseUserSubDepartment,
} from "../../../services/Skunkworks/Generated"

export type TableProps = {
    itemId: string
    tableCaption: string
    info: ExpenseUserSubDepartment[] | SubDepartment[] | any[]
    message: JSX.Element
    columns: any[]
    showTable: boolean
    search?: Search
}

export default function Table(props: TableProps) {
    // in EUI Tables, if you want to set the selection
    // programmatically you have to use a ref
    const tableRef = useRef(null)

    const pagination = {
        initialPageSize: 10,
        pageSizeOptions: [10, 25, 50],
    }

    return (
        <>
            {props.showTable && (
                <EuiInMemoryTable
                    ref={tableRef}
                    itemId={props.itemId}
                    tableCaption={props.tableCaption}
                    items={props.info}
                    message={props.message}
                    loading={false}
                    hasActions={true}
                    columns={props.columns}
                    isSelectable={true}
                    sorting={true}
                    tableLayout="auto"
                    pagination={pagination}
                    search={props.search}
                />
            )}
        </>
    )
}
