import { useState } from "react"
import moment, { Moment } from "moment"

import {
    EuiFormRow,
    EuiSelect,
    EuiDatePicker,
    EuiSpacer,
    EuiFieldNumber,
    EuiFieldText,
} from "@equipmentshare/ds2"

import { PayoutProgramBillingEnum } from "../../../../../services/PayoutProgram/Generated"

import { PayoutProgramPicker, PayoutSchedulePicker } from "../../../../Pickers"

export const billingType = [
    { value: "MSA", text: "MSA" },
    { value: "DIRECT", text: "DIRECT" },
    { value: undefined, text: "" },
]

type AssignmentFieldsRequest = {
    asset_id?: number | null
    billing_type?: PayoutProgramBillingEnum | null
    end_date?: string | null
    payout_program_id?: number | null
    replaced_by_asset_id?: number | null
    replaced_or_removed_reason?: string | null
    schedule_id?: number | null
    start_date?: string | null
}

export type AssignmentFieldsProps = {
    assignmentReq: AssignmentFieldsRequest
    setAssignmentReq: (
        value: React.SetStateAction<AssignmentFieldsRequest>
    ) => void

    hasErrors?: boolean
}
// update and create both use same fields but almost everything can be optional in update causing types to technically be different

type AssignmentFieldsCreateReq = {
    asset_id: number
    billing_type: PayoutProgramBillingEnum
    end_date?: string | null
    payout_program_id: number
    replaced_by_asset_id?: number | null
    replaced_or_removed_reason?: string | null
    schedule_id: number
    start_date: string
}
export type AssignmentFieldsCreateProps = {
    assignmentReq: AssignmentFieldsCreateReq
    setAssignmentReq: (
        value: React.SetStateAction<AssignmentFieldsCreateReq>
    ) => void

    hasErrors?: boolean
}

export default function AssignmentFields(
    props: AssignmentFieldsProps | AssignmentFieldsCreateProps
) {
    const [startDate, setStartDate] = useState<Moment | null>(null)
    const [endDate, setEndDate] = useState<Moment | null>(null)
    function isValidPayoutEnum(
        value: string
    ): value is PayoutProgramBillingEnum {
        return Object.values(PayoutProgramBillingEnum).includes(
            value as PayoutProgramBillingEnum
        )
    }

    function isAssignmentFieldsProps(
        props: AssignmentFieldsProps | AssignmentFieldsCreateProps
    ): props is AssignmentFieldsProps {
        const req = props.assignmentReq
        return (
            req.asset_id === undefined ||
            req.asset_id === null ||
            req.billing_type === undefined ||
            req.billing_type === null ||
            req.payout_program_id === undefined ||
            req.payout_program_id === null ||
            req.schedule_id === undefined ||
            req.schedule_id === null ||
            req.start_date === undefined ||
            req.start_date === null
        )
    }

    return (
        <>
            <EuiFormRow
                label="Asset ID"
                hasChildLabel={false}
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiFieldNumber
                    placeholder="Input Asset ID"
                    onChange={(e) => {
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  asset_id: parseInt(e.target.value),
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  asset_id: parseInt(e.target.value),
                              })
                    }}
                />
            </EuiFormRow>

            <EuiSpacer />
            <PayoutProgramPicker
                onIdSelected={(e) => {
                    isAssignmentFieldsProps(props)
                        ? props.setAssignmentReq({
                              ...props.assignmentReq,
                              payout_program_id: e,
                          })
                        : props.setAssignmentReq({
                              ...props.assignmentReq,
                              payout_program_id: e ? e : 0,
                          })
                }}
                hasErrors={props.hasErrors ? props.hasErrors : false}
            />
            <EuiSpacer />
            <EuiFormRow
                label="Billing Type"
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiSelect
                    options={billingType}
                    value={undefined}
                    onChange={(e) => {
                        if (isValidPayoutEnum(e.target.value)) {
                            isAssignmentFieldsProps(props)
                                ? props.setAssignmentReq({
                                      ...props.assignmentReq,
                                      billing_type: e.target.value,
                                  })
                                : props.setAssignmentReq({
                                      ...props.assignmentReq,
                                      billing_type: e.target.value,
                                  })
                        }
                    }}
                />
            </EuiFormRow>

            <EuiSpacer />
            <PayoutSchedulePicker
                onIdSelected={(e) => {
                    isAssignmentFieldsProps(props)
                        ? props.setAssignmentReq({
                              ...props.assignmentReq,
                              schedule_id: e,
                          })
                        : props.setAssignmentReq({
                              ...props.assignmentReq,
                              schedule_id: e ? e : 0,
                          })
                }}
                hasErrors={props.hasErrors ? props.hasErrors : false}
            />
            <EuiSpacer />
            <EuiFormRow
                label="Replaced by Asset ID"
                hasChildLabel={false}
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiFieldNumber
                    placeholder="Input Asset ID"
                    onChange={(e) => {
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  replaced_by_asset_id: parseInt(
                                      e.target.value
                                  ),
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  replaced_by_asset_id: parseInt(
                                      e.target.value
                                  ),
                              })
                    }}
                />
            </EuiFormRow>

            <EuiFormRow label="Replaced or Removed Reason">
                <EuiFieldText
                    placeholder="Enter a reason"
                    onChange={(e) => {
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  replaced_or_removed_reason: e.target.value,
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  replaced_or_removed_reason: e.target.value,
                              })
                    }}
                />
            </EuiFormRow>
            <EuiFormRow
                label="Start Date"
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiDatePicker
                    selected={startDate}
                    onChange={(date) => {
                        setStartDate(date)
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  start_date: date ? date.format() : undefined,
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  start_date: date
                                      ? date.format()
                                      : moment().format(),
                              })
                    }}
                    onClear={() => {
                        setStartDate(null)
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  schedule_id: undefined,
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  start_date: "",
                              })
                    }}
                    placeholder="Start Date"
                />
            </EuiFormRow>

            <EuiFormRow label="End Date">
                <EuiDatePicker
                    selected={endDate}
                    onChange={(date) => {
                        setEndDate(date)
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  end_date: date ? date.format() : undefined,
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  end_date: date
                                      ? date.format()
                                      : moment().format(),
                              })
                    }}
                    onClear={() => {
                        setEndDate(null)
                        isAssignmentFieldsProps(props)
                            ? props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  end_date: undefined,
                              })
                            : props.setAssignmentReq({
                                  ...props.assignmentReq,
                                  end_date: undefined,
                              })
                    }}
                    placeholder="End Date"
                />
            </EuiFormRow>

            <EuiSpacer />
        </>
    )
}
