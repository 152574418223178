import { datadogRum } from "@datadog/browser-rum"

import packageJson from "../package.json"
import config from "./services/config"

window.toolsPortal = {
    version: packageJson.version,
}

const env = process.env.NODE_ENV === "production" ? "prod" : "stage"

datadogRum.init({
    applicationId: config.DDOG_RUM.APPLICATION_ID,
    clientToken: config.DDOG_RUM.CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "internal-tools-portal",
    env,
    version: window.toolsPortal.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
})
