import {
    ExpenseUser,
    SubDepartment,
    Department,
} from "../../services/Skunkworks/Generated"
import {
    UserTableFormat,
    ExpenseLineTableFormat,
    SubdepartmentTableFormat,
} from "./ExpenseTrackerComponents/ExpenseTrackerAdmin"

type SubDepartmentMap = [string, SubDepartment]

export const transformSubDepartmentData = (subdepartments: SubDepartment) => {
    const data =
        subdepartments.expense_lines?.map((e) => ({
            expenseLineName: e.name,
            expenseLineId: e.expense_line_id,
        })) ?? []
    return data
}

export const transformUserData = (expenseUser: ExpenseUser) => {
    //this function goes only two levels because the endpoint supports at most two levels currently
    //once more subdepartments and departments are added, code will have to be refactored

    const departments = expenseUser.departments
    if (!departments) {
        return []
    }

    const subDepartments = departments.reduce<[string, SubDepartment][]>(
        (prev, department) => {
            if (department.sub_departments) {
                return [
                    ...prev,
                    ...department.sub_departments.map<SubDepartmentMap>(
                        (subDepartment) => [department.name, subDepartment]
                    ),
                ]
            } else {
                return [...prev]
            }
        },
        []
    )
    const newDepartments = subDepartments.map(([fullString, subDepartment]) => {
        const fullName = `${fullString}/${subDepartment.name}`
        const result = {
            subdepartment: fullName,
            primary: subDepartment.primary ? true : false,
            sub_id: subDepartment.sub_department_id,
        }
        return result
    })
    return newDepartments
}

export const transformExpenseLineData = (subdepartments: SubDepartment[]) => {
    const res = subdepartments.map((e) => {
        return { sub_name: e.name, sub_id: e.sub_department_id }
    })

    return res
}

export function isExpenseUser(
    object: ExpenseUser | Department | SubDepartment | SubDepartment[] | null
): object is ExpenseUser {
    return object !== null && "expense_user_id" in object
}

export function isDepartment(
    object: ExpenseUser | Department | SubDepartment | SubDepartment[] | null
): object is Department {
    return object !== null && "department_id" in object
}

export function isSubDepartment(
    object: ExpenseUser | Department | SubDepartment | SubDepartment[] | null
): object is SubDepartment {
    return object !== null && "sub_department_id" in object
}

export function isSubDepartmentArray(
    object: ExpenseUser | Department | SubDepartment | SubDepartment[] | null
): object is SubDepartment[] {
    return (
        Array.isArray(object) && object.every((o) => "sub_department_id" in o)
    )
}

export function instanceOfUserTableFormat(
    object:
        | UserTableFormat[]
        | ExpenseLineTableFormat[]
        | SubdepartmentTableFormat[]
): object is UserTableFormat[] {
    return "subdepartment" in object[0]
}

export function instanceOfSubdepartmentTableFormat(
    object:
        | UserTableFormat[]
        | ExpenseLineTableFormat[]
        | SubdepartmentTableFormat[]
): object is SubdepartmentTableFormat[] {
    return "expenseLineName" in object[0]
}
