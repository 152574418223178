import { useState } from "react"

import { uploadBlobToS3 } from "../services/Misc"
import { useCreateCompetitorQuoteUploadUrl } from "../services/Skunkworks/Generated"

function useUploadCompetitorQuote() {
    const [isUploading, setIsLoading] = useState(false)
    const { mutateAsync: createUploadUrl } = useCreateCompetitorQuoteUploadUrl()

    async function uploadCompetitorQuote(file: File) {
        setIsLoading(true)
        try {
            const { object_key, upload_url } = await createUploadUrl({
                data: { content_type: file.type },
            })
            await uploadBlobToS3(file, upload_url, file.type)
            return object_key
        } finally {
            setIsLoading(false)
        }
    }

    return { isUploading, uploadCompetitorQuote }
}

export default useUploadCompetitorQuote
