import Table from "./Table"
import {
    assignColumns,
    masterAgreeColumns,
    programColumns,
    scheduleColumns,
} from "./Columns"

import { Tab } from "../../PayoutProgramConsole"
import { PromiseDataMult } from "../RenderViewModal"
import { EuiBasicTableColumn } from "@equipmentshare/ds2"

export type RenderTableProps = {
    data: PromiseDataMult
    tab: Tab
    showTable: boolean
}
export default function RenderTable(props: RenderTableProps) {
    const columnMapping: Record<Tab, Array<EuiBasicTableColumn<{}>> | null> = {
        [Tab.ASSIGNMENTS]: assignColumns,
        [Tab.MASTERAGREEMENTS]: masterAgreeColumns,
        [Tab.PROGRAMS]: programColumns,
        [Tab.SCHEDULES]: scheduleColumns,
        [Tab.ADMIN]: null,
    }

    const columns = columnMapping[props.tab] ?? []

    return (
        <Table
            info={props.data}
            columns={columns}
            showTable={props.showTable}
        />
    )
}
