import { useState } from "react"
import { EuiFormRow, EuiComboBox, EuiComboBoxOptionOption } from "@equipmentshare/ds2"

export type MultiSelectInputFieldProps = {
    labelName?: string
    placeholder?: string
    onCleared?: () => void
    options: EuiComboBoxOptionOption<unknown>[]
    selected: EuiComboBoxOptionOption<unknown>[]
    onChange: (options: EuiComboBoxOptionOption<unknown>[]) => void
    onSearchChange?: (searchValue: string, hasMatchingOptions?: boolean | undefined) => void
    hasErrors?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    isInvalid?: boolean
    onCreateOption?: (text: string) => void
}

export default function MultiSelectInputField(props: MultiSelectInputFieldProps) {
    const [preventOnCreate, setPreventOnCreate] = useState(false)

    function handleOnChange(options: EuiComboBoxOptionOption<unknown>[]) {
        if (!options.length && props.onCleared) {
            props.onCleared()
        }

        props.onChange(options)
    }

    /**
     * These functions are weird ones but the point is to get around this issue:
     * https://github.com/elastic/eui/issues/3573
     * ElasticUI's onCreateOption is just broken because it'll call it after onBlur
     * i.e. even if they don't hit Enter.
     */
    const handleOnCreate = props.onCreateOption
        ? {
              onBlur: () => setPreventOnCreate(true), // ensure we prevent any onCreate
              onFocus: () => setPreventOnCreate(false), // ensure we don't prevent onCreate
              onCreateOption: (text: string) => {
                  if (preventOnCreate) {
                      setPreventOnCreate(false)
                  } else if (props.onCreateOption) {
                      props.onCreateOption(text)
                  }
              },
          }
        : {}

    const comboBox = (
        <EuiComboBox
            style={{ minWidth: "220px" }}
            onSearchChange={props.onSearchChange}
            placeholder={props.placeholder || "Enter query to display suggestions"}
            singleSelection={false}
            options={props.options}
            selectedOptions={props.selected}
            onChange={handleOnChange}
            isLoading={props.isLoading}
            isDisabled={props.isDisabled}
            isInvalid={props.isInvalid}
            sortMatchesBy="none"
            async
            {...handleOnCreate}
        />
    )

    return props.labelName ? (
        <EuiFormRow label={props.labelName} isInvalid={props.hasErrors}>
            {comboBox}
        </EuiFormRow>
    ) : (
        comboBox
    )
}
