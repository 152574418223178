import { simpleJsonApiClient } from "@equipmentshare/simple-json-api-client"

export type EsApiClient = typeof simpleJsonApiClient

export interface EquipmentClass {
    equipment_class_id: number
    name: string
}

export interface Category {
    category_id: number
    name: string
    categories: Category[] // children
}

export interface EquipmentMake {
    equipment_make_id: number
    name: string
}

export interface EquipmentModel {
    equipment_model_id: number
    name: string
}

class EsApiService {
    private esApiClient: EsApiClient

    public constructor(esApiClient: EsApiClient) {
        this.esApiClient = esApiClient
    }

    public getAllCategories() {
        const headers = { "x-fields": "{category_id, name, categories}" }
        return this.esApiClient.get<Category[]>(`/categories`, { headers })
    }

    public getAllMakes() {
        return this.esApiClient.get<EquipmentMake[]>(`/makes`)
    }

    public getAllModels() {
        return this.esApiClient.get<EquipmentModel[]>(`/models`)
    }

    public getClassNameById(id: number) {
        const headers = { "x-fields": "{equipment_class_id, name}" }
        return this.esApiClient
            .get(`/equipment-classes/${id}`, { headers })
            .then((r) => r.name as string)
    }

    public queryClasses(query: string) {
        const headers = { "x-fields": "{equipment_class_id, name}" }
        return this.esApiClient.get<EquipmentClass[]>(
            `/equipment-classes?query=${query}`,
            { headers }
        )
    }

    public getClassesByCategoryId(categoryId: number) {
        const headers = { "x-fields": "{equipment_class_id, name}" }
        return this.esApiClient.get<EquipmentClass[]>(
            `/equipment-classes?category_id=${categoryId}`,
            { headers }
        )
    }
}

export default EsApiService
