import React, { useState } from "react"

import { EuiBasicTable, EuiButtonEmpty, EuiLink } from "@equipmentshare/ds2"

import {
    CompetitorQuote,
    Competitor,
    DocType,
    EsdbUser,
    useDeleteCompetitorQuote,
} from "../../services/Skunkworks/Generated"
import CompetitorQuoteDetailsModal from "./CompetitorQuoteDetailsModal"

interface CompetitorQuotesTableProps {
    data: CompetitorQuote[]
    onItemDeleted: () => void
}

/**
 * @param quotes
 * @returns list of unique class ids among all quotes
 */
function getDetailClassIds(quotes: CompetitorQuote[]): number[] {
    const classIds: Set<number> = new Set()
    for (const quote of quotes) {
        for (const detail of quote.details) {
            if (detail.competitor_class.es_class_id) {
                classIds.add(detail.competitor_class.es_class_id)
            }
        }
    }
    return Array.from(classIds)
}

type SortDirection = "asc" | "desc"

interface SortState {
    field: keyof CompetitorQuote
    direction: SortDirection
}

function CompetitorQuotesTable(props: CompetitorQuotesTableProps) {
    const [active, setActive] = useState<CompetitorQuote | null>(null)
    const [sortState, setSortState] = useState<SortState>({
        field: "date_created",
        direction: "desc",
    })
    const classIds = getDetailClassIds(props.data)
    const deleteQuote = useDeleteCompetitorQuote()

    const actions = [
        {
            render: (competitorQuote: CompetitorQuote) => {
                const disabled =
                    deleteQuote.isLoading &&
                    competitorQuote.competitor_quote_id ===
                        deleteQuote.variables?.competitorQuoteId

                return (
                    <EuiButtonEmpty
                        onClick={() =>
                            deleteQuote
                                .mutateAsync({
                                    competitorQuoteId:
                                        competitorQuote.competitor_quote_id,
                                })
                                .then(props.onItemDeleted)
                        }
                        color="danger"
                        isDisabled={disabled}
                        isLoading={disabled}
                    >
                        Delete
                    </EuiButtonEmpty>
                )
            },
        },
    ]

    const columns = [
        {
            field: "competitor_quote_id",
            name: "Competitor Quote Id",
            sortable: true,
        },
        {
            field: "competitor",
            name: "Competitor",
            sortable: true,
            render: (competitor: Competitor) => competitor.name,
        },
        {
            field: "date_created",
            name: "Date Created",
            sortable: true,
            render: (dateCreated: string) =>
                new Date(dateCreated).toLocaleDateString(),
        },
        {
            field: "salesperson",
            name: "Salesperson",
            sortable: true,
            render: (salesperson: EsdbUser | null) => {
                return salesperson
                    ? `${salesperson.first_name} ${salesperson.last_name}`
                    : ""
            },
        },
        {
            field: "doc_type",
            name: "Document Type",
            render: (docType: DocType) => docType.name,
        },
        {
            field: "object_url",
            name: "Documents",
            render: (url: string | null) =>
                url ? (
                    <EuiLink href={url} target="_blank">
                        Link
                    </EuiLink>
                ) : (
                    "N/A"
                ),
        },
        {
            name: "Details",
            render: (quote: CompetitorQuote) => (
                <EuiLink onClick={() => setActive(quote)}>Open</EuiLink>
            ),
        },
        { name: "Actions", actions },
    ]

    const sorting = {
        sort: {
            field: sortState.field,
            direction: sortState.direction,
        },
    }

    const onTableSort = (sort: SortState) => {
        setSortState(sort)
    }

    const sortItems = (
        items: CompetitorQuote[],
        field: keyof CompetitorQuote,
        direction: string
    ) => {
        return items.slice().sort((a, b) => {
            let valA,
                valB = null

            switch (field) {
                case "competitor":
                    valA = a.competitor.name
                    valB = b.competitor.name
                    break
                case "competitor_quote_id":
                    valA = a.competitor_quote_id
                    valB = b.competitor_quote_id
                    break
                case "date_created":
                    valA = new Date(a.date_created)
                    valB = new Date(b.date_created)
                    break
                case "salesperson":
                    valA = `${a.salesperson?.first_name} ${a.salesperson?.last_name}`
                    valB = `${b.salesperson?.first_name} ${b.salesperson?.last_name}`
                    break
            }
            if (valA && valB) {
                if (valA < valB) {
                    return direction === "asc" ? -1 : 1
                }
                if (valA > valB) {
                    return direction === "asc" ? 1 : -1
                }
            }

            return 0
        })
    }

    const sortedItems = sortItems(
        props.data,
        sortState.field,
        sortState.direction
    )

    return (
        <>
            {active && (
                <CompetitorQuoteDetailsModal
                    quote={active}
                    classIds={classIds}
                    onClose={() => {
                        setActive(null)
                    }}
                />
            )}
            <EuiBasicTable<CompetitorQuote>
                items={sortedItems}
                itemId="competitor_quote_id"
                columns={columns}
                sorting={sorting}
                onChange={({ sort }) => {
                    if (sort) {
                        onTableSort(sort)
                    }
                }}
            />
        </>
    )
}

export default CompetitorQuotesTable
