import { useState } from "react"

import {
    EuiFieldText,
    EuiSpacer,
    EuiText,
    EuiFormRow,
} from "@equipmentshare/ds2"

import { useToasts } from "../../../hooks/useToasts"

import AssignmentFields from "./Modals/ModalFields/AssignmentFields"
import ProgramFields from "./Modals/ModalFields/ProgramFields"
import ScheduleFields from "./Modals/ModalFields/ScheduleFields"

import { Tab } from "../PayoutProgramConsole"
import { verifyArgs, PromiseData } from "./HelperFunctions"

import {
    UpdateAssignmentRequest,
    UpdateMasterAgreementRequest,
    UpdatePayoutProgramRequest,
    UpdateScheduleRequest,
    updateAssignments,
    updatePayoutProgramMasterAgreement,
    updatePayoutProgram,
    updateSchedule,
} from "../../../services/PayoutProgram/Generated"

import {
    PayoutProgramPicker,
    PayoutAssignmentPicker,
    MasterAgreementPicker,
    PayoutSchedulePicker,
} from "../../../components/Pickers"
import PayoutModal from "./Modals/PayoutModal"

type RequestTypes =
    | UpdateAssignmentRequest
    | UpdateMasterAgreementRequest
    | UpdatePayoutProgramRequest
    | UpdateScheduleRequest

type UpdateFunctions<T extends Tab> = T extends Tab.ASSIGNMENTS
    ? (
          id: number,
          req: UpdateAssignmentRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.MASTERAGREEMENTS
    ? (
          id: number,
          req: UpdateMasterAgreementRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.PROGRAMS
    ? (
          id: number,
          req: UpdatePayoutProgramRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : T extends Tab.SCHEDULES
    ? (
          id: number,
          req: UpdateScheduleRequest,
          signal?: AbortSignal
      ) => Promise<PromiseData>
    : null

type RenderUpdateModalProps = {
    tabName: typeof Tab[keyof typeof Tab]
    closeModal: () => void
}

export default function RenderUpdateModal(props: RenderUpdateModalProps) {
    const [id, setId] = useState<number | null>(null)
    const { addErrorToast, addSuccessToast } = useToasts()
    //request objects needed for creation
    // anything defined as undefined is optional
    const [assignmentReq, setAssignmentReq] = useState<UpdateAssignmentRequest>(
        {
            asset_id: undefined,
            billing_type: undefined,
            end_date: undefined,
            payout_program_id: undefined,
            replaced_by_asset_id: undefined,
            replaced_or_removed_reason: undefined,
            schedule_id: undefined,
            start_date: undefined,
        }
    )

    const [masterAgreeReq, setMasterAgreeReq] =
        useState<UpdateMasterAgreementRequest>({
            master_agreement_no: "",
        })

    const [programReq, setProgramReq] = useState<UpdatePayoutProgramRequest>({
        name: undefined,
        payout_program_type_id: undefined,
    })

    const [scheduleReq, setScheduleReq] = useState<UpdateScheduleRequest>({
        name: undefined,
        payout_percentage: undefined,
        payout_program_id: undefined,
    })

    const updateRules = (
        <EuiText>
            <h5>Enter data into the fields that you would like to update:</h5>
        </EuiText>
    )

    const assignmentBody = (
        <>
            <PayoutAssignmentPicker onIdSelected={(e) => setId(e)} />
            {id && (
                <>
                    {updateRules}
                    <EuiSpacer />
                    <AssignmentFields
                        assignmentReq={assignmentReq}
                        setAssignmentReq={setAssignmentReq}
                    />
                </>
            )}
        </>
    )

    const masterAgreeBody = (
        <>
            <MasterAgreementPicker onIdSelected={(e) => setId(e)} />

            {id && (
                <>
                    <EuiSpacer />
                    <EuiFieldText
                        placeholder="Master Agreement Number"
                        onChange={(e) =>
                            setMasterAgreeReq({
                                master_agreement_no: e.target.value,
                            })
                        }
                    />
                </>
            )}
        </>
    )

    const programBody = (
        <>
            <PayoutProgramPicker onIdSelected={(e) => setId(e)} />
            {id && (
                <>
                    {updateRules}
                    <EuiSpacer />
                    <ProgramFields
                        programReq={programReq}
                        setProgramReq={setProgramReq}
                    />
                </>
            )}
        </>
    )

    const scheduleBody = (
        <>
            <PayoutSchedulePicker onIdSelected={(e) => setId(e)} />

            {id && (
                <>
                    {updateRules}
                    <EuiSpacer />
                    <ScheduleFields
                        scheduleReq={scheduleReq}
                        setScheduleReq={setScheduleReq}
                    />
                    <EuiSpacer />
                    <EuiFormRow label="Schedule Payout Percentage">
                        <EuiFieldText
                            placeholder="Enter Schedule Payout Percentage"
                            onChange={(e) =>
                                setScheduleReq({
                                    ...scheduleReq,
                                    payout_percentage: parseFloat(
                                        e.target.value
                                    ),
                                })
                            }
                        />
                    </EuiFormRow>
                </>
            )}
        </>
    )

    const updateFunctions: {
        [K in Tab]: UpdateFunctions<K>
    } = {
        [Tab.ASSIGNMENTS]: updateAssignments,
        [Tab.MASTERAGREEMENTS]: updatePayoutProgramMasterAgreement,
        [Tab.PROGRAMS]: updatePayoutProgram,
        [Tab.SCHEDULES]: updateSchedule,
        [Tab.ADMIN]: null,
    }

    async function processCreation(
        tab: typeof Tab[keyof typeof Tab],
        id: number,
        req: any,
        successMessage: string,
        errorMessage?: string
    ) {
        const updateFunc = updateFunctions[tab]
        if (!updateFunc) {
            return
        }

        if (!verifyArgs(req)) {
            addErrorToast({
                text: "Please fill in at least 1 field",
                toastLifeTimeMs: 5000,
            })
            return
        }

        Object.keys(req).forEach((key) => {
            if (isKeyOfRequest(key, req)) {
                return req[key] === undefined || req[key] === null
                    ? delete req[key]
                    : {}
            }
        })

        try {
            await updateFunc(id, req)
            addSuccessToast({ text: successMessage, toastLifeTimeMs: 10000 })
            props.closeModal()
        } catch (e) {
            addErrorToast({
                text: errorMessage + e.message,
                toastLifeTimeMs: 10000,
            })
        }
    }

    function isKeyOfRequest(
        key: string,
        req: RequestTypes
    ): key is keyof RequestTypes {
        return key in req
    }

    async function handleCreate(
        tab: typeof Tab[keyof typeof Tab],
        req:
            | UpdateAssignmentRequest
            | UpdateMasterAgreementRequest
            | UpdatePayoutProgramRequest
            | UpdateScheduleRequest
    ) {
        if (!id) {
            addErrorToast({
                text: "Please input an ID",
                toastLifeTimeMs: 10000,
            })
            return
        }
        const singTabName = tab.substring(0, tab.length - 1)
        await processCreation(
            tab,
            id,
            req,
            `${singTabName} successfully updated`
        )
    }

    switch (props.tabName) {
        case "Assignments":
            return (
                <PayoutModal
                    modalTitle={`Update ${props.tabName}`}
                    modalBody={assignmentBody}
                    onConfirm={() => handleCreate(props.tabName, assignmentReq)}
                    closeModal={props.closeModal}
                />
            )
        case "Master Agreements":
            return (
                <PayoutModal
                    modalTitle={`Update ${props.tabName}`}
                    modalBody={masterAgreeBody}
                    onConfirm={() =>
                        handleCreate(props.tabName, masterAgreeReq)
                    }
                    closeModal={props.closeModal}
                />
            )
        case "Programs":
            return (
                <PayoutModal
                    modalTitle={`Update ${props.tabName}`}
                    modalBody={programBody}
                    onConfirm={() => handleCreate(props.tabName, programReq)}
                    closeModal={props.closeModal}
                />
            )
        case "Schedules":
            return (
                <PayoutModal
                    modalTitle={`Update ${props.tabName}`}
                    modalBody={scheduleBody}
                    onConfirm={() => handleCreate(props.tabName, scheduleReq)}
                    closeModal={props.closeModal}
                />
            )
        default:
            return <></>
    }
}
