import { Routes, Route } from "react-router-dom"

import InvoiceDownloadPage from "./components/InvoiceDownload/InvoiceDownloadPage"
import PhoneSystemPage from "./components/PhoneSystem/PhoneSystemPage"
import HomePage from "./components/Home/HomePage"
import VehicleUsageTracker from "./components/VehicleUsageTracker/VehicleUsageTracker"
import DriverAssignment from "./components/DriverAssignment/DriverAssignment"
import FleetBatchTools from "./components/FleetBatchTools/FleetBatchTools"
import { EquipmentReferenceCatalogue } from "./components/EquipmentReferenceCatalogue/EquipmentReferenceCatalogue"
import BaMap from "./components/BaMap/BaMap"
import { PrivateRoute } from "./PrivateRoute"
import {
    FLEET_ASSIGNMENT_PERMISSION,
    DISCOUNTS_MANAGER_PERMISSION,
    FLEET_BATCH_GROUP,
    FLEET_ENABLE_GROUP,
    CASE_TOOL_GROUP,
    VIEW_REBATE_AGREEMENT_PERMISSION,
} from "./Const"
import { SecurityLevel } from "./components/Menu/Menu"
import { Discounts } from "./components/Discounts/Discounts"
import CaseOrder from "./components/CaseOrder/CaseOrder"
import CompetitorQuotes from "./components/CompetitorQuotes/CompetitorQuotes"
import AdminConsole from "./components/ExpenseTracker/ExpenseTracker"
import PayoutProgramConsole from "./components/PayoutProgramConsole/PayoutProgramConsole"
import AutoFleetEnablement from "./components/AutoFleetEnablement/AutoFleetEnablement"
import DSMRequestsPage from "./components/CommissionOverride/DSMRequestsPage"
import CommissionOverrideForm from "./components/CommissionOverride/CommissionOverrideForm"
import { CustomerRebateConsole } from "./components/CustomerRebate/CustomerRebateConsole"

export default function AppRoutes() {
    return (
        <Routes>
            <Route
                path="/invoices"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <InvoiceDownloadPage />
                    </PrivateRoute>
                }
            />
            <Route
                path="/phone-system"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <PhoneSystemPage />
                    </PrivateRoute>
                }
            />

            <Route
                path="/equipment-reference-catalogue"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <EquipmentReferenceCatalogue />
                    </PrivateRoute>
                }
            />

            <Route
                path="/ba-map"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <BaMap />
                    </PrivateRoute>
                }
            />

            <Route
                path="/assign-drivers"
                element={
                    <PrivateRoute permission={FLEET_ASSIGNMENT_PERMISSION}>
                        <DriverAssignment />
                    </PrivateRoute>
                }
            />

            <Route
                path="/discounts"
                element={
                    <PrivateRoute permission={DISCOUNTS_MANAGER_PERMISSION}>
                        <Discounts />
                    </PrivateRoute>
                }
            />

            <Route
                path="/fleet-batch-tools"
                element={
                    <PrivateRoute group={FLEET_BATCH_GROUP}>
                        <FleetBatchTools />
                    </PrivateRoute>
                }
            />

            <Route
                path="/auto-fleet-track"
                element={
                    <PrivateRoute group={FLEET_ENABLE_GROUP}>
                        <AutoFleetEnablement />
                    </PrivateRoute>
                }
            />

            <Route path="/vehicle-tracker" element={<VehicleUsageTracker />} />

            <Route
                path="/case-orders"
                element={
                    <PrivateRoute group={CASE_TOOL_GROUP}>
                        <CaseOrder />
                    </PrivateRoute>
                }
            />

            <Route
                path="/quote-generator"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <CompetitorQuotes />
                    </PrivateRoute>
                }
            />

            <Route
                path="/expense-admin-console"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <AdminConsole />
                    </PrivateRoute>
                }
            />

            <Route
                path="/payout-admin-console"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <PayoutProgramConsole />
                    </PrivateRoute>
                }
            />

            <Route
                path="/commission-override/dsm-review"
                element={
                    <PrivateRoute securityLevels={[SecurityLevel.ADMIN]}>
                        <DSMRequestsPage />
                    </PrivateRoute>
                }
            />

            <Route path="/commission-override/tam-form" element={<CommissionOverrideForm />} />

            <Route
                path="/customer-rebate-console"
                element={
                    <PrivateRoute permission={VIEW_REBATE_AGREEMENT_PERMISSION}>
                        <CustomerRebateConsole />
                    </PrivateRoute>
                }
            />

            <Route path="/" element={<HomePage />} />
        </Routes>
    )
}
