import { Status, Wrapper } from "@googlemaps/react-wrapper"

import GoogleMap from "./GoogleMap"
import { EuiLoadingSpinner } from "@equipmentshare/ds2"
import { BA_GOOGLE_MAP_KEY } from "../../Const"

const render = (status: Status) => {
    switch (status) {
        default:
        case Status.LOADING:
            return <EuiLoadingSpinner />
        case Status.FAILURE:
            return <p>failed to load map</p>
        case Status.SUCCESS:
            return <GoogleMap />
    }
}

function BaMap() {
    return (
        <Wrapper
            apiKey={BA_GOOGLE_MAP_KEY}
            libraries={["visualization", "places", "geometry"]}
            render={render}
        />
    )
}

export default BaMap
