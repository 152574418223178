import { EuiFlexItem, EuiButton, EuiFlexGroup } from "@equipmentshare/ds2"

export type ConfirmationButtonsProps = {
    onCancelClicked: () => void
    onDownloadClicked: () => Promise<void>
    downloadDisabled: boolean
}

export default function ConfirmationButtons(props: ConfirmationButtonsProps) {
    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={props.onCancelClicked}>
                        Cancel
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        fill
                        onClick={props.onDownloadClicked}
                        isLoading={props.downloadDisabled}
                    >
                        Download
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}
