import {
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiModalFooter,
    EuiMarkdownFormat,
    EuiSpacer,
    EuiFieldText,
    EuiSelect,
    EuiButtonEmpty,
    EuiButton,
} from "@equipmentshare/ds2"

interface ReportIssueComponentProps {
    onClose: () => void
    reportIssueInfo: string
    onReasonChange: (value: any) => void
    distinctAssetIds: number[]
    assetSelectValue: string
    assetSelectOptions: {
        value: number
        text: string
    }[]
    onAssetSelect: (value: React.SetStateAction<string>) => void
    onSubmit: () => Promise<void>
    isLoading: boolean
}

export default function ReportIssueComponent(props: ReportIssueComponentProps) {
    return (
        <EuiModal onClose={props.onClose}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Report Device Issues</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiMarkdownFormat>
                    Clicking "Submit" will send a report to IT that there are
                    Errors with your Telematics device. Provide any additional
                    details on the specific errors below:
                </EuiMarkdownFormat>
                <EuiSpacer />
                <EuiFieldText
                    placeholder="Cannot be null (required field)"
                    value={props.reportIssueInfo}
                    onChange={(e) => props.onReasonChange(e)}
                />

                <EuiSpacer />

                {props.distinctAssetIds.length > 1 && (
                    <EuiSelect
                        value={props.assetSelectValue}
                        options={props.assetSelectOptions}
                        onChange={(e) => {
                            props.onAssetSelect(e.target.value)
                        }}
                        isLoading={props.isLoading}
                    />
                )}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={props.onClose}>Close</EuiButtonEmpty>
                <EuiButton
                    onClick={props.onSubmit}
                    isDisabled={props.reportIssueInfo ? false : true}
                    fill
                >
                    Submit
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
