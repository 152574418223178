import React, { useEffect, useState } from "react"
import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiFormRow,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiButtonEmpty,
    EuiFlexItem,
    EuiButton,
    EuiSelect,
    EuiRange,
    EuiText,
} from "@equipmentshare/ds2"

import {
    Option,
    CompanyPickerWithInitial,
    ProviderPicker,
    ProviderPickerWithInitial,
} from "../Pickers"
import { getCompanyById } from "../../services/Misc"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import { getProviderById } from "../../services/Discounts"
import {
    CreateDiscountRequest,
    Discount,
    UpdateDiscountRequest,
} from "../../services/Skunkworks/Generated"
import { formatDiscount, LIST_BASED } from "./Discounts"

interface DiscountFormProps {
    onCloseClicked: () => void
    onSavedClicked: (
        data: UpdateDiscountRequest | CreateDiscountRequest
    ) => void
    initialValues?: Partial<Discount>
    isSaving: boolean
}

const options = [
    { value: 1, text: "Cost Price Based Discount" },
    { value: 2, text: "List Price Based Discount" },
]

export function DiscountForm(props: DiscountFormProps) {
    const initialModifier = Number(props.initialValues?.modifier) || 1
    const initialTypeId = props.initialValues?.discount_type_id ?? LIST_BASED

    const [provider, setProvider] = useState<Option | null>(null)
    const [company, setCompany] = useState<Option | null>(null)
    const [modifier, setModifier] = useState<number>(initialModifier)
    const [discountTypeId, setDiscountTypeId] = useState<number>(initialTypeId)
    const { accessToken } = useAuthenticationDetails()
    const isValid = provider && company

    function handleSaveClicked() {
        if (provider && company) {
            props.onSavedClicked({
                modifier,
                discount_type_id: discountTypeId,
                company_id: parseInt(company.id),
                provider_id: parseInt(provider.id),
            })
        }
    }

    function handleChangeType(e: any) {
        setDiscountTypeId(parseInt(e.target.value))
        setModifier(1)
    }

    useEffect(() => {
        if (accessToken) {
            if (props.initialValues?.company_id) {
                getCompanyById(props.initialValues.company_id).then(
                    (company) => {
                        if (company) {
                            setCompany({
                                id: `${company.company_id}`,
                                label: company.name,
                            })
                        }
                    }
                )
            }

            if (props.initialValues?.provider_id) {
                getProviderById(props.initialValues.provider_id).then(
                    (provider) => {
                        if (provider) {
                            setProvider({
                                id: `${provider.providerId}`,
                                label: provider.name,
                            })
                        }
                    }
                )
            }
        }
    }, [
        accessToken,
        props.initialValues?.company_id,
        props.initialValues?.provider_id,
    ])

    return (
        <EuiFlyout ownFocus onClose={props.onCloseClicked}>
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>
                        {props.initialValues?.date_created
                            ? "Update"
                            : "Create"}{" "}
                        Discount
                    </h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <CompanyPickerWithInitial
                    onSelected={setCompany}
                    initial={company?.label || "Loading..."}
                />

                {props.initialValues?.provider_id ? (
                    <ProviderPickerWithInitial
                        onSelected={setProvider}
                        initial={provider?.label || "Loading..."}
                    />
                ) : (
                    <ProviderPicker onSelected={setProvider} />
                )}

                <EuiFormRow label="Discount Type">
                    <EuiSelect
                        options={options}
                        value={discountTypeId}
                        onChange={handleChangeType}
                    />
                </EuiFormRow>

                <EuiFormRow label="Modifier">
                    <EuiRange
                        value={modifier}
                        onChange={(e: any) =>
                            setModifier(parseFloat(e.currentTarget.value))
                        }
                        step={0.01}
                        showRange
                        min={discountTypeId === LIST_BASED ? 0 : 1}
                        max={discountTypeId === LIST_BASED ? 1 : 5}
                    />
                </EuiFormRow>

                <EuiText>
                    <p>{formatDiscount(discountTypeId, modifier)}</p>
                </EuiText>
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            onClick={props.onCloseClicked}
                            flush="left"
                            isDisabled={props.isSaving}
                        >
                            Close
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton
                            onClick={handleSaveClicked}
                            fill
                            disabled={props.isSaving || !isValid}
                            isLoading={props.isSaving}
                        >
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    )
}
