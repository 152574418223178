import { useState, useRef, useMemo, useEffect } from "react"
import moment from "moment"

import {
    EuiFormRow,
    EuiDatePicker,
    EuiDatePickerRange,
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiBasicTable,
    EuiButtonEmpty,
    EuiRadioGroup,
    EuiFieldNumber,
    EuiSwitch,
    EuiFilePicker,
    EuiButtonIcon,
} from "@equipmentshare/ds2"
import { DeleteBin6LineIcon } from "@equipmentshare/ds2-icons"

import { RSPCompanyMultiSelect, MultiSelectHandle, Option, getCompanyOptions } from "../../MultiSelectPickers"
import { AddRebateTierButton } from "./AddRebateTierButton"
import { EditRebateTierButton } from "./EditRebateTierButton"
import { useToasts } from "../../../hooks/useToasts"
import { CustomerRebateData, ValidatedCustomerRebateData, RebateTierData } from "../Utils"
import { usdFormatter, formatException } from "../../../Utils"

const REBATE_TIER_TEXT = "Note: You need at least one rebate tier upon submission"

const PAYMENT_TERMS: Record<string, string> = {
    "30": "30 Days",
    "45": "45 Days",
    "60": "60 Days",
    other: "Other",
}

const verifyFormData = (data: CustomerRebateData): data is ValidatedCustomerRebateData =>
    data.companies.length > 0 && !!data.rebate_period_begin && !!data.rebate_period_end && data.rebate_tiers.length > 0

// Assumes tiers are sorted by the min payment
const doTiersOverlap = (tiers: RebateTierData[]) => {
    for (let i = 0; i < tiers.length - 1; i++) {
        if (tiers[i].gross_rent_payments_max >= tiers[i + 1].gross_rent_payments_min) {
            return true // overlap
        }
    }

    return false // no overlap
}

export type CustomerRebateFormProps = {
    initialFormData: CustomerRebateData
    handleSubmit: (formData: ValidatedCustomerRebateData, agreementPdf: FileList | null) => Promise<void>
    clearOnSubmit?: boolean
}

export const CustomerRebateForm: React.FC<CustomerRebateFormProps> = ({
    initialFormData,
    handleSubmit,
    clearOnSubmit,
}) => {
    const { addErrorToast } = useToasts()

    const rspCompanyMultiSelectRef = useRef<MultiSelectHandle>(null)

    const [formData, setFormData] = useState<CustomerRebateData>(initialFormData)
    const [agreementPdf, setAgreementPdf] = useState<FileList | null>(null)
    const [otherPaymentTerms, setOtherPaymentTerms] = useState<number | null>(null)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<Option[]>(
        getCompanyOptions(initialFormData.companies)
    )

    const filePickerRef = useRef<EuiFilePicker>(null)

    const sortedTiers = useMemo(
        () => formData.rebate_tiers.sort((a, b) => a.gross_rent_payments_min - b.gross_rent_payments_min),
        [formData.rebate_tiers]
    )

    const handleRebateTierSave = (rebateTier: RebateTierData) => {
        setFormData({
            ...formData,
            rebate_tiers: [...formData.rebate_tiers, rebateTier],
        })
    }

    const handleCompanySelectionChange = (selectedCompanies: Option[]) => {
        setSelectedCompanyOptions(selectedCompanies)
        setFormData({
            ...formData,
            companies: selectedCompanies.map((company) => ({
                name: company.label.split(" - ").slice(0, -1).join(" - "),
                company_id: parseInt(company.key),
            })),
        })
    }

    const resetForm = () => {
        setFormData({
            ...formData,
            companies: [],
            rebate_period_begin: moment.utc(),
            rebate_period_end: moment.utc().endOf("year"),
            payment_terms: "30",
            custom_rental_rates: false,
            rebate_tiers: [],
            file_name: null,
        })
        setOtherPaymentTerms(null)
        rspCompanyMultiSelectRef.current?.clearValue()
        filePickerRef.current?.removeFiles()
    }

    const createSubmissionData = (validatedFormData: ValidatedCustomerRebateData) => {
        // Add type { [key: string]: any } here makes values accessible by a string variable key for loops below
        const submissionData: ValidatedCustomerRebateData & { [key: string]: any } = { ...validatedFormData }

        if (validatedFormData.payment_terms === "other") {
            submissionData.payment_terms = String(otherPaymentTerms)
        }

        return submissionData
    }

    const onFileChange = (agreementPdf: FileList | null) => {
        const file = agreementPdf?.[0]
        if (file && file.type !== "application/pdf") {
            addErrorToast({
                text: "Invalid file type. Please upload a PDF file only.",
                toastLifeTimeMs: 10000,
            })
            filePickerRef.current?.removeFiles()
            return
        }

        setAgreementPdf(agreementPdf)
        setFormData({ ...formData, file_name: agreementPdf ? agreementPdf[0]?.name : null })
    }

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!verifyFormData(formData)) {
            addErrorToast({
                text: "Please complete all required fields.",
                toastLifeTimeMs: 10000,
            })
            return
        }

        if (doTiersOverlap(sortedTiers)) {
            addErrorToast({
                text: "Please ensure no rebate tier ranges overlap.",
                toastLifeTimeMs: 10000,
            })
            return
        }

        try {
            setIsSubmitting(true)
            const submissionData = createSubmissionData(formData)
            await handleSubmit(submissionData, agreementPdf)
            if (clearOnSubmit) {
                resetForm()
            }
        } catch (e) {
            addErrorToast({
                text: formatException(e),
                toastLifeTimeMs: 10000,
            })
        } finally {
            setIsSubmitting(false)
        }
    }

    const tierTableActions = [
        {
            render: (rebateTier: RebateTierData) => {
                return (
                    <EditRebateTierButton
                        rebateTier={rebateTier}
                        handleSave={(rTier: RebateTierData) =>
                            setFormData({
                                ...formData,
                                rebate_tiers: formData.rebate_tiers.map((currTier) => {
                                    if (currTier.rebate_tier_id === rebateTier.rebate_tier_id) {
                                        return rTier
                                    }

                                    return currTier
                                }),
                            })
                        }
                    />
                )
            },
        },
        {
            render: (rebateTier: RebateTierData) => {
                return (
                    <EuiButtonEmpty
                        onClick={() =>
                            setFormData({
                                ...formData,
                                rebate_tiers: formData.rebate_tiers.filter((currTier) => currTier !== rebateTier),
                            })
                        }
                        color="danger"
                    >
                        Delete
                    </EuiButtonEmpty>
                )
            },
        },
    ]

    const renderTierTable = () => {
        if (formData.rebate_tiers.length) {
            return (
                <EuiBasicTable
                    items={sortedTiers}
                    style={{ maxWidth: 650 }}
                    columns={[
                        {
                            field: "gross_rent_payments_min",
                            name: "Min Rent Payment",
                            render: usdFormatter.format,
                        },
                        {
                            field: "gross_rent_payments_max",
                            name: "Max Rent Payment",
                            render: usdFormatter.format,
                        },
                        {
                            field: "rebate_percentage",
                            name: "Rebate Percentage",
                            render: (value: number) => (value * 100).toFixed(2),
                        },
                        {
                            name: "Actions",
                            actions: tierTableActions,
                        },
                    ]}
                />
            )
        }
    }

    useEffect(() => {
        if (!Object.keys(PAYMENT_TERMS).includes(initialFormData.payment_terms)) {
            setFormData({ ...initialFormData, payment_terms: "other" })
            setOtherPaymentTerms(parseFloat(initialFormData.payment_terms))
        }
        // Only run once on component mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form onSubmit={onSubmit}>
            <EuiFormRow fullWidth helpText={formData.companies.length < 1 ? "Must select a company" : undefined}>
                <RSPCompanyMultiSelect
                    ref={rspCompanyMultiSelectRef}
                    selected={selectedCompanyOptions}
                    onSelected={handleCompanySelectionChange}
                    isInvalid={selectedCompanyOptions.length === 0}
                />
            </EuiFormRow>

            <EuiFormRow label="Rebate Period">
                <EuiDatePickerRange
                    startDateControl={
                        <EuiDatePicker
                            selected={formData.rebate_period_begin}
                            onChange={(date) => setFormData({ ...formData, rebate_period_begin: date })}
                            aria-label="Start Date"
                            placeholder="Start Date"
                            startDate={formData.rebate_period_begin}
                            endDate={formData.rebate_period_end}
                            maxDate={formData.rebate_period_end ?? undefined}
                            isInvalid={
                                !!formData.rebate_period_begin &&
                                !!formData.rebate_period_end &&
                                formData.rebate_period_begin > formData.rebate_period_end
                            }
                            adjustDateOnChange={false}
                            fullWidth
                            required
                        />
                    }
                    endDateControl={
                        <EuiDatePicker
                            selected={formData.rebate_period_end}
                            onChange={(date) => setFormData({ ...formData, rebate_period_end: date })}
                            aria-label="End Date"
                            placeholder="End Date"
                            startDate={formData.rebate_period_begin}
                            endDate={formData.rebate_period_end}
                            minDate={formData.rebate_period_begin ?? undefined}
                            isInvalid={
                                !!formData.rebate_period_end &&
                                !!formData.rebate_period_begin &&
                                formData.rebate_period_end < formData.rebate_period_begin
                            }
                            adjustDateOnChange={false}
                            fullWidth
                            required
                        />
                    }
                    fullWidth
                />
            </EuiFormRow>

            <EuiFormRow label="Payment Terms (Days)">
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiRadioGroup
                            options={Object.keys(PAYMENT_TERMS).map((key) => ({
                                id: key,
                                label: PAYMENT_TERMS[key],
                            }))}
                            idSelected={formData.payment_terms}
                            onChange={(id) => setFormData({ ...formData, payment_terms: id })}
                        />
                    </EuiFlexItem>
                    {formData.payment_terms === "other" && (
                        <EuiFlexItem>
                            <EuiFieldNumber
                                type="number"
                                placeholder="Enter Payment Term"
                                value={otherPaymentTerms ?? ""}
                                onChange={(e) => {
                                    const value = parseFloat(e.target.value)
                                    setOtherPaymentTerms(isNaN(value) ? null : value)
                                }}
                                step={1}
                                min={0}
                                required
                            />
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiFormRow label="Other Settings">
                <EuiSwitch
                    label="Custom Rental Rates"
                    checked={formData.custom_rental_rates}
                    onChange={(e) => setFormData({ ...formData, custom_rental_rates: e.target.checked })}
                />
            </EuiFormRow>

            <EuiFormRow label="Rebate Tiers" fullWidth>
                <EuiFlexGroup direction="column">
                    <EuiFlexItem>
                        <EuiFlexGroup alignItems="center">
                            <EuiFlexItem grow={false}>
                                <AddRebateTierButton handleSave={handleRebateTierSave} />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiText size="m">{REBATE_TIER_TEXT}</EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>{renderTierTable()}</EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>

            <EuiFormRow label="Agreement PDF">
                {formData?.file_name ? (
                    <EuiFlexGroup gutterSize="s">
                        <EuiFlexItem grow={false}>
                            <EuiText>{formData.file_name}</EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                                aria-label="Delete PDF"
                                display="empty"
                                iconType={DeleteBin6LineIcon}
                                onClick={() => {
                                    setAgreementPdf(null)
                                    setFormData({ ...formData, file_name: null })
                                }}
                            />
                        </EuiFlexItem>
                    </EuiFlexGroup>
                ) : (
                    <EuiFilePicker
                        ref={filePickerRef}
                        initialPromptText="Select or drag and drop a single pdf file"
                        onChange={onFileChange}
                        display={"large"}
                        accept=".pdf"
                    />
                )}
            </EuiFormRow>

            <EuiFormRow>
                <EuiButton type="submit" isLoading={isSubmitting}>
                    Submit
                </EuiButton>
            </EuiFormRow>
        </form>
    )
}

export default CustomerRebateForm
