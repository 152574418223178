import { useCallback, useEffect, useRef, useState } from "react"
import debounce from "lodash.debounce"
import axios, { AxiosResponse } from "axios"

import Overlay from "./Overlay"
import MapControl from "./MapControl"
import MapUploadFlyout from "./MapUploadFlyout"
import { useQueryParams } from "../../hooks/useQueryParams"
import { getLatestMapUrl } from "../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import { BaMapData } from "./BaMapTypes"

function GoogleMap() {
    const ref = useRef<HTMLDivElement>(null)
    const { params, setParams } = useQueryParams()
    const { accessToken } = useAuthenticationDetails()

    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [date, setDate] = useState<Date | null>(null)
    const [uploadIsOpen, setUploadIsOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function loadProspects(map: google.maps.Map, prospects: number[][]) {
        function getAssetWidth(lat: number) {
            const zoom = map.getZoom()

            // if zoomed out enough, just return a fixed size
            if (!zoom || zoom < 12) {
                return 32
            }

            // strategy at this point is to stretch an asset over a fixed physical distance
            // found this magic on the internet https://stackoverflow.com/a/31990016
            // I didn't put any time into understanding the math but it works as desired
            const oneMileInKm = 1600 // desired physical width
            return (
                oneMileInKm /
                ((156543.03392 * Math.cos((lat * Math.PI) / 180)) /
                    Math.pow(2, zoom))
            )
        }

        function createProspectMarker(lat: number, lng: number) {
            const len = getAssetWidth(lat)
            return new google.maps.Marker({
                map,
                position: { lat, lng },
                icon: {
                    url: "/target-hi-res.png",
                    scaledSize: new google.maps.Size(len, len),
                    origin: new google.maps.Point(0, 0),
                    // NOTE: anchor should always be the center of the image
                    anchor: new google.maps.Point(len / 2, len / 2),
                },
            })
        }

        let markers = prospects.map(([lat, lng]) =>
            createProspectMarker(lat, lng)
        )

        map.addListener("zoom_changed", () => {
            markers = markers.map((marker) => {
                const position = marker.getPosition()!
                const [lat, lng] = [position.lat(), position.lng()]
                marker.setMap(null) // remove previous marker
                return createProspectMarker(lat, lng)
            })
        })
    }

    function loadEsLocations(map: google.maps.Map, esLocations: number[][]) {
        esLocations.forEach(([lat, lng]) => {
            new google.maps.Marker({
                map,
                position: { lat, lng },
                icon: "/logosmall.png",
            })
        })
    }

    function setRadius(
        map: google.maps.Map,
        heatmap: google.maps.visualization.HeatmapLayer
    ) {
        // equation was derived from a set of hard-coded zoom -> radius
        // pairs from BA by using exponential regression -- primarily to
        // accomodate ANY potential zoom level, no matter how extreme
        const equation = (x: number) => 0.13386 * Math.pow(1.7957, x)

        const defaultRadius = 70
        const zoomLevel = map.getZoom()
        heatmap.set("radius", zoomLevel ? equation(zoomLevel) : defaultRadius)
    }

    const updateQueryParams = useCallback(() => {
        if (map) {
            const zoom = map.getZoom()
            const center = map.getCenter()
            if (zoom && center) {
                setParams({
                    zoom: `${zoom}`,
                    lat: `${center.lat()}`,
                    lng: `${center.lng()}`,
                })
            }
        }
    }, [map, setParams])

    const createHeatmap = useCallback(
        (_map: google.maps.Map, data: number[][]) => {
            const heatmap = new google.maps.visualization.HeatmapLayer({
                data: data.map(([lat, lng, weight]) => ({
                    location: new google.maps.LatLng(lat, lng),
                    weight,
                })),
                maxIntensity: 1500,
                map: _map,
                gradient: [
                    "rgba(255, 255, 178, 0)",
                    "rgba(254, 217, 118, 0.2)",
                    "rgba(254, 178, 76, 1)",
                    "rgba(253, 141, 60, 1)",
                    "rgba(240, 59, 32, 1)",
                    "rgba(189, 0, 38, 1)",
                ],
            })

            setRadius(_map, heatmap)
            _map.addListener("zoom_changed", () => setRadius(_map, heatmap))
        },
        []
    )

    useEffect(() => {
        if (ref.current && !map) {
            // attempts to loads data from params, if not, it defaults to texas
            // NOTE: parseInt/parseFloat are safe functions that return NaN if value doesn't make sense
            const zoom = parseInt(params.get("zoom")!) || 10
            const lat = parseFloat(params.get("lat")!) || 29.7604
            const lng = parseFloat(params.get("lng")!) || -95.3698

            setMap(
                new (window as any).google.maps.Map(ref.current, {
                    zoom,
                    center: { lat, lng },
                    mapTypeId: "roadmap",
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT,
                    },
                })
            )
        }
    }, [ref, map, params])

    useEffect(() => {
        if (map && accessToken && !date) {
            setIsLoading(true)
            getLatestMapUrl()
                .then(({ url }) => axios.create().get<BaMapData>(url))
                .then((response: AxiosResponse<BaMapData>) => {
                    const baMapData = response.data
                    if (baMapData) {
                        setDate(new Date(baMapData.date))
                        createHeatmap(map, baMapData.heatmap_data)
                        loadEsLocations(map, baMapData.es_locations)
                        if (baMapData.prospective_locations) {
                            loadProspects(map, baMapData.prospective_locations)
                        }
                    }
                })
                .catch((err) => console.error(err))
                .finally(() => setIsLoading(false))

            map.addListener("center_changed", debounce(updateQueryParams, 200))
            map.addListener("zoom_changed", debounce(updateQueryParams, 200))
        }
    }, [map, accessToken, date, createHeatmap, updateQueryParams])

    function handleNewPlaceSelected(
        place: google.maps.places.PlaceResult | null
    ) {
        if (map && place && place.geometry) {
            const viewport = place.geometry.viewport
            if (viewport) {
                map.fitBounds(viewport)
            } else {
                map.setCenter(place.geometry.location!)
                map.setZoom(17)
            }
        }
    }

    // height needs to be explicitly set for this to work
    // 48px is a bit of a hack to account for the height of the EUI Header
    const style = { height: "calc(100vh - 48px)" }

    return (
        <div style={style} ref={ref} id="map">
            {uploadIsOpen && (
                <MapUploadFlyout
                    onClose={() => setUploadIsOpen(false)}
                    onSaved={() => window.location?.reload()}
                />
            )}
            {map && (
                <MapControl
                    map={map}
                    position={google.maps.ControlPosition.TOP_CENTER}
                >
                    <Overlay
                        map={map}
                        date={date}
                        isLoading={isLoading}
                        onNewPlaceSelected={handleNewPlaceSelected}
                        onUploadClicked={() => setUploadIsOpen(true)}
                    />
                </MapControl>
            )}
        </div>
    )
}

export default GoogleMap
