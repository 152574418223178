import { EuiSpacer, EuiText, EuiButton } from "@equipmentshare/ds2"

export type TabScreensProps = {
    name: string
    picker: JSX.Element
    searchButtonClick: () => void
    table: JSX.Element
    isLoading: boolean
    editButton?: JSX.Element
    showEditButton?: boolean
}

export default function TabScreens(props: TabScreensProps) {
    return (
        <>
            <EuiSpacer />
            <EuiText>{props.name}</EuiText>
            <EuiSpacer />
            {props.picker}
            <EuiButton
                onClick={props.searchButtonClick}
                fill
                isLoading={props.isLoading}
            >
                Search
            </EuiButton>
            {props.showEditButton && (
                <>
                    <EuiSpacer />
                    {props.editButton}
                </>
            )}
            <EuiSpacer />
            {props.table}
        </>
    )
}
