import React from "react"

import {
    EuiText,
    EuiSpacer,
    EuiHorizontalRule,
    EuiBasicTable,
    EuiBasicTableColumn,
    EuiTableFieldDataColumnType,
    CriteriaWithPagination,
    EuiIcon,
    useEuiTheme,
} from "@equipmentshare/ds2"
import { CheckboxFillIcon, CheckboxBlankLineIcon } from "@equipmentshare/ds2-icons"

import { EquipmentServiceManual, ServiceManualsAssetStatus } from "../../../services/Skunkworks/Generated"

export const PAGE_SIZE_OPTIONS = [20, 50, 100]

export type PageCriteria = {
    pageSize: number
    pageIndex: number
    sortField: keyof ServiceManualsAssetStatus
    sortDirection: "asc" | "desc"
}

export type EquipmentReferenceTableProps = {
    documentStatuses: ServiceManualsAssetStatus[]
    totalDocumentStatusesCount: number
    pageCriteria: PageCriteria
    onPageCriteriaChange: (newPageCriteria: PageCriteria) => void
    isLoading?: boolean
}

export const DocumentStatusTable: React.FC<EquipmentReferenceTableProps> = ({
    documentStatuses,
    totalDocumentStatusesCount,
    pageCriteria,
    onPageCriteriaChange,
    isLoading,
}) => {
    const checkboxColumnWidth = "100px"
    const colorTheme = useEuiTheme().euiTheme.colors

    const renderCheckboxIcon = (manualTypeFound: boolean) => {
        return manualTypeFound ? (
            <EuiIcon type={CheckboxFillIcon} style={{ color: colorTheme.success }} size="l" />
        ) : (
            <EuiIcon type={CheckboxBlankLineIcon} style={{ color: colorTheme.darkShade }} size="l" />
        )
    }

    const columns: EuiBasicTableColumn<ServiceManualsAssetStatus>[] = [
        {
            field: "asset_count",
            name: "Count",
            textOnly: true,
            sortable: true,
        },
        {
            field: "equipment_class_name",
            name: "Class",
            sortable: true,
            textOnly: true,
        },
        {
            field: "equipment_make_name",
            name: "Make",
            sortable: true,
            textOnly: true,
        },
        {
            field: "equipment_model_name",
            name: "Model",
            sortable: true,
            textOnly: true,
        },
        {
            field: "has_operators_manual",
            name: "Operator",
            textOnly: true,
            align: "center",
            width: checkboxColumnWidth,
            render: (hasOperatorsManual: boolean) => renderCheckboxIcon(hasOperatorsManual),
        },
        {
            field: "has_parts_manual",
            name: "Parts",
            textOnly: true,
            align: "center",
            width: checkboxColumnWidth,
            render: (hasPartsManual: boolean) => renderCheckboxIcon(hasPartsManual),
        },
        {
            field: "has_service_manual",
            name: "Service",
            textOnly: true,
            align: "center",
            width: checkboxColumnWidth,
            render: (hasServiceManual: boolean) => renderCheckboxIcon(hasServiceManual),
        },
        {
            field: "has_warranty_manual",
            name: "Warranty",
            textOnly: true,
            align: "center",
            width: checkboxColumnWidth,
            render: (hasWarrantyManual: boolean) => renderCheckboxIcon(hasWarrantyManual),
        },
        {
            field: "has_other_manual",
            name: "Other",
            textOnly: true,
            align: "center",
            width: checkboxColumnWidth,
            render: (hasOtherManual: boolean) => renderCheckboxIcon(hasOtherManual),
        },
    ]

    const getRowProps = (item: ServiceManualsAssetStatus) => {
        return {
            "data-test-subj": `row-${item.equipment_class_id}-${item.equipment_make_id}-${item.equipment_model_id}`,
        }
    }

    const getCellProps = (
        item: ServiceManualsAssetStatus,
        column: EuiTableFieldDataColumnType<EquipmentServiceManual>
    ) => {
        return {
            "data-test-subj": `cell-${item.equipment_make_id}-${item.equipment_model_id}-${column.field}`,
        }
    }

    const onTableChange = ({ page, sort }: CriteriaWithPagination<ServiceManualsAssetStatus>) => {
        onPageCriteriaChange({
            pageSize: page.size,
            pageIndex: page.index,
            sortField: sort?.field ?? pageCriteria.sortField,
            sortDirection: sort?.direction ?? pageCriteria.sortDirection,
        })
    }

    const renderResultsCount = () => {
        const pageSize = pageCriteria.pageSize
        const pageIndex = pageCriteria.pageIndex

        const pageStart = pageSize * pageIndex + 1
        const pageEnd = pageSize * pageIndex + pageSize

        return (
            <>
                <strong>
                    {pageStart > totalDocumentStatusesCount ? totalDocumentStatusesCount : pageStart}-
                    {pageEnd > totalDocumentStatusesCount ? totalDocumentStatusesCount : pageEnd}
                </strong>{" "}
                of {totalDocumentStatusesCount}
            </>
        )
    }

    return (
        <>
            <EuiText size="xs">
                Showing {renderResultsCount()} <strong>Document Statuses</strong>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin="none" style={{ height: 1 }} />
            <EuiBasicTable
                items={documentStatuses}
                itemId={(item) => `${item.equipment_class_id}-${item.equipment_make_id}-${item.equipment_model_id}`}
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                tableLayout="auto"
                loading={isLoading}
                noItemsMessage={isLoading ? "Loading..." : "No documents found"}
                pagination={{
                    pageIndex: pageCriteria.pageIndex,
                    pageSize: pageCriteria.pageSize,
                    totalItemCount: totalDocumentStatusesCount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
                sorting={{
                    sort: {
                        field: pageCriteria.sortField,
                        direction: pageCriteria.sortDirection,
                    },
                }}
                onChange={onTableChange}
            />
        </>
    )
}

export default DocumentStatusTable
