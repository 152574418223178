import { useState } from "react"

import {
    EuiFormRow,
    EuiButton,
    EuiSpacer,
    EuiCallOut,
} from "@equipmentshare/ds2"

import Page from "../Page/Page"
import FormattedNumberFormRow, {
    FormattedNumberType,
} from "./FormattedNumberFormRow"
import { useAddPhoneNumber } from "../../services/Skunkworks/Generated"
import { CompanyPicker } from "../Pickers"

export default function PhoneSystemEntry() {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [companyId, setCompanyId] = useState<number | null>(null)
    const [showErrors, setShowErrors] = useState(false)
    const [buttonLoad, setButtonLoad] = useState(false)
    const [postError, setPostError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState("")
    const { mutateAsync: addPhoneNumber } = useAddPhoneNumber()

    const onSubmitButtonClicked = async () => {
        setButtonLoad(true)
        setErrorMessage("")
        setPostError(false)
        setSuccess("")

        if (phoneNumber.length < 12 || !companyId) {
            setShowErrors(true)
            setButtonLoad(false)
        } else {
            try {
                await addPhoneNumber({
                    data: {
                        phone_number: phoneNumber.replace(
                            new RegExp("-", "g"),
                            ""
                        ),
                        company_id: companyId,
                    },
                })
                setSuccess(
                    `Phone number ${phoneNumber} for company #${companyId} has been added!`
                )
            } catch (e) {
                setErrorMessage(e.message)
                setPostError(true)
            } finally {
                setButtonLoad(false)
            }
        }
    }
    return (
        <>
            <Page title="Phone System">
                <FormattedNumberFormRow
                    name="telephone"
                    label="Phone Number:"
                    onChange={setPhoneNumber}
                    required
                    type={FormattedNumberType.PHONE}
                />
                <CompanyPicker
                    hasErrors={showErrors}
                    onIdSelected={setCompanyId}
                />
                <EuiFormRow>
                    <EuiButton
                        onClick={onSubmitButtonClicked}
                        isLoading={buttonLoad}
                    >
                        Submit
                    </EuiButton>
                </EuiFormRow>
                {postError && (
                    <>
                        <EuiSpacer size="m" />
                        <EuiCallOut
                            title="Sorry, there was an error"
                            color="danger"
                            iconType="alert"
                        >
                            <p>{errorMessage}</p>
                        </EuiCallOut>
                    </>
                )}
                {success && (
                    <>
                        <EuiSpacer size="m" />
                        <EuiCallOut
                            title="Success!"
                            color="success"
                            iconType="check"
                        >
                            <p>{success}</p>
                        </EuiCallOut>
                    </>
                )}
            </Page>
        </>
    )
}
