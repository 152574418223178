import React, { FocusEventHandler } from "react"

import { EuiFieldText, EuiFormRow } from "@equipmentshare/ds2"

export enum FormattedNumberType {
    PHONE,
    SSN,
}

interface FormattedNumberFormRowProps {
    name: string
    label: string
    type: FormattedNumberType

    onChange?: (value: string) => void
    onBlur?: FocusEventHandler
    isInvalid?: boolean
    required?: boolean
    error?: string
}

function FormattedNumberFormRow(props: FormattedNumberFormRowProps) {
    const [value, setValue] = React.useState<string>("")

    const handleChange = (event: any) => {
        const offset = props.type === FormattedNumberType.SSN ? 0 : 1

        // stolen from https://stackoverflow.com/questions/7685175/autoformat-ssn-while-entering-the-number
        const value = event.target.value
        let val = value.replace(/\D/g, "")
        let newVal = ""
        if (val.length > 4) {
            setValue(val)
        }

        // Adds first Dash
        if (val.length > 3 && val.length < 6 + offset) {
            newVal += val.substr(0, 3) + "-"
            val = val.substr(3)
        }

        // Adds second Dash
        if (val.length > 5 + offset) {
            newVal += val.substr(0, 3) + "-"
            newVal += val.substr(3, 2 + offset) + "-"
            val = val.substr(5 + offset)
        }
        newVal += val
        newVal = newVal.substring(0, 11 + offset)
        setValue(newVal)
        if (props.onChange) props.onChange(newVal)
    }

    return (
        <EuiFormRow
            label={props.label}
            error={props.error}
            isInvalid={props.isInvalid}
        >
            <EuiFieldText
                name={props.name}
                isInvalid={props.isInvalid}
                onBlur={props.onBlur}
                required={props.required}
                value={value}
                onChange={handleChange}
            />
        </EuiFormRow>
    )
}

export default FormattedNumberFormRow
