import { useAuth0 } from "@auth0/auth0-react"

import { SimpleApiProvider } from "@equipmentshare/simple-json-api-client"

import config from "../services/config"

const EsApiProvider = ({ children }: any) => {
    const { getAccessTokenSilently } = useAuth0()
    return (
        <SimpleApiProvider
            apiUrl={config.API_URL}
            getToken={getAccessTokenSilently}
        >
            {children}
        </SimpleApiProvider>
    )
}

export default EsApiProvider
