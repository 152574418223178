import React, { useEffect, useState } from "react"
import {
    EuiFlyout,
    EuiFlyoutHeader,
    EuiTitle,
    EuiFlyoutBody,
    EuiDatePicker,
    EuiFormRow,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiButtonEmpty,
    EuiFlexItem,
    EuiButton,
    EuiFieldText,
} from "@equipmentshare/ds2"
import { Moment } from "moment"
import moment from "moment"

import { UserPicker, UserPickerWithInitial, Option } from "../Pickers"
import { UpdateAssetAssignmentRequest } from "../../services/Skunkworks/Generated"
import { getUsers } from "../../services/Misc"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"

interface AssignmentFormProps {
    onCloseClicked: () => void
    onSavedClicked: (data: UpdateAssetAssignmentRequest, name: string) => void
    initialValues?: Partial<UpdateAssetAssignmentRequest>
    isSaving: boolean
}

export function AssignmentForm(props: AssignmentFormProps) {
    const initialStart = props.initialValues
        ? moment(props.initialValues.start_date)
        : moment()
    const initialEnd = props.initialValues?.end_date
        ? moment(props.initialValues.end_date)
        : null
    const initialAssetId = props.initialValues?.asset_id
        ? `${props.initialValues?.asset_id}`
        : ""

    const [assetId, setAssetId] = useState(initialAssetId)
    const [user, setUser] = useState<Option | null>(null)
    const [startDate, setStartDate] = useState<Moment>(initialStart)
    const [endDate, setEndDate] = useState<Moment | null>(initialEnd)
    const { accessToken } = useAuthenticationDetails()

    const isValid = user && startDate

    function handleSaveClicked() {
        if (user) {
            props.onSavedClicked(
                {
                    start_date: startDate.toDate().toISOString(),
                    end_date: endDate
                        ? endDate.toDate().toISOString()
                        : undefined,
                    user_id: parseInt(user.id),
                    asset_id: parseInt(assetId),
                },
                user.label.split(" ").slice(0, 2).join(" ")
            )
        }
    }

    useEffect(() => {
        if (accessToken && props.initialValues?.user_id) {
            getUsers([props.initialValues.user_id]).then((users) => {
                if (users.length && users[0]) {
                    const { first_name, last_name, user_id } = users[0]
                    setUser({
                        id: `${user_id}`,
                        label: `${first_name} ${last_name}`,
                    })
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    return (
        <EuiFlyout ownFocus onClose={props.onCloseClicked}>
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>
                        {props.initialValues?.start_date ? "Update" : "Create"}{" "}
                        Assignment
                    </h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <EuiFormRow label="Asset Id">
                    <EuiFieldText
                        placeholder="Enter Asset Id..."
                        value={assetId}
                        onChange={(e) => setAssetId(e.target.value)}
                    />
                </EuiFormRow>

                {props.initialValues?.user_id ? (
                    <UserPickerWithInitial
                        onSelected={setUser}
                        initial={user?.label || "Loading..."}
                    />
                ) : (
                    <UserPicker onSelected={setUser} />
                )}

                <EuiFormRow label="Start Date">
                    <EuiDatePicker
                        required
                        showTimeSelect
                        selected={startDate}
                        onChange={(date) => setStartDate(date!)}
                    />
                </EuiFormRow>

                <EuiFormRow label="End Date (Optional)">
                    <EuiDatePicker
                        showTimeSelect
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                    />
                </EuiFormRow>
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            onClick={props.onCloseClicked}
                            flush="left"
                            isDisabled={props.isSaving}
                        >
                            Close
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton
                            onClick={handleSaveClicked}
                            fill
                            disabled={props.isSaving || !isValid}
                            isLoading={props.isSaving}
                        >
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    )
}
