import moment from "moment"

import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiTitle } from "@equipmentshare/ds2"

import { CustomerRebateForm } from "./CustomerRebateForm"
import { CustomerRebateData, ValidatedCustomerRebateData, RebateTierData } from "../Utils"
import {
    useUpdateRebateAgreement,
    UpdateRebateAgreementRequest,
    RebateCompanyUpdateBody,
    RebateTierUpdateBody,
    CreateRebateTierRequest,
    UpdateRebateTierRequest,
    RebateAgreement,
    useUploadRebateAgreementFile,
} from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"

const areTiersDifferent = (tierA: RebateTierData, tierB: RebateTierData) =>
    tierA.gross_rent_payments_min !== tierB.gross_rent_payments_min ||
    tierA.gross_rent_payments_max !== tierB.gross_rent_payments_max ||
    tierA.rebate_percentage !== tierB.rebate_percentage

const getCompanyUpdateBody = (
    originalRebateAgreement: RebateAgreement,
    currentRebateAgreement: ValidatedCustomerRebateData
): RebateCompanyUpdateBody => {
    const originalCompanyIds = originalRebateAgreement.companies?.map((company) => company.company_id) ?? []
    const currentCompanyIds = currentRebateAgreement.companies.map((company) => company.company_id)

    const newIds = currentCompanyIds.filter((id) => !originalCompanyIds.includes(id))
    const deletedIds = originalCompanyIds.filter((id) => !currentCompanyIds.includes(id))

    return {
        create: newIds,
        delete: deletedIds,
    }
}

const getRebateTierUpdateBody = (
    originalRebateAgreement: RebateAgreement,
    currentRebateAgreement: ValidatedCustomerRebateData
): RebateTierUpdateBody => {
    const newTiers: CreateRebateTierRequest[] = currentRebateAgreement.rebate_tiers
        .filter(
            (currRebateTier) =>
                !originalRebateAgreement.rebate_tiers?.some(
                    (origRebateTier) => currRebateTier.rebate_tier_id === origRebateTier.rebate_tier_id
                )
        )
        .map((rTier) => ({
            gross_rent_payments_min: rTier.gross_rent_payments_min,
            gross_rent_payments_max: rTier.gross_rent_payments_max,
            rebate_percentage: rTier.rebate_percentage,
        }))

    const updatedTiers: UpdateRebateTierRequest[] = currentRebateAgreement.rebate_tiers.filter((rTier) => {
        const originalTier = originalRebateAgreement.rebate_tiers?.find(
            (originalTier) => originalTier.rebate_tier_id === rTier.rebate_tier_id
        )

        return !!originalTier && areTiersDifferent(originalTier, rTier)
    })

    const originalTierIds = originalRebateAgreement.rebate_tiers?.map((rTier) => rTier.rebate_tier_id) ?? []
    const currentTierIds = currentRebateAgreement.rebate_tiers.map((rTier) => rTier.rebate_tier_id)
    const deletedTierIds: number[] = originalTierIds.filter((id) => !currentTierIds.includes(id))

    return {
        create: newTiers,
        update: updatedTiers,
        delete: deletedTierIds,
    }
}

export type UpdateCustomerRebateFlyoutProps = {
    originalRebateAgreement: RebateAgreement
    onClose: () => void
    onUpdate: () => void
}

export const UpdateCustomerRebateFlyout: React.FC<UpdateCustomerRebateFlyoutProps> = ({
    originalRebateAgreement,
    onClose,
    onUpdate,
}) => {
    const { addSuccessToast, addErrorToast } = useToasts()
    const { mutateAsync: updateRebateAgreement } = useUpdateRebateAgreement()
    const { mutateAsync: uploadRebateAgreementFile } = useUploadRebateAgreementFile()

    const initialFormData: CustomerRebateData = {
        ...originalRebateAgreement,
        companies: originalRebateAgreement.companies ?? [],
        rebate_period_begin: moment.utc(originalRebateAgreement.rebate_period_begin),
        rebate_period_end: moment.utc(originalRebateAgreement.rebate_period_end),
        rebate_tiers: originalRebateAgreement.rebate_tiers ?? [],
    }

    const handleSubmit = async (validatedFormData: ValidatedCustomerRebateData, agreementPdf: FileList | null) => {
        const hasNewFile = agreementPdf && agreementPdf.length === 1
        const deleteFile = !validatedFormData.file_name || hasNewFile

        const payload: UpdateRebateAgreementRequest = {
            companies: getCompanyUpdateBody(originalRebateAgreement, validatedFormData),
            custom_rental_rates: validatedFormData.custom_rental_rates,
            payment_terms: validatedFormData.payment_terms,
            rebate_period_begin: moment.utc(validatedFormData.rebate_period_begin).startOf("day").toISOString(true),
            rebate_period_end: moment.utc(validatedFormData.rebate_period_end).endOf("day").toISOString(true),
            rebate_tiers: getRebateTierUpdateBody(originalRebateAgreement, validatedFormData),
            file_name: deleteFile ? null : validatedFormData.file_name ?? null,
        }

        await updateRebateAgreement({ rebateAgreementId: originalRebateAgreement.rebate_agreement_id, data: payload })

        try {
            if (hasNewFile) {
                const pdfPayload = {
                    rebateAgreementId: originalRebateAgreement.rebate_agreement_id,
                    data: { file: agreementPdf[0] },
                }
                await uploadRebateAgreementFile(pdfPayload)
            }
            addSuccessToast({ text: "Customer Rebate Agreement Successfully Updated!", toastLifeTimeMs: 5000 })
        } catch (e) {
            addErrorToast({ text: "Agreement updated, but failed to upload agreement PDF", toastLifeTimeMs: 10000 })
        }

        onUpdate()
    }

    return (
        <EuiFlyout onClose={onClose} size={700}>
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Update Customer Rebate Agreement</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <CustomerRebateForm initialFormData={initialFormData} handleSubmit={handleSubmit} />
            </EuiFlyoutBody>
        </EuiFlyout>
    )
}

export default UpdateCustomerRebateFlyout
