import { useState } from "react"

import { EuiFlexGroup, EuiFlexItem } from "@equipmentshare/ds2"

import { useGetServiceManuals, GetServiceManualsParams } from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"
import { DocumentSearchForm, SearchCriteria } from "./DocumentSearchForm"
import { DocumentTable, PAGE_SIZE_OPTIONS, PageCriteria } from "./DocumentTable"
import { DocumentActionsFlyout } from "./DocumentActionsFlyout"

const INITIAL_SEARCH_CRITERIA: SearchCriteria = {
    file_name: null,
    file_path: null,
    file_type: null,
    classified: null,
    is_licensed: null,
    has_notice: null,
    has_restrictions: null,
    exclude: null,
    needs_expert_review: null,
    service_manual_type_id: null,
}

const INITIAL_PAGE_CRITERIA: PageCriteria = {
    pageSize: PAGE_SIZE_OPTIONS[0],
    pageIndex: 0,
    sortField: "service_manual_id",
    sortDirection: "asc",
}

export const DocumentCatalogue = () => {
    const { addErrorToast } = useToasts()

    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(INITIAL_SEARCH_CRITERIA)
    const [pageCriteria, setPageCriteria] = useState<PageCriteria>(INITIAL_PAGE_CRITERIA)
    const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null)

    const searchParams: GetServiceManualsParams = {
        ...searchCriteria,
        sort_by: pageCriteria.sortField,
        sort_order: pageCriteria.sortDirection,
        limit: pageCriteria.pageSize,
        offset: pageCriteria.pageIndex * pageCriteria.pageSize,
    }

    const {
        data: getServiceManualsResponse = { service_manuals: [], total_count: 0 },
        isLoading,
        refetch: refetchDocuments,
    } = useGetServiceManuals(searchParams, {
        query: {
            refetchOnWindowFocus: false,
            onError: (e) => {
                addErrorToast({
                    text: formatException(e),
                })
            },
        },
    })

    const handleSearch = (newSearchCriteria: SearchCriteria) => {
        setPageCriteria({ ...pageCriteria, pageIndex: 0 })
        setSearchCriteria(newSearchCriteria)
    }

    return (
        <>
            <EuiFlexGroup direction="column">
                <EuiFlexItem>
                    <DocumentSearchForm
                        onSearch={handleSearch}
                        initialSearchCriteria={INITIAL_SEARCH_CRITERIA}
                        isLoading={isLoading}
                    />
                </EuiFlexItem>

                <EuiFlexItem>
                    <DocumentTable
                        documents={getServiceManualsResponse?.service_manuals}
                        totalDocumentsCount={getServiceManualsResponse?.total_count}
                        pageCriteria={pageCriteria}
                        onPageCriteriaChange={setPageCriteria}
                        isLoading={isLoading}
                        onDocumentSelect={setSelectedDocumentId}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            {!!selectedDocumentId && (
                <DocumentActionsFlyout
                    onClose={() => {
                        setSelectedDocumentId(null)
                        refetchDocuments()
                    }}
                    serviceManualId={selectedDocumentId}
                />
            )}
        </>
    )
}

export default DocumentCatalogue
