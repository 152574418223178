import { useState } from "react"
import { EuiFormRow, EuiComboBox, EuiComboBoxOptionOption } from "@equipmentshare/ds2"

export type InputFieldProps = {
    labelName?: string
    placeholder?: string
    onCleared?: () => void
    options: EuiComboBoxOptionOption<unknown>[]
    selected: EuiComboBoxOptionOption<unknown> | null
    onChange: (option: EuiComboBoxOptionOption<unknown> | null) => void
    onBlur?: () => void
    onSearchChange?: (searchValue: string, hasMatchingOptions?: boolean | undefined) => void
    hasErrors?: boolean
    isLoading?: boolean
    isDisabled?: boolean
    isInvalid?: boolean
    onCreateOption?: (text: string) => void
}

export default function InputField(props: InputFieldProps) {
    const [preventOnCreate, setPreventOnCreate] = useState(false)

    function handleOnChange(options: EuiComboBoxOptionOption<unknown>[]) {
        if (!options.length && props.onCleared) {
            props.onCleared()
        }

        // Since ComboBox is designed for multiple options but we only use it for 1 in this component
        // we're going to make sure onChange only gets called with a single item as opposed to a
        // collection because we don't want these details to leak outside of this component
        options.length ? props.onChange(options[0]) : props.onChange(null)
    }

    /**
     * These functions are weird ones but the point is to get around this issue:
     * https://github.com/elastic/eui/issues/3573
     * ElasticUI's onCreateOption is just broken because it'll call it after onBlur
     * i.e. even if they don't hit Enter.
     */
    const handleOnCreate = props.onCreateOption
        ? {
              onBlur: () => setPreventOnCreate(true), // ensure we prevent any onCreate
              onFocus: () => setPreventOnCreate(false), // ensure we don't prevent onCreate
              onCreateOption: (text: string) => {
                  if (preventOnCreate) {
                      setPreventOnCreate(false)
                  } else if (props.onCreateOption) {
                      props.onCreateOption(text)
                  }
              },
          }
        : {}

    const comboBox = (
        <EuiComboBox
            style={{ minWidth: "220px" }}
            onSearchChange={props.onSearchChange}
            placeholder={props.placeholder || "Enter query to display suggestions"}
            singleSelection={{ asPlainText: true }}
            options={props.options}
            selectedOptions={props.selected ? [props.selected] : []}
            onChange={handleOnChange}
            isLoading={props.isLoading}
            isClearable={!!props.selected}
            isDisabled={props.isDisabled}
            isInvalid={props.isInvalid}
            {...handleOnCreate}
        />
    )

    return props.labelName ? (
        <EuiFormRow label={props.labelName} isInvalid={props.hasErrors}>
            {comboBox}
        </EuiFormRow>
    ) : (
        comboBox
    )
}
