import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { useDebounce } from "use-debounce"

import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiInputPopover,
    EuiPopoverTitle,
    EuiSpacer,
} from "@equipmentshare/ds2"

import InputField from "../InputField"
import { CategoryPicker, ClassPicker, Option, PickerHandle } from "../Pickers"
import {
    CompetitorClass,
    useCreateCompetitorClass,
    useQueryCompetitorClass,
} from "../../services/Skunkworks/Generated"
import { formatException } from "../../Utils"
import { useToasts } from "../../hooks/useToasts"

interface CompetitorClassPickerProps {
    competitorId: number
    onSelected: (option: CompetitorClass | null) => void
}

function classToOption(competitorClass: CompetitorClass): Option {
    return {
        id: `${competitorClass.competitor_class_id}`,
        label: competitorClass.description,
    }
}

const CompetitorClassPicker = forwardRef<
    PickerHandle,
    CompetitorClassPickerProps
>((props, ref) => {
    const { addErrorToast } = useToasts()
    const [isCreating, setIsCreating] = useState(false)
    const [input, setInput] = useState("")
    const [inputDebounced] = useDebounce(input, 300)
    const [selected, setSelected] = useState<Option | null>(null)
    const [newName, setNewName] = useState("")
    const [newCat, setNewCat] = useState<string>("")
    const [newClass, setNewClass] = useState<string>("")
    const [newEsCat, setNewEsCat] = useState<Option | null>(null)
    const [newEsClass, setNewEsClass] = useState<Option | null>(null)
    const classPickerRef = useRef<PickerHandle>(null)
    const { isLoading, mutateAsync: createClass } = useCreateCompetitorClass()
    const { isLoading: isQuerying, data } = useQueryCompetitorClass(
        {
            competitor_id: props.competitorId,
            query: inputDebounced,
        },
        { query: { enabled: !!input } }
    )

    useImperativeHandle(ref, () => ({
        clearValue() {
            setSelected(null)
            setInput("")
        },
    }))

    const isValid = newName && newEsCat

    const findCompetitorClass = (id: number) => {
        const filteredClassArr = data!.filter(
            (cc) => cc.competitor_class_id === id
        )
        return filteredClassArr[0]
    }

    async function handleSave() {
        if (!isValid) return
        setInput(newName)
        try {
            const created = await createClass({
                data: {
                    competitor_id: props.competitorId,
                    description: newName,
                    class_id: newClass ? parseInt(newClass) : undefined,
                    category_id: newCat ? parseInt(newCat) : undefined,
                    es_class_id: newEsClass
                        ? parseInt(newEsClass.id)
                        : undefined,
                    es_category_id: parseInt(newEsCat.id),
                },
            })
            setIsCreating(false)
            const option = classToOption(created)
            setSelected(option)
            props.onSelected(created)
        } catch (e) {
            addErrorToast({ text: formatException(e) })
        }
    }

    function handleOnCreate(text: string) {
        setIsCreating(true)
        setNewName(text)
    }

    function handleCategorySelected(category: Option | null) {
        setNewEsCat(category)
        classPickerRef.current?.clearValue()
    }

    async function handleOnChange(option: Option | null) {
        setSelected(option)
        props.onSelected(option ? findCompetitorClass(Number(option.id)) : null)
    }

    return (
        <EuiInputPopover
            input={
                <InputField
                    options={data?.map(classToOption) || []}
                    selected={selected}
                    onChange={handleOnChange as any}
                    isLoading={isLoading || isQuerying}
                    onCleared={() => setSelected(null)}
                    onSearchChange={setInput}
                    onCreateOption={handleOnCreate}
                />
            }
            style={{ width: 380 }}
            isOpen={isCreating}
            closePopover={() => setIsCreating(false)}
            initialFocus="#name"
            fullWidth
        >
            <EuiPopoverTitle>Create new Competitor Class</EuiPopoverTitle>

            <EuiFormRow label="Competitior Class Name" id="name">
                <EuiFieldText
                    placeholder="Competitor Class Name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
            </EuiFormRow>

            <EuiSpacer size="m" />

            <CategoryPicker onSelected={handleCategorySelected} />

            <EuiSpacer size="m" />

            <ClassPicker
                ref={classPickerRef}
                onSelected={setNewEsClass}
                context={{
                    categoryId: newEsCat ? parseInt(newEsCat.id) : undefined,
                }}
            />

            <EuiSpacer size="m" />

            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Comp. Category (optional)">
                        <EuiFieldNumber
                            value={newCat}
                            onChange={(e) => setNewCat(e.target.value)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label="Comp. Class (optional)">
                        <EuiFieldNumber
                            value={newClass}
                            onChange={(e) => setNewClass(e.target.value)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="s" />

            <EuiFlexGroup justifyContent="center">
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        color="text"
                        onClick={() => setIsCreating(false)}
                    >
                        Cancel
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={handleSave} disabled={!isValid}>
                        Save
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiInputPopover>
    )
})

export default CompetitorClassPicker
