import { EuiLoadingSpinner } from "@equipmentshare/ds2"

import { useGetIsExpenseAdmin } from "../../services/Skunkworks/Generated"
import Page from "../Page/Page"

import ExpenseTrackerAdmin from "./ExpenseTrackerComponents/ExpenseTrackerAdmin"
import ExpenseTrackerNonAdmin from "./ExpenseTrackerComponents/ExpenseTrackerNonAdmin"

export default function AdminConsole() {
    const { data: isExpenseAdmin } = useGetIsExpenseAdmin()

    const ExpenseAdminComponent = () => {
        if (isExpenseAdmin === undefined) {
            return <EuiLoadingSpinner size="l" />
        } else {
            return isExpenseAdmin ? (
                <ExpenseTrackerAdmin />
            ) : (
                <ExpenseTrackerNonAdmin />
            )
        }
    }

    return (
        <Page title="Expense Tracker Console">
            <ExpenseAdminComponent />
        </Page>
    )
}
