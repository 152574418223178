import { useRef, useState } from "react"

import {
    EuiButton,
    EuiButtonEmpty,
    EuiFilePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiSpacer,
    EuiTitle,
} from "@equipmentshare/ds2"

import { useToasts } from "../../hooks/useToasts"
import { useCreateCompetitorQuote } from "../../services/Skunkworks/Generated"
import {
    BranchPicker,
    CompanyPicker,
    CompetitorPicker,
    DocTypePicker,
    SalespersonPicker,
} from "../Pickers"
import { formatException } from "../../Utils"
import useUploadCompetitorQuote from "../../hooks/useUploadCompetitorQuote"
import CompetitorQuotesDetailsInput, {
    NewDetailRow,
} from "./CompetitorQuotesDetailsInput"

interface CreateCompetitorQuoteFlyoutProps {
    onClose: () => void
    onCreated: () => void
}

// only require a file to be uploaded if it's not a test environment
const FILE_REQUIRED = process.env.NODE_ENV !== "test"

function CreateCompetitorQuoteFlyout(props: CreateCompetitorQuoteFlyoutProps) {
    const { addErrorToast } = useToasts()
    const filePickerRef = useRef(null)

    const [competitorId, setCompetitorId] = useState<number | null>(null)
    const [salespersonId, setSalespersonId] = useState<number | null>(null)
    const [branchId, setBranchId] = useState<number | null>(null)
    const [companyId, setCompanyId] = useState<number | null>(null)
    const [docTypeId, setDocTypeId] = useState<number | null>(null)
    const [objectKey, setObjectKey] = useState<string | null>(null)
    const { isLoading, mutateAsync: createQuote } = useCreateCompetitorQuote()
    const { isUploading, uploadCompetitorQuote } = useUploadCompetitorQuote()
    const [details, setDetails] = useState<NewDetailRow[]>([])

    const isValid = !!(
        competitorId &&
        salespersonId &&
        branchId &&
        companyId &&
        details.length > 0 &&
        (FILE_REQUIRED ? objectKey : true)
    )

    async function handleSave() {
        try {
            await createQuote({
                data: {
                    competitor_id: competitorId as number,
                    market_id: branchId || undefined,
                    salesperson_id: salespersonId || undefined,
                    company_id: companyId || undefined,
                    doc_type_id: docTypeId || undefined,
                    object_key: objectKey || undefined,
                    details: details.map((detail) => ({
                        competitor_class_id:
                            detail.competitorClass.competitor_class_id,
                        quantity: detail.quantity,
                        price_per_day:
                            parseFloat(detail.pricePerDay) || undefined,
                        price_per_week:
                            parseFloat(detail.pricePerWeek) || undefined,
                        price_per_month:
                            parseFloat(detail.pricePerMonth) || undefined,
                    })),
                },
            })
            props.onCreated()
        } catch (e) {
            addErrorToast({ text: formatException(e) })
        }
    }

    async function handleFilesPicked(files: FileList | null) {
        if (files?.length !== 1) return

        try {
            const objectKey = await uploadCompetitorQuote(files[0])
            setObjectKey(objectKey)
        } catch (e) {
            addErrorToast({ text: formatException(e) })
        }
    }

    return (
        <EuiFlyout ownFocus onClose={props.onClose} size="l">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Create Competitor Quote</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <CompetitorPicker onIdSelected={setCompetitorId} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <SalespersonPicker onIdSelected={setSalespersonId} />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer />

                <EuiFlexGroup>
                    <EuiFlexItem>
                        <BranchPicker onIdSelected={setBranchId} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <CompanyPicker onIdSelected={setCompanyId} />
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiSpacer />
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <DocTypePicker onIdSelected={setDocTypeId} />
                    </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer size="xxl" />

                {competitorId ? (
                    <CompetitorQuotesDetailsInput
                        onDetailsUpdated={setDetails}
                        competitorId={competitorId}
                    />
                ) : null}
                <EuiSpacer />

                <EuiFilePicker
                    ref={filePickerRef}
                    id="competitor-quote-file-picker"
                    initialPromptText="Upload file"
                    onChange={handleFilesPicked}
                    isLoading={isUploading}
                    fullWidth
                    display="large"
                />
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            onClick={props.onClose}
                            flush="left"
                            isDisabled={isLoading}
                        >
                            Close
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton
                            onClick={handleSave}
                            fill
                            disabled={isLoading || !isValid || isUploading}
                            isLoading={isLoading}
                        >
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    )
}

export default CreateCompetitorQuoteFlyout
