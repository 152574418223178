import { useState } from "react"

import { EuiForm, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldSearch, EuiButton } from "@equipmentshare/ds2"

import { GetServiceManualsAssetStatusParams } from "../../../services/Skunkworks/Generated"
import { ServiceManualTypePicker } from "../../Pickers"

const SEARCH_FIELD_STYLE: React.CSSProperties = { width: "250px" }

const ManualTypeMapping: Record<ManualQueryTypes, number> = {
    has_operators_manual: 1,
    has_service_manual: 2,
    has_parts_manual: 3,
    has_warranty_manual: 4,
    has_other_manual: 5,
}

export type SearchCriteria = Omit<GetServiceManualsAssetStatusParams, "offset" | "limit" | "sort_by" | "sort_order">

export type FilterCriteria = Omit<
    SearchCriteria,
    "equipment_class_name" | "equipment_make_name" | "equipment_model_name"
>

type ManualQueryTypes = keyof FilterCriteria

export type EquipmentReferenceSearchFormProps = {
    onSearch: (searchCriteria: SearchCriteria) => void
    initialSearchCriteria: SearchCriteria
    isLoading?: boolean
}

export const DocumentStatusSearchForm: React.FC<EquipmentReferenceSearchFormProps> = ({
    onSearch,
    initialSearchCriteria,
    isLoading,
}) => {
    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(initialSearchCriteria)
    const [selectedManualType, setSelectedManualType] = useState<number | null>(null)

    const resetSearchCriteria = () => {
        setSearchCriteria(initialSearchCriteria)
        setSelectedManualType(null)
    }

    const submitSearch = (e: React.FormEvent) => {
        e.preventDefault()

        const selectedMissingType = getKeyByValue(selectedManualType)

        const searchParams: GetServiceManualsAssetStatusParams = {
            ...searchCriteria,
        }

        if (selectedMissingType) {
            searchParams[selectedMissingType] = false
        }

        onSearch(searchParams)
    }

    const getKeyByValue = (value: number | null): ManualQueryTypes | undefined => {
        const typeMappingKeyValPairs = Object.entries(ManualTypeMapping) as [ManualQueryTypes, number][]
        return typeMappingKeyValPairs.find(([_, v]) => v === value)?.[0]
    }

    return (
        <EuiForm component="form" onSubmit={submitSearch}>
            <EuiFlexGroup alignItems="flexEnd" gutterSize="m" wrap>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow label="Class">
                        <EuiFieldSearch
                            value={searchCriteria.equipment_class_name ?? ""}
                            placeholder="Search"
                            onChange={(e) =>
                                setSearchCriteria({ ...searchCriteria, equipment_class_name: e.target.value })
                            }
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow label="Make">
                        <EuiFieldSearch
                            value={searchCriteria.equipment_make_name ?? ""}
                            placeholder="Search"
                            onChange={(e) =>
                                setSearchCriteria({ ...searchCriteria, equipment_make_name: e.target.value })
                            }
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={SEARCH_FIELD_STYLE}>
                    <EuiFormRow label="Model">
                        <EuiFieldSearch
                            value={searchCriteria.equipment_model_name ?? ""}
                            placeholder="Search"
                            onChange={(e) =>
                                setSearchCriteria({ ...searchCriteria, equipment_model_name: e.target.value })
                            }
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiFormRow>
                        <ServiceManualTypePicker
                            label="Missing Type"
                            placeholder="Select a Manual Type"
                            selectedId={selectedManualType}
                            onIdSelected={(id) => setSelectedManualType(id)}
                            onCleared={() => setSelectedManualType(null)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton color="text" isLoading={isLoading} onClick={resetSearchCriteria}>
                        Reset Filters
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton type="submit" isLoading={isLoading}>
                        Search
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiForm>
    )
}

export default DocumentStatusSearchForm
