import React, { ReactChild, ReactNode, Dispatch, SetStateAction } from "react"

import { EuiGlobalToastList, IconType } from "@equipmentshare/ds2"

// essentially redefined from EUI because it wasn't formally exported
export interface ToastWithoutId {
    title?: ReactNode
    text?: ReactChild
    toastLifeTimeMs?: number
    color?: "primary" | "success" | "warning" | "danger"
    iconType?: IconType
    onClose?: () => void
}

export interface Toast extends ToastWithoutId {
    id: string
}

interface ToastState {
    toasts: Toast[]
}

const ToastContext = React.createContext({
    state: {} as ToastState,
    setState: {} as Dispatch<SetStateAction<ToastState>>,
})

const ToastProvider = ({
    children,
    value = { toasts: [] } as ToastState,
}: {
    children: React.ReactNode
    value?: ToastState
}) => {
    const [state, setState] = React.useState(value)

    const removeToast = (toast: Toast) => {
        setState({ toasts: state.toasts.filter((t) => t.id !== toast.id) })
    }

    return (
        <ToastContext.Provider value={{ state, setState }}>
            <EuiGlobalToastList
                toasts={state.toasts}
                dismissToast={removeToast}
                toastLifeTimeMs={5000}
            />

            {children}
        </ToastContext.Provider>
    )
}

const useToastStore = () => React.useContext(ToastContext)

export { ToastProvider, useToastStore }
