import React, { useState } from "react"

import {
    EuiForm,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiButton,
    EuiText,
    EuiLink,
    EuiSwitch,
    EuiSpacer,
} from "@equipmentshare/ds2"

import {
    useUpdateServiceManual,
    ServiceManual,
    UpdateServiceManualRequest,
} from "../../../services/Skunkworks/Generated"
import { ServiceManualTypePicker } from "../../Pickers"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"

export type DocumentDataFormProps = {
    onSave?: () => void
    onCancel: () => void
    document: ServiceManual
}

export const DocumentDataForm: React.FC<DocumentDataFormProps> = ({ onSave, onCancel, document }) => {
    const [formData, setFormData] = useState<UpdateServiceManualRequest>({
        classified: document.classified,
        exclude: document.exclude,
        has_notice: document.has_notice,
        has_restrictions: document.has_restrictions,
        is_licensed: document.is_licensed,
        needs_expert_review: document.needs_expert_review,
        service_manual_type_id: document.service_manual_type_id,
        version_number: document.version_number,
    })

    const { addSuccessToast, addErrorToast } = useToasts()

    const { mutateAsync: updateDocument, isLoading } = useUpdateServiceManual({
        mutation: {
            onSuccess: () => addSuccessToast({ text: "Document Updated Successfully" }),
        },
    })

    const submitUpdate = async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            const { version_number: updatedVersionNumber } = await updateDocument({
                serviceManualId: document.service_manual_id,
                data: formData,
            })
            setFormData({
                ...formData,
                version_number: updatedVersionNumber,
            })
            onSave && onSave()
        } catch (err) {
            addErrorToast({ text: formatException(err) })
        }
    }

    return (
        <EuiForm component="form" onSubmit={submitUpdate}>
            <EuiFormRow label="Document Title">
                <EuiText size="s">{document.file_name}</EuiText>
            </EuiFormRow>
            <EuiFormRow label="GDrive Path">
                <EuiText size="s">{document.file_path}</EuiText>
            </EuiFormRow>
            <EuiFormRow label="Document Format">
                <EuiText size="s">{document.file_type}</EuiText>
            </EuiFormRow>
            <EuiFormRow label="GDrive Link">
                <EuiLink href={`https://drive.google.com/file/d/${document.file_id}/view`} target="_blank" external>
                    Document Link
                </EuiLink>
            </EuiFormRow>
            <EuiFormRow>
                <ServiceManualTypePicker
                    placeholder="Select a Manual Type"
                    onIdSelected={(id) => id && setFormData({ ...formData, service_manual_type_id: id })}
                    onCleared={() => setFormData({ ...formData, service_manual_type_id: null })}
                    selectedId={formData.service_manual_type_id}
                />
            </EuiFormRow>
            <EuiFormRow label="Licensing Data">
                <EuiFlexGroup direction="column" gutterSize="s">
                    <EuiFlexItem>
                        <EuiSwitch
                            label="Is Licensed"
                            checked={formData.is_licensed}
                            onChange={(e) => setFormData({ ...formData, is_licensed: e.target.checked })}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiSwitch
                            label="Has Notice"
                            checked={formData.has_notice}
                            onChange={(e) => setFormData({ ...formData, has_notice: e.target.checked })}
                        />
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiSwitch
                            label="Has Restrictions"
                            checked={formData.has_restrictions}
                            onChange={(e) => setFormData({ ...formData, has_restrictions: e.target.checked })}
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
            <EuiFormRow label="Exclude">
                <EuiSwitch
                    label="Exclude"
                    checked={formData.exclude}
                    onChange={(e) => setFormData({ ...formData, exclude: e.target.checked })}
                />
            </EuiFormRow>
            <EuiFormRow label="Needs Review">
                <EuiSwitch
                    label="Needs Review"
                    checked={formData.needs_expert_review}
                    onChange={(e) => setFormData({ ...formData, needs_expert_review: e.target.checked })}
                />
            </EuiFormRow>
            <EuiFormRow label="Status">
                <EuiSwitch
                    label="Classified"
                    checked={formData.classified}
                    onChange={(e) => setFormData({ ...formData, classified: e.target.checked })}
                />
            </EuiFormRow>
            <EuiSpacer size="xxl" />
            <EuiFormRow>
                <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiButton color="text" onClick={onCancel}>
                            Close
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill type="submit" isLoading={isLoading}>
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiForm>
    )
}

export default DocumentDataForm
