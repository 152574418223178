// Auth0 Role Permissions
export const FLEET_ASSIGNMENT_PERMISSION = "vut:assignments:all"
export const BA_MAP_UPLOAD_PERMISSION = "ba-map:update"
export const DISCOUNTS_MANAGER_PERMISSION = "sworks:discounts:manage"
export const VIEW_REBATE_AGREEMENT_PERMISSION = "sworks:view:customer-rebate-agreements"
export const WRITE_REBATE_AGREEMENT_PERMISSION = "sworks:write:customer-rebate-agreements"
export const WRITE_OEM_PERMISSION = "sworks:write:oem-service-manuals"

// Google Groups
export const FLEET_BATCH_GROUP = "Fleet Batch Update Users"
export const FLEET_ENABLE_GROUP = "Fleet Track Enablement Users"
export const CASE_TOOL_GROUP = "CASE Tool Users"

// NOTE the key shown here in plain text is the stage/prod gmaps api key.
// It only works when the `referrer` header is a staging/prod tools portal url.
// For local dev, you'll need to find or create a temporary key gmaps api key
// and set that up (see README).
export const BA_GOOGLE_MAP_KEY = process.env.REACT_APP_GMAPS_API_KEY || "AIzaSyAGIzvwIhQAlpHbQtJNGa3_MgXrZ3JzOtQ"
