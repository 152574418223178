import axios from "axios"

import { BaMapData } from "../components/BaMap/BaMapTypes"

export const S3_UPLOAD_AXIOS_INSTANCE = axios.create()

export function uploadToS3(data: BaMapData, url: string) {
    return S3_UPLOAD_AXIOS_INSTANCE.put(url, data, {
        headers: { "Content-Type": "application/json" },
    })
}
