import React from "react"
import moment, { Moment } from "moment"

import {
    EuiButton,
    EuiTextArea,
    EuiSpacer,
    EuiText,
    EuiDatePicker,
} from "@equipmentshare/ds2"

import { Job } from "./Job"
import { changePayoutProgram, PayoutProgram } from "../../services/Misc"
import CenteredContent from "../CenteredContent"
import { PayoutProgramPicker } from "../Pickers"
import { parseNumbersFromText } from "./Utils"

export interface EnrollPayoutProgramJobProposal {
    assetIds: number[]
    payoutProgram: PayoutProgram
    date: Moment
}

const getDescription = (
    proposal: EnrollPayoutProgramJobProposal,
    assetId: number
): string => {
    return `Updating Asset ID #${assetId}'s Payout program to ${proposal.payoutProgram.name} for ${proposal.date}`
}

const EnrollPayoutProgramJob: Job<EnrollPayoutProgramJobProposal> = {
    options: process.env.NODE_ENV === "test" ? {} : { rateLimit: 3 },
    gen: (proposal) =>
        proposal.assetIds.map((assetId) => ({
            description: getDescription(proposal, assetId),
            fn: () =>
                changePayoutProgram(assetId, {
                    payoutProgramId: proposal.payoutProgram.id,
                    startDate: `${proposal.date.toISOString()}`,
                }),
        })),
    Configure: (props) => {
        const [text, setText] = React.useState("")
        const [date, setDate] = React.useState<Moment | null>(moment())
        const [payoutProgram, setPayoutProgram] =
            React.useState<PayoutProgram | null>(null)

        const handleSubmit = () => {
            if (!payoutProgram) {
                alert("Need to choose a payout program.")
                return
            }
            if (!date) {
                alert("Need to choose a date.")
                return
            }
            const parsedAssetIds = parseNumbersFromText(text)
            if (!parsedAssetIds || !parsedAssetIds.every((n) => !!n)) {
                alert(
                    "Enter valid asset IDs. Separate with a new line or commas."
                )
                return
            }

            props.onSubmit({
                date,
                payoutProgram,
                assetIds: parsedAssetIds,
            })
        }

        return (
            <>
                <PayoutProgramPicker
                    onSelected={(option) =>
                        setPayoutProgram(
                            option && {
                                id: parseInt(option.id),
                                name: option.label,
                            }
                        )
                    }
                />
                <EuiSpacer size="m" />
                <EuiDatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                />
                <EuiSpacer size="m" />
                <EuiTextArea
                    fullWidth={true}
                    placeholder="Enter asset IDs however you'd like..."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <EuiSpacer size="m" />
                <EuiButton isDisabled={!text} onClick={handleSubmit}>
                    Next
                </EuiButton>
            </>
        )
    },
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    You are planning on changing the payout program to{" "}
                    {props.proposal.payoutProgram.name} and the date to{" "}
                    {props.proposal.date.format("MMMM Do YYYY")} for{" "}
                    {props.proposal.assetIds.length} assets.
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

export default EnrollPayoutProgramJob
