import { useQueries } from "react-query"

import { mapListsByIndex } from "../Utils"
import { getBranchRentalRates } from "../services/Misc"

function useBatchClassRates(branch_id: number, classIds: number[]) {
    const queries = useQueries(
        classIds.map((classId) => ({
            queryKey: ["classIdToRate", classId],
            queryFn: () => getBranchRentalRates(branch_id, classId),
        }))
    )

    return mapListsByIndex(classIds, queries)
}

export default useBatchClassRates
