import axios from "axios"

import { UserPermissions } from "../components/AutoFleetEnablement/AutoFleetEnablement"
import { makeObjectCamelCase } from "../Utils"

export async function updateAssetCompanyOwner(
    assetId: number,
    updates: {
        companyId?: number
        removeFromRentalFleet?: boolean
    }
) {
    return await axios.patch(`admin/assets/${assetId}/owner`, {
        company_id: updates.companyId,
        remove_from_rental_fleet: updates.removeFromRentalFleet,
    })
}

export async function updateAssetRspMsp(
    assetId: number,
    updates: {
        rentalBranchId?: number | null
        serviceBranchId?: number | null
    }
) {
    const resp = await axios.patch(`assets/${assetId}`, {
        rental_branch_id: updates.rentalBranchId,
        service_branch_id: updates.serviceBranchId,
    })
    return resp.data
}

export type Company = {
    company_id: number
    name: string
}

interface Branch {
    id: number
    name: string
}
export async function getBranches(): Promise<Branch[]> {
    const resp = await axios.get<Branch[]>(`branches`, {
        params: {
            is_billable_branch: true,
            is_public_rsp: true,
            active: true,
        },
        headers: {
            "x-fields": "{ id, name }",
        },
    })
    return resp.data
}

export async function getCompanies(query: string): Promise<Company[]> {
    const resp = await axios.get<Company[]>(`companies`, {
        params: {
            query,
        },
        headers: {
            "x-fields": "{ company_id, name }",
        },
    })
    return resp.data
}

export async function getRSPCompanies(query: string): Promise<Company[]> {
    const resp = await axios.get<Company[]>(`rsp/companies`, {
        params: {
            query,
        },
        headers: {
            "x-fields": "{ company_id, name }",
        },
    })
    return resp.data
}

export type Make = {
    equipmentMakeId: number
    name: string
}

export async function getMakes(query?: string): Promise<Make[]> {
    const url = query ? `makes?query=${query}` : `makes`
    const resp = await axios.get<Make[]>(url, {
        headers: {
            "x-fields": "{ equipment_make_id, name }",
        },
    })
    return resp.data.map(makeObjectCamelCase)
}

export type Model = {
    equipmentModelId: number
    name: string
}

export async function getCompanyById(
    companyId: number
): Promise<Company | null> {
    try {
        const resp = await axios.get<Company>(`companies/${companyId}`, {
            headers: {
                "x-fields": "{ company_id, name }",
            },
        })
        return resp.data
    } catch (e) {
        return null
    }
}

export type User = {
    user_id: number
    first_name: string
    last_name: string
    can_read_asset_financial_records?: boolean
    can_create_asset_financial_records?: boolean
}

export function getUsers(userIds: number[]): Promise<Array<User | null>> {
    return Promise.all(
        userIds.map(async (id) => {
            const resp = await axios.get<User | null>(`admin/users/${id}`)
            return resp.data
        })
    )
}

export async function getNames(query: string): Promise<User[]> {
    const resp = await axios.get<User[]>(`admin/users`, {
        params: {
            company_id: 1854,
            query,
        },
    })
    return resp.data
}

export type FinancialLender = {
    financial_lender_id: number
    name: string
}
export async function getLenders(): Promise<FinancialLender[]> {
    const resp = await axios.get<FinancialLender[]>(`financial-lenders`)
    return resp.data
}

export type FinancialSchedule = {
    financial_schedule_id: number
    current_schedule_number: string
}

export async function getFinancialSchedules(): Promise<FinancialSchedule[]> {
    const resp = await axios.get<FinancialSchedule[]>(
        `companies/1854/financial-schedules`
    )
    return resp.data
}

export type PayoutProgram = {
    id: number
    name: string
}

export async function getPayoutPrograms(): Promise<PayoutProgram[]> {
    const resp = await axios.get(`admin/payout-programs`)
    return resp.data
}

export async function changePayoutProgram(
    assetId: number,
    data: { payoutProgramId: number; startDate: string }
) {
    return axios.post(`admin/payout-program-assignments`, {
        asset_id: assetId,
        payout_program_id: `${data.payoutProgramId}`,
        start_date: data.startDate,
    })
}

export type PayoutProgramAssignments = {
    id: number
    end_date: string | null
}

export async function getPayoutProgramAssignments(
    assetId: number
): Promise<PayoutProgramAssignments[]> {
    const resp = await axios.get<PayoutProgramAssignments[]>(
        `admin/payout-program-assignments`,
        {
            params: {
                asset_id: assetId,
            },
        }
    )
    return resp.data
}

export async function endPayoutProgramAssignment(
    payoutProgramAssignmentId: number,
    endDate: string
) {
    return axios.patch(
        `admin/payout-program-assignments/${payoutProgramAssignmentId}`,
        { end_date: endDate }
    )
}

export type EquipmentModel = {
    equipment_model_id: number
    equipment_make_id: number
    name: string
    equipment_classes: [
        {
            equipment_class_id: number
        }
    ]
}

export async function getModels(
    query: string,
    makeId?: number
): Promise<EquipmentModel[]> {
    const params = makeId ? { query, equipment_make_id: makeId } : { query }
    const resp = await axios.get<EquipmentModel[]>(`models`, {
        params,
    })
    return resp.data
}

export async function updateAssetModel(
    assetId: number,
    updates: {
        equipmentClassId: number | null
        equipmentMakeId: number | null
        equipmentModelId: number | null
    }
) {
    return await axios.patch(`admin/assets/${assetId}`, {
        equipment_class_id: updates.equipmentClassId,
        equipment_make_id: updates.equipmentMakeId,
        equipment_model_id: updates.equipmentModelId,
    })
}

export async function uploadBlobToS3(
    data: Blob,
    url: string,
    contentType: string
) {
    const headers = { "content-type": contentType }
    return axios.create().put(url, data, { headers })
}

export async function updateUserFleetTrack(
    userId: number,
    userPermissions: UserPermissions
) {
    await axios.patch(`admin/users/${userId}`, userPermissions)
}

export async function getOneUser(userId: number): Promise<User> {
    const resp = await axios.get<User>(`admin/users/${userId}`)
    return resp.data
}

export type CompanyPurchaseOrderUserLimit = {
    approval_limit: number | null
    can_change_out_of_financial_schedule: boolean | null
    can_change_out_of_reconciliation: boolean | null
    can_change_reconciliation_status: boolean | null
    can_edit_line_after_schedule_assignment: boolean | null
    can_edit_line_asset_id_assignments: boolean | null
    can_edit_pending_schedule: boolean | null
    can_edit_read_only_line: boolean | null
}

export async function getCompanyPurchaseOrderUserLimit(
    userId: number
): Promise<CompanyPurchaseOrderUserLimit | null> {
    const resp = await axios.get<CompanyPurchaseOrderUserLimit | null>(
        `sworks/company-purchase-order-user-limit/${userId}`
    )
    return resp.data
}

export async function createCompanyPurchaseOrderUserLimit(
    userId: number,
    params: CompanyPurchaseOrderUserLimit
) {
    const body = {
        ...params,
        user_id: userId,
    }
    await axios.post(`sworks/company-purchase-order-user-limit`, {
        params: body,
    })
}

export async function updateCompanyPurchaseOrderUserLimit(
    userId: number,
    params: CompanyPurchaseOrderUserLimit
) {
    await axios.patch(`sworks/company-purchase-order-user-limit/${userId}`, {
        params,
    })
}

export interface RentalRate {
    price_per_hour: number
    price_per_day: number
    price_per_week: number
    price_per_month: number
}

export async function getBranchRentalRates(
    branch_id: number,
    equipment_class_id: number
) {
    const resp = await axios.get<RentalRate | null>(
        `/branches/${branch_id}/rental-rates`,
        {
            params: {
                equipment_class_id,
            },
        }
    )

    return resp.data
}

export interface BranchRentalRates {
    branch_id: number
    rate_type_id: number
    price_per_hour: number
    price_per_day: number
    price_per_week: number
    price_per_month: number
}

// previous getBranchRentalRate func only returns book rate but I also needed floor rate
export async function getDetailedBranchRentalRates(
    equipment_class_id: number,
    branch_id: number
) {
    const resp = await axios.get<BranchRentalRates[] | null>(
        `/branch-rental-rates`,
        {
            params: {
                equipment_class_id,
                branch_id,
            },
        }
    )

    return resp.data
}
