import React, { useCallback } from "react"

import { EuiComboBox, EuiComboBoxProps, EuiComboBoxOptionOption } from "@equipmentshare/ds2"

export type BooleanPickerProps = {
    value: boolean | null
    onChange: (newValue?: boolean | null) => void
    options: EuiComboBoxOptionOption<boolean | null>[]
    defaultNullOption?: boolean
} & Omit<
    EuiComboBoxProps<boolean | null>,
    "isClearable" | "singleSelection" | "selectedOptions" | "options" | "onChange"
>

export const BooleanPicker: React.FC<BooleanPickerProps> = ({
    value,
    onChange,
    options,
    defaultNullOption = true,
    ...props
}) => {
    const handleChange = useCallback(
        (selectedOptions: BooleanPickerProps["options"]) => {
            if (onChange) {
                onChange(selectedOptions[0]?.value)
            }
        },
        [onChange]
    )

    const booleanOptions = defaultNullOption ? [{ value: null, label: "Any" }, ...options] : options

    return (
        <EuiComboBox
            isClearable={false}
            singleSelection={{ asPlainText: true }}
            selectedOptions={booleanOptions.filter((option) => option.value === value)}
            options={booleanOptions}
            onChange={handleChange}
            {...props}
        />
    )
}

export default BooleanPicker
