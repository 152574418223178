import React from "react"

import {
    EuiText,
    EuiSpacer,
    EuiHorizontalRule,
    EuiBasicTable,
    EuiBasicTableColumn,
    EuiTableFieldDataColumnType,
    EuiButtonIcon,
    EuiBadge,
    EuiBadgeProps,
    EuiLink,
    CriteriaWithPagination,
    COLORSLIGHT,
} from "@equipmentshare/ds2"
import { MenuLineIcon } from "@equipmentshare/ds2-icons"

import { ServiceManual } from "../../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../../hooks/useAuthenticationDetails"
import { WRITE_OEM_PERMISSION } from "../../../Const"

export const PAGE_SIZE_OPTIONS = [20, 50, 100]

export type PageCriteria = {
    pageSize: number
    pageIndex: number
    sortField: keyof ServiceManual
    sortDirection: "asc" | "desc"
}

export type DocumentTableProps = {
    documents: ServiceManual[]
    totalDocumentsCount: number
    pageCriteria: PageCriteria
    onPageCriteriaChange: (newPageCriteria: PageCriteria) => void
    isLoading?: boolean
    onDocumentSelect: (documentId: number) => void
}

export const DocumentTable: React.FC<DocumentTableProps> = ({
    documents,
    totalDocumentsCount,
    pageCriteria,
    onPageCriteriaChange,
    isLoading,
    onDocumentSelect,
}) => {
    const { permissions: userPermissions } = useAuthenticationDetails()
    const hasWritePermissions = userPermissions.includes(WRITE_OEM_PERMISSION)

    const columns: EuiBasicTableColumn<ServiceManual>[] = [
        {
            field: "file_name",
            name: "Document Title",
            sortable: true,
            textOnly: true,
        },
        {
            field: "file_type",
            name: "Document Format",
            width: "130px",
            sortable: true,
            textOnly: true,
        },
        {
            field: "classified",
            name: "Status",
            sortable: true,
            render: (classified: boolean) => {
                const badgeColor: EuiBadgeProps["color"] = classified ? COLORSLIGHT.success.toString() : "default"
                const badgeText = classified ? "Classified" : "Unclassified"

                return <EuiBadge color={badgeColor}>{badgeText}</EuiBadge>
            },
        },
        {
            name: "Licensing Data",
            width: "165px",
            render: (document: ServiceManual) => (
                <ul>
                    <li>{`Is Licensed: ${document.is_licensed ? "Yes" : "No"}`}</li>
                    <li>{`Has Notice: ${document.has_notice ? "Yes" : "No"}`}</li>
                    <li>{`Has Restrictions: ${document.has_restrictions ? "Yes" : "No"}`}</li>
                </ul>
            ),
        },
        {
            name: "GDrive Link",
            width: "140px",
            render: (document: ServiceManual) => (
                <EuiLink href={`https://drive.google.com/file/d/${document.file_id}/view`} target="_blank" external>
                    Document Link
                </EuiLink>
            ),
        },
        {
            field: "file_path",
            name: "GDrive Path",
            sortable: true,
            textOnly: true,
        },
        {
            field: "exclude",
            name: "Exclude",
            sortable: true,
            render: (exclude: boolean) => (exclude ? "Yes" : "No"),
        },
        {
            field: "needs_expert_review",
            name: "Needs Review",
            sortable: true,
            render: (needsExpertReview: boolean) => (needsExpertReview ? "Yes" : "No"),
        },
    ]

    if (hasWritePermissions) {
        columns.push({
            name: "Actions",
            actions: [
                {
                    render: (document: ServiceManual) => {
                        return (
                            <EuiButtonIcon
                                aria-label={`document_actions_${document.service_manual_id}`}
                                iconType={MenuLineIcon}
                                color="primary"
                                size="m"
                                iconSize="l"
                                onClick={() => onDocumentSelect(document.service_manual_id)}
                            />
                        )
                    },
                },
            ],
        })
    }

    const getRowProps = (item: ServiceManual) => {
        return {
            "data-test-subj": `row-${item.service_manual_id}`,
        }
    }

    const getCellProps = (item: ServiceManual, column: EuiTableFieldDataColumnType<ServiceManual>) => {
        return {
            "data-test-subj": `cell-${item.service_manual_id}-${column.field}`,
        }
    }

    const onTableChange = ({ page, sort }: CriteriaWithPagination<ServiceManual>) => {
        onPageCriteriaChange({
            pageSize: page.size,
            pageIndex: page.index,
            sortField: sort?.field ?? pageCriteria.sortField,
            sortDirection: sort?.direction ?? pageCriteria.sortDirection,
        })
    }

    const renderResultsCount = () => {
        const pageSize = pageCriteria.pageSize
        const pageIndex = pageCriteria.pageIndex

        const pageStart = pageSize * pageIndex + 1
        const pageEnd = pageSize * pageIndex + pageSize

        return (
            <>
                <strong>
                    {pageStart > totalDocumentsCount ? totalDocumentsCount : pageStart}-
                    {pageEnd > totalDocumentsCount ? totalDocumentsCount : pageEnd}
                </strong>{" "}
                of {totalDocumentsCount}
            </>
        )
    }

    return (
        <>
            <EuiText size="xs">
                Showing {renderResultsCount()} <strong>Documents</strong>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin="none" style={{ height: 1 }} />
            <EuiBasicTable
                items={documents}
                itemId={"service_manual_id"}
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                tableLayout="auto"
                loading={isLoading}
                noItemsMessage={isLoading ? "Loading..." : "No documents found"}
                pagination={{
                    pageIndex: pageCriteria.pageIndex,
                    pageSize: pageCriteria.pageSize,
                    totalItemCount: totalDocumentsCount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
                sorting={{
                    sort: {
                        field: pageCriteria.sortField,
                        direction: pageCriteria.sortDirection,
                    },
                }}
                onChange={onTableChange}
                hasActions
            />
        </>
    )
}

export default DocumentTable
