import axios from "axios"

export interface UserAssetAssignment {
    userAssetAssignmentId: number
    userId: number
    assetId: number
    startDate: Date
    endDate: Date | null
}

export interface AssignmentUpdateData {
    userId: number
    assetId: number
    startDate: Date
    endDate: Date | null
}

export interface Asset {
    assetId: number
    vin?: string
    year: number
    make: string
    model: string
}

export async function getAsset(assetId: number): Promise<Asset | null> {
    try {
        const resp = await axios.get(`assets/${assetId}`, {
            headers: {
                "x-fields":
                    "{ vin, asset_id, year, make { name }, model { name } }",
            },
        })
        return {
            assetId: resp.data.asset_id,
            vin: resp.data.vin,
            year: resp.data.year,
            make: resp.data.make.name,
            model: resp.data.model,
        }
    } catch (e) {
        return null
    }
}

export async function updateAssetDriverName(
    assetId: number,
    name: string
): Promise<void> {
    await axios.patch(`assets/${assetId}`, { driver_name: name })
}
