import {
    EuiButton,
    EuiModal,
    EuiModalHeaderTitle,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiSpacer,
} from "@equipmentshare/ds2"

export type ModalProps = {
    modalTitle: string
    closeModal: () => void
    onConfirm: () => void
    modalField: JSX.Element
}

export default function Modal(props: ModalProps) {
    return (
        <EuiModal onClose={props.closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>{props.modalTitle}</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiSpacer />
                {props.modalField}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={props.closeModal}>
                    Cancel
                </EuiButtonEmpty>
                <EuiButton onClick={props.onConfirm}>Confirm</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
