import { EuiPageHeader, EuiEmptyPrompt, EuiText } from "@equipmentshare/ds2"
import { useMemo } from "react"
import { useAuthenticationDetails } from "../../../hooks/useAuthenticationDetails"
import { useGetExpenseUser } from "../../../services/Skunkworks/Generated"
import { transformUserData } from "../ExpenseTrackerDataHelper"
import Table from "./Table"

export default function ExpenseTrackerNonAdmin() {
    const { userId, userName } = useAuthenticationDetails()
    const { data } = useGetExpenseUser(userId, { sub_departments: true })

    const tableInfo = useMemo(() => data && transformUserData(data), [data])

    const userColumns = [
        {
            field: "subdepartment",
            name: "Sub-Department",
            truncateText: true,
        },
        {
            field: "primary",
            name: "Primary",
        },
    ]

    const message = (
        <EuiEmptyPrompt
            title={<h3>No Data </h3>}
            titleSize="xs"
            body="No data was found"
        />
    )

    return (
        <>
            <EuiText>
                <h3>User: {userName}</h3>
            </EuiText>
            <EuiPageHeader
                tabs={[
                    {
                        label: "User Information",
                        isSelected: true,
                    },
                ]}
            />
            <Table
                itemId={"change"}
                tableCaption={"change"}
                info={tableInfo ?? []}
                message={message}
                columns={userColumns}
                showTable={true}
            />
        </>
    )
}
