import React from "react"
import CurrencyInput from "react-currency-input-field"

import { EuiFieldText } from "@equipmentshare/ds2"

interface UsdInputProps {
    onChange?: (value: string) => void
    value?: string // setting value makes it a controlled input
}

// CurrencyInput passes an inputRef to the given customInput which throws a warning
// Function components cannot be given refs. Attempts to access this ref will fail. Did you mean to use React.forwardRef()
// to fix have to wrap EuiFieldText around a forward ref
const CustomInput = React.forwardRef(
    (props, ref: React.Ref<HTMLInputElement>) => (
        <EuiFieldText {...props} inputRef={ref} />
    )
)

function UsdInput(props: UsdInputProps) {
    const { value, onChange, ...rest } = props
    function handleChange(val: any) {
        if (!props.onChange) return
        props.onChange(val)
    }
    return (
        <CurrencyInput
            onValueChange={handleChange}
            value={props.value}
            prefix="$"
            customInput={CustomInput}
            placeholder="$0.00"
            {...rest}
        />
    )
}

export default UsdInput
