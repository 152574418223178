import { useSimpleApiClient as useEsApiClient } from "@equipmentshare/simple-json-api-client"

import EsApiService from "../services/EsApiService"

export function useEsApiService() {
    const esApiClient = useEsApiClient()
    return new EsApiService(esApiClient)
}

export default useEsApiService
