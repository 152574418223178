import React from "react"

import {
    EuiText,
    EuiSpacer,
    EuiHorizontalRule,
    EuiBasicTable,
    EuiBasicTableColumn,
    EuiTableFieldDataColumnType,
    EuiButtonIcon,
    EuiLink,
    CriteriaWithPagination,
} from "@equipmentshare/ds2"
import { MenuLineIcon, DeleteBinLineIcon } from "@equipmentshare/ds2-icons"

import { EquipmentServiceManual } from "../../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../../hooks/useAuthenticationDetails"
import { WRITE_OEM_PERMISSION } from "../../../Const"

export const PAGE_SIZE_OPTIONS = [20, 50, 100]

export type PageCriteria = {
    pageSize: number
    pageIndex: number
    sortField: keyof EquipmentServiceManual
    sortDirection: "asc" | "desc"
}

export type EquipmentReferenceTableProps = {
    equipmentReferences: EquipmentServiceManual[]
    totalEquipmentReferencesCount: number
    pageCriteria: PageCriteria
    onPageCriteriaChange: (newPageCriteria: PageCriteria) => void
    isLoading?: boolean
    onEquipmentReferenceSelect: (equipmentReferenceId: number) => void
    onEquipmentReferenceDelete: (equipmentReferenceId: number) => void
}

export const EquipmentReferenceTable: React.FC<EquipmentReferenceTableProps> = ({
    equipmentReferences,
    totalEquipmentReferencesCount,
    pageCriteria,
    onPageCriteriaChange,
    isLoading,
    onEquipmentReferenceSelect,
    onEquipmentReferenceDelete,
}) => {
    const { permissions: userPermissions } = useAuthenticationDetails()
    const hasWritePermissions = userPermissions.includes(WRITE_OEM_PERMISSION)

    const columns: EuiBasicTableColumn<EquipmentServiceManual>[] = [
        {
            name: "Document Title",
            textOnly: true,
            render: (equipmentReference: EquipmentServiceManual) =>
                equipmentReference.service_manual?.file_name ?? "--",
        },
        {
            field: "serial_start",
            name: "Serial Start",
            sortable: true,
            textOnly: true,
            render: (serialStart: string) => serialStart || "--",
        },
        {
            field: "serial_end",
            name: "Serial End",
            sortable: true,
            textOnly: true,
            render: (serialEnd: string) => serialEnd || "--",
        },
        {
            field: "equipment_make_name",
            name: "Make",
            textOnly: true,
        },
        {
            field: "equipment_model_name",
            name: "Model",
            textOnly: true,
            render: (modelName: string) => modelName || "--",
        },
        {
            name: "Year(s)",
            textOnly: true,
            style: { minWidth: "120px", maxWidth: "160px" },
            render: (equipmentReference: EquipmentServiceManual) => equipmentReference.years?.sort().join(", ") || "--",
        },
        {
            name: "Manual Type",
            textOnly: true,
            style: { minWidth: "110px" },
            render: (equipmentReference: EquipmentServiceManual) =>
                equipmentReference.service_manual?.service_manual_type_name ?? "--",
        },
        {
            name: "GDrive Link",
            style: { minWidth: "90px" },
            render: (equipmentReference: EquipmentServiceManual) => {
                const fileId = equipmentReference.service_manual?.file_id

                if (!fileId) {
                    return "--"
                }

                return (
                    <EuiLink href={`https://drive.google.com/file/d/${fileId}/view`} target="_blank" external>
                        Document Link
                    </EuiLink>
                )
            },
        },
    ]

    if (hasWritePermissions) {
        columns.push({
            name: "Actions",
            actions: [
                {
                    render: (equipmentReference: EquipmentServiceManual) => {
                        return (
                            <EuiButtonIcon
                                aria-label={`equipment_reference_actions_${equipmentReference.equipment_service_manual_id}`}
                                iconType={MenuLineIcon}
                                color="primary"
                                size="m"
                                iconSize="l"
                                onClick={() =>
                                    onEquipmentReferenceSelect(equipmentReference.equipment_service_manual_id)
                                }
                            />
                        )
                    },
                },
                {
                    render: (equipmentReference: EquipmentServiceManual) => {
                        return (
                            <EuiButtonIcon
                                aria-label={`equipment_reference_delete_${equipmentReference.equipment_service_manual_id}`}
                                iconType={DeleteBinLineIcon}
                                color="danger"
                                size="m"
                                iconSize="l"
                                onClick={() =>
                                    onEquipmentReferenceDelete(equipmentReference.equipment_service_manual_id)
                                }
                            />
                        )
                    },
                },
            ],
        })
    }

    const getRowProps = (item: EquipmentServiceManual) => {
        return {
            "data-test-subj": `row-${item.equipment_service_manual_id}`,
        }
    }

    const getCellProps = (
        item: EquipmentServiceManual,
        column: EuiTableFieldDataColumnType<EquipmentServiceManual>
    ) => {
        return {
            "data-test-subj": `cell-${item.equipment_service_manual_id}-${column.field}`,
        }
    }

    const onTableChange = ({ page, sort }: CriteriaWithPagination<EquipmentServiceManual>) => {
        onPageCriteriaChange({
            pageSize: page.size,
            pageIndex: page.index,
            sortField: sort?.field ?? pageCriteria.sortField,
            sortDirection: sort?.direction ?? pageCriteria.sortDirection,
        })
    }

    const renderResultsCount = () => {
        const pageSize = pageCriteria.pageSize
        const pageIndex = pageCriteria.pageIndex

        const pageStart = pageSize * pageIndex + 1
        const pageEnd = pageSize * pageIndex + pageSize

        return (
            <>
                <strong>
                    {pageStart > totalEquipmentReferencesCount ? totalEquipmentReferencesCount : pageStart}-
                    {pageEnd > totalEquipmentReferencesCount ? totalEquipmentReferencesCount : pageEnd}
                </strong>{" "}
                of {totalEquipmentReferencesCount}
            </>
        )
    }

    return (
        <>
            <EuiText size="xs">
                Showing {renderResultsCount()} <strong>Equipment References</strong>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin="none" style={{ height: 1 }} />
            <EuiBasicTable
                items={equipmentReferences}
                itemId={"equipment_service_manual_id"}
                columns={columns}
                rowProps={getRowProps}
                cellProps={getCellProps}
                tableLayout="auto"
                loading={isLoading}
                noItemsMessage={isLoading ? "Loading..." : "No equipment references found"}
                pagination={{
                    pageIndex: pageCriteria.pageIndex,
                    pageSize: pageCriteria.pageSize,
                    totalItemCount: totalEquipmentReferencesCount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                }}
                sorting={{
                    sort: {
                        field: pageCriteria.sortField,
                        direction: pageCriteria.sortDirection,
                    },
                }}
                onChange={onTableChange}
                hasActions
            />
        </>
    )
}

export default EquipmentReferenceTable
