import { useRef, useState } from "react"

import {
    EuiButton,
    EuiButtonEmpty,
    EuiFilePicker,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiText,
    EuiTitle,
    EuiSpacer,
    EuiCodeBlock,
} from "@equipmentshare/ds2"

import { useToasts } from "../../hooks/useToasts"
import { uploadToS3 } from "../../services/BaMap"
import { useCreateUploadUrl } from "../../services/Skunkworks/Generated"
import { BaMapData, decodeData, shapeDescription } from "./BaMapTypes"

interface MapUploadFlyoutProps {
    onClose: () => void
    onSaved: () => void
}

async function getJson(file: File) {
    const text = await file.text()
    try {
        return JSON.parse(text)
    } catch (e) {
        throw new Error(`File ${file.name} is not valid JSON!`)
    }
}

function MapUploadFlyout(props: MapUploadFlyoutProps) {
    const { addErrorToast, addSuccessToast } = useToasts()
    const [data, setData] = useState<BaMapData | null>(null)
    const [isSaving, setIsSaving] = useState(false)
    const filePickerRef = useRef(null)

    const { mutateAsync: createUploadUrl } = useCreateUploadUrl()

    async function handleSave() {
        try {
            setIsSaving(true)
            const results = await createUploadUrl()
            const uploadUrl = results.url
            await uploadToS3(data!, uploadUrl!)
            addSuccessToast({ text: "Uploaded map data!" })
            props.onSaved()
        } catch (error) {
            addErrorToast({ text: error.message, toastLifeTimeMs: 20000 })
        } finally {
            setIsSaving(false)
        }
    }

    async function handleFilesPicked(files: FileList | null) {
        if (files?.length) {
            try {
                const json = await getJson(files[0])
                const validated = decodeData(json)
                setData(validated)
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 20000 })
            }
        }
    }

    return (
        <EuiFlyout ownFocus onClose={props.onClose} size="s">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Upload data for BA Map</h2>
                </EuiTitle>
                <EuiSpacer />
                <EuiText>
                    <p>Upload your JSON file. It must be in this shape</p>
                </EuiText>
                <EuiCodeBlock language="ts" fontSize="s" paddingSize="s">
                    {shapeDescription}
                </EuiCodeBlock>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <EuiFilePicker
                    ref={filePickerRef}
                    id="ba-map-file-picker"
                    data-testid="ba-map-file-picker"
                    initialPromptText="Upload file"
                    onChange={handleFilesPicked}
                    display="large"
                />
            </EuiFlyoutBody>

            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            onClick={props.onClose}
                            flush="left"
                            isDisabled={isSaving}
                        >
                            Close
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton
                            onClick={handleSave}
                            fill
                            disabled={isSaving || !data}
                            isLoading={isSaving}
                        >
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    )
}

export default MapUploadFlyout
