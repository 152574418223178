import { useState } from "react"
import debounce from "lodash.debounce"
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService"

import InputField from "../InputField"
import { BA_GOOGLE_MAP_KEY } from "../../Const"

interface MapsAutocompleteProps {
    onChange: (place: google.maps.places.PlaceResult | null) => void
}

export function MapsAutocomplete(props: MapsAutocompleteProps) {
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
        placesService,
    } = usePlacesService({
        apiKey: BA_GOOGLE_MAP_KEY,
    })

    const [selected, setSelected] = useState<{
        id: string
        label: string
    } | null>(null)

    function handleChange(input: string) {
        getPlacePredictions({ input })
    }

    function onChange(place: { id: string; label: string } | null) {
        setSelected(place)

        if (place) {
            placesService?.getDetails({ placeId: place.id }, props.onChange)
        }
    }

    return (
        <InputField
            placeholder="Select location"
            options={placePredictions.map((p) => ({
                id: p.place_id,
                label: p.description,
            }))}
            selected={selected}
            onChange={onChange as any}
            isLoading={isPlacePredictionsLoading}
            onSearchChange={debounce(handleChange, 300)}
        />
    )
}

export default MapsAutocomplete
