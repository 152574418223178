import moment from "moment"

import { EuiFlyout, EuiFlyoutBody, EuiFlyoutHeader, EuiTitle } from "@equipmentshare/ds2"

import { CustomerRebateForm } from "./CustomerRebateForm"
import { CustomerRebateData, ValidatedCustomerRebateData } from "../Utils"
import {
    useCreateRebateAgreement,
    CreateRebateAgreementRequest,
    useUploadRebateAgreementFile,
} from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"

export type AddCustomerRebateFlyoutProps = {
    onClose: () => void
    onCreate: () => void
}

export const AddCustomerRebateFlyout: React.FC<AddCustomerRebateFlyoutProps> = ({ onClose, onCreate }) => {
    const { addSuccessToast, addErrorToast } = useToasts()
    const { mutateAsync: createRebateAgreement } = useCreateRebateAgreement()
    const { mutateAsync: uploadRebateAgreementFile } = useUploadRebateAgreementFile()

    const initialFormData: CustomerRebateData = {
        companies: [],
        custom_rental_rates: false,
        rebate_period_begin: moment.utc(),
        rebate_period_end: moment.utc().endOf("year"),
        payment_terms: "30",
        rebate_tiers: [],
    }

    const handleSubmit = async (validatedFormData: ValidatedCustomerRebateData, agreementPdf: FileList | null) => {
        const agreementPayload: CreateRebateAgreementRequest = {
            companies: validatedFormData.companies.map((company) => company.company_id),
            custom_rental_rates: validatedFormData.custom_rental_rates,
            payment_terms: validatedFormData.payment_terms,
            rebate_period_begin: moment.utc(validatedFormData.rebate_period_begin).startOf("day").toISOString(true),
            rebate_period_end: moment.utc(validatedFormData.rebate_period_end).endOf("day").toISOString(true),
            rebate_tiers: validatedFormData.rebate_tiers.map((rTier) => ({
                gross_rent_payments_min: rTier.gross_rent_payments_min,
                gross_rent_payments_max: rTier.gross_rent_payments_max,
                rebate_percentage: rTier.rebate_percentage,
            })),
        }

        const newRebateAgreement = await createRebateAgreement({ data: agreementPayload })

        try {
            if (agreementPdf && agreementPdf.length === 1) {
                const pdfPayload = {
                    rebateAgreementId: newRebateAgreement.rebate_agreement_id,
                    data: { file: agreementPdf[0] },
                }
                await uploadRebateAgreementFile(pdfPayload)
            }
            addSuccessToast({ text: "Customer Rebate Agreement Successfully Created!", toastLifeTimeMs: 5000 })
        } catch (e) {
            addErrorToast({ text: "Agreement created, but failed to upload agreement PDF", toastLifeTimeMs: 10000 })
        }

        onCreate()
    }

    return (
        <EuiFlyout onClose={onClose} size={700}>
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>New Customer Rebate Agreement</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <CustomerRebateForm initialFormData={initialFormData} handleSubmit={handleSubmit} clearOnSubmit />
            </EuiFlyoutBody>
        </EuiFlyout>
    )
}

export default AddCustomerRebateFlyout
