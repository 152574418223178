import { useState } from "react"

import { EuiFieldNumber, EuiFormRow, EuiButton, EuiFieldText } from "@equipmentshare/ds2"

import { RebateTierData } from "../Utils"
import { useToasts } from "../../../hooks/useToasts"
import { cleanUSDString, formatUSD } from "../../../Utils"

type InitialFormData = {
    gross_rent_payments_max: number | null
    gross_rent_payments_min: number | null
    rebate_percentage: number | null
    rebate_tier_id: number
}

type TierFormData = {
    gross_rent_payments_max: string
    gross_rent_payments_min: string
    rebate_percentage: number | null
    rebate_tier_id: number
}

export type RebateTierFormProps = {
    initialData: InitialFormData
    handleSave: (rebateTier: RebateTierData) => void
}

export const RebateTierForm: React.FC<RebateTierFormProps> = ({ initialData, handleSave }) => {
    const [formData, setFormData] = useState<TierFormData>({
        ...initialData,
        gross_rent_payments_min:
            initialData.gross_rent_payments_min !== null
                ? formatUSD(initialData.gross_rent_payments_min.toString())
                : "",
        gross_rent_payments_max:
            initialData.gross_rent_payments_max !== null
                ? formatUSD(initialData.gross_rent_payments_max.toString())
                : "",
    })

    const { addErrorToast } = useToasts()

    const onSave = (event: React.FormEvent) => {
        event.preventDefault()
        event.stopPropagation()

        const parsedGrossPaymentMin = isNaN(parseFloat(cleanUSDString(formData.gross_rent_payments_min)))
            ? null
            : parseFloat(cleanUSDString(formData.gross_rent_payments_min))

        const parsedGrossPaymentMax = isNaN(parseFloat(cleanUSDString(formData.gross_rent_payments_max)))
            ? null
            : parseFloat(cleanUSDString(formData.gross_rent_payments_max))

        if (parsedGrossPaymentMin === null || parsedGrossPaymentMax === null || formData.rebate_percentage === null) {
            addErrorToast({
                text: "Please complete all required fields.",
                toastLifeTimeMs: 10000,
            })
            return
        }
        if (parsedGrossPaymentMin >= parsedGrossPaymentMax) {
            addErrorToast({
                text: "Please ensure the maximum payment is greater than the minimum payment.",
                toastLifeTimeMs: 10000,
            })
            return
        }

        if (formData.rebate_percentage < 0 || formData.rebate_percentage > 100) {
            addErrorToast({
                text: "Please ensure the rebate percentage is between 0 and 100.",
                toastLifeTimeMs: 10000,
            })
            return
        }

        const rebateTier: RebateTierData = {
            rebate_tier_id: formData.rebate_tier_id,
            gross_rent_payments_min: parsedGrossPaymentMin,
            gross_rent_payments_max: parsedGrossPaymentMax,
            rebate_percentage: parseFloat((formData.rebate_percentage / 100).toFixed(4)),
        }

        handleSave(rebateTier)
    }

    return (
        <form onSubmit={onSave}>
            <EuiFormRow id="minimumPayment" label="Minimum Rent Payment">
                <EuiFieldText
                    placeholder="Enter Minimum Payment"
                    value={formData.gross_rent_payments_min}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            gross_rent_payments_min: formatUSD(e.target.value),
                        })
                    }}
                    required
                />
            </EuiFormRow>

            <EuiFormRow label="Maximum Rent Payment">
                <EuiFieldText
                    placeholder="Enter Maximum Payment"
                    value={formData.gross_rent_payments_max}
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            gross_rent_payments_max: formatUSD(e.target.value),
                        })
                    }}
                    required
                />
            </EuiFormRow>

            <EuiFormRow label="Rebate Percentage">
                <EuiFieldNumber
                    type="number"
                    placeholder="Enter Rebate Percentage"
                    value={formData.rebate_percentage ?? ""}
                    onChange={(e) => {
                        const value = parseFloat(e.target.value)
                        setFormData({
                            ...formData,
                            rebate_percentage: isNaN(value) ? null : value,
                        })
                    }}
                    step={0.01}
                    min={0}
                    max={100}
                    required
                />
            </EuiFormRow>

            <EuiFormRow>
                <EuiButton type="submit">Save</EuiButton>
            </EuiFormRow>
        </form>
    )
}

export default RebateTierForm
