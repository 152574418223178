import { useState } from "react"

import { EuiButton, EuiPopover, EuiPopoverTitle } from "@equipmentshare/ds2"

import { RebateTierData } from "../Utils"
import { RebateTierForm } from "./RebateTierForm"

export type AddRebateTierButtonProps = {
    handleSave: (rebateTier: RebateTierData) => void
}

export const AddRebateTierButton: React.FC<AddRebateTierButtonProps> = ({ handleSave }) => {
    const [isRebateTierFormOpen, setIsRebateTierFormOpen] = useState<boolean>(false)
    const [newRebateTierId, setNewRebateTierId] = useState<number>(0)

    const initialData = {
        gross_rent_payments_max: null,
        gross_rent_payments_min: null,
        rebate_percentage: null,
        rebate_tier_id: newRebateTierId,
    }

    const getNextRebateTierId = () => {
        const newId = newRebateTierId - 1
        setNewRebateTierId(newId)
    }

    const onSave = (rebateTier: RebateTierData) => {
        handleSave(rebateTier)
        setIsRebateTierFormOpen(false)
    }

    return (
        <EuiPopover
            button={
                <EuiButton
                    onClick={() => {
                        getNextRebateTierId()
                        setIsRebateTierFormOpen(!isRebateTierFormOpen)
                    }}
                >
                    Add Rebate Tier
                </EuiButton>
            }
            isOpen={isRebateTierFormOpen}
            closePopover={() => setIsRebateTierFormOpen(false)}
            anchorPosition="rightDown"
            initialFocus="#minimumPayment"
            panelStyle={{ width: 300 }}
            repositionOnScroll
        >
            <EuiPopoverTitle>Add Rebate Tier</EuiPopoverTitle>
            <RebateTierForm initialData={initialData} handleSave={onSave} />
        </EuiPopover>
    )
}

export default AddRebateTierButton
