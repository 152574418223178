import {
    Assignment,
    MasterAgreement,
    PayoutProgram,
    Schedule,
} from "../../../services/PayoutProgram/Generated"

import moment from "moment"

export type PromiseData =
    | Assignment
    | MasterAgreement
    | PayoutProgram
    | Schedule

export function verifyArgs(req: any) {
    for (const key in req) {
        if (req.hasOwnProperty(key)) {
            const val = req[key]
            if (val !== undefined && val !== "" && val !== null) {
                return true
            }
        }
    }
    return false
}

export function formatDate(date: string) {
    if (moment(date).isValid()) {
        return moment(date).format("MM/DD/YYYY")
    }

    return "---"
}
