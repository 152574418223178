import { forwardRef, useImperativeHandle, useState, useEffect } from "react"
import debounce from "lodash.debounce"

import MultiSelectInputField from "./MultiSelectInputField"
import { useAuthenticationDetails } from "../hooks/useAuthenticationDetails"
import { useToasts } from "../hooks/useToasts"
import useEsApiService from "../hooks/useEsApiService"
import { getRSPCompanies, getModels } from "../services/Misc"
import EsApiService from "../services/EsApiService"

export interface MultiSelectProps<T extends Option, K extends object = {}> {
    onCleared?: () => void
    onSelected: (options: T[]) => void
    selected: T[]
    context?: K
    hasErrors?: boolean
    isDisabled?: boolean
    isInvalid?: boolean
    placeholder?: string
}

export type Option = { key: string; label: string }

interface GenConfig<T extends Option, K extends object = {}> {
    label?: string
    createOption?: (text: string) => Promise<any>
    getOptions: (text: string, esApiService: EsApiService, context?: K) => Promise<T[]>
    loadOnce?: boolean
}

export interface MultiSelectHandle {
    clearValue: () => void
}

const genMultiSelect = <T extends Option, K extends object = {}>(config: GenConfig<T, K>) =>
    forwardRef<MultiSelectHandle, MultiSelectProps<T, K>>((props, ref) => {
        const { accessToken } = useAuthenticationDetails()
        const esApiService = useEsApiService()
        const { addErrorToast } = useToasts()
        const [value, setValue] = useState("")
        const [isLoading, setIsLoading] = useState<boolean>(false)
        const [options, setOptions] = useState<T[]>([])

        useImperativeHandle(ref, () => ({
            clearValue() {
                props.onSelected([])
                setValue("")
                if (!config.loadOnce) {
                    setOptions([])
                }
                if (props.onCleared) {
                    props.onCleared()
                }
            },
        }))

        async function fetchOptions() {
            setIsLoading(true)
            let fetchedOptions: T[] = []

            try {
                fetchedOptions = await config.getOptions(value, esApiService, props.context)
            } catch (err) {
                console.error(err)
                addErrorToast({ text: `Error loading picker options for ${config.label}` })
            } finally {
                setIsLoading(false)
            }

            return fetchedOptions
        }

        /**
         * @param text the text entered when create option is activated
         * @returns
         *
         * handle creating an option but also automate its selection
         * as soon as it is persisted
         */
        async function handleCreateOption(text: string) {
            if (!config.createOption) {
                return
            }
            await config.createOption(text)
            const newOptions = await fetchOptions()

            // NOTE: having 1 is the most likely case because the only
            // way createOption was called before is if there were 0
            if (newOptions.length === 1) {
                props.onSelected(newOptions)
            }
        }

        useEffect(() => {
            if (accessToken && (config.loadOnce || (value && value.length > 2))) {
                fetchOptions().then(setOptions)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [accessToken, config.loadOnce || value, JSON.stringify(props.context)])

        return (
            <MultiSelectInputField
                labelName={config.label}
                hasErrors={props.hasErrors}
                options={options}
                selected={props.selected}
                onChange={props.onSelected as any}
                isLoading={isLoading}
                onCleared={props.onCleared}
                onSearchChange={debounce(setValue, 500)}
                isDisabled={props.isDisabled}
                onCreateOption={config.createOption ? handleCreateOption : undefined}
                placeholder={props.placeholder}
                isInvalid={props.isInvalid}
            />
        )
    })

type Company = {
    company_id: number
    name?: string
}

export const getCompanyOptions = (companies: Company[]) =>
    companies.map((c) => ({
        label: `${c.name || "UNKNOWN COMPANY NAME"} - ${c.company_id}`,
        key: `${c.company_id}`,
    }))

export const RSPCompanyMultiSelect = genMultiSelect({
    label: "Company Id/Name",
    getOptions: (text) => getRSPCompanies(text).then(getCompanyOptions),
})

export const ModelMultiSelect = genMultiSelect<Option, { makeId?: number }>({
    label: "Model",
    getOptions: (query, _, context) =>
        getModels(query, context?.makeId).then((models) =>
            models.map((m) => ({
                label: `${m.name}`,
                key: `${m.equipment_model_id}`,
            }))
        ),
})

export const getYearOptions = (): Option[] => {
    const currentYear = new Date().getFullYear()

    const years = [currentYear + 2, currentYear + 1, currentYear]

    for (let i = 1; i <= 100; i++) {
        years.push(currentYear - i)
    }

    return years.map((y) => ({
        label: `${y}`,
        key: `${y}`,
    }))
}

export const YearMultiSelect = genMultiSelect({
    label: "Year(s)",
    loadOnce: true,
    getOptions: () => Promise.resolve(getYearOptions()),
})
