import React from "react"
import moment, { Moment } from "moment"

import {
    EuiButton,
    EuiTextArea,
    EuiSpacer,
    EuiText,
    EuiDatePicker,
    EuiLoadingSpinner,
} from "@equipmentshare/ds2"

import { Job } from "./Job"
import {
    endPayoutProgramAssignment,
    getPayoutProgramAssignments,
} from "../../services/Misc"
import CenteredContent from "../CenteredContent"
import { parseNumbersFromText } from "./Utils"

interface Data {
    assetId: number
    payoutProgramAssignmentId: number | null
}

export interface UnenrollPayoutProgramJobProposal {
    data: Data[]
    endDate: Moment
}

function makeDescription(data: Data) {
    return data.payoutProgramAssignmentId
        ? `Unenrolling Asset ID #${data.assetId}'s from its current payout program...`
        : `Skipping unenrolling for asset ${data.assetId} because it doesn't have a current assignment.`
}

const UnenrollPayoutProgramJob: Job<UnenrollPayoutProgramJobProposal> = {
    options: process.env.NODE_ENV === "test" ? {} : { rateLimit: 3 },
    gen: (proposal) =>
        proposal.data.map((data) => ({
            description: makeDescription(data),
            fn: () =>
                data.payoutProgramAssignmentId
                    ? endPayoutProgramAssignment(
                          data.payoutProgramAssignmentId,
                          proposal.endDate.toISOString()
                      )
                    : Promise.resolve(null),
        })),
    Configure: (props) => {
        const [text, setText] = React.useState("")
        const [date, setDate] = React.useState<Moment | null>(moment())
        const [isLoading, setIsLoading] = React.useState(false)

        const handleSubmit = () => {
            if (!date) {
                alert("Need to choose a date.")
                return
            }
            const parsedAssetIds = parseNumbersFromText(text)
            if (!parsedAssetIds || !parsedAssetIds.every((n) => !!n)) {
                alert(
                    "Enter valid asset IDs. Separate with a new line or commas."
                )
                return
            }

            // We need to have an intermediary step where we query for the actual assignments we wish to end
            setIsLoading(true)
            Promise.all(
                parsedAssetIds.map((assetId) =>
                    getPayoutProgramAssignments(assetId).then((assignments) => {
                        const a = assignments.find((a) => a.end_date === null)
                        return {
                            payoutProgramAssignmentId: a?.id || null,
                            assetId,
                        }
                    })
                )
            ).then((data) => {
                props.onSubmit({
                    data,
                    endDate: date,
                })
            })
        }

        return isLoading ? (
            <EuiLoadingSpinner />
        ) : (
            <>
                <EuiSpacer size="m" />
                <EuiDatePicker
                    showTimeSelect
                    selected={date}
                    onChange={(date) => setDate(date)}
                />
                <EuiSpacer size="m" />
                <EuiTextArea
                    fullWidth={true}
                    placeholder="Enter asset IDs however you'd like..."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <EuiSpacer size="m" />
                <EuiButton isDisabled={!text} onClick={handleSubmit}>
                    Next
                </EuiButton>
            </>
        )
    },
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    You are planning on ending the payout program on{" "}
                    {props.proposal.data.length} assets with date:{" "}
                    {props.proposal.endDate.format("MMMM Do YYYY")}
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

export default UnenrollPayoutProgramJob
