import React from "react"
import { Navigate } from "react-router-dom"

import { useAuthenticationDetails } from "./hooks/useAuthenticationDetails"

export function PrivateRoute({ children, userIds, securityLevels, permission, group, ...rest }: any) {
    const { userId, securityLevel, permissions, groups } = useAuthenticationDetails()

    if (!userId) {
        return null
    } else if (
        // For now, if any is true, they'll be authed
        (userIds || []).includes(userId) ||
        (securityLevels || []).includes(securityLevel) ||
        permissions.includes(permission || "VOID") ||
        groups.includes(group)
    ) {
        return children
    } else {
        return <Navigate to="/" />
    }
}
