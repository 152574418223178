import React from "react"
import {
    EuiButton,
    EuiModal,
    EuiModalHeaderTitle,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiSpacer,
} from "@equipmentshare/ds2"

export type PayoutModalProps = {
    modalTitle: string
    closeModal: () => void
    onConfirm: () => void
    modalBody: JSX.Element
}

export default function PayoutModal(props: PayoutModalProps) {
    return (
        <EuiModal onClose={props.closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1> {props.modalTitle}</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiSpacer />
                {props.modalBody}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={props.closeModal}>
                    Cancel
                </EuiButtonEmpty>
                <EuiButton onClick={props.onConfirm}>Confirm</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
