import { useState, useRef, useEffect } from "react"
import moment from "moment"

import Page from "../Page/Page"
import {
    EuiFormRow,
    EuiFieldNumber,
    EuiButton,
    EuiFilePicker,
    useGeneratedHtmlId,
    EuiFieldText,
    EuiSpacer,
    EuiText,
    EuiLink,
    EuiDatePicker,
} from "@equipmentshare/ds2"

import { BranchPicker, RSPCompanyPicker, TTUClassPicker, PickerHandle } from "../Pickers"
import { useToasts } from "../../hooks/useToasts"

import {
    CreateCommissionOverrideRequestReq,
    useCreateRequest,
    useUploadCommissionOverrideReqFiles,
    useDeleteRequest,
} from "../../services/Skunkworks/Generated"

type FormFormat = {
    company_id: number | null
    market_id: number | null
    equipment_class_id: number | null
    request_rate: string | null
    request_note: string | null
}

type VerifiedFormFormat = {
    company_id: number
    market_id: number
    equipment_class_id: number
    request_rate: string
    request_note: string
}

const FILE_UPLOAD_TEXT = "Screenshots of a description of the situation are acceptable"

const FILE_UPLOAD_HELP_TEXT = [
    "Please ensure all files have a valid file extension (e.g., .pdf, .txt, .png, etc.),",
    "and note that Microsoft file extensions (e.g., .docx, .xlsx, etc.) are not supported.",
    "If a file extension is not working, try converting the file to a PDF.",
].join(" ")

export default function CommissionOverrideForm() {
    const initalFormState = {
        company_id: null,
        market_id: null,
        equipment_class_id: null,
        request_rate: null,
        request_note: null,
    }
    const [formData, setFormData] = useState<FormFormat>(initalFormState)
    const [files, setFiles] = useState<File[]>([])
    const [loading, setIsLoading] = useState(false)
    const [submissionDate, setSubmissionDate] = useState(moment())
    const { mutateAsync: createRequest } = useCreateRequest()
    const { mutateAsync: uploadFiles } = useUploadCommissionOverrideReqFiles()
    const { mutateAsync: deleteRequest } = useDeleteRequest()

    const { addErrorToast, addSuccessToast, addToast } = useToasts()

    const companyPickerRef = useRef<PickerHandle>(null)
    const branchPickerRef = useRef<PickerHandle>(null)
    const ttuClassPickerRef = useRef<PickerHandle>(null)
    const filePickerRef = useRef<EuiFilePicker>(null)

    const verifyArgs = (req: FormFormat): req is VerifiedFormFormat => !Object.values(req).includes(null)

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === "visible") setSubmissionDate(moment())
        }

        document.addEventListener("visibilitychange", handleVisibilityChange)

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange)
        }
    }, [])

    function renderCommissionOverrideDescription() {
        return (
            <EuiText size="m">
                <p>
                    This form is a request for commission override for <b>new orders only.</b> If a <br /> long term
                    override is needed, please reach out to your manager.{" "}
                    <EuiLink
                        href="https://drive.google.com/file/d/1r8pPFDUGJpVl6jGlgGh2UYtUf4PBioPc/view"
                        target="_blank"
                    >
                        Learn more
                    </EuiLink>
                </p>
            </EuiText>
        )
    }

    function renderSubmissionDateInfo() {
        const thirtyDaysAhead = submissionDate.clone().add(30, "days").format("MMM Do")
        const hundredDaysAhead = submissionDate.clone().add(100, "days").format("MMM Do, YYYY")

        return `If approved, this override will apply to orders placed before ${thirtyDaysAhead} and invoices from approved orders until ${hundredDaysAhead}.`
    }

    function handleFiles(fileInfo: FileList | null) {
        if (fileInfo !== null) {
            const newFiles = Array.from(fileInfo)

            if (newFiles.some((file) => !file.type)) {
                addErrorToast({
                    text: "All files must have a valid file extension (e.g., .pdf, .txt, .png, etc...).",
                })

                filePickerRef.current?.removeFiles()
                setFiles([])

                return
            }

            setFiles(newFiles)
        } else {
            setFiles([])
        }
    }

    function transformFormData(formData: VerifiedFormFormat): CreateCommissionOverrideRequestReq {
        return {
            company_id: formData.company_id,
            market_id: formData.market_id,
            equipment_class_id: formData.equipment_class_id,
            request_rate: formData.request_rate,
            request_note: formData.request_note,
        }
    }

    function resetFormState() {
        companyPickerRef.current?.clearValue()
        branchPickerRef.current?.clearValue()
        ttuClassPickerRef.current?.clearValue()
        filePickerRef.current?.removeFiles()
        setFiles([])
        setFormData(initalFormState)
    }

    async function handleCleanup(requestId: number) {
        try {
            await deleteRequest({ requestId: requestId })
            addErrorToast({
                text: "Request creation was rolled back due to file upload failure",
                toastLifeTimeMs: 10000,
            })
        } catch (e) {
            addErrorToast({
                text: "Failed to roll back the request. Please contact support.",
                toastLifeTimeMs: 10000,
            })
        }
    }

    async function handleSubmit() {
        if (!verifyArgs(formData)) {
            addErrorToast({
                text: "Please fill in all fields",
                toastLifeTimeMs: 10000,
            })
            return
        }

        try {
            setIsLoading(true)
            addToast({ title: "Submitting", text: "Submitting Form..." })

            const createReqData = transformFormData(formData)
            const newCommissionOverrideReq = await createRequest({
                data: createReqData,
            })

            let uploadSuccessful = true

            const requestId = newCommissionOverrideReq.commission_override_request_id

            // uploading files if any exist
            try {
                if (files.length > 0) {
                    await uploadFiles({
                        commissionOverrideRequestId: requestId,
                        data: { files },
                    })
                }
            } catch (e) {
                uploadSuccessful = false
                handleCleanup(requestId)
            }

            if (uploadSuccessful) {
                addSuccessToast({
                    text: "Request successfully submitted",
                    toastLifeTimeMs: 5000,
                })
            }

            resetFormState()
        } catch (e: any) {
            addErrorToast({
                text: e?.response?.data?.detail || e.message,
                toastLifeTimeMs: 10000,
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Page title="Commission Override Form">
            {renderCommissionOverrideDescription()}
            <EuiSpacer size="s" />

            <EuiFormRow label="Date" helpText={renderSubmissionDateInfo()}>
                <EuiDatePicker showTimeSelect disabled selected={submissionDate} dateFormat="MMM Do, YYYY hh:mm a" />
            </EuiFormRow>
            <EuiSpacer size="s" />

            <EuiFormRow>
                <RSPCompanyPicker
                    ref={companyPickerRef}
                    onIdSelected={(e) => setFormData({ ...formData, company_id: e })}
                />
            </EuiFormRow>

            <EuiFormRow>
                <BranchPicker
                    ref={branchPickerRef}
                    onIdSelected={(e) => {
                        setFormData({ ...formData, market_id: e })
                    }}
                />
            </EuiFormRow>

            <EuiFormRow>
                <TTUClassPicker
                    ref={ttuClassPickerRef}
                    onIdSelected={(e) => setFormData({ ...formData, equipment_class_id: e })}
                    context={{ marketId: formData.market_id }}
                    isDisabled={!formData.market_id}
                    placeholder={!formData.market_id ? "Select Branch first" : ""}
                />
            </EuiFormRow>

            <EuiFormRow label="Requested Month Rate">
                <EuiFieldNumber
                    placeholder="Enter Requested Rate"
                    value={formData.request_rate ?? ""}
                    onChange={({ target: { value } }) => {
                        setFormData({
                            ...formData,
                            request_rate: value ? value : null,
                        })
                    }}
                />
            </EuiFormRow>

            <EuiFormRow label="Rationale">
                <EuiFieldText
                    value={formData.request_note ?? ""}
                    onChange={({ target: { value } }) =>
                        setFormData({
                            ...formData,
                            request_note: value ? value : null,
                        })
                    }
                    placeholder="Please enter any notes you may have"
                />
            </EuiFormRow>

            <EuiFormRow label="Support File(s) (Optional)" helpText={FILE_UPLOAD_HELP_TEXT}>
                <EuiFilePicker
                    ref={filePickerRef}
                    id={useGeneratedHtmlId({ prefix: "filePicker" })}
                    initialPromptText="Select or drag and drop multiple files"
                    onChange={handleFiles}
                    display={"large"}
                    multiple
                />
            </EuiFormRow>

            <EuiSpacer size="s" />
            <EuiText size="m">
                <i>{FILE_UPLOAD_TEXT}</i>
            </EuiText>
            <EuiSpacer size="s" />

            <EuiButton onClick={handleSubmit} isLoading={loading} isDisabled={loading}>
                Submit
            </EuiButton>
        </Page>
    )
}
