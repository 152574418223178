import { useRef } from "react"
import {
    EuiInMemoryTable,
    EuiEmptyPrompt,
    EuiBasicTableColumn,
} from "@equipmentshare/ds2"

import { PromiseDataMult } from "../RenderViewModal"

export type TableProps = {
    info: PromiseDataMult
    columns: Array<EuiBasicTableColumn<{}>>
    showTable: boolean
}

export default function Table(props: TableProps) {
    // in EUI Tables, if you want to set the selection
    // programmatically you have to use a ref
    const tableRef = useRef(null)

    const pagination = {
        initialPageSize: 10,
        pageSizeOptions: [10, 25, 50],
    }

    return (
        <>
            {props.showTable && (
                <EuiInMemoryTable
                    ref={tableRef}
                    items={props.info}
                    message={
                        <EuiEmptyPrompt
                            title={<h3>No Data </h3>}
                            titleSize="xs"
                            body="No data was found"
                        />
                    }
                    loading={false}
                    columns={props.columns}
                    isSelectable={true}
                    sorting={true}
                    tableLayout="auto"
                    pagination={pagination}
                />
            )}
        </>
    )
}
