import React from "react"

import { Job } from "./Job"
import { AllModelsPicker, DataOption, ModelsData } from "../Pickers"
import {
    EuiButton,
    EuiSpacer,
    EuiTextArea,
    EuiText,
    EuiTitle,
} from "@equipmentshare/ds2"
import { updateAssetModel } from "../../services/Misc"
import { parseNumbersFromText } from "./Utils"
import CenteredContent from "../CenteredContent"

export interface AssetModelUpdateJobProposal {
    assetIds: number[]
    equipmentClassId: number | null
    equipmentMakeId: number | null
    equipmentModelId: number | null
}

const getDescription = (
    proposal: AssetModelUpdateJobProposal,
    assetId: number
): string =>
    `Updating Asset ID #${assetId} to Model ID #${proposal.equipmentModelId}`

const AssetModelUpdateJob: Job<AssetModelUpdateJobProposal> = {
    gen: (proposal) =>
        proposal.assetIds.map((assetId) => ({
            description: getDescription(proposal, assetId),
            fn: () => {
                const { assetIds: _, ...updates } = proposal
                return updateAssetModel(assetId, updates)
            },
        })),
    Configure: (props) => {
        const [text, setText] = React.useState("")
        const [option, setOption] =
            React.useState<DataOption<ModelsData> | null>(null)

        const handleSubmit = () => {
            if (!option?.id) {
                alert("Need to choose a model")
                return
            }
            const parsedAssetIds = parseNumbersFromText(text)
            if (!parsedAssetIds || !parsedAssetIds.every((n) => !!n)) {
                alert(
                    "Enter valid asset IDs. Separate with a new line or commas."
                )
                return
            }
            const {
                equipment_class_id,
                equipment_make_id,
                equipment_model_id,
            } = option.data
            props.onSubmit({
                assetIds: parsedAssetIds,
                equipmentClassId: equipment_class_id,
                equipmentMakeId: equipment_make_id,
                equipmentModelId: equipment_model_id,
            })
        }

        return (
            <>
                <EuiTitle size="m">
                    <h2>Update Model</h2>
                </EuiTitle>

                <EuiSpacer size="m" />
                <AllModelsPicker onSelected={setOption} />
                <EuiSpacer size="m" />
                <EuiTextArea
                    fullWidth={true}
                    placeholder="Enter asset IDs separated by comma or new line"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <EuiSpacer size="m" />
                <EuiButton isDisabled={!text} onClick={handleSubmit}>
                    Next
                </EuiButton>
            </>
        )
    },
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    You are planning on changing the model id to{" "}
                    {props.proposal.equipmentModelId} for{" "}
                    {props.proposal.assetIds.length} assets.
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

export default AssetModelUpdateJob
