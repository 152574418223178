import {
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiLink,
    EuiPopover,
    EuiSpacer,
    EuiText,
} from "@equipmentshare/ds2"
import React from "react"

interface AvatarProps {
    handleLogout: () => void
    name: string
}

const Avatar = ({ handleLogout, name }: AvatarProps) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen)
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={name}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Account menu"
            onClick={onMenuButtonClick}
        >
            <EuiAvatar name={name} size="m" />
        </EuiHeaderSectionItemButton>
    )

    return (
        <EuiPopover
            id={name}
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="none"
        >
            <div style={{ width: 320 }}>
                <EuiFlexGroup
                    gutterSize="m"
                    className="euiHeaderProfile"
                    responsive={false}
                >
                    <EuiFlexItem grow={false}>
                        <EuiAvatar name={name} size="xl" />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <EuiText>
                            <p>{name}</p>
                        </EuiText>

                        <EuiSpacer size="m" />

                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiFlexGroup justifyContent="spaceBetween">
                                    <EuiFlexItem grow={false}>
                                        <EuiLink onClick={handleLogout}>
                                            Log out
                                        </EuiLink>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        </EuiPopover>
    )
}

export default Avatar
