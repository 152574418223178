import { useState } from "react"

import { EuiFlexGroup, EuiFlexItem } from "@equipmentshare/ds2"

import { useGetEquipmentServiceManuals, GetEquipmentServiceManualsParams } from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"
import { EquipmentReferenceSearchForm, SearchCriteria } from "./EquipmentReferenceSearchForm"
import { EquipmentReferenceTable, PAGE_SIZE_OPTIONS, PageCriteria } from "./EquipmentReferenceTable"
import { EquipmentReferenceActionsFlyout } from "./EquipmentReferenceActionsFlyout"
import { DeleteEquipmentReferenceModal } from "./DeleteEquipmentReferenceModal"

const INITIAL_SEARCH_CRITERIA: SearchCriteria = {
    equipment_make_id: null,
    equipment_model_id: null,
    serial_start: null,
    serial_end: null,
    year: null,
}

const INITIAL_PAGE_CRITERIA: PageCriteria = {
    pageSize: PAGE_SIZE_OPTIONS[0],
    pageIndex: 0,
    sortField: "equipment_service_manual_id",
    sortDirection: "asc",
}

export const EquipmentReference = () => {
    const { addErrorToast } = useToasts()

    const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(INITIAL_SEARCH_CRITERIA)
    const [pageCriteria, setPageCriteria] = useState<PageCriteria>(INITIAL_PAGE_CRITERIA)
    const [selectedEquipmentReferenceId, setSelectedEquipmentReferenceId] = useState<number | null>(null)
    const [deleteEquipmentReferenceId, setDeleteEquipmentReferenceId] = useState<number | null>(null)

    const searchParams: GetEquipmentServiceManualsParams = {
        ...searchCriteria,
        sort_by: pageCriteria.sortField,
        sort_order: pageCriteria.sortDirection,
        limit: pageCriteria.pageSize,
        offset: pageCriteria.pageIndex * pageCriteria.pageSize,
    }

    const {
        data: getEquipmentServiceManualsResponse = { equipment_service_manuals: [], total_count: 0 },
        isLoading,
        refetch: refetchEquipmentServiceManuals,
    } = useGetEquipmentServiceManuals(searchParams, {
        query: {
            refetchOnWindowFocus: false,
            onError: (e) => addErrorToast({ text: formatException(e) }),
        },
    })

    const handleSearch = (newSearchCriteria: SearchCriteria) => {
        setPageCriteria({ ...pageCriteria, pageIndex: 0 })
        setSearchCriteria(newSearchCriteria)
    }

    return (
        <>
            <EuiFlexGroup direction="column">
                <EuiFlexItem>
                    <EquipmentReferenceSearchForm
                        onSearch={handleSearch}
                        initialSearchCriteria={INITIAL_SEARCH_CRITERIA}
                        isLoading={isLoading}
                    />
                </EuiFlexItem>

                <EuiFlexItem>
                    <EquipmentReferenceTable
                        equipmentReferences={getEquipmentServiceManualsResponse.equipment_service_manuals ?? []}
                        totalEquipmentReferencesCount={getEquipmentServiceManualsResponse.total_count}
                        pageCriteria={pageCriteria}
                        onPageCriteriaChange={setPageCriteria}
                        isLoading={isLoading}
                        onEquipmentReferenceSelect={setSelectedEquipmentReferenceId}
                        onEquipmentReferenceDelete={setDeleteEquipmentReferenceId}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>

            {!!selectedEquipmentReferenceId && (
                <EquipmentReferenceActionsFlyout
                    onClose={() => {
                        setSelectedEquipmentReferenceId(null)
                        refetchEquipmentServiceManuals()
                    }}
                    equipmentServiceManualId={selectedEquipmentReferenceId}
                />
            )}

            {!!deleteEquipmentReferenceId && (
                <DeleteEquipmentReferenceModal
                    onClose={() => {
                        setDeleteEquipmentReferenceId(null)
                        refetchEquipmentServiceManuals()
                    }}
                    equipmentServiceManualId={deleteEquipmentReferenceId}
                />
            )}
        </>
    )
}

export default EquipmentReference
