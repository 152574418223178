import React from "react"

import { EuiFieldText, EuiSpacer, EuiFormRow } from "@equipmentshare/ds2"

import { PayoutProgramTypePicker } from "../../../../Pickers"

type ProgramFieldsReq = {
    name?: string | null
    payout_program_type_id?: number | null
}

export type ProgramFieldsProps = {
    programReq: ProgramFieldsReq
    setProgramReq: (value: React.SetStateAction<ProgramFieldsReq>) => void
    hasErrors?: boolean
}

type ProgramFieldsCreateReq = {
    name: string
    payout_program_type_id: number
}

export type ProgramFieldsCreateProps = {
    programReq: ProgramFieldsCreateReq
    setProgramReq: (value: React.SetStateAction<ProgramFieldsCreateReq>) => void
    hasErrors?: boolean
}

export default function ProgramFields(
    props: ProgramFieldsProps | ProgramFieldsCreateProps
) {
    function isProgramFieldsProps(
        props: ProgramFieldsProps | ProgramFieldsCreateProps
    ): props is ProgramFieldsProps {
        const req = props.programReq
        return (
            req.name === undefined ||
            req.name === null ||
            req.payout_program_type_id === undefined ||
            req.payout_program_type_id === null
        )
    }
    return (
        <>
            <EuiFormRow
                label="Program Name"
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiFieldText
                    placeholder="Program Name"
                    onChange={(e) =>
                        isProgramFieldsProps(props)
                            ? props.setProgramReq({
                                  ...props.programReq,
                                  name: e.target.value,
                              })
                            : props.setProgramReq({
                                  ...props.programReq,
                                  name: e.target.value,
                              })
                    }
                />
            </EuiFormRow>

            <EuiSpacer />
            <PayoutProgramTypePicker
                onIdSelected={(e) =>
                    isProgramFieldsProps(props)
                        ? props.setProgramReq({
                              ...props.programReq,
                              payout_program_type_id: e,
                          })
                        : props.setProgramReq({
                              ...props.programReq,
                              payout_program_type_id: e ? e : 0,
                          })
                }
                hasErrors={props.hasErrors}
            />
        </>
    )
}
