import {
    EuiEmptyPrompt,
    EuiButton,
    EuiPageHeader,
    EuiFieldText,
    EuiButtonIcon,
    EuiSearchBarProps,
    EuiSwitch,
} from "@equipmentshare/ds2"

import { useState } from "react"

import { useToasts } from "../../../hooks/useToasts"
import {
    ExpenseLinePicker,
    SubDepartmentPicker,
    DepartmentPicker,
    EsdbUserPicker,
} from "../../Pickers"
import Table from "./Table"
import TabScreens from "./TabScreens"

import {
    useGetDepartment,
    useGetSubDepartments,
    useGetSubDepartment,
    useGetExpenseUser,
    useUpdateExpenseLine,
    useUpdateSubDepartment,
    useCreateSubDepartment,
    useCreateSubDepartmentExpenseLine,
    useCreateExpenseUserSubDepartment,
    useDeleteSubDepartmentExpenseLine,
    useDeleteExpenseUserSubDepartment,
    useUpdateMarketAccess,
    useCreateExpenseLine,
} from "../../../services/Skunkworks/Generated"

import {
    transformSubDepartmentData,
    transformUserData,
    transformExpenseLineData,
    instanceOfSubdepartmentTableFormat,
    instanceOfUserTableFormat,
    isExpenseUser,
    isSubDepartment,
    isSubDepartmentArray,
    isDepartment,
} from "../ExpenseTrackerDataHelper"

import Modals from "./Modal"

export const Tab = {
    USERSEARCH: "User to Sub-Department Search",
    EXPENSELINE: "Expense Line Search",
    SUBDEPARTMENT: "Sub-Department Search",
    DEPARTMENT: "Department Search",
    ADMIN: "Admin",
} as const

export const TabButtons = {
    USERSUB: "userSubdept",
    EDITEXPENSELINENAME: "editExpenseName",
    EDITSUBDEPTNAME: "editSubdeptName",
    ADDEXPENSELINE: "addExpenseLine",
    CREATEEXPENSELINE: "createExpenseLine",
    ADDSUBDEPT: "addSubdept",
} as const

type TabButtonsType = typeof TabButtons[keyof typeof TabButtons]

export interface UserTableFormat {
    subdepartment: string
    primary: boolean
    sub_id: number
}

export interface ExpenseLineTableFormat {
    sub_name: string
    sub_id: number
}

export interface SubdepartmentTableFormat {
    expenseLineName: string
    expenseLineId: number
}

export default function ExpenseTrackerAdmin() {
    const [inputId, setInputId] = useState<number | null>(null)
    const [showErrors, setShowErrors] = useState(false)
    const [tableInfo, setTableInfo] = useState<
        | UserTableFormat[]
        | ExpenseLineTableFormat[]
        | SubdepartmentTableFormat[]
    >([])
    const [tab, setTab] = useState<string>(Tab.USERSEARCH)
    const [tabButtonName, setTabButtonName] = useState<TabButtonsType>(
        TabButtons.USERSUB
    )
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [showTable, setShowTable] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [pickerValue, setPickerValue] = useState<string>("")
    const [editNameInput, setEditNameInput] = useState("")
    const [addSubInput, setAddSubInput] = useState("")
    const [deptId, setDeptId] = useState<number>()
    const [modalInputId, setModalInputId] = useState<number | null>(null)
    const [marketAccess, setMarketAccess] = useState(false)
    const [showEditButtons, setShowEditButtons] = useState(false)
    const [editModalBool, setEditModalBool] = useState(false)
    const { addErrorToast, addSuccessToast } = useToasts()

    const { refetch: getDepartment } = useGetDepartment(
        inputId!,
        { sub_departments: true },
        { query: { enabled: false } }
    )
    const { refetch: getExpenseUser } = useGetExpenseUser(
        inputId!,
        { sub_departments: true },
        { query: { enabled: false } }
    )
    const { refetch: getSubDepartments } = useGetSubDepartments(
        { expense_line: inputId! },
        { query: { enabled: false } }
    )
    const { refetch: getSubDepartment } = useGetSubDepartment(
        inputId!,
        { expense_lines: true },
        { query: { enabled: false } }
    )
    const { mutateAsync: createExpenseUserSubDepartment } =
        useCreateExpenseUserSubDepartment()
    const { mutateAsync: createSubDepartment } = useCreateSubDepartment()
    const { mutateAsync: createSubDepartmentExpenseLine } =
        useCreateSubDepartmentExpenseLine()
    const { mutateAsync: updateMarketAccess } = useUpdateMarketAccess()
    const { mutateAsync: updateExpenseLine } = useUpdateExpenseLine()
    const { mutateAsync: createExpenseLine } = useCreateExpenseLine()
    const { mutateAsync: updateSubDepartment } = useUpdateSubDepartment()
    const { mutateAsync: deleteExpenseUserSubDepartment } =
        useDeleteExpenseUserSubDepartment()
    const { mutateAsync: deleteSubDepartmentExpenseLine } =
        useDeleteSubDepartmentExpenseLine()

    const closeModal = () => {
        setIsModalVisible(false)
    }

    function showModal(modalName: TabButtonsType) {
        setTabButtonName(modalName)
        setIsModalVisible(true)
    }

    let modal: JSX.Element | null = null

    const userModal = (
        <Modals
            modalTitle="Add Sub-Department"
            closeModal={closeModal}
            onConfirm={onUserConfirm}
            modalField={
                <SubDepartmentPicker
                    onIdSelected={setModalInputId}
                    hasErrors={showErrors}
                    onCleared={() => setModalInputId(0)}
                />
            }
        />
    )

    const subdeptModal = (
        <Modals
            modalTitle="Add Expense Line"
            closeModal={closeModal}
            onConfirm={onSubDepartmentConfirm}
            modalField={
                <ExpenseLinePicker
                    onIdSelected={setModalInputId}
                    hasErrors={showErrors}
                    onCleared={() => setModalInputId(0)}
                />
            }
        />
    )

    const subdeptEditNameModal = (
        <Modals
            modalTitle="Edit Sub-Department Name"
            closeModal={closeModal}
            onConfirm={onSubDepartmentEditNameConfirm}
            modalField={
                <EuiFieldText
                    placeholder="Enter Name"
                    value={editNameInput}
                    onChange={(e) => {
                        setEditNameInput(e.target.value)
                    }}
                    isInvalid={editModalBool}
                />
            }
        />
    )

    const expenseLineModal = (
        <Modals
            modalTitle="Edit Expense Line Name"
            closeModal={closeModal}
            onConfirm={onExpenseConfirm}
            modalField={
                <EuiFieldText
                    placeholder="Enter Name"
                    value={editNameInput}
                    onChange={(e) => {
                        setEditNameInput(e.target.value)
                    }}
                />
            }
        />
    )

    const createExpenseLineModal = (
        <Modals
            modalTitle="Create New Expense LIne"
            closeModal={closeModal}
            onConfirm={onCreateExpenseLineConfirm}
            modalField={
                <EuiFieldText
                    placeholder="Enter Expense Line Name"
                    value={editNameInput}
                    onChange={(e) => {
                        setEditNameInput(e.target.value)
                    }}
                />
            }
        />
    )

    const deptModal = (
        <Modals
            modalTitle="Add New Sub-Department"
            closeModal={closeModal}
            onConfirm={onDepartmentConfirm}
            modalField={
                <EuiFieldText
                    placeholder="Enter Sub-Department Name"
                    value={addSubInput}
                    onChange={(e) => {
                        setAddSubInput(e.target.value)
                    }}
                />
            }
        />
    )

    if (isModalVisible) {
        const modalMapping = {
            [TabButtons.USERSUB]: userModal,
            [TabButtons.EDITEXPENSELINENAME]: expenseLineModal,
            [TabButtons.EDITSUBDEPTNAME]: subdeptEditNameModal,
            [TabButtons.ADDSUBDEPT]: deptModal,
            [TabButtons.CREATEEXPENSELINE]: createExpenseLineModal,
            [TabButtons.ADDEXPENSELINE]: subdeptModal,
        }

        modal = modalMapping[tabButtonName]
    }

    const message = (
        <EuiEmptyPrompt
            title={<h3>No Data </h3>}
            titleSize="xs"
            body="No data was found"
        />
    )

    async function onDelete(item: UserTableFormat & SubdepartmentTableFormat) {
        if (!inputId) {
            return
        }
        switch (tab) {
            case Tab.USERSEARCH:
                try {
                    const deleteUserSubData = {
                        expense_user_id: inputId,
                        sub_department_id: item.sub_id,
                    }
                    await deleteExpenseUserSubDepartment({
                        data: deleteUserSubData,
                    })

                    if (instanceOfUserTableFormat(tableInfo)) {
                        const temp = tableInfo.filter(function (
                            obj: UserTableFormat
                        ) {
                            return obj.sub_id !== item.sub_id
                        })

                        setTableInfo(temp)
                    }
                } catch (e) {
                    addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
                }
                break

            case Tab.SUBDEPARTMENT:
                try {
                    const deleteSubExpenseData = {
                        sub_department_id: inputId!,
                        expense_line_id: item.expenseLineId,
                    }
                    await deleteSubDepartmentExpenseLine({
                        data: deleteSubExpenseData,
                    })

                    if (instanceOfSubdepartmentTableFormat(tableInfo)) {
                        const temp = tableInfo.filter(function (
                            obj: SubdepartmentTableFormat
                        ) {
                            return obj.expenseLineId !== item.expenseLineId
                        })

                        setTableInfo(temp)
                    }
                } catch (e) {
                    addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
                }
                break
        }
    }

    async function onUserConfirm() {
        if (inputId && modalInputId) {
            const updates = {
                expense_user_id: inputId,
                sub_department_id: modalInputId,
            }

            try {
                await createExpenseUserSubDepartment({ data: updates })

                addSuccessToast({
                    text: "Sub-Department successfully added",
                    toastLifeTimeMs: 10000,
                })
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
            }
            closeModal()
        }
    }

    async function onSubDepartmentConfirm() {
        if (modalInputId && inputId) {
            const update = {
                sub_department_id: inputId,
                expense_line_id: modalInputId,
            }

            try {
                await createSubDepartmentExpenseLine({ data: update })

                addSuccessToast({
                    text: "Expense Line successfully added",
                    toastLifeTimeMs: 10000,
                })
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
            }

            closeModal()
        }
    }
    async function onDepartmentConfirm() {
        if (addSubInput) {
            const update = {
                name: addSubInput,
                department_id: 1,
            }

            try {
                await createSubDepartment({ data: update })

                addSuccessToast({
                    text: "Sub-Department successfully added",
                    toastLifeTimeMs: 10000,
                })
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
            }

            closeModal()
        }
    }

    async function onExpenseConfirm() {
        if (inputId) {
            const updates = { name: editNameInput, active: true }

            try {
                await updateExpenseLine({
                    expenseLineId: inputId,
                    data: updates,
                })

                addSuccessToast({
                    text: "Expense Line name successfully changed",
                    toastLifeTimeMs: 10000,
                })
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
            }

            closeModal()
        }
    }

    async function onCreateExpenseLineConfirm() {
        if (!editNameInput) return

        const updates = { name: editNameInput, active: true }

        try {
            await createExpenseLine({
                data: updates,
            })

            addSuccessToast({
                text: "New Expense Line successfully created",
                toastLifeTimeMs: 10000,
            })
        } catch (e) {
            addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
        }

        closeModal()
    }

    async function onSubDepartmentEditNameConfirm() {
        if (editNameInput && inputId) {
            const updates = { name: editNameInput, department_id: deptId }

            try {
                await updateSubDepartment({
                    subDepartmentId: inputId,
                    data: updates,
                })

                addSuccessToast({
                    text: "Sub-Department name successfully changed",
                    toastLifeTimeMs: 10000,
                })
            } catch (e) {
                addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
            }

            closeModal()
        } else {
            setEditModalBool(true)
        }
    }

    async function onMarketAccessToggle() {
        if (!inputId) return

        const updates = { market_access: !marketAccess }
        try {
            await updateMarketAccess({
                expenseUserId: inputId,
                data: updates,
            })

            addSuccessToast({
                text: "Market Access successfully changed",
                toastLifeTimeMs: 10000,
            })

            setMarketAccess(!marketAccess)
        } catch (e) {
            addErrorToast({ text: e.message, toastLifeTimeMs: 10000 })
        }
    }

    function setPickerValueFunc(
        func: React.Dispatch<React.SetStateAction<string>>
    ) {
        return (option: { label: string; id: string } | null) => {
            if (option) {
                func(option.label)
                setEditNameInput(option.label)
            }
        }
    }

    const actions = [
        {
            render: (item: UserTableFormat & SubdepartmentTableFormat) => {
                return (
                    <EuiButtonIcon
                        iconType="trash"
                        onClick={() => onDelete(item)}
                        aria-label="Delete"
                        color="danger"
                    />
                )
            },
        },
    ]

    const userColumns = [
        {
            field: "subdepartment",
            name: "Sub-Department",
            truncateText: true,
        },
        {
            field: "primary",
            name: "Primary",
        },
        {
            name: "Actions",
            actions,
        },
    ]

    const expenseLineColumns = [
        {
            field: "sub_name",
            name: "Sub-Department",
            sortable: true,
            truncateText: true,
        },
    ]

    const subDepartmentColumns = [
        {
            field: "expenseLineName",
            name: "Expense Line",
            sortable: true,
            truncateText: true,
        },
        {
            name: "Actions",
            actions,
        },
    ]

    const departmentColumns = [
        {
            field: "sub_name",
            name: "Sub-Department",
            sortable: true,
            truncateText: true,
        },
    ]

    const search: EuiSearchBarProps = {
        box: {
            incremental: true,
            schema: true,
        },
    }

    const tabProps = {
        isLoading: isLoading,
        searchButtonClick: onSearchButton,
        showEditButton: showEditButtons,
    }
    const userTabProps = {
        ...tabProps,
        name: "User to Sub-Department Search",
        picker: (
            <EsdbUserPicker
                onIdSelected={setInputId}
                hasErrors={showErrors}
                onCleared={() => setInputId(0)}
            />
        ),
        table: (
            <Table
                itemId={"change"}
                tableCaption={"change"}
                info={tableInfo}
                message={message}
                columns={userColumns}
                showTable={showTable}
            />
        ),
        editButton: (
            <>
                <EuiSwitch
                    checked={marketAccess}
                    onChange={onMarketAccessToggle}
                    label={"Market Access"}
                />
                <EuiButton
                    onClick={() => showModal(TabButtons.USERSUB)}
                    style={{ float: "right" }}
                >
                    Add Sub-Department
                </EuiButton>
            </>
        ),
    }

    const expenseLineProps = {
        ...tabProps,
        name: "Expense Line Search",
        picker: (
            <ExpenseLinePicker
                onIdSelected={setInputId}
                hasErrors={showErrors}
                onCleared={() => setInputId(0)}
                onSelected={setPickerValueFunc(setPickerValue)}
                selectedValue={pickerValue}
            />
        ),
        table: (
            <Table
                itemId={"change"}
                tableCaption={"change"}
                info={tableInfo}
                message={message}
                columns={expenseLineColumns}
                showTable={showTable}
            />
        ),
        editButton: (
            <EuiButton
                onClick={() => showModal(TabButtons.EDITEXPENSELINENAME)}
                style={{ float: "right" }}
            >
                Edit Expense Line Name
            </EuiButton>
        ),
    }

    const subDepartmentProps = {
        ...tabProps,
        name: "Sub-Department Search",
        picker: (
            <SubDepartmentPicker
                onIdSelected={setInputId}
                hasErrors={showErrors}
                onCleared={() => setInputId(0)}
                onSelected={setPickerValueFunc(setPickerValue)}
                selectedValue={pickerValue}
            />
        ),
        table: (
            <Table
                itemId={"change"}
                tableCaption={"change"}
                info={tableInfo}
                message={message}
                columns={subDepartmentColumns}
                showTable={showTable}
            />
        ),
        editButton: (
            <>
                <EuiButton
                    onClick={() => showModal(TabButtons.ADDEXPENSELINE)}
                    style={{ float: "right" }}
                >
                    Add Expense Line
                </EuiButton>

                <EuiButton
                    onClick={() => showModal(TabButtons.EDITSUBDEPTNAME)}
                    style={{ float: "right", marginRight: 5 }}
                >
                    Edit Sub-Department Name
                </EuiButton>
            </>
        ),
    }

    const departmentProps = {
        ...tabProps,
        name: "Department Search",
        picker: (
            <DepartmentPicker
                onIdSelected={setInputId}
                hasErrors={showErrors}
                onCleared={() => setInputId(0)}
            />
        ),
        table: (
            <Table
                itemId={"change"}
                tableCaption={"change"}
                info={tableInfo}
                message={message}
                columns={departmentColumns}
                showTable={showTable}
                search={search}
            />
        ),
    }

    const adminPage = (
        <>
            <EuiButton onClick={() => showModal(TabButtons.CREATEEXPENSELINE)}>
                Create Expense Line
            </EuiButton>

            <EuiButton
                onClick={() => showModal(TabButtons.ADDSUBDEPT)}
                style={{ marginLeft: 5 }}
            >
                Add Sub-Department
            </EuiButton>
        </>
    )

    async function onSearchButton() {
        if (inputId) {
            setIsLoading(true)
            const queryMethod =
                {
                    [Tab.USERSEARCH]: getExpenseUser,
                    [Tab.EXPENSELINE]: getSubDepartments,
                    [Tab.SUBDEPARTMENT]: getSubDepartment,
                    [Tab.DEPARTMENT]: getDepartment,
                }[tab] || getExpenseUser

            const tableData = await queryMethod()
            setShowTable(true)

            if (tableData.isSuccess) {
                const { data } = tableData
                switch (tab) {
                    case Tab.USERSEARCH:
                        if (isExpenseUser(data)) {
                            setTableInfo(transformUserData(data))
                            setMarketAccess(data.market_access)
                        } else {
                            setTableInfo([])
                        }

                        break

                    case Tab.SUBDEPARTMENT:
                        if (isSubDepartment(data)) {
                            const subDeptData = transformSubDepartmentData(data)
                            setTableInfo(subDeptData)
                            setDeptId(data.department_id)
                        } else {
                            setTableInfo([])
                        }

                        break

                    case Tab.EXPENSELINE:
                        if (isSubDepartmentArray(data)) {
                            setTableInfo(transformExpenseLineData(data))
                        } else {
                            setTableInfo([])
                        }

                        break
                    case Tab.DEPARTMENT:
                        if (isDepartment(data)) {
                            setTableInfo(
                                transformExpenseLineData(
                                    data.sub_departments ?? []
                                )
                            )
                        } else {
                            setTableInfo([])
                        }
                }
            } else {
                setTableInfo([])
            }
            setShowEditButtons(true)
            setShowErrors(false)
            setIsLoading(false)
        } else {
            setShowErrors(true)
            addErrorToast({
                text: "Search input required",
                toastLifeTimeMs: 5000,
            })
        }
    }

    const onTabClicked = (tab: string) => {
        setTab(tab)
        setTableInfo([])
        setShowTable(false)
        setShowErrors(false)
        setShowEditButtons(false)
    }

    return (
        <>
            <EuiPageHeader
                tabs={[
                    {
                        label: "User to Search",
                        isSelected: tab === Tab.USERSEARCH,
                        onClick: () => onTabClicked(Tab.USERSEARCH),
                    },
                    {
                        label: "Expense Line Search",
                        isSelected: tab === Tab.EXPENSELINE,
                        onClick: () => onTabClicked(Tab.EXPENSELINE),
                    },
                    {
                        label: "Sub-Department Search",
                        isSelected: tab === Tab.SUBDEPARTMENT,
                        onClick: () => onTabClicked(Tab.SUBDEPARTMENT),
                    },
                    {
                        label: "Department Search",
                        isSelected: tab === Tab.DEPARTMENT,
                        onClick: () => onTabClicked(Tab.DEPARTMENT),
                    },
                    {
                        label: "Admin",
                        isSelected: tab === Tab.ADMIN,
                        onClick: () => onTabClicked(Tab.ADMIN),
                    },
                ]}
            />

            {tab === Tab.USERSEARCH && <TabScreens {...userTabProps} />}
            {tab === Tab.EXPENSELINE && <TabScreens {...expenseLineProps} />}
            {tab === Tab.SUBDEPARTMENT && (
                <TabScreens {...subDepartmentProps} />
            )}
            {tab === Tab.DEPARTMENT && <TabScreens {...departmentProps} />}
            {tab === Tab.ADMIN && adminPage}

            {modal}
        </>
    )
}
