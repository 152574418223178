import Axios, { AxiosError, AxiosRequestConfig } from "axios"

import config from "../config"

export const PAYOUT_PROGRAM_AXIOS_INSTANCE = Axios.create({
    baseURL: `${config.PAYOUT_PROGRAM_URL}`,
})

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
    const source = Axios.CancelToken.source()
    const promise = PAYOUT_PROGRAM_AXIOS_INSTANCE({
        ...config,
        cancelToken: source.token,
    }).then(({ data }) => data)

    // @ts-ignore
    promise.cancel = () => {
        source.cancel("Query was cancelled by Vue Query")
    }

    return promise
}

export default customInstance

export interface ErrorType<Error> extends AxiosError<Error> {}
