import React from "react" //should not need this
import { EuiFlexGroup, EuiFlexItem, EuiButton } from "@equipmentshare/ds2"

export type TabHomeScreenProps = {
    title: string
    onViewClick: () => void
    onCreateClick: () => void
    onUpdateClick: () => void
    onDeleteClick: () => void
}

export default function TabHomeScreen(props: TabHomeScreenProps) {
    return (
        <>
            <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={props.onViewClick} isLoading={false}>
                        View {props.title}
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={props.onCreateClick} isLoading={false}>
                        Create {props.title}
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={props.onUpdateClick} isLoading={false}>
                        Update {props.title}
                    </EuiButton>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton onClick={props.onDeleteClick} isLoading={false}>
                        Delete {props.title}
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </>
    )
}
