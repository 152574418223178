import React from "react"

import { updateAssetDriverName } from "../../services/DriverAssignment"
import {
    UserAssetAssignment,
    UpdateAssetAssignmentRequest,
    useCreateAssetAssignment,
} from "../../services/Skunkworks/Generated"
import { useToasts } from "../../hooks/useToasts"
import { AssignmentForm } from "./AssignmentForm"

interface NewAssignmentFlyoutProps {
    onClose: () => void
    onSaved: (assignment: UserAssetAssignment) => void
    assetId?: number
    userId?: number
}

export function NewAssignmentFlyout(props: NewAssignmentFlyoutProps) {
    const [isSaving, setIsSaving] = React.useState(false)
    const { addToast } = useToasts()

    const { mutateAsync: createAssetAssignment } = useCreateAssetAssignment()

    function handleSave(values: UpdateAssetAssignmentRequest, name: string) {
        setIsSaving(true)
        createAssetAssignment({ data: values })
            .then(async (data) => {
                if (!values.end_date) {
                    try {
                        await updateAssetDriverName(data.asset_id, name)
                    } catch (e) {
                        // this update is not mission critical so we won't
                        // worry about error handling if this fails for now
                    }
                }

                setIsSaving(false)
                props.onSaved(data)
            })
            .catch((e) => {
                setIsSaving(false)
                addToast({
                    text: e.message,
                    title: "Error saving assignment",
                    iconType: "alert",
                    color: "danger",
                })
            })
    }

    return (
        <AssignmentForm
            onCloseClicked={props.onClose}
            onSavedClicked={handleSave}
            initialValues={{ asset_id: props.assetId, user_id: props.userId }}
            isSaving={isSaving}
        />
    )
}
