import { useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"

function parsed(params: URLSearchParams) {
    return Object.entries(params).reduce(
        (prev, [key, val]) => ({ ...prev, [key]: val }),
        {}
    )
}

export function useQueryParams() {
    const location = useLocation()
    const navigate = useNavigate()
    const urlSearchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search]
    )

    const setParams = useCallback(
        (obj: { [key: string]: string }) => {
            const update = {
                ...parsed(urlSearchParams),
                ...obj,
            }

            const updatedSearch = "?" + new URLSearchParams(update).toString()
            navigate(`${location.pathname}${updatedSearch}`)
        },
        [navigate, location.pathname, urlSearchParams]
    )

    return { setParams, params: new URLSearchParams(useLocation().search) }
}
