import axios from "axios"

export interface ESQuoteRequest {
    branch_id: number
    start_date: string
    end_date: string
    delivery_fee: number
    pickup_fee: number
    delivery_type_id: number
    delivery_type_name: string
    is_tax_exempt: boolean
    equipment_types: ESQuoteEquipmentTypeRequest[]
}

export interface ESQuoteEquipmentTypeRequest {
    equipment_class_id: number
    equipment_class_name: string
    quantity: number
    day_rate: number
    week_rate: number
    four_week_rate: number
    shift_id: string
    selected_rate_type_id: string
}

export interface Shift {
    id: string
    name: string
    multiplier: number
}

export interface ESQuote {
    id?: string
    quote_number?: number
    order_id?: number
    order_created_date?: string
    order_created_by?: number
    po_id?: string
    po_name?: string
    branch_id?: number
    created_date?: string
    expiry_date?: string
    created_by?: number
    sales_rep_id?: number
    secondary_sales_rep_ids?: number[]
    rsp_company_id?: number
    company_id?: number
    deliver_to?: string
    delivery_mileage?: number
    ordered_by?: string
    ordered_by_phone?: string
    ordered_by_email?: string
    start_date: string
    end_date: string
    delivery_fee: number
    pickup_fee: number
    sales_tax_percentage?: number
    state_specific_tax_percentage?: number
    equipment_types: EquipmentType[]
    sale_items?: SaleItem[]
    last_modified_date?: string
    company_name?: string
    new_company_name?: string
    contact_id?: number
    contact_name?: string
    contact_phone?: string
    contact_email?: string
    location_id?: number
    location_description?: string
    rpp_id?: number
    rpp_name?: string
    delivery_type_id: number
    delivery_type_name: string
    last_modified_by?: number
    is_tax_exempt: boolean
    has_pdf?: boolean
    pdf_url?: string
}

export interface SaleItem {
    id?: string
    quote_id?: string
    line_item_type_id?: number
    sale_item?: string
    quantity?: number
    price?: number
}

export interface EquipmentType {
    id?: string
    quote_id?: string
    equipment_class_id: number
    equipment_class_name: string
    quantity: number
    day_rate: number
    week_rate: number
    four_week_rate: number
    shift_id: string
    selected_rate_type_id?: string
    suggested_floor_daily_rate?: number
    suggested_floor_weekly_rate?: number
    suggested_floor_monthly_rate?: number
    suggested_bench_mark_daily_rate?: number
    suggested_bench_mark_weekly_rate?: number
    suggested_bench_mark_monthly_rate?: number
    suggested_online_daily_rate?: number
    suggested_online_weekly_rate?: number
    suggested_online_monthly_rate?: number
    shift?: Shift
}

export async function createESQuote(body: ESQuoteRequest) {
    const resp = await axios.post<ESQuote>("quotes-web-api/quotes", body)
    return resp.data
}
