import { Link } from "react-router-dom"

import { EuiCallOut, EuiLoadingSpinner, EuiIcon } from "@equipmentshare/ds2"

export type ErrorConfirmationProps = {
    invoiceError: boolean
    confirmation: boolean
    downloadComplete: boolean
}

export default function ErrorConfirmation(props: ErrorConfirmationProps) {
    return (
        <>
            {props.invoiceError && (
                <EuiCallOut title="Error" color="danger">
                    <p color="red">
                        Couldn't find any invoices. Please adjust the filters
                        below
                    </p>
                </EuiCallOut>
            )}

            {props.confirmation && (
                <>
                    <EuiCallOut color="primary">
                        <p color="black">
                            <EuiLoadingSpinner />
                            &emsp; We're preparing your file now. Please do not
                            navigate away from this page. Your export will
                            automatically download when it is complete. Note
                            that large exports may take up to 10 minutes.
                        </p>
                    </EuiCallOut>
                </>
            )}

            {props.downloadComplete && (
                <>
                    <EuiCallOut color="success">
                        <p color="black">
                            <EuiIcon type="check" />
                            &emsp; File download initiated. You can now return
                            to the <Link to="/">homepage</Link>.
                        </p>
                    </EuiCallOut>
                </>
            )}
        </>
    )
}
