import { FC, useEffect, useState, useMemo, CSSProperties } from "react"
import moment from "moment"

import {
    EuiBasicTableColumn,
    Criteria,
    EuiInMemoryTable,
    EuiTableFieldDataColumnType,
    EuiTableSelectionType,
    EuiButtonEmpty,
    EuiDescriptionList,
    EuiButtonIcon,
    EuiText,
    EuiAccordion,
    useGeneratedHtmlId,
} from "@equipmentshare/ds2"
import { DownloadLineIcon } from "@equipmentshare/ds2-icons"

import {
    RebateAgreement,
    Company,
    RebateTier,
    downloadRebateAgreementFile,
} from "../../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../../hooks/useAuthenticationDetails"
import { usdFormatter } from "../../../Utils"
import { WRITE_REBATE_AGREEMENT_PERMISSION } from "../../../Const"
import { useToasts } from "../../../hooks/useToasts"

const DEFAULT_MESSAGE = "No Customer Rebate Agreements to View"

const DATE_FORMAT = "M/DD/YYYY"

type TiersDisplayProps = {
    rebateTiers: RebateTier[]
}

const TiersDisplay: FC<TiersDisplayProps> = ({ rebateTiers }) => {
    const sortedTIers = useMemo(
        () => rebateTiers?.sort((a, b) => a.gross_rent_payments_min - b.gross_rent_payments_min),
        [rebateTiers]
    )

    return (
        <EuiDescriptionList
            compressed
            gutterSize="s"
            titleProps={{ style: { fontWeight: 500 } }}
            listItems={sortedTIers.map((rTier) => ({
                title: `${usdFormatter.format(rTier.gross_rent_payments_min)} - ${usdFormatter.format(
                    rTier.gross_rent_payments_max
                )}`,
                description: `${(rTier.rebate_percentage * 100).toFixed(2)}%`,
            }))}
        />
    )
}

type CompaniesDisplayProps = {
    companies: Company[]
    rebateAgreementId: number
}

const CompaniesDisplay: FC<CompaniesDisplayProps> = ({ companies = [], rebateAgreementId }) => {
    const unknownCompanyDisplay = "UNKNOWN COMPANY NAME"
    const accordionId = useGeneratedHtmlId({ suffix: String(rebateAgreementId) })
    const listStyle: CSSProperties = { listStyle: "inside" }
    const shortListLength = 2

    const shortCompanyList = (
        <ul onClick={(e) => e.stopPropagation()} style={listStyle}>
            {companies.slice(0, shortListLength).map((company) => (
                <li key={company.company_id}>{company.name || unknownCompanyDisplay}</li>
            ))}
        </ul>
    )

    return companies.length > shortListLength ? (
        <EuiAccordion
            id={accordionId}
            buttonContent={shortCompanyList}
            buttonProps={{ style: { cursor: "default", textDecoration: "unset" } }}
            arrowDisplay="right"
            element="div"
            buttonElement="div"
            paddingSize="none"
        >
            <ul style={listStyle}>
                {companies.slice(shortListLength).map((company) => (
                    <li key={company.company_id}>{company.name || unknownCompanyDisplay}</li>
                ))}
            </ul>
        </EuiAccordion>
    ) : (
        shortCompanyList
    )
}

export type CustomerRebatesTableProps = {
    items: RebateAgreement[]
    loading: boolean
    error: boolean
    selectable?: boolean
    onSelectionChange?: (selection: RebateAgreement[]) => void
    onRebateSelect: (rebateAgreement: RebateAgreement) => void
}

export const CustomerRebatesTable: FC<CustomerRebatesTableProps> = ({
    items,
    loading,
    error,
    selectable,
    onSelectionChange = () => {},
    onRebateSelect,
}) => {
    const [sortField, setSortField] = useState<string>("rebate_agreement_id")
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
    const [message, setMessage] = useState(DEFAULT_MESSAGE)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const { permissions: userPermissions } = useAuthenticationDetails()
    const { addErrorToast } = useToasts()
    const hasWritePermissions = userPermissions.includes(WRITE_REBATE_AGREEMENT_PERMISSION)

    let columns: Array<EuiBasicTableColumn<RebateAgreement>> = [
        {
            field: "rebate_agreement_id",
            name: "Rebate Agreement ID",
            sortable: true,
        },
        {
            name: "Company",
            render: (rebateAgreement: RebateAgreement) => (
                <CompaniesDisplay
                    companies={rebateAgreement.companies ?? []}
                    rebateAgreementId={rebateAgreement.rebate_agreement_id}
                />
            ),
        },
        {
            field: "rebate_period_begin",
            name: "Rebate Period Begin",
            sortable: true,
            render: (_, rebateAgreement) => moment.utc(rebateAgreement.rebate_period_begin).format(DATE_FORMAT),
        },
        {
            field: "rebate_period_end",
            name: "Rebate Period End",
            sortable: true,
            render: (_, rebateAgreement) => moment.utc(rebateAgreement.rebate_period_end).format(DATE_FORMAT),
        },
        {
            field: "payment_terms",
            name: "Payment Terms",
            sortable: (rebateAgreement) => parseFloat(rebateAgreement.payment_terms),
            render: (_, rebateAgreement) => `${rebateAgreement.payment_terms} Days`,
        },
        {
            field: "custom_rental_rates",
            name: "Custom Rental Rates",
            sortable: true,
            render: (_, rebateAgreement) => (rebateAgreement.custom_rental_rates ? "Yes" : "No"),
        },
        {
            name: "Rebate Tiers",
            render: (rebateAgreement: RebateAgreement) => (
                <TiersDisplay rebateTiers={rebateAgreement.rebate_tiers ?? []} />
            ),
        },
        {
            field: "rebate_agreement_id",
            name: "Agreement PDF",
            render: (_, rebateAgreement) =>
                rebateAgreement.file_name && (
                    <>
                        <EuiText size="s">{rebateAgreement.file_name}</EuiText>
                        <EuiButtonIcon
                            display="empty"
                            iconType={DownloadLineIcon}
                            onClick={() => handleFileDownload(rebateAgreement.rebate_agreement_id)}
                        />
                    </>
                ),
        },
    ]

    if (hasWritePermissions) {
        columns.push({
            name: "Actions",
            actions: [
                {
                    render: (rebateAgreement: RebateAgreement) => {
                        return <EuiButtonEmpty onClick={() => onRebateSelect(rebateAgreement)}>Update</EuiButtonEmpty>
                    },
                },
            ],
        })
    }

    const getRowProps = (record: RebateAgreement) => {
        return {
            "data-test-subj": `row-${record.rebate_agreement_id}`,
        }
    }

    const getCellProps = (record: RebateAgreement, cell: EuiTableFieldDataColumnType<RebateAgreement>) => {
        return {
            "data-test-subj": `cell-${record.rebate_agreement_id}-${String(cell.field)}`,
        }
    }

    const sortOptions = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    }

    const tableOnChange = ({ sort }: Criteria<RebateAgreement>) => {
        if (sort) {
            setSortField(String(sort.field))
            setSortDirection(sort.direction)
        }
    }

    const selection: EuiTableSelectionType<RebateAgreement> = {
        onSelectionChange: (records) => onSelectionChange(records),
    }

    const handleFileDownload = async (rebateAgreementId: number) => {
        try {
            const agreementFile = await downloadRebateAgreementFile(rebateAgreementId)
            window.open(agreementFile.url, "_blank")
        } catch (e) {
            addErrorToast({
                text: "Failed to download agreement PDF.",
                toastLifeTimeMs: 10000,
            })
        }
    }

    useEffect(() => {
        if (loading) {
            setMessage("Loading...")
        } else {
            setMessage(DEFAULT_MESSAGE)
        }
        if (error) {
            setErrorMessage("Failed to Load Customer Rebate Agreements")
        } else {
            setErrorMessage(undefined)
        }
    }, [error, loading])

    return (
        <EuiInMemoryTable
            tableCaption={`Customer Rebate Agreements`}
            tableLayout="auto"
            items={items}
            itemId="rebate_agreement_id"
            columns={columns}
            message={message}
            error={errorMessage}
            rowProps={getRowProps}
            cellProps={getCellProps}
            loading={loading}
            sorting={sortOptions}
            onChange={tableOnChange}
            selection={selectable && hasWritePermissions ? selection : undefined}
        />
    )
}

export default CustomerRebatesTable
