import {
    EuiCheckboxGroup,
    EuiButton,
    EuiSpacer,
    EuiFormRow,
    EuiLoadingSpinner,
    EuiText,
    EuiRadioGroup,
    EuiFieldText,
} from "@equipmentshare/ds2"
import Page from "../Page/Page"
import { useCallback, useEffect, useState } from "react"
import { useToasts } from "../../hooks/useToasts"
import {
    createCompanyPurchaseOrderUserLimit,
    getCompanyPurchaseOrderUserLimit,
    getOneUser,
    updateCompanyPurchaseOrderUserLimit,
    updateUserFleetTrack,
    User,
} from "../../services/Misc"
import { UserPicker } from "../Pickers"

export interface UserPermissions {
    [index: string]: boolean
    can_read_asset_financial_records: boolean
    can_create_asset_financial_records: boolean
}

export interface FleetPermissions {
    [index: string]: boolean
    can_edit_line_asset_id_assignments: boolean
    can_edit_line_after_schedule_assignment: boolean
    can_change_reconciliation_status: boolean
    can_edit_pending_schedule: boolean
    can_change_out_of_reconciliation: boolean
    can_change_out_of_financial_schedule: boolean
    can_edit_read_only_line: boolean
}

const fleetPermissionDefaultValue: FleetPermissions = {
    can_edit_line_asset_id_assignments: false,
    can_edit_line_after_schedule_assignment: false,
    can_change_reconciliation_status: false,
    can_edit_pending_schedule: false,
    can_change_out_of_reconciliation: false,
    can_change_out_of_financial_schedule: false,
    can_edit_read_only_line: false,
}

export default function AutoFleetEnablement() {
    const [userId, setUserId] = useState<number | null>(null)
    const [userIdError, setUserIdError] = useState(false)
    const [sourceUserId, setSourceUserId] = useState<number | null>(null)
    const [sourceUserIdError, setSourceUserIdError] = useState(false)
    const [loadingText, setLoadingText] = useState({ loading: false, text: "" })
    const [userPermissionSelectedId, setUserPermissionSelectedId] =
        useState("none")
    const [userPermissions, setUserPermissions] = useState<UserPermissions>({
        can_read_asset_financial_records: false,
        can_create_asset_financial_records: false,
    })
    const [fleetPermissions, setFleetPermissions] = useState<FleetPermissions>(
        fleetPermissionDefaultValue
    )
    const { addSuccessToast, addErrorToast } = useToasts()
    const [hasFleetPermissions, setHasFleetPermissions] = useState(false)
    const [approvalLimit, setApprovalLimit] = useState<string>("")
    const [disablePermissions, setDisablePermissions] = useState(false)

    const parseApprovalLimit = useCallback(() => {
        const value = parseFloat(approvalLimit)
        if (isNaN(value)) {
            return null
        }
        return value
    }, [approvalLimit])

    const handleFleetPermissionsChange = useCallback(
        (permission: keyof FleetPermissions) => {
            setFleetPermissions({
                ...fleetPermissions,
                [permission]: !fleetPermissions[permission],
            })
        },
        [fleetPermissions]
    )

    const handleUserPermissionChange = (id: string) => {
        switch (id) {
            case "none":
                setUserPermissions({
                    can_create_asset_financial_records: false,
                    can_read_asset_financial_records: false,
                })
                setDisablePermissions(true)
                break
            case "read":
                setUserPermissions({
                    can_create_asset_financial_records: false,
                    can_read_asset_financial_records: true,
                })
                setDisablePermissions(true)
                break
            case "create":
                setUserPermissions({
                    can_create_asset_financial_records: true,
                    can_read_asset_financial_records: true,
                })
                setDisablePermissions(false)
                break
        }
        setUserPermissionSelectedId(id)
    }

    async function loadRequest<T>(text: string, requestFunc: () => Promise<T>) {
        setLoadingText({ loading: true, text })
        const result = await requestFunc()
        setLoadingText({ loading: false, text: "" })
        return result
    }

    const getUserAndPermissions = useCallback(
        async (userId: number | null, isTargetUser: boolean) => {
            const setUserPermissionSelected = (user: User) => {
                const {
                    can_read_asset_financial_records: can_read,
                    can_create_asset_financial_records: can_create,
                } = user
                setUserPermissionSelectedId(
                    (can_read && can_create && "create") ||
                        (can_read && "read") ||
                        "none"
                )
                setDisablePermissions(!(can_read && can_create))
            }

            if (userId) {
                const user = await loadRequest("Getting User", () =>
                    getOneUser(userId)
                )
                const permissions = await loadRequest(
                    "Getting Permissions",
                    () => getCompanyPurchaseOrderUserLimit(userId)
                )
                if (user) {
                    setUserPermissions({
                        can_create_asset_financial_records:
                            !!user.can_create_asset_financial_records,
                        can_read_asset_financial_records:
                            !!user.can_read_asset_financial_records,
                    })
                    setUserPermissionSelected(user)
                }
                if (permissions) {
                    if (isTargetUser) {
                        setHasFleetPermissions(true)
                    }
                    setFleetPermissions({
                        can_edit_line_asset_id_assignments:
                            !!permissions.can_edit_line_asset_id_assignments,
                        can_edit_line_after_schedule_assignment:
                            !!permissions.can_edit_line_after_schedule_assignment,
                        can_change_reconciliation_status:
                            !!permissions.can_change_reconciliation_status,
                        can_edit_pending_schedule:
                            !!permissions.can_edit_pending_schedule,
                        can_change_out_of_reconciliation:
                            !!permissions.can_change_out_of_reconciliation,
                        can_change_out_of_financial_schedule:
                            !!permissions.can_change_out_of_financial_schedule,
                        can_edit_read_only_line:
                            !!permissions.can_edit_read_only_line,
                    })
                    setApprovalLimit(`${permissions.approval_limit ?? ""}`)
                } else {
                    if (isTargetUser) {
                        setHasFleetPermissions(false)
                    }
                    setApprovalLimit("")
                    setFleetPermissions(fleetPermissionDefaultValue)
                }
            }
        },
        []
    )

    const updatePermissions = useCallback(async () => {
        try {
            if (!userId) return
            const params = {
                ...(disablePermissions
                    ? fleetPermissionDefaultValue
                    : fleetPermissions),
                approval_limit: disablePermissions
                    ? null
                    : parseApprovalLimit(),
            }
            if (hasFleetPermissions) {
                await updateCompanyPurchaseOrderUserLimit(userId, params)
                addSuccessToast({ text: "Successfully Updated Permissions" })
            } else {
                await createCompanyPurchaseOrderUserLimit(userId, params)
                addSuccessToast({ text: "Successfully Created Permissions" })
            }
        } catch (e) {
            console.error(e)
            addErrorToast({ text: "Failed to Update/Create Permissions" })
        }
    }, [
        userId,
        addErrorToast,
        addSuccessToast,
        fleetPermissions,
        hasFleetPermissions,
        parseApprovalLimit,
        disablePermissions,
    ])

    const handleUpdateUser = useCallback(async () => {
        try {
            if (!userId) {
                setUserIdError(true)
                return
            } else if (
                userPermissions.can_create_asset_financial_records &&
                !userPermissions.can_read_asset_financial_records
            ) {
                addErrorToast({ text: "Can't only add create permission" })
                return
            } else if (userId === sourceUserId) {
                addErrorToast({
                    text: "Target and Source users can't be the same",
                })
                return
            }
            await loadRequest("Updating User", () =>
                updateUserFleetTrack(userId, userPermissions)
            )
            addSuccessToast({ text: "User updated" })
            await loadRequest("Updating/Creating Permissions", () =>
                updatePermissions()
            )
            getUserAndPermissions(userId, true)
        } catch (e) {
            console.error(e)
            addErrorToast({ text: "Failed to Update User" })
        }
    }, [
        userPermissions,
        userId,
        sourceUserId,
        addSuccessToast,
        addErrorToast,
        updatePermissions,
        getUserAndPermissions,
    ])

    useEffect(() => {
        const id = sourceUserId || userId
        getUserAndPermissions(id, id === userId)
    }, [userId, sourceUserId, getUserAndPermissions])

    const userPermissionOptions = [
        {
            id: "none",
            label: "None",
        },
        {
            id: "read",
            label: "Read",
        },
        {
            id: "create",
            label: "Read/Create",
        },
    ]

    const fleetPermissionsOptions = [
        {
            id: "can_edit_line_asset_id_assignments",
            label: "Can Edit Line Asset Id Assignments",
        },
        {
            id: "can_edit_line_after_schedule_assignment",
            label: "Can Edit Line After Schedule Assignment",
        },
        {
            id: "can_change_reconciliation_status",
            label: "Can Change Reconciliation Status",
        },
        {
            id: "can_edit_pending_schedule",
            label: "Can Edit Pending Schedule",
        },
        {
            id: "can_change_out_of_reconciliation",
            label: "Can Change Out Of Reconciliation",
        },
        {
            id: "can_change_out_of_financial_schedule",
            label: "Can Change Out Of Financial Schedule",
        },
        {
            id: "can_edit_read_only_line",
            label: "Can Edit Read Only Line",
        },
    ]

    const LoadingText = function (props: { loading: boolean; text: string }) {
        return (
            (props.loading || null) && (
                <>
                    <EuiLoadingSpinner />
                    <EuiText>{props.text}</EuiText>
                </>
            )
        )
    }

    return (
        <Page title="Add/Edit User to Fleet Track">
            <UserPicker
                onIdSelected={(id) => {
                    const duplicated =
                        id === sourceUserId && sourceUserId != null
                    setUserIdError(duplicated)
                    setSourceUserIdError(duplicated)
                    setUserId(id)
                }}
                onCleared={() => setUserId(null)}
                hasErrors={userIdError}
            />
            <EuiSpacer size="m" />
            <EuiFormRow label="Optional: Copy from Other User ">
                <UserPicker
                    onIdSelected={(id) => {
                        const duplicated = userId === id && userId != null
                        setUserIdError(duplicated)
                        setSourceUserIdError(duplicated)
                        setSourceUserId(id)
                    }}
                    onCleared={() => setSourceUserId(null)}
                    hasErrors={sourceUserIdError}
                />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiFormRow label="User Permissions">
                <EuiRadioGroup
                    options={userPermissionOptions}
                    onChange={handleUserPermissionChange}
                    idSelected={userPermissionSelectedId}
                />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiFormRow label="Approval Limit" isDisabled={disablePermissions}>
                <EuiFieldText
                    placeholder="Enter Approval Limit"
                    value={approvalLimit}
                    onChange={(e) => setApprovalLimit(e.target.value)}
                />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiFormRow
                label="Fleet Permissions"
                isDisabled={disablePermissions}
            >
                <EuiCheckboxGroup
                    options={fleetPermissionsOptions}
                    onChange={handleFleetPermissionsChange}
                    idToSelectedMap={fleetPermissions}
                />
            </EuiFormRow>
            <EuiSpacer size="m" />
            <EuiButton onClick={handleUpdateUser}>Update User</EuiButton>
            <LoadingText
                loading={loadingText.loading}
                text={loadingText.text}
            />
        </Page>
    )
}
