import { withAuthenticationRequired } from "@auth0/auth0-react"
import { BrowserRouter as Router } from "react-router-dom"
import { useEffect, useState } from "react"

import AppRoutes from "./AppRoutes"
import Menu from "./components/Menu/Menu"
import UpdateModal from "./components/UpdateBanner/UpdateModal"
import "./App.css"
import { cyrb53 } from "./components/UpdateBanner/BannerHelperFunctions"

import { EuiCallOut, EuiButtonEmpty, EuiText } from "@equipmentshare/ds2"
import { AlertLineIcon } from "@equipmentshare/ds2-icons"

export function App() {
    const [isCalloutVisible, setIsCalloutVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const releaseNotes = "Added user notifications for hauled trips in Vehicle Usage Tracker!"
    const isStagingEnv = process.env.REACT_APP_CI_COMMIT_REF_SLUG !== "main" || process.env.NODE_ENV === "test"

    const showModal = () => setIsModalVisible(true)
    const closeModal = () => {
        setIsModalVisible(false)
        setIsCalloutVisible(false)
    }

    const modal = <UpdateModal closeModal={closeModal} releaseNotes={releaseNotes} />

    const stagingCallout = (
        <EuiCallOut title="Testing Environment!" color="warning" iconType={AlertLineIcon} size="s">
            <EuiText color="warning" size="s">
                You are currently in a testing environment. Changes reset nightly.
            </EuiText>
        </EuiCallOut>
    )

    const updateCallout = (
        <EuiCallOut title="New Update!" color="primary" iconType="branch" size="s">
            <EuiButtonEmpty style={{ marginLeft: 16 }} onClick={showModal}>
                Click for more details
            </EuiButtonEmpty>
        </EuiCallOut>
    )

    useEffect(() => {
        const calloutDisplayedBool = localStorage.getItem("calloutDisplayedBool")
        const currentReleaseNotes = localStorage.getItem("releaseNotes")

        if (calloutDisplayedBool && currentReleaseNotes) {
            const newReleaseNotes = cyrb53(releaseNotes).toString()
            if (currentReleaseNotes !== newReleaseNotes) {
                setIsCalloutVisible(true)
                localStorage.setItem("calloutDisplayedBool", "yes")
                localStorage.setItem("releaseNotes", newReleaseNotes)
            }
        } else {
            setIsCalloutVisible(true)

            localStorage.setItem("calloutDisplayedBool", "yes")
            localStorage.setItem("releaseNotes", cyrb53(releaseNotes).toString())
        }
    }, [])
    return (
        <>
            <Router>
                {isStagingEnv && stagingCallout}
                <Menu />
                {isCalloutVisible && updateCallout}
                <AppRoutes />
            </Router>
            {isModalVisible && modal}
        </>
    )
}

export default withAuthenticationRequired(App)
