import { Auth0Provider } from "@auth0/auth0-react"

import { EuiProvider } from "@equipmentshare/ds2"

import { ServiceProvider } from "../services/Provider"
import { ToastProvider } from "./ToastProvider"
import ReactQueryProvider from "./ReactQueryProvider"
import EsApiProvider from "./EsApiProvider"
import config from "../services/config"

export function AllProvidersExceptForAuth0({ children }: any) {
    return (
        <ServiceProvider>
            <EsApiProvider>
                <ReactQueryProvider>
                    <EuiProvider colorMode="light">
                        <ToastProvider>{children}</ToastProvider>
                    </EuiProvider>
                </ReactQueryProvider>
            </EsApiProvider>
        </ServiceProvider>
    )
}

export function AllProviders({ children }: any) {
    return (
        <Auth0Provider
            domain={config.AUTH0.DOMAIN}
            clientId={config.AUTH0.CLIENTID}
            audience={config.AUTH0.AUDIENCE}
            redirectUri={window.location.origin}
        >
            <AllProvidersExceptForAuth0>{children}</AllProvidersExceptForAuth0>
        </Auth0Provider>
    )
}
