import React, { useState } from "react"

import {
    EuiButton,
    EuiFilePicker,
    EuiFlexItem,
    EuiFlexGroup,
    useGeneratedHtmlId,
    EuiSpacer,
    EuiPanel,
    EuiText,
    EuiLink,
    EuiCallOut,
} from "@equipmentshare/ds2"

import { createPurchaseOrder } from "../../services/CaseOrder"
import Page from "../Page/Page"
import { useToasts } from "../../hooks/useToasts"
import { useUploadFile } from "../../services/Skunkworks/Generated"
import { formatException } from "../../Utils"
import config from "../../services/config"
import { BranchPicker, StorePicker, VendorPicker } from "../Pickers"

function CaseOrder() {
    const { addErrorToast, addSuccessToast } = useToasts()
    const [poCreated, setPoCreated] = useState<number>(0)
    const [poUpdated, setPoUpdated] = useState<boolean>(false)
    const [poId, setPoId] = useState<string>("")
    const [missingItems, setMissingItems] = useState<string[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { isLoading: isUploading, mutateAsync: uploadFile } = useUploadFile()
    const [branchId, setBranchId] = useState<number | null>(null)
    const [storeId, setStoreId] = useState<number | null>(null)
    const [vendorId, setVendorId] = useState<number | null>(null)
    const [isRequired, setIsRequired] = useState(false)

    const filePickerId = useGeneratedHtmlId({ prefix: "filePicker" })
    const handleFileSelected = (files: any) => {
        setFile(files.length === 1 ? files[0] : null)
    }

    async function onSubmitButtonPressed() {
        if (!file) {
            addErrorToast({
                text: "PDF file is required",
                toastLifeTimeMs: 5000,
            })
            return
        }

        try {
            setPoUpdated(false)
            const purchaseOrderInfo = await uploadFile({ data: { file } })
            const poId = purchaseOrderInfo.purchase_order_id
            setPoId(poId)
            const missingItems = purchaseOrderInfo.missing_items
            setMissingItems(missingItems)
            setPoUpdated(true)
            addSuccessToast({ text: "PO Updated!" })
        } catch (e) {
            addErrorToast({ text: formatException(e) })
        }
    }

    async function handleOnClick() {
        setIsLoading(true)
        if (branchId && storeId && vendorId) {
            const createPoResponse = await createPurchaseOrder(
                branchId,
                storeId,
                vendorId
            )
            const po = createPoResponse.data.createPurchaseOrder.purchaseOrder
            if (po) {
                setPoCreated(po.purchaseOrderNumber)
                setPoId(po.id)
            } else {
                addErrorToast({
                    text: createPoResponse.data.createPurchaseOrder.message,
                    toastLifeTimeMs: 5000,
                })
            }
        } else {
            setIsRequired(true)
            addErrorToast({
                text: "Branch, Store, and Vendor selections are required",
                toastLifeTimeMs: 5000,
            })
        }
        setIsLoading(false)
    }

    function poDetailText(poNumber: number = 0) {
        if (poNumber !== 0) {
            return (
                <div>
                    <EuiText>PO #{poNumber} was created</EuiText>
                    Click to open the{" "}
                    <EuiLink
                        href={`${config.COST_CAPTURE_LINK}/purchase-orders/${poId}/detail`}
                        target="_blank"
                    >
                        Cost Capture PO
                    </EuiLink>{" "}
                    in a new tab.
                </div>
            )
        } else {
            return (
                <div>
                    <EuiText>PO Updated</EuiText>
                    Click to open the{" "}
                    <EuiLink
                        href={`${config.COST_CAPTURE_LINK}/purchase-orders/${poId}/detail`}
                        target="_blank"
                    >
                        Cost Capture PO
                    </EuiLink>{" "}
                    in a new tab.
                </div>
            )
        }
    }

    function missingItemsPrompt() {
        if (missingItems.length !== 0) {
            return (
                <div>
                    <EuiCallOut
                        title="Missing Items"
                        color="warning"
                        iconType="alert"
                    >
                        <p>
                            {missingItems.length > 1
                                ? "These items were "
                                : "This item was "}
                            not added to the PO. Please add manually
                        </p>
                        <p>{missingItems.join(", ")}</p>
                    </EuiCallOut>
                </div>
            )
        }
    }

    function fileUpload() {
        return (
            <EuiFilePicker
                id={filePickerId}
                initialPromptText="Select or drag and drop file"
                onChange={handleFileSelected}
                display="large"
                aria-label="Use aria labels when no actual label is in use"
                required={isRequired}
            />
        )
    }

    function setPickerId(
        func: React.Dispatch<React.SetStateAction<number | null>>
    ) {
        return (id: number | null) => {
            setIsRequired(false)
            func(id)
        }
    }

    function branchPickerIdSelector(id: number | null) {
        const pickerFunc = setPickerId(setBranchId)
        pickerFunc(id)
        if (storeId) {
            setStoreId(null)
        }
    }

    return (
        <Page title={"CASE Orders"}>
            <EuiFlexGroup justifyContent="flexStart" wrap>
                <EuiFlexItem style={{ minWidth: "500px" }} grow={false}>
                    <EuiPanel hasShadow={false} hasBorder>
                        <EuiText>
                            <h3>Create Blank PO</h3>
                        </EuiText>
                        <EuiSpacer size="s" />
                        <BranchPicker
                            onIdSelected={branchPickerIdSelector}
                            onCleared={() => setStoreId(null)}
                            hasErrors={!branchId && isRequired}
                        />
                        <StorePicker
                            onIdSelected={setPickerId(setStoreId)}
                            hasErrors={!storeId && isRequired}
                            context={{ branchId }}
                            selectedId={storeId}
                            isDisabled={!branchId}
                            placeholder={!branchId ? "Select Branch first" : ""}
                        />
                        <VendorPicker
                            onIdSelected={setPickerId(setVendorId)}
                            hasErrors={!vendorId && isRequired}
                        />
                        <EuiSpacer size="m" />
                        <div>
                            <EuiButton
                                onClick={handleOnClick}
                                isLoading={isLoading}
                                isDisabled={!!file}
                            >
                                Create
                            </EuiButton>
                        </div>
                        {poCreated ? (
                            <>
                                <EuiSpacer size="m" />
                                <div>{poDetailText(poCreated)}</div>
                            </>
                        ) : null}
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem style={{ minWidth: "500px" }} grow={false}>
                    <EuiPanel hasShadow={false} hasBorder>
                        <EuiText>
                            <h3>Update PO</h3>
                        </EuiText>
                        <EuiSpacer size="s" />
                        <div>{fileUpload()}</div>
                        <EuiSpacer size="m" />
                        <div>
                            <EuiButton
                                onClick={onSubmitButtonPressed}
                                isLoading={isUploading}
                                isDisabled={!file}
                            >
                                Update
                            </EuiButton>
                        </div>
                        {poUpdated ? (
                            <>
                                <EuiSpacer size="m" />
                                <div>{poDetailText()}</div>
                            </>
                        ) : null}
                        <EuiSpacer size="m" />
                        {missingItems ? missingItemsPrompt() : null}
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Page>
    )
}

export default CaseOrder
