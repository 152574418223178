import React from "react"

import {
    EuiButton,
    EuiTextArea,
    EuiSpacer,
    EuiText,
    EuiCheckboxGroup,
    EuiTitle,
} from "@equipmentshare/ds2"

import { Job } from "./Job"
import { updateAssetRspMsp } from "../../services/Misc"
import CenteredContent from "../CenteredContent"
import { BranchPicker } from "../Pickers"
import { parseNumbersFromText } from "./Utils"

export interface AssetRspMspUpdateJobProposal {
    assetIds: number[]
    rentalBranchId?: number | null
    serviceBranchId?: number | null
}

const getDescription = (
    proposal: AssetRspMspUpdateJobProposal,
    assetId: number
): string => {
    return branchIdDescriptionText(
        assetId,
        proposal.rentalBranchId,
        proposal.serviceBranchId
    )
}

const AssetRspMspUpdateJob: Job<AssetRspMspUpdateJobProposal> = {
    gen: (proposal) =>
        proposal.assetIds.map((assetId) => ({
            description: getDescription(proposal, assetId),
            fn: () =>
                updateAssetRspMsp(assetId, {
                    rentalBranchId: proposal.rentalBranchId,
                    serviceBranchId: proposal.serviceBranchId,
                }),
        })),
    Configure: (props) => {
        const [text, setText] = React.useState("")
        const [branchId, setBranchId] = React.useState<number | null>(null)
        const checkboxes = [
            {
                id: "rsp",
                label: "Remove RSP",
            },
            {
                id: "msp",
                label: "Remove MSP",
            },
        ]
        const [idCheckboxMap, setIdCheckboxMap] = React.useState<{
            [key: string]: boolean
        }>({
            rsp: false,
            msp: false,
        })

        const handleChange = (id: string) => {
            setIdCheckboxMap({
                ...idCheckboxMap,
                [id]: !idCheckboxMap[id],
            })
        }

        const handleSubmit = () => {
            if (!branchId && !idCheckboxMap["rsp"] && !idCheckboxMap["msp"]) {
                alert("Need to choose a branch or select a checkbox.")
                return
            }
            const parsedAssetIds = parseNumbersFromText(text)
            if (!parsedAssetIds || !parsedAssetIds.every((n) => !!n)) {
                alert(
                    "Enter valid asset IDs. Separate with a new line or commas."
                )
                return
            }

            props.onSubmit({
                rentalBranchId: idCheckboxMap["rsp"]
                    ? null
                    : branchId || undefined,
                serviceBranchId: idCheckboxMap["msp"]
                    ? null
                    : branchId || undefined,
                assetIds: parsedAssetIds,
            })
        }

        return (
            <>
                <EuiTitle size="m">
                    <h2>Change Asset RSP/MSP</h2>
                </EuiTitle>
                <EuiSpacer size="m" />
                <EuiCheckboxGroup
                    options={checkboxes}
                    idToSelectedMap={idCheckboxMap}
                    onChange={handleChange}
                />
                <BranchPicker
                    onIdSelected={setBranchId}
                    isDisabled={idCheckboxMap["rsp"] && idCheckboxMap["msp"]}
                />
                <EuiSpacer size="m" />
                <EuiTextArea
                    fullWidth={true}
                    placeholder="Enter asset IDs separated by comma or new line"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <EuiSpacer size="m" />
                <EuiButton isDisabled={!text} onClick={handleSubmit}>
                    Next
                </EuiButton>
            </>
        )
    },
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    {branchIdConfirmText(
                        props.proposal.assetIds,
                        props.proposal.rentalBranchId,
                        props.proposal.serviceBranchId
                    )}
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

function branchIdConfirmText(
    assetIds: number[],
    rentalBranchId?: number | null,
    serviceBranchId?: number | null
) {
    const rentalBranchText =
        rentalBranchId !== undefined
            ? `${
                  rentalBranchId
                      ? `changing the RSP branch id to ${rentalBranchId}`
                      : "removing the RSP branch id"
              }`
            : ""
    const serviceBranchText =
        serviceBranchId !== undefined
            ? `${
                  serviceBranchId
                      ? `changing the MSP branch id to ${serviceBranchId}`
                      : "removing the MSP branch id"
              }`
            : ""

    return `You are planning on ${rentalBranchText}${
        rentalBranchText && serviceBranchText && " and "
    }${serviceBranchText} for ${assetIds.length} assets.`
}

function branchIdDescriptionText(
    assetId: number,
    rentalBranchId?: number | null,
    serviceBranchId?: number | null
) {
    const rentalBranchText =
        rentalBranchId !== undefined ? `RSP to ${rentalBranchId || "NULL"}` : ""
    const serviceBranchText =
        serviceBranchId !== undefined
            ? `MSP to ${serviceBranchId || "NULL"}`
            : ""

    return `Updating Asset ID #${assetId}'s ${rentalBranchText}${
        rentalBranchText && serviceBranchText && " and "
    }${serviceBranchText}`
}

export default AssetRspMspUpdateJob
