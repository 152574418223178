import axios, { AxiosRequestConfig } from "axios"
import { createContext, ReactNode, useEffect, useCallback } from "react"
import { useAuthenticationDetails } from "../hooks/useAuthenticationDetails"
import { COST_CAPTURE_AXIOS_INSTANCE } from "./CaseOrder"
import config from "./config"
import { SKUNKWORKS_AXIOS_INSTANCE } from "./Skunkworks/AxiosInstance"
import { PAYOUT_PROGRAM_AXIOS_INSTANCE } from "./PayoutProgram/AxiosInstance"

type ServiceProviderProps = {
    children: ReactNode
    initialState?: string | null
}

const Context = createContext<string | null>(null)

const ServiceProvider = ({
    children,
    initialState = null,
}: ServiceProviderProps) => {
    const token = useAuthenticationDetails().accessToken

    const createInterceptor = useCallback(
        (baseURL: string) => async (config: AxiosRequestConfig) => ({
            ...config,
            baseURL,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${token}`,
            },
        }),
        [token]
    )

    useEffect(() => {
        const axiosInterceptorId = axios.interceptors.request.use(
            createInterceptor(config.API_URL)
        )
        const skunkworksInterceptorId =
            SKUNKWORKS_AXIOS_INSTANCE.interceptors.request.use(
                createInterceptor(config.SKUNKWORKS_URL)
            )
        const costCaptureInterceptorId =
            COST_CAPTURE_AXIOS_INSTANCE.interceptors.request.use(
                createInterceptor(config.COST_CAPTURE_API_URL)
            )
        const payoutProgramInterceptorId =
            PAYOUT_PROGRAM_AXIOS_INSTANCE.interceptors.request.use(
                createInterceptor(config.PAYOUT_PROGRAM_URL)
            )

        return () => {
            axios.interceptors.request.eject(axiosInterceptorId)
            SKUNKWORKS_AXIOS_INSTANCE.interceptors.request.eject(
                skunkworksInterceptorId
            )
            COST_CAPTURE_AXIOS_INSTANCE.interceptors.request.eject(
                costCaptureInterceptorId
            )
            PAYOUT_PROGRAM_AXIOS_INSTANCE.interceptors.request.eject(
                payoutProgramInterceptorId
            )
        }
    }, [createInterceptor])

    return <Context.Provider value={token}>{children}</Context.Provider>
}

export { ServiceProvider }
