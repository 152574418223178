import { useMemo, useState } from "react"
import {
    EuiPage,
    EuiPageBody,
    EuiPageHeader,
    EuiPageSection,
    EuiSpacer,
    EuiFlexGroup,
    EuiFlexItem,
    EuiButton,
    EuiTabsProps,
    EuiFieldText,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiTitle,
    EuiText,
    EuiListGroup,
} from "@equipmentshare/ds2"

import DSMRequestsTable from "./DSMRequestsTable"
import { useToasts } from "../../hooks/useToasts"
import {
    CommissionOverrideRequest,
    RequestStatus,
    useGetRequests,
    useUpdateRequest,
} from "../../services/Skunkworks/Generated"

type TabId = "pending-requests" | "approved-requests"

export default function DSMRequestsPage() {
    const [error, setError] = useState(false)
    const [selected, setSelected] = useState<CommissionOverrideRequest[]>([])
    const [tabSelected, setTabSelected] = useState<TabId>("pending-requests")
    const [reviewNote, setReviewNote] = useState<string | null>(null)
    const [openModal, setOpenModal] = useState<CommissionOverrideRequest | null>(null)
    const { addSuccessToast, addErrorToast, addToast } = useToasts()

    const { data: requests, refetch, isLoading } = useGetRequests()
    const { mutateAsync: updateRequest } = useUpdateRequest()

    const update = async (status: RequestStatus, note: string | null, selected: CommissionOverrideRequest[]) => {
        const updatePromises: Promise<CommissionOverrideRequest>[] = []
        for (const selection of selected) {
            updatePromises.push(
                updateRequest({
                    requestId: selection.commission_override_request_id,
                    data: {
                        review_status: status,
                        review_note: note,
                    },
                })
            )
        }

        const results = await Promise.allSettled(updatePromises)
        let failedResults: PromiseRejectedResult[] = []
        let passedResults: PromiseSettledResult<CommissionOverrideRequest>[] = []

        results.forEach((result) => {
            result.status === "rejected" ? failedResults.push(result) : passedResults.push(result)
        })

        await refetch()

        if (failedResults.length > 0) {
            const errorMessage = failedResults[0].reason.response.data.detail ?? "Failed to Update Requests!"

            throw new Error(errorMessage)
        } else {
            setError(false)
            addSuccessToast({ text: "Updated Requests!" })
        }
    }
    const approve = async (selection: CommissionOverrideRequest[]) => await update("approved", reviewNote, selection)
    const reject = async (selection: CommissionOverrideRequest[]) => await update("rejected", reviewNote, selection)

    const { pendingRequests, otherRequests } = useMemo(
        () =>
            requests?.reduce<{
                pendingRequests: CommissionOverrideRequest[]
                otherRequests: CommissionOverrideRequest[]
            }>(
                (obj, curr) => {
                    if (curr.review_status === "pending") {
                        obj.pendingRequests.push(curr)
                    } else {
                        obj.otherRequests.push(curr)
                    }
                    return obj
                },
                {
                    pendingRequests: [],
                    otherRequests: [],
                }
            ) ?? { pendingRequests: [], otherRequests: [] },
        [requests]
    )

    const handleSelection = (selection: CommissionOverrideRequest[]) => setSelected(selection)
    const handleApprove = async () => {
        addToast({ title: "Approving", text: `Approving ${selected.length}` })
        try {
            await approve(selected)
            setSelected([])
            addSuccessToast({ text: "Submissions Approved!" })
        } catch (e) {
            addErrorToast({ text: e.message })
        }
    }
    const handleReject = async () => {
        if (!reviewNote) {
            addErrorToast({
                title: "Unable to Reject",
                text: "Need Review Note for Rejections",
            })
            return
        }
        addToast({ title: "Rejecting", text: `Rejecting ${selected.length}` })
        try {
            await reject(selected)
            setSelected([])
            addSuccessToast({ text: "Submissions Rejected!" })
        } catch (e) {
            addErrorToast({ text: e.message })
        }
    }

    const handleTabSwitch = (tabId: TabId) => {
        setReviewNote(null)
        setTabSelected(tabId)
    }

    const handleDetailsSelect = (item: CommissionOverrideRequest | null) => {
        setOpenModal(item)
    }

    const renderContents = () => {
        switch (tabSelected) {
            case "pending-requests":
                return (
                    <>
                        <EuiFlexGroup responsive={false} gutterSize="xs" alignItems="center">
                            <EuiFlexItem grow={false}>
                                <EuiButton iconType="check" onClick={handleApprove} isDisabled={!selected.length}>
                                    Approve
                                </EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton iconType="cross" onClick={handleReject} isDisabled={!selected.length}>
                                    Reject
                                </EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={3}>
                                <EuiFieldText
                                    placeholder={"Review Note"}
                                    value={reviewNote ?? ""}
                                    onChange={(e) => {
                                        setReviewNote(e.target.value)
                                    }}
                                />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size="m" />
                        <DSMRequestsTable
                            tableName="Pending Submissions"
                            items={pendingRequests}
                            loading={isLoading}
                            error={error}
                            selectable
                            onSelect={handleSelection}
                            onDetailsSelect={handleDetailsSelect}
                        />
                    </>
                )
            case "approved-requests":
                return (
                    <>
                        <DSMRequestsTable
                            tableName="Approved/Rejected Submissions"
                            items={otherRequests}
                            loading={isLoading}
                            error={error}
                            onDetailsSelect={handleDetailsSelect}
                        />
                    </>
                )
        }
    }

    const tabs: EuiTabsProps[] = [
        {
            id: "pending-submissions",
            label: "Pending",
            isSelected: tabSelected === "pending-requests",
            onClick: () => handleTabSwitch("pending-requests"),
        },
        {
            id: "approved-submissions",
            label: "Approved/Rejected",
            isSelected: tabSelected === "approved-requests",
            onClick: () => handleTabSwitch("approved-requests"),
        },
    ]

    return (
        <>
            <EuiPage>
                <EuiPageBody>
                    <EuiPageHeader
                        paddingSize="l"
                        bottomBorder={true}
                        pageTitle="DSM Approvals Dashboard"
                        tabs={tabs}
                    />
                    <EuiPageSection>{renderContents()}</EuiPageSection>
                </EuiPageBody>
            </EuiPage>
            {!!openModal ? (
                <EuiModal onClose={() => handleDetailsSelect(null)}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>Details</EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        <EuiTitle size="xxs">
                            <h5>Request Note</h5>
                        </EuiTitle>
                        <EuiText size="m">{openModal.request_note ?? "N/A"}</EuiText>
                        <EuiSpacer size="s" />
                        <EuiTitle size="xxs">
                            <h5>Review Note</h5>
                        </EuiTitle>
                        <EuiText size="m">{openModal.review_note ?? "N/A"}</EuiText>
                        <EuiSpacer size="m" />
                        <EuiTitle size="xxs">
                            <h5>Files</h5>
                        </EuiTitle>
                        <EuiListGroup
                            listItems={
                                openModal.request_uploads
                                    ? openModal.request_uploads.map((r, index) => ({
                                          label: `File ${index + 1}`,
                                          href: r.url ?? "",
                                          target: "_blank",
                                      }))
                                    : []
                            }
                            color="primary"
                        />
                    </EuiModalBody>
                </EuiModal>
            ) : null}
        </>
    )
}
