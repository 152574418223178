import {
    EuiButton,
    EuiModal,
    EuiModalHeaderTitle,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiSpacer,
} from "@equipmentshare/ds2"

export type ModalProps = {
    closeModal: () => void
    releaseNotes: string
}

export default function UpdateModal(props: ModalProps) {
    return (
        <EuiModal onClose={props.closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>Release Notes</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiSpacer />
                {props.releaseNotes}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButton onClick={props.closeModal}>Ok</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
