import axios from "axios"

export interface TrackerInfo {
    tracker_id: number
    equipment_model: { name: string; equipment_make: { name: string } }
    name: string
}

export async function getAssetInfo(assetId: number): Promise<TrackerInfo> {
    const resp = await axios.get<TrackerInfo>(`assets/${assetId}`)
    return resp.data
}
