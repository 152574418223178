import moment, { Moment } from "moment"
import { useState } from "react"

import {
    EuiButton,
    EuiFormRow,
    EuiRadioGroup,
    EuiSpacer,
    EuiForm,
} from "@equipmentshare/ds2"

import Page from "../Page/Page"
import ErrorConfirmation from "./InvoiceSelectionComponents/ErrorConfirmation"
import Dates from "./InvoiceSelectionComponents/Dates"
import ConfirmationButtons from "./InvoiceSelectionComponents/ConfirmationButtons"
import { download, batchDownload } from "../../services/Skunkworks/Generated"
import {
    CompanyPicker,
    SalespersonPicker,
    FinancialSchedulePicker,
    FinancialLenderPicker,
} from "../Pickers"

namespace RadioButton {
    export const ONLY_PAID_RENTAL = "Paid Rental Invoices Only"
    export const ONLY_RENTAL = "Rental Invoices Only"
    export const ALL = "All Invoices"
}

export default function InvoiceSelection() {
    const [radioIdSelected, setRadioIdSelected] = useState<string>(
        RadioButton.ALL
    )
    const [salespersonId, setSalespersonId] = useState<number | null>(null)
    const [companyId, setCompanyId] = useState<number | null>(null)
    const [financialScheduleId, setFinancialScheduleId] = useState<
        number | null
    >(null)
    const [financialLenderId, setFinancialLenderId] = useState<number | null>(
        null
    )
    const [startDate, setStartDate] = useState<Moment>()
    const [endDate, setEndDate] = useState<Moment>(moment())
    const [showErrors, setShowErrors] = useState(false)
    const [showDateErrors, setShowDateErrors] = useState(false)
    const [searchDisabled, setSearchDisabled] = useState(false)
    const [downloadDisabled, setDownloadDisabled] = useState(false)
    const [pageStatus, setPageStatus] = useState(true)
    const [confirmationPageStatus, setConfirmationPageStatus] = useState(false)
    const [invoiceLength, setInvoiceLength] = useState(0)
    const [invoiceError, setInvoiceError] = useState(false)
    const [tempData, setTempData] = useState<number[]>([])
    const [confirmation, setConfirmation] = useState(false)
    const [downloadComplete, setDownloadComplete] = useState(false)

    const radios = [
        RadioButton.ONLY_PAID_RENTAL,
        RadioButton.ONLY_RENTAL,
        RadioButton.ALL,
    ]

    const onSearchButtonClicked = async () => {
        setShowErrors(true)
        const hasIdSelected =
            salespersonId ||
            financialScheduleId ||
            companyId ||
            financialLenderId

        startDate && endDate
            ? setShowDateErrors(false)
            : setShowDateErrors(true)

        if (hasIdSelected && startDate && endDate) {
            setShowErrors(false)
            setSearchDisabled(true)

            const realData = await download({
                salesperson_user_id: salespersonId ?? undefined,
                financial_schedule_id: financialScheduleId ?? undefined,
                company_id: companyId ?? undefined,
                financial_lender_id: financialLenderId ?? undefined,
                line_item_type_id:
                    radioIdSelected === RadioButton.ONLY_RENTAL ||
                    RadioButton.ONLY_PAID_RENTAL
                        ? 8
                        : undefined,
                start_date: startDate?.toISOString(),
                end_date: endDate?.toISOString(),
                paid_invoices: radioIdSelected === RadioButton.ONLY_PAID_RENTAL,
            })

            if (realData.length > 0) {
                setPageStatus(false)
                setInvoiceLength(realData.length)
                setConfirmationPageStatus(true)
                setInvoiceError(false)
                setTempData(realData)
            } else {
                setSearchDisabled(false)
                setInvoiceError(true)
            }
        }
    }

    const onDownloadClicked = async () => {
        setDownloadDisabled(true)
        setInvoiceError(false)
        // Show note to stay on the page and that large exports can take a while to run.
        setConfirmationPageStatus(false)
        setConfirmation(true)

        const downloadInvoicesData = await batchDownload({
            invoice_ids: tempData,
        })

        // After the batchDownload call, the delay long enough that browsers reject window.open().
        // Preimptively calling window.open() then setting the window location works, but since this is
        // just a download, opening a whole new tab which needs to be manually closed isn't necessary anyway.
        window.location.href = downloadInvoicesData.file

        setConfirmation(false)
        setDownloadComplete(true)
    }

    const handleChangeStart = (date: Moment) => {
        setStartDate(date)
    }

    const handleChangeEnd = (date: Moment) => {
        setEndDate(date)
    }

    const onCancelClicked = () => {
        setSalespersonId(null)
        setCompanyId(null)
        setFinancialScheduleId(null)
        setFinancialLenderId(null)

        setConfirmation(false)
        setConfirmationPageStatus(false)
        setPageStatus(true)
        setSearchDisabled(false)
    }

    return (
        <>
            <Page title="Download Invoices">
                <ErrorConfirmation
                    invoiceError={invoiceError}
                    confirmation={confirmation}
                    downloadComplete={downloadComplete}
                />

                {pageStatus && (
                    <>
                        <EuiSpacer size="m" />

                        <EuiForm invalidCallout="above" error="test">
                            <EuiSpacer />

                            <SalespersonPicker
                                onIdSelected={setSalespersonId}
                                hasErrors={showErrors}
                                onCleared={() => setSalespersonId(0)}
                            />

                            <FinancialSchedulePicker
                                onIdSelected={setFinancialScheduleId}
                                hasErrors={showErrors}
                                onCleared={() => setFinancialScheduleId(0)}
                            />

                            <CompanyPicker
                                onIdSelected={setCompanyId}
                                hasErrors={showErrors}
                                onCleared={() => setCompanyId(0)}
                            />

                            <FinancialLenderPicker
                                onIdSelected={setFinancialLenderId}
                                hasErrors={showErrors}
                                onCleared={() => setFinancialLenderId(0)}
                            />

                            <Dates
                                showErrors={showDateErrors}
                                startDate={startDate}
                                handleChangeStart={handleChangeStart}
                                endDate={endDate}
                                handleChangeEnd={handleChangeEnd}
                            />

                            <EuiSpacer size="s" />

                            <EuiFormRow>
                                <EuiRadioGroup
                                    options={radios.map((r) => ({
                                        id: r,
                                        label: r,
                                    }))}
                                    idSelected={radioIdSelected}
                                    onChange={setRadioIdSelected}
                                    name="radio group"
                                />
                            </EuiFormRow>

                            <EuiFormRow>
                                <EuiButton
                                    onClick={onSearchButtonClicked}
                                    isLoading={searchDisabled}
                                    fill
                                >
                                    Search
                                </EuiButton>
                            </EuiFormRow>
                        </EuiForm>
                    </>
                )}

                {confirmationPageStatus && (
                    <>
                        <p>{invoiceLength} invoices were found.</p>

                        <EuiSpacer size="l" />

                        <ConfirmationButtons
                            onCancelClicked={onCancelClicked}
                            onDownloadClicked={onDownloadClicked}
                            downloadDisabled={downloadDisabled}
                        />
                    </>
                )}
            </Page>
        </>
    )
}
