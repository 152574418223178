import React, { useEffect, useState } from "react"
import {
    EuiBasicTableColumn,
    EuiBadge,
    Criteria,
    EuiInMemoryTable,
    EuiSearchBarProps,
    EuiTableFieldDataColumnType,
    EuiLink,
} from "@equipmentshare/ds2"

import {
    CommissionOverrideRequest,
    RequestStatus,
} from "../../services/Skunkworks/Generated"

export const RequestColors = {
    pending: "warning",
    approved: "success",
    rejected: "danger",
}

const DEFAULT_MESSAGE = "No Submissions to View"

interface DSMApprovalTableProps {
    tableName: string
    items: CommissionOverrideRequest[]
    loading: boolean
    error: boolean
    selectable?: boolean
    onSelect?: (selection: CommissionOverrideRequest[]) => void
    onDetailsSelect: (item: CommissionOverrideRequest) => void
}

export default function DSMRequestsTable(props: DSMApprovalTableProps) {
    const {
        tableName,
        items,
        loading,
        error,
        selectable,
        onSelect,
        onDetailsSelect,
    } = props
    const [sortField, setSortField] =
        useState<keyof CommissionOverrideRequest>("request_user_id")
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc")
    const [selected, setSelected] = useState<CommissionOverrideRequest[]>([])
    const [message, setMessage] = useState(DEFAULT_MESSAGE)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    )
    const approval_badge = (status: RequestStatus) => {
        return <EuiBadge color={RequestColors[status]}>{status}</EuiBadge>
    }

    let columns = [
        {
            field: "request_user_id",
            name: "Request User",
            render: (_: any, record: CommissionOverrideRequest) =>
                record.request_user_name,
            sortable: (item: CommissionOverrideRequest) =>
                item.request_user_name.split(" ").pop(), // sorting by last name
            textOnly: true,
        },
        {
            field: "review_status",
            name: "Status",
            render: (reviewStatus: RequestStatus | null) =>
                approval_badge(reviewStatus ?? "pending"),
        },
        {
            name: "Details",
            render: (item: CommissionOverrideRequest) => (
                <EuiLink onClick={() => onDetailsSelect(item)}>Open</EuiLink>
            ),
        },
        !selectable
            ? {
                  field: "review_date",
                  name: "Date Reviewed",
                  render: (reviewDate: string | null) =>
                      reviewDate
                          ? new Date(reviewDate).toLocaleDateString()
                          : null,
                  sortable: true,
              }
            : undefined,
        {
            field: "company_id",
            name: "Company",
            render: (_: any, record: CommissionOverrideRequest) =>
                record.company_name,
            sortable: (item: CommissionOverrideRequest) => item.company_name,
            textOnly: true,
        },
        {
            field: "market_id",
            name: "Market",
            render: (_: any, record: CommissionOverrideRequest) =>
                record.market_name,
            sortable: (item: CommissionOverrideRequest) => item.market_name,
            textOnly: true,
        },
        {
            field: "equipment_class_id",
            name: "Equipment Class",
            render: (_: any, record: CommissionOverrideRequest) =>
                record.equipment_class_name,
            sortable: (item: CommissionOverrideRequest) =>
                item.equipment_class_name,
            textOnly: true,
        },
        {
            field: "equipment_rate",
            name: "Floor Rate",
            render: (equipmentRate: string) => `$${equipmentRate}`,
        },
        {
            field: "request_rate",
            name: "Requested Month Rate",
            render: (requestRate: string) => `$${requestRate}`,
        },
        {
            field: "equipment_utilization",
            name: "Utilization",
            render: (equipmentUtil: string) =>
                `${parseFloat(equipmentUtil) * 100}%`,
        },
        {
            field: "date_created",
            name: "Date Submitted",
            render: (dateCreated: string) =>
                new Date(dateCreated).toLocaleDateString(),
            sortable: true,
        },
    ].filter(Boolean)

    const getRowProps = (record: CommissionOverrideRequest) => {
        return {
            "data-test-subj": `row-${record.commission_override_request_id}`,
        }
    }

    const getCellProps = (
        record: CommissionOverrideRequest,
        cell: EuiTableFieldDataColumnType<CommissionOverrideRequest>
    ) => {
        return {
            "data-test-subj": `cell-${
                record.commission_override_request_id
            }-${String(cell.field)}`,
        }
    }

    const sortOptions = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    }

    const tableOnChange = ({ sort }: Criteria<CommissionOverrideRequest>) => {
        if (sort) {
            const { field: sortField, direction: sortDirection } = sort
            setSortField(sortField)
            setSortDirection(sortDirection)
        }
    }

    const selection = {
        onSelectionChange: (records: CommissionOverrideRequest[]) =>
            setSelected(records),
    }

    const search: EuiSearchBarProps = {
        box: {
            incremental: true,
            schema: true,
        },
    }

    useEffect(() => {
        onSelect?.(selected)
    }, [onSelect, selected])

    useEffect(() => {
        if (loading) {
            setMessage("Loading...")
        } else {
            setMessage(DEFAULT_MESSAGE)
        }
        if (error) {
            setErrorMessage("Failed to get Rows")
        } else {
            setErrorMessage(undefined)
        }
    }, [error, loading])

    return (
        <EuiInMemoryTable
            tableCaption={`DSM Approvals Table ${tableName}`}
            items={items}
            itemId="commission_override_request_id"
            columns={
                columns as Array<EuiBasicTableColumn<CommissionOverrideRequest>>
            }
            message={message}
            error={errorMessage}
            rowProps={getRowProps}
            cellProps={getCellProps}
            loading={loading}
            sorting={sortOptions}
            onChange={tableOnChange}
            selection={selectable ? selection : undefined}
            search={search}
        />
    )
}
