import React, { forwardRef, useImperativeHandle } from "react"
import debounce from "lodash.debounce"

import { EuiButtonEmpty, EuiFormControlLayout, EuiFormRow } from "@equipmentshare/ds2"

import InputField from "./InputField"
import { useAuthenticationDetails } from "../hooks/useAuthenticationDetails"
import {
    getBranches,
    getCompanies,
    getLenders,
    getNames,
    getFinancialSchedules,
    getMakes,
    getModels,
    getRSPCompanies,
} from "../services/Misc"
import { getProviders } from "../services/Discounts"
import useEsApiService from "../hooks/useEsApiService"
import { useToasts } from "../hooks/useToasts"
import EsApiService, { Category } from "../services/EsApiService"
import {
    Competitor,
    createCompetitor,
    queryCompetitors,
    getEsdbUsers,
    getExpenseLines,
    getSubDepartments,
    getDepartments,
    getEquipmentClasses,
    queryDocTypes,
    getServiceManualTypes,
} from "../services/Skunkworks/Generated"

import {
    getAssignments,
    getPayoutProgramMasterAgreements,
    getPayoutPrograms,
    getSchedules,
} from "../services/PayoutProgram/Generated"

import { getStores, getVendors } from "../services/CaseOrder"

export interface PickerProps<T extends Option, K extends object = {}> {
    onCleared?: () => void
    onIdSelected?: (id: number | null) => void
    onSelected?: (option: T | null) => void
    context?: K
    hasErrors?: boolean
    isDisabled?: boolean
    label?: string
    selectedId?: number | null
    placeholder?: string
    selectedValue?: string | null
    isInvalid?: boolean
    initialSelected?: T
    initialOptions?: T[]
}

export interface StorePickerContext {
    branchId: number | null
}

export interface SchedulePickerContext {
    payoutProgramId: number | null
}

export interface TTUClassPickerContext {
    marketId: number | null
}

export type Option = { id: string; label: string }

export interface DataOption<T> extends Option {
    data: T
}

export type ModelsData = {
    equipment_class_id: number
    equipment_make_id: number
    equipment_model_id: number
}

const genPickerWithInitialValue =
    <T extends Option, K extends object = {}>(Picker: any, label?: string) =>
    (props: PickerProps<T, K> & { initial: string }) => {
        const [isInitial, setIsInitial] = React.useState(true)

        const content = (
            <EuiFormControlLayout
                append={
                    <EuiButtonEmpty onClick={() => setIsInitial(false)} size="xs">
                        Change
                    </EuiButtonEmpty>
                }
                readOnly
            >
                <input
                    type="text"
                    className="euiFieldText euiFieldText--inGroup"
                    value={props.initial}
                    readOnly
                    disabled
                />
            </EuiFormControlLayout>
        )

        if (isInitial) {
            return label ? <EuiFormRow label={label}>{content}</EuiFormRow> : content
        }

        return <Picker {...props} />
    }

interface GenConfig<T extends Option, K extends object = {}> {
    label?: string
    createOption?: (text: string) => Promise<any>
    getOptions: (text: string, esApiService: EsApiService, context?: K) => Promise<T[]>
    loadOnce?: boolean
}

export interface PickerHandle {
    clearValue: () => void
}

const genPicker = <T extends Option, K extends object = {}>(config: GenConfig<T, K>) =>
    forwardRef<PickerHandle, PickerProps<T, K>>((props, ref) => {
        const { accessToken } = useAuthenticationDetails()
        const esApiService = useEsApiService()
        const { addErrorToast } = useToasts()
        const [value, setValue] = React.useState("")
        const [isLoading, setIsLoading] = React.useState<boolean>(false)
        const [options, setOptions] = React.useState<T[]>(props.initialOptions ?? [])
        const [selected, setSelected] = React.useState<T | null>(props.initialSelected ?? null)
        const { onIdSelected, onSelected } = props

        useImperativeHandle(ref, () => ({
            clearValue() {
                setSelected(null)
                setValue("")
                if (!config.loadOnce) setOptions([])
                if (props.onCleared) props.onCleared()
                if (props.onSelected) props.onSelected(null)
            },
        }))

        async function fetchOptions() {
            setIsLoading(true)
            let fetchedOptions: T[] = []

            try {
                fetchedOptions = await config.getOptions(value, esApiService, props.context)
            } catch (err) {
                console.error(err)
                addErrorToast({ text: `Error loading picker options for ${config.label}` })
            } finally {
                setIsLoading(false)
            }

            return fetchedOptions
        }

        /**
         * @param text the text entered when create option is activated
         * @returns
         *
         * handle creating an option but also automate its selection
         * as soon as it is persisted
         */
        async function handleCreateOption(text: string) {
            if (!config.createOption) return
            await config.createOption(text)
            const newOptions = await fetchOptions()

            // NOTE: having 1 is the most likely case because the only
            // way createOption was called before is if there were 0
            if (newOptions.length === 1) {
                onChange(newOptions[0])
            }
        }

        React.useEffect(() => {
            if (accessToken && (config.loadOnce || value)) {
                fetchOptions().then(setOptions)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [accessToken, config.loadOnce || value, JSON.stringify(props.context)])

        const onChange = (selectedOption: T | null) => {
            setSelected(selectedOption)
        }

        React.useEffect(() => {
            if (props.selectedId !== undefined) {
                const option = options.find((o) => o.id === props.selectedId?.toString())
                setSelected(option ?? null)
            }
        }, [options, props.selectedId])

        React.useEffect(() => {
            if (props.selectedValue !== undefined) {
                const option = options.find((o) => o.label === props.selectedValue?.toString())
                setSelected(option ?? null)
            }
        }, [options, props.selectedValue])

        React.useEffect(() => {
            const id = selected ? parseInt(selected.id) : null
            if (onIdSelected) {
                onIdSelected(id)
            }
            if (onSelected) {
                onSelected(selected)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selected])

        return (
            <InputField
                labelName={props.label ?? config.label}
                hasErrors={props.hasErrors}
                options={options}
                selected={selected}
                onChange={onChange as any}
                isLoading={isLoading}
                onCleared={props.onCleared}
                onSearchChange={debounce(setValue, 300)}
                isDisabled={props.isDisabled}
                onCreateOption={config.createOption ? handleCreateOption : undefined}
                placeholder={props.placeholder}
                isInvalid={props.isInvalid}
            />
        )
    })

const genPickers = <T extends Option, K extends object = {}>(config: GenConfig<T, K>) => {
    const Picker = genPicker<T, K>(config)

    return {
        Picker,
        PickerWithInitialValue: genPickerWithInitialValue<T, K>(Picker, config.label),
    }
}

function getUserOptions(text: string) {
    return getNames(text).then((results) =>
        results
            .filter((r) => r.first_name && r.last_name)
            .map((r) => ({
                label: `${r.first_name.trim() + " " + r.last_name.trim()} - ${r.user_id}`,
                id: `${r.user_id}`,
            }))
    )
}

export const { Picker: UserPicker, PickerWithInitialValue: UserPickerWithInitial } = genPickers({
    label: "User Id/Name",
    getOptions: getUserOptions,
})

export const { Picker: SalespersonPicker } = genPickers({
    label: "Salesperson",
    getOptions: getUserOptions,
})

export const { Picker: EsdbUserPicker } = genPickers({
    label: "User Id/Name",
    getOptions: (text) =>
        getEsdbUsers({ company_id: 1854, query: text }).then((results) =>
            results.map((r) => ({
                label: `${r.first_name.trim()} ${r.last_name.trim()} - ${r.user_id} - ${r.employee_id}`,
                id: `${r.user_id}`,
            }))
        ),
})

export const { Picker: ExpenseLinePicker } = genPickers({
    label: "Expense Line",
    getOptions: () =>
        getExpenseLines().then((results) =>
            results.map((r) => ({
                label: r.name,
                id: `${r.expense_line_id}`,
            }))
        ),
})

export const { Picker: SubDepartmentPicker } = genPickers({
    label: "Sub-Department",
    getOptions: () =>
        getSubDepartments().then((results) =>
            results.map((r) => ({
                label: r.name,
                id: `${r.sub_department_id}`,
            }))
        ),
})

export const { Picker: DepartmentPicker } = genPickers({
    label: "Department",
    getOptions: () =>
        getDepartments().then((results) =>
            results.map((r) => ({
                label: r.name,
                id: `${r.department_id}`,
            }))
        ),
})

export const { Picker: BranchPicker } = genPickers({
    label: "Branch Name",
    loadOnce: true,
    getOptions: () =>
        getBranches().then((results) =>
            results.map((r) => ({
                label: r.name,
                id: `${r.id}`,
            }))
        ),
})

export const { Picker: DocTypePicker } = genPickers({
    label: "Document Type",
    loadOnce: true,
    getOptions: () =>
        queryDocTypes().then((results) =>
            results.map((r) => ({
                label: r.name,
                id: `${r.doc_type_id}`,
            }))
        ),
})

export const { Picker: StorePicker } = genPickers<Option, StorePickerContext>({
    label: "Store Name",
    loadOnce: true,
    getOptions: (_, __, context) => {
        if (context?.branchId) {
            return getStores(context.branchId).then((results) =>
                results.data.inventoryStores.edges.map((s) => ({
                    label: `${s.node.name}`,
                    id: `${s.node.id}`,
                }))
            )
        } else {
            return Promise.resolve([])
        }
    },
})

export const { Picker: VendorPicker } = genPickers({
    label: "Vendor Name",
    getOptions: (search) =>
        getVendors(search).then((results) =>
            results.data.vendors.edges.map((v) => ({
                label: `${v.node.name}`,
                id: `${v.node.id}`,
            }))
        ),
})

export const { Picker: CompanyPicker, PickerWithInitialValue: CompanyPickerWithInitial } = genPickers({
    label: "Company Id/Name",
    getOptions: (text) =>
        getCompanies(text).then((results) =>
            results.map((r) => ({
                label: `${r.name} - ${r.company_id}`,
                id: `${r.company_id}`,
            }))
        ),
})

export const { Picker: RSPCompanyPicker, PickerWithInitialValue: RSPCompanyPickerWithInitial } = genPickers({
    label: "Company Id/Name",
    getOptions: (text: string) =>
        getRSPCompanies(text).then((results) =>
            results.map((r) => ({
                label: `${r.name} - ${r.company_id}`,
                id: `${r.company_id}`,
            }))
        ),
})

export const { Picker: FinancialLenderPicker } = genPickers({
    label: "Financial Lender",
    loadOnce: true,
    getOptions: (_) =>
        getLenders().then((result) =>
            result.map((r) => ({
                label: `${r.name}`,
                id: `${r.financial_lender_id}`,
            }))
        ),
})

export const { Picker: FinancialSchedulePicker } = genPickers({
    label: "Financial Schedule",
    loadOnce: true,
    getOptions: (_) =>
        getFinancialSchedules().then((result) =>
            result
                .filter((r) => r.current_schedule_number)
                .map((r) => ({
                    label: `${r.current_schedule_number}`,
                    id: `${r.financial_schedule_id}`,
                }))
        ),
})

export const { Picker: MakePicker, PickerWithInitialValue: MakePickerWithInitialValue } = genPickers({
    label: "Make",
    getOptions: (text) =>
        getMakes(text).then((results) =>
            results.map((r) => ({
                label: `${r.name}`,
                id: `${r.equipmentMakeId}`,
            }))
        ),
})

export const { Picker: SchedulePicker } = genPickers<Option, SchedulePickerContext>({
    label: "Schedule Picker",
    loadOnce: true,
    getOptions: (_, __, context) =>
        getSchedules({ payout_program_id: context?.payoutProgramId }).then((result) =>
            result.map((r) => ({
                label: `${r.name}`,
                id: `${r.schedule_id}`,
            }))
        ),
})

export const { Picker: AllModelsPicker, PickerWithInitialValue: AllModelsPickerWithInitialValue } = genPickers<
    DataOption<ModelsData>,
    { makeId?: number }
>({
    label: "Model",
    getOptions: (query, _, context) =>
        getModels(query, context?.makeId).then((result) =>
            result.map<DataOption<ModelsData>>((r) => ({
                label: `${r.name}`,
                id: `${r.equipment_model_id}`,
                data: {
                    equipment_class_id: r.equipment_classes[0]?.equipment_class_id,
                    equipment_make_id: r.equipment_make_id,
                    equipment_model_id: r.equipment_model_id,
                },
            }))
        ),
})

export const { Picker: ProviderPicker, PickerWithInitialValue: ProviderPickerWithInitial } = genPickers({
    label: "Provider",
    getOptions: (text) =>
        getProviders(text).then((providers) =>
            providers.map((v) => ({
                label: `${v.name}`,
                id: `${v.providerId}`,
            }))
        ),
})

export const { Picker: CompetitorPicker } = genPickers({
    label: "Competitor",
    createOption: async (text) => createCompetitor({ name: text }),
    getOptions: (text) =>
        queryCompetitors({ query: text }).then((competitors) =>
            competitors.map((c: Competitor) => ({
                label: `${c.name}`,
                id: `${c.competitor_id}`,
            }))
        ),
})

export const { Picker: PayoutProgramPicker } = genPickers({
    label: "Payout Program",
    loadOnce: true,
    getOptions: (_) =>
        getPayoutPrograms().then((result) =>
            result.map((r) => ({
                label: `${r.name}`,
                id: `${r.payout_program_id}`,
            }))
        ),
})

export const { Picker: PayoutProgramTypePicker } = genPickers({
    label: "Payout Program",
    loadOnce: true,
    getOptions: (_) =>
        getPayoutPrograms().then((result) =>
            result.map((r) => ({
                label: `${r.name}`,
                id: `${r.payout_program_type_id}`,
            }))
        ),
})

export const { Picker: MasterAgreementPicker } = genPickers({
    label: "Master Agreement",
    loadOnce: true,
    getOptions: (_) =>
        getPayoutProgramMasterAgreements().then((result) =>
            result.map((r) => ({
                label: `${r.company_id} - ${r.master_agreement_id}`,
                id: `${r.master_agreement_id}`,
            }))
        ),
})

export const { Picker: PayoutSchedulePicker } = genPickers({
    label: "Payout Schedules",
    loadOnce: true,
    getOptions: (_) =>
        getSchedules().then((result) =>
            result.map((r) => ({
                label: `${r.name}`,
                id: `${r.schedule_id}`,
            }))
        ),
})

export const { Picker: PayoutAssignmentPicker } = genPickers({
    label: "Assignments",
    loadOnce: true,
    getOptions: (_) =>
        getAssignments().then((result) =>
            result.map((r) => ({
                label: `${r.assignment_id}`,
                id: `${r.assignment_id}`,
            }))
        ),
})
/**
 * @param allCategories
 * @returns Option[]
 *
 * Creating options from categories is a bit complicated because the
 * hierarchy in the response must be represented in the option
 *
 * pseudo-response:
 * [
 *  {id: 1, name: foo, children: [{id: 2, name: bar, children: []}]},
 *  {id: 2, name: bar, children: []},
 *  {id: 3, name: spam: children: []}
 * ]
 *
 * They want it represented as:
 *  {id: 1, label: foo}, {id: 2, label: foo > bar}, {id: 3, label: spam}
 *
 * To achieve this we can run basic depth-first search (dfs) and preserve longest names
 * to filter out the redundancy in the response.
 */
function categoriesToLabels(allCategories: Category[]): Option[] {
    const longest: { [key: number]: Option } = {}
    const getLongest = (a: string, b: string) => (a.length > b.length ? a : b)
    function dfs(categories: Category[], acc = "") {
        for (const cat of categories) {
            const { category_id, name, categories: children } = cat
            const nextName = acc ? `${acc} > ${name}` : name
            longest[category_id] = {
                id: `${category_id}`,
                label: getLongest(nextName, longest[category_id]?.label || ""),
            }
            dfs(children, nextName)
        }
    }
    dfs(allCategories)
    return Object.values(longest)
}

export const { Picker: CategoryPicker } = genPickers({
    loadOnce: true,
    label: "ES Category",
    getOptions: (_, esApiService) => esApiService.getAllCategories().then(categoriesToLabels),
})

export const { Picker: ClassPicker } = genPickers<Option, { categoryId?: number }>({
    label: "ES Class",
    loadOnce: true,
    getOptions: (text, esApiService, context) => {
        const promise = context?.categoryId
            ? esApiService.getClassesByCategoryId(context.categoryId)
            : esApiService.queryClasses(text)
        return promise.then((classes) =>
            classes.map(({ name, equipment_class_id }) => ({
                label: name,
                id: `${equipment_class_id}`,
            }))
        )
    },
})

export const { Picker: TTUClassPicker } = genPickers<Option, TTUClassPickerContext>({
    label: "ES Class",
    loadOnce: true,
    getOptions: (_, __, context) => {
        if (context?.marketId) {
            return getEquipmentClasses({ market_id: context.marketId }).then((results) =>
                results.map((r) => ({
                    label: r.equipment_class_name,
                    id: `${r.equipment_class_id}`,
                }))
            )
        } else {
            return Promise.resolve([])
        }
    },
})

export const DELIVERY_TYPES = [
    { id: `1`, label: `Internal` },
    { id: `2`, label: `Third Party` },
    { id: `3`, label: `Customer` },
]

export const { Picker: DeliveryTypePicker } = genPickers({
    label: "Delivery Type",
    loadOnce: true,
    getOptions: () => {
        return Promise.resolve(DELIVERY_TYPES)
    },
})

export const { Picker: ServiceManualTypePicker, PickerWithInitialValue: ServiceManualTypePickerWithInitial } =
    genPickers({
        label: "Manual Type",
        loadOnce: true,
        getOptions: () =>
            getServiceManualTypes().then((results) =>
                results.map((r) => ({
                    label: r.name,
                    id: `${r.service_manual_type_id}`,
                }))
            ),
    })

export const getYearOptions = (): Option[] => {
    const currentYear = new Date().getFullYear()

    const years = [currentYear + 2, currentYear + 1, currentYear]

    for (let i = 1; i <= 100; i++) {
        years.push(currentYear - i)
    }

    return years.map((y) => ({
        label: `${y}`,
        id: `${y}`,
    }))
}

export const { Picker: YearPicker } = genPickers({
    label: "Year",
    loadOnce: true,
    getOptions: () => Promise.resolve(getYearOptions()),
})
