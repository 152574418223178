import Page from "../Page/Page"

export default function HomePage() {
    return (
        <Page title="Home">
            Welcome to the Internal Tools portal! Choose a function from the
            header to get started.
        </Page>
    )
}
