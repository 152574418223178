import React from "react"

import { updateAssetDriverName } from "../../services/DriverAssignment"
import {
    UpdateAssetAssignmentRequest,
    UserAssetAssignment,
    useUpdateAssetAssignment,
} from "../../services/Skunkworks/Generated"
import { useToasts } from "../../hooks/useToasts"
import { AssignmentForm } from "./AssignmentForm"

interface UpdateAssignmentFlyoutProps {
    onClose: () => void
    onSaved: (assignment: UserAssetAssignment) => void
    assetAssignmentId: number
    initialValues: UpdateAssetAssignmentRequest
}

export function UpdateAssignmentFlyout(props: UpdateAssignmentFlyoutProps) {
    const [isSaving, setIsSaving] = React.useState(false)
    const { addErrorToast } = useToasts()

    const { mutateAsync: updateAssetAssignment } = useUpdateAssetAssignment()

    function handleSave(values: UpdateAssetAssignmentRequest, name: string) {
        setIsSaving(true)
        updateAssetAssignment({
            assetAssignmentId: props.assetAssignmentId,
            data: values,
        })
            .then(async (data) => {
                if (
                    !values.end_date &&
                    values.user_id !== props.initialValues.user_id
                ) {
                    try {
                        await updateAssetDriverName(data.asset_id, name)
                    } catch (e) {
                        // this update is not mission critical so we won't
                        // worry about error handling if this fails for now
                    }
                }

                setIsSaving(false)
                props.onSaved(data)
            })
            .catch((e) => {
                setIsSaving(false)
                addErrorToast({
                    text: e.message,
                    title: "Error updating assignment",
                })
            })
    }

    return (
        <AssignmentForm
            onCloseClicked={props.onClose}
            onSavedClicked={handleSave}
            initialValues={props.initialValues}
            isSaving={isSaving}
        />
    )
}
