import React, { useEffect, useState } from "react"

import {
    EuiSpacer,
    EuiButton,
    EuiBasicTable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFieldText,
    EuiButtonEmpty,
    EuiConfirmModal,
    EuiCallOut,
    EuiText,
    EuiFormRow,
} from "@equipmentshare/ds2"

import { useQueryParams } from "../../hooks/useQueryParams"

import { getAsset } from "../../services/DriverAssignment"
import {
    UserAssetAssignment,
    useSearchAssetAssignments,
    useDeleteAssetAssignment,
} from "../../services/Skunkworks/Generated"
import { useAuthenticationDetails } from "../../hooks/useAuthenticationDetails"
import Page from "../Page/Page"
import { getUsers } from "../../services/Misc"
import { NewAssignmentFlyout } from "./NewAssignmentFlyout"
import { UpdateAssignmentFlyout } from "./UpdateAssignmentFlyout"
import { UserPicker } from "../Pickers"

export default function DriverAssignment() {
    const [hasSearchedOnce, setHasSearchedOnce] = useState(false)
    const [assetId, setAssetId] = useState("")
    const [userId, setUserId] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedAssignmentId, setSelectedAssignmentId] = useState<
        number | null
    >(null)
    const [error, setError] = useState<string | null>(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [assignmentIdToDelete, setAssignmentIdToDelete] = useState<
        number | null
    >(null)
    const [assignments, setAssignments] = useState<UserAssetAssignment[]>([])
    const [users, setUsers] = useState<{ [key: number]: string }>({})
    const [assets, setAssets] = useState<{ [key: number]: string }>({})
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false)

    const { refetch: searchAssetAssignments } = useSearchAssetAssignments(
        {
            asset_id: assetId ? parseInt(assetId) : undefined,
            user_id: userId ?? undefined,
        },
        { query: { enabled: false } }
    )
    const { mutateAsync: deleteAssetAssignment } = useDeleteAssetAssignment()
    const { accessToken } = useAuthenticationDetails()

    const queryParams = useQueryParams().params

    function fetchUsers(userIds: Set<number>) {
        getUsers(Array.from(userIds)).then((users) => {
            const userToName: { [key: number]: string } = {}
            for (const user of users) {
                if (user) {
                    userToName[
                        user.user_id
                    ] = `${user.first_name} ${user.last_name} (${user.user_id})`
                }
            }
            setUsers(userToName)
        })
    }

    function fetchAssets(assetIds: Set<number>) {
        Promise.all(
            Array.from(assetIds).map((assetId) => getAsset(assetId))
        ).then((assets) => {
            const assetsToName: { [key: number]: string } = {}
            for (const asset of assets) {
                if (asset) {
                    const { make, model, vin, assetId } = asset
                    const safe = `${vin} - ${assetId}`
                    const more = make && model ? `${make} ${model}` : null
                    const name = more ? `${safe} ${more}` : safe
                    assetsToName[asset.assetId] = name
                }
            }
            setAssets(assetsToName)
        })
    }

    useEffect(() => {
        if (assignments.length) {
            setIsLoading(true)
            Promise.all([
                fetchUsers(new Set(assignments.map((a) => a.user_id))),
                fetchAssets(new Set(assignments.map((a) => a.asset_id))),
            ]).finally(() => setIsLoading(false))
        }
        if (queryParams.get("/asset_id")) {
            setAssetId(queryParams.get("/asset_id")!)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, assignments])

    const handleGetAssetAssignments = (isRefresh = true) => {
        if (assetId || userId) {
            if (!isRefresh) {
                setAssignments([])
            }

            setError(null)
            setIsLoading(true)
            setHasSearchedOnce(true)
            searchAssetAssignments()
                .then(
                    (res) =>
                        res.data?.sort((a, b) =>
                            new Date(a.start_date) > new Date(b.start_date)
                                ? 1
                                : -1
                        ) ?? []
                )
                .then((res) => {
                    if (res.some((v) => v === null)) {
                        setError(`Asset #${assetId} not found.`)
                    } else {
                        setAssignments(res)
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handleDeleteAssignment = () => {
        if (assignmentIdToDelete) {
            setIsDeleting(true)
            setAssignmentIdToDelete(null)
            deleteAssetAssignment({ assetAssignmentId: assignmentIdToDelete })
                .then(() => handleGetAssetAssignments())
                .finally(() => setIsDeleting(false))
        }
    }

    const actions = [
        {
            render: (assignment: UserAssetAssignment) => {
                return (
                    <EuiButtonEmpty
                        onClick={() => {
                            setIsFlyoutOpen(true)
                            setSelectedAssignmentId(
                                assignment.user_asset_assignment_id
                            )
                        }}
                    >
                        Update
                    </EuiButtonEmpty>
                )
            },
        },
        {
            render: (assignment: UserAssetAssignment) => {
                const disable =
                    isDeleting &&
                    assignment.user_asset_assignment_id === assignmentIdToDelete
                return (
                    <EuiButtonEmpty
                        onClick={() =>
                            setAssignmentIdToDelete(
                                assignment.user_asset_assignment_id
                            )
                        }
                        color="danger"
                        disabled={disable}
                        isLoading={disable}
                    >
                        Delete
                    </EuiButtonEmpty>
                )
            },
        },
    ]

    const columns = [
        {
            field: "user_id",
            name: "User",
            render: (userId: number) =>
                userId in users ? users[userId] : userId,
        },
        {
            field: "asset_id",
            name: "Asset",
            render: (assetId: number) =>
                assetId in assets ? assets[assetId] : assetId,
        },
        {
            field: "start_date",
            name: "Start",
            render: (date: string) => new Date(date).toLocaleString(),
        },
        {
            field: "end_date",
            name: "End",
            render: (date?: string) =>
                date ? new Date(date).toLocaleString() : "--",
        },
        {
            name: "Actions",
            actions,
        },
    ]

    const confirmDeleteModal = () => {
        if (assignmentIdToDelete) {
            const assignment = assignments.find(
                (a) => a.user_asset_assignment_id === assignmentIdToDelete
            )!
            return (
                <EuiConfirmModal
                    title="Delete Assignment"
                    onCancel={() => setAssignmentIdToDelete(null)}
                    onConfirm={handleDeleteAssignment}
                    cancelButtonText="No, don't do it"
                    confirmButtonText="Yes, delete it"
                    buttonColor="danger"
                    defaultFocusedButton="confirm"
                >
                    <p>
                        Are you sure you want to delete the assignment for User
                        #{assignment.user_id} from{" "}
                        {new Date(assignment.start_date).toLocaleString()} to{" "}
                        {assignment.end_date
                            ? new Date(assignment.end_date).toLocaleString()
                            : "present"}
                        ?
                    </p>
                </EuiConfirmModal>
            )
        }
    }

    function renderFlyout() {
        if (isFlyoutOpen) {
            const closeFlyout = () => {
                setIsFlyoutOpen(false)
                setSelectedAssignmentId(null)
            }
            const common = {
                onClose: closeFlyout,
                onSaved: () => {
                    closeFlyout()
                    handleGetAssetAssignments()
                },
            }
            if (selectedAssignmentId) {
                const assignment = assignments.find(
                    (a) => a.user_asset_assignment_id === selectedAssignmentId
                )
                return (
                    <UpdateAssignmentFlyout
                        {...common}
                        initialValues={assignment!}
                        assetAssignmentId={selectedAssignmentId}
                    />
                )
            } else {
                return (
                    <NewAssignmentFlyout
                        {...common}
                        assetId={assetId ? parseInt(assetId) : undefined}
                        userId={userId || undefined}
                    />
                )
            }
        }
    }

    function renderAssignments() {
        if (assignments.length) {
            return (
                <EuiBasicTable<UserAssetAssignment>
                    items={assignments}
                    itemId="user_asset_assignment_id"
                    columns={columns}
                />
            )
        } else if (isLoading) {
            return <div>Loading...</div>
        } else if (!hasSearchedOnce) {
            return null
        } else {
            return (
                <EuiText>
                    <p>Could not find assignments.</p>
                </EuiText>
            )
        }
    }

    const renderError = () =>
        error ? (
            <>
                <EuiSpacer size="m" />
                <EuiCallOut title="Error" color="danger" iconType="alert">
                    <p>{error}</p>
                </EuiCallOut>
            </>
        ) : null

    return (
        <Page title="Driver to Asset Assignment">
            {renderFlyout()}
            {confirmDeleteModal()}
            <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={false}>
                    <EuiFormRow label="Asset Id">
                        <EuiFieldText
                            placeholder="Enter Asset Id..."
                            value={assetId}
                            onChange={(e) => setAssetId(e.target.value)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ minWidth: "300px" }}>
                    <UserPicker
                        onIdSelected={setUserId}
                        onCleared={() => setUserId(0)}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    {/* invisible char is hack to get things to line up vertically */}
                    <EuiFormRow label="ㅤ">
                        <EuiButton
                            disabled={isLoading}
                            onClick={() => handleGetAssetAssignments(false)}
                        >
                            Get Assignments
                        </EuiButton>
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>

            {renderError()}

            <EuiSpacer size="l" />
            <EuiButton onClick={() => setIsFlyoutOpen(true)}>
                Add Assignment
            </EuiButton>

            <EuiSpacer size="m" />
            {renderAssignments()}
        </Page>
    )
}
