import { useState } from "react"

import { EuiPage, EuiPageHeader, EuiPageBody, EuiPageSection, EuiTabsProps } from "@equipmentshare/ds2"

import { DocumentCatalogue } from "./DocumentCatalogue/DocumentCatalogue"
import { EquipmentReference } from "./EquipmentReference/EquipmentReference"
import { DocumentStatus } from "./DocumentStatus/DocumentStatus"

type TabId = "document-library" | "equipment-service-manuals" | "document-status"

export const EquipmentReferenceCatalogue = () => {
    const [tabSelected, setTabSelected] = useState<TabId>("document-library")

    const tabs: EuiTabsProps[] = [
        {
            id: "document-library",
            label: "Document Catalogue",
            isSelected: tabSelected === "document-library",
            onClick: () => setTabSelected("document-library"),
        },
        {
            id: "equipment-service-manuals",
            label: "Equipment Reference",
            isSelected: tabSelected === "equipment-service-manuals",
            onClick: () => setTabSelected("equipment-service-manuals"),
        },
        {
            id: "document-status",
            label: "Document Status",
            isSelected: tabSelected === "document-status",
            onClick: () => setTabSelected("document-status"),
        },
    ]

    const renderContents = () => {
        switch (tabSelected) {
            case "document-library":
                return <DocumentCatalogue />
            case "equipment-service-manuals":
                return <EquipmentReference />
            case "document-status":
                return <DocumentStatus />
        }
    }

    return (
        <EuiPage>
            <EuiPageBody>
                <EuiPageHeader
                    paddingSize="l"
                    bottomBorder={true}
                    pageTitle="Equipment Reference Catalogue"
                    tabs={tabs}
                />
                <EuiPageSection>{renderContents()}</EuiPageSection>
            </EuiPageBody>
        </EuiPage>
    )
}

export default EquipmentReferenceCatalogue
