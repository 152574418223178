/**
 * Generated by orval v6.19.1 🍺
 * Do not edit manually.
 * Sworks Service
 * An HTTP REST API for various Sworks related projects
 * OpenAPI spec version: 2.102.0rc1532
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import { customInstance } from './AxiosInstance';
import type { ErrorType } from './AxiosInstance';
export type GetTripsParams = {
user_id: number;
cursor?: number;
limit?: number;
desc?: boolean;
};

export type SearchAssetAssignmentsParams = {
asset_id?: number | null;
user_id?: number | null;
};

export type GetServiceManualsAssetStatusParams = {
has_operators_manual?: boolean | null;
has_service_manual?: boolean | null;
has_parts_manual?: boolean | null;
has_warranty_manual?: boolean | null;
has_other_manual?: boolean | null;
equipment_class_name?: string | null;
equipment_make_name?: string | null;
equipment_model_name?: string | null;
offset?: number;
limit?: number;
sort_by?: string;
sort_order?: string;
};

export type GetEquipmentServiceManualsParams = {
equipment_make_id?: number | null;
equipment_model_id?: number | null;
serial_start?: string | null;
serial_end?: string | null;
serial_format?: string | null;
year?: number | null;
service_manual_id?: number | null;
created_by?: number | null;
updated_by?: number | null;
offset?: number;
limit?: number;
sort_by?: string;
sort_order?: string;
};

export type GetServiceManualsParams = {
file_id?: string | null;
file_type?: string | null;
file_name?: string | null;
file_path?: string | null;
exclude?: boolean | null;
needs_expert_review?: boolean | null;
classified?: boolean | null;
has_notice?: boolean | null;
is_licensed?: boolean | null;
has_restrictions?: boolean | null;
service_manual_type_id?: number | null;
created_by?: number | null;
updated_by?: number | null;
offset?: number;
limit?: number;
sort_by?: string;
sort_order?: string;
};

export type GetRebateAgreementsParams = {
/**
 * Filter by company ID
 */
company_id?: number | null;
/**
 * Filter by start date
 */
start_date?: string | null;
/**
 * Filter by end date
 */
end_date?: string | null;
};

export type DownloadParams = {
salesperson_user_id?: number | null;
financial_schedule_id?: number | null;
company_id?: number | null;
financial_lender_id?: number | null;
line_item_type_id?: number | null;
start_date?: string | null;
end_date?: string | null;
paid_invoices?: boolean | null;
};

export type RequestImageParams = {
redirect?: boolean;
};

export type GetEsdbUsersParams = {
/**
 * Filter users returned to those assigned a specified company_id
 */
company_id?: number;
/**
 * Filter users returned to those partially matching on name or id
 */
query?: string;
};

export type GetExpenseUser200 = ExpenseUser | null;

export type GetExpenseUserParams = {
/**
 * Include the departments of the returned user
 */
departments?: boolean;
/**
 * Filter users returned to those assigned a specified department_id
 */
department?: number;
/**
 * Include the departments and sub-departments of returned users
 */
sub_departments?: boolean;
/**
 * Filter users returned to those assigned a specific sub_department_id
 */
sub_department?: number;
/**
 * Include the departments, sub-departments, and expense lines of returned users.
 */
expense_lines?: boolean;
/**
 * Filter users returned to those assigned a specific expense_line_id
 */
expense_line?: number;
};

export type GetExpenseUsersParams = {
/**
 * Include the departments of returned users
 */
departments?: boolean;
/**
 * Filter users returned to those assigned a specified department_id
 */
department?: number;
/**
 * Include the departments and sub-departments of returned users
 */
sub_departments?: boolean;
/**
 * Filter users returned to those assigned a specific sub_department_id
 */
sub_department?: number;
/**
 * Include the departments, sub-departments, and expense lines of returned users.
 */
expense_lines?: boolean;
/**
 * Filter users returned to those assigned a specific expense_line_id
 */
expense_line?: number;
};

export type GetSubDepartmentParams = {
/**
 * Include the expense lines of returned sub-departments
 */
expense_lines?: boolean;
};

export type GetSubDepartmentsParams = {
/**
 * Include the expense lines of returned sub-departments
 */
expense_lines?: boolean;
/**
 * Filter sub-departments returned to those assigned a specific expense_line_id
 */
expense_line?: number;
};

export type GetDepartment200 = Department | null;

export type GetDepartmentParams = {
/**
 * Include the sub-departments of the returned department
 */
sub_departments?: boolean;
/**
 * Include the expense lines and sub-departments of returned departments
 */
expense_lines?: boolean;
/**
 * Filter departments returned to those assigned a specific expense_line_id
 */
expense_line?: number;
/**
 * (Admin only) Include all sub-departments, not just the ones assigned to the current user
 */
all_sub_departments?: boolean;
};

export type GetDepartmentsParams = {
/**
 * Include the sub-departments of returned departments
 */
sub_departments?: boolean;
/**
 * Include the expense lines and sub-departments of returned departments
 */
expense_lines?: boolean;
/**
 * Filter departments returned to those assigned a specific expense_line_id
 */
expense_line?: number;
};

export type GetDiscountsParams = {
company_id: number;
};

export type QueryCompetitorsParams = {
query: string;
};

export type QueryCompetitorClassParams = {
competitor_id: number;
query: string;
};

export type QueryCompetitorQuotesParams = {
company_id?: number | null;
salesperson_id?: number | null;
market_id?: number | null;
competitor_id?: number | null;
};

export type GetEquipmentClassesParams = {
market_id: number;
};

export interface _ErrorModel {
  detail: string;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UserAssetAssignmentEndDate = string | null;

export interface UserAssetAssignment {
  asset_id: number;
  end_date?: UserAssetAssignmentEndDate;
  start_date: string;
  user_asset_assignment_id: number;
  user_id: number;
}

export type UpdateSubDepartmentRequestName = string | null;

export type UpdateSubDepartmentRequestDepartmentId = number | null;

export interface UpdateSubDepartmentRequest {
  department_id?: UpdateSubDepartmentRequestDepartmentId;
  name?: UpdateSubDepartmentRequestName;
}

export type UpdateServiceManualRequestServiceManualTypeId = number | null;

export interface UpdateServiceManualRequest {
  classified: boolean;
  exclude: boolean;
  has_notice: boolean;
  has_restrictions: boolean;
  is_licensed: boolean;
  needs_expert_review: boolean;
  service_manual_type_id?: UpdateServiceManualRequestServiceManualTypeId;
  version_number: number;
}

export interface UpdateRebateTierRequest {
  gross_rent_payments_max: number;
  gross_rent_payments_min: number;
  rebate_percentage: number;
  rebate_tier_id: number;
}

export type UpdateRebateAgreementRequestFileName = string | null;

export interface UpdateRebateAgreementRequest {
  companies: RebateCompanyUpdateBody;
  custom_rental_rates: boolean;
  file_name: UpdateRebateAgreementRequestFileName;
  payment_terms: string;
  rebate_period_begin: string;
  rebate_period_end: string;
  rebate_tiers: RebateTierUpdateBody;
}

export interface UpdateMarketAccessRequest {
  market_access: boolean;
}

export interface UpdateExpenseLineRequest {
  active: boolean;
  name: string;
}

export type UpdateEquipmentServiceManualRequestYears = number[] | null;

export type UpdateEquipmentServiceManualRequestSerialStart = string | null;

export type UpdateEquipmentServiceManualRequestSerialFormat = string | null;

export type UpdateEquipmentServiceManualRequestSerialEnd = string | null;

export type UpdateEquipmentServiceManualRequestEquipmentModelId = number | null;

export interface UpdateEquipmentServiceManualRequest {
  equipment_make_id: number;
  equipment_model_id?: UpdateEquipmentServiceManualRequestEquipmentModelId;
  serial_end?: UpdateEquipmentServiceManualRequestSerialEnd;
  serial_format?: UpdateEquipmentServiceManualRequestSerialFormat;
  serial_start?: UpdateEquipmentServiceManualRequestSerialStart;
  service_manual_id: number;
  version_number: number;
  years?: UpdateEquipmentServiceManualRequestYears;
}

export type UpdateDiscountRequestProviderId = number | null;

export type UpdateDiscountRequestModifier = number | string | null;

export type UpdateDiscountRequestDiscountTypeId = number | null;

export type UpdateDiscountRequestCompanyId = number | null;

export interface UpdateDiscountRequest {
  company_id?: UpdateDiscountRequestCompanyId;
  discount_type_id?: UpdateDiscountRequestDiscountTypeId;
  modifier?: UpdateDiscountRequestModifier;
  provider_id?: UpdateDiscountRequestProviderId;
}

export interface UpdateDepartmentRequest {
  name: string;
}

export type UpdateCommissionOverrideRequestReqReviewNote = string | null;

export interface UpdateCommissionOverrideRequestReq {
  review_note?: UpdateCommissionOverrideRequestReqReviewNote;
  review_status: RequestStatus;
}

export type UpdateAssetAssignmentRequestEndDate = string | null;

export interface UpdateAssetAssignmentRequest {
  asset_id: number;
  end_date?: UpdateAssetAssignmentRequestEndDate;
  start_date: string;
  user_id: number;
}

export interface TripAndClassification {
  trip_classification_type_id: number;
  trip_id: number;
}

export type SuperTripsPaginatedNextCursor = number | null;

export interface SuperTripsPaginated {
  cursor: number;
  limit: number;
  miles_info: MilesInfo;
  next_cursor: SuperTripsPaginatedNextCursor;
  super_trips: SuperTrip[];
  total: number;
}

export type SuperTripTripTypeId = number | null;

export type SuperTripStartLng = number | null;

export type SuperTripStartLat = number | null;

export type SuperTripStartAddressZip = string | null;

export type SuperTripStartAddressStreet = string | null;

export type SuperTripStartAddressStateAbbreviation = string | null;

export type SuperTripStartAddressCity = string | null;

export type SuperTripEndLng = number | null;

export type SuperTripEndLat = number | null;

export type SuperTripEndAddressZip = string | null;

export type SuperTripEndAddressStreet = string | null;

export type SuperTripEndAddressStateAbbreviation = string | null;

export type SuperTripEndAddressCity = string | null;

export interface SuperTrip {
  asset_id: number;
  end_address_city: SuperTripEndAddressCity;
  end_address_state_abbreviation: SuperTripEndAddressStateAbbreviation;
  end_address_street: SuperTripEndAddressStreet;
  end_address_zip: SuperTripEndAddressZip;
  end_lat: SuperTripEndLat;
  end_lng: SuperTripEndLng;
  end_timestamp: string;
  start_address_city: SuperTripStartAddressCity;
  start_address_state_abbreviation: SuperTripStartAddressStateAbbreviation;
  start_address_street: SuperTripStartAddressStreet;
  start_address_zip: SuperTripStartAddressZip;
  start_lat: SuperTripStartLat;
  start_lng: SuperTripStartLng;
  start_timestamp: string;
  super_trip_id: string;
  trip_classification_type_id: number;
  trip_distance_miles: number;
  trip_type_id: SuperTripTripTypeId;
  trips: number[];
}

export interface SubDepartmentExpenseLine {
  date_created: string;
  date_updated: string;
  expense_line_id: number;
  sub_department_expense_line_id: number;
  sub_department_id: number;
}

export type SubDepartmentPrimary = boolean | null;

export interface SubDepartment {
  date_created: string;
  date_updated: string;
  department_id: number;
  expense_lines?: ExpenseLine[];
  name: string;
  primary?: SubDepartmentPrimary;
  sub_department_id: number;
}

export interface StatusResponse {
  database_connection_up: boolean;
  env_type: string;
  healthy: boolean;
  level: string;
}

export interface SignedUrl {
  object_key: string;
  upload_url: string;
}

export interface ServiceManualsAssetStatus {
  asset_count: number;
  equipment_class_id: number;
  equipment_class_name: string;
  equipment_make_id: number;
  equipment_make_name: string;
  equipment_model_id: number;
  equipment_model_name: string;
  has_operators_manual: boolean;
  has_other_manual: boolean;
  has_parts_manual: boolean;
  has_service_manual: boolean;
  has_warranty_manual: boolean;
}

export interface ServiceManualsAssetStatusAndCount {
  service_manuals_asset_status?: ServiceManualsAssetStatus[];
  total_count: number;
}

export interface ServiceManualsAndCount {
  service_manuals: ServiceManual[];
  total_count: number;
}

export interface ServiceManualType {
  name: string;
  service_manual_type_id: number;
}

export type ServiceManualServiceManualTypeName = string | null;

export type ServiceManualServiceManualTypeId = number | null;

export interface ServiceManual {
  classified: boolean;
  created_by: number;
  date_created: string;
  date_updated: string;
  exclude: boolean;
  file_id: string;
  file_name: string;
  file_path: string;
  file_type: string;
  has_notice: boolean;
  has_restrictions: boolean;
  is_licensed: boolean;
  needs_expert_review: boolean;
  service_manual_id: number;
  service_manual_type_id?: ServiceManualServiceManualTypeId;
  service_manual_type_name?: ServiceManualServiceManualTypeName;
  updated_by: number;
  version_number: number;
}

export type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestStatus = {
  pending: 'pending',
  approved: 'approved',
  rejected: 'rejected',
} as const;

export interface RequestInvoicesImageUrlResponse {
  id: string;
  url: string;
}

export type ReportTripsRequestTrackerId = number | null;

export type ReportTripsRequestAssetId = number | null;

export interface ReportTripsRequest {
  asset_id?: ReportTripsRequestAssetId;
  report_info: string;
  tracker_id?: ReportTripsRequestTrackerId;
  user_email: string;
  user_name: string;
}

export interface RegionCodeHolder {
  region_id: number;
}

export interface RebateTierUpdateBody {
  create: CreateRebateTierRequest[];
  delete: number[];
  update: UpdateRebateTierRequest[];
}

export type RebateTierDateUpdated = string | null;

export interface RebateTier {
  active: boolean;
  created_by: number;
  date_created: string;
  date_updated: RebateTierDateUpdated;
  gross_rent_payments_max: number;
  gross_rent_payments_min: number;
  rebate_agreement_id: number;
  rebate_percentage: number;
  rebate_tier_id: number;
  updated_by: number;
}

export interface RebateCompanyUpdateBody {
  create: number[];
  delete: number[];
}

export type RebateAgreementFileName = string | null;

export interface RebateAgreement {
  active: boolean;
  companies?: Company[];
  created_by: number;
  custom_rental_rates: boolean;
  date_created: string;
  date_updated: string;
  file_name?: RebateAgreementFileName;
  payment_terms: string;
  rebate_agreement_id: number;
  rebate_period_begin: string;
  rebate_period_end: string;
  rebate_tiers?: RebateTier[];
  updated_by: number;
}

export interface QuarterlyDeadlineResponse {
  hard_quarter_deadline: string;
  soft_quarter_deadline: string;
}

export interface PurchaseOrder {
  missing_items: string[];
  purchase_order_id: string;
}

export interface PhoneSystemUser {
  company_id: number;
  phone_number: number;
  phone_user_id: number;
}

export interface MilesInfo {
  business_miles: number;
  personal_miles: number;
  total_miles: number;
}

export interface MapUrlResponse {
  url: string;
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface ExpenseUserSubDepartment {
  date_created: string;
  date_updated: string;
  expense_user_id: number;
  expense_user_sub_department_id: number;
  primary_sub_department: boolean;
  sub_department_id: number;
}

export interface ExpenseUser {
  admin: boolean;
  date_created: string;
  date_updated: string;
  departments?: Department[];
  expense_user_id: number;
  market_access: boolean;
}

export interface ExpenseLine {
  active: boolean;
  date_created: string;
  date_updated: string;
  expense_line_id: number;
  name: string;
}

export type EsdbUserPhoneNumber = string | null;

export type EsdbUserEmployeeId = string | null;

export interface EsdbUser {
  company_id: number;
  email_address: string;
  employee_id?: EsdbUserEmployeeId;
  first_name: string;
  last_name: string;
  phone_number?: EsdbUserPhoneNumber;
  user_id: number;
}

export interface EquipmentServiceManualsAndCount {
  equipment_service_manuals?: EquipmentServiceManual[];
  total_count: number;
}

export type EquipmentServiceManualServiceManual = ServiceManual | null;

export type EquipmentServiceManualSerialStart = string | null;

export type EquipmentServiceManualSerialFormat = string | null;

export type EquipmentServiceManualSerialEnd = string | null;

export type EquipmentServiceManualEquipmentModelName = string | null;

export type EquipmentServiceManualEquipmentModelId = number | null;

export type EquipmentServiceManualEquipmentMakeName = string | null;

export interface EquipmentServiceManual {
  created_by: number;
  date_created: string;
  date_updated: string;
  equipment_make_id: number;
  equipment_make_name?: EquipmentServiceManualEquipmentMakeName;
  equipment_model_id?: EquipmentServiceManualEquipmentModelId;
  equipment_model_name?: EquipmentServiceManualEquipmentModelName;
  equipment_service_manual_id: number;
  serial_end: EquipmentServiceManualSerialEnd;
  serial_format: EquipmentServiceManualSerialFormat;
  serial_start: EquipmentServiceManualSerialStart;
  service_manual?: EquipmentServiceManualServiceManual;
  service_manual_id: number;
  updated_by: number;
  version_number: number;
  years?: number[];
}

export interface EquipmentClass {
  equipment_class_id: number;
  equipment_class_name: string;
  time_utilization: string;
}

export interface ESQuote {
  competitor_quote_id: number;
  date_created: string;
  date_updated: string;
  es_quote_id: number;
  quote: string;
}

export interface DownloadRebateAgreementFileRequest {
  rebate_agreement_id: number;
  url: string;
}

export interface DownloadInvoicesResponse {
  file: string;
}

export interface DownloadInvoicesRequest {
  invoice_ids: number[];
}

export interface DocType {
  doc_type_id: number;
  name: string;
}

export interface Discount {
  company_id: number;
  date_created: string;
  discount_id: number;
  discount_type_id: number;
  modifier: string;
  provider_id: number;
}

export type DepartmentPrimary = boolean | null;

export interface Department {
  date_created: string;
  date_updated: string;
  department_id: number;
  name: string;
  primary?: DepartmentPrimary;
  sub_departments?: SubDepartment[];
}

export interface DeleteSubDepartmentExpenseLineRequest {
  expense_line_id: number;
  sub_department_id: number;
}

export interface DeleteExpenseUserSubDepartmentRequest {
  expense_user_id: number;
  sub_department_id: number;
}

export interface CreateSubDepartmentRequest {
  department_id: number;
  name: string;
}

export interface CreateSubDepartmentExpenseLineRequest {
  expense_line_id: number;
  sub_department_id: number;
}

export interface CreateSignedUrlRequest {
  content_type: string;
}

export type CreateServiceManualRequestServiceManualTypeId = number | null;

export interface CreateServiceManualRequest {
  exclude?: boolean;
  file_id: string;
  file_name: string;
  file_path: string;
  file_type: string;
  has_notice?: boolean;
  has_restrictions?: boolean;
  is_licensed?: boolean;
  needs_expert_review?: boolean;
  service_manual_type_id?: CreateServiceManualRequestServiceManualTypeId;
}

export interface CreateServiceManualsRequest {
  service_manuals: CreateServiceManualRequest[];
}

export interface CreateRebateTierRequest {
  gross_rent_payments_max: number;
  gross_rent_payments_min: number;
  rebate_percentage: number;
}

export interface CreateRebateAgreementRequest {
  companies: number[];
  custom_rental_rates: boolean;
  payment_terms: string;
  rebate_period_begin: string;
  rebate_period_end: string;
  rebate_tiers: CreateRebateTierRequest[];
}

export interface CreateJotformRequest {
  form_id: number;
  submission_id: number;
}

export interface CreateExpenseUserSubDepartmentRequest {
  expense_user_id: number;
  primary_sub_department?: boolean;
  sub_department_id: number;
}

export interface CreateExpenseLineRequest {
  active: boolean;
  name: string;
}

export type CreateEquipmentServiceManualRequestYears = number[] | null;

export type CreateEquipmentServiceManualRequestSerialStart = string | null;

export type CreateEquipmentServiceManualRequestSerialFormat = string | null;

export type CreateEquipmentServiceManualRequestSerialEnd = string | null;

export type CreateEquipmentServiceManualRequestEquipmentModelIds = number[] | null;

export interface CreateEquipmentServiceManualRequest {
  equipment_make_id: number;
  equipment_model_ids?: CreateEquipmentServiceManualRequestEquipmentModelIds;
  serial_end?: CreateEquipmentServiceManualRequestSerialEnd;
  serial_format?: CreateEquipmentServiceManualRequestSerialFormat;
  serial_start?: CreateEquipmentServiceManualRequestSerialStart;
  service_manual_id: number;
  years?: CreateEquipmentServiceManualRequestYears;
}

export interface CreateESQuoteRequest {
  competitor_quote_id: number;
  quote: string;
}

export type CreateDiscountRequestModifier = number | string;

export interface CreateDiscountRequest {
  company_id: number;
  discount_type_id: number;
  modifier: CreateDiscountRequestModifier;
  provider_id: number;
}

export interface CreateDepartmentRequest {
  name: string;
}

export interface CreateCompetitorRequest {
  name: string;
}

export type CreateCompetitorQuoteRequestSalespersonId = number | null;

export type CreateCompetitorQuoteRequestObjectKey = string | null;

export type CreateCompetitorQuoteRequestMarketId = number | null;

export type CreateCompetitorQuoteRequestDocTypeId = number | null;

export type CreateCompetitorQuoteRequestCompanyId = number | null;

export interface CreateCompetitorQuoteRequest {
  company_id?: CreateCompetitorQuoteRequestCompanyId;
  competitor_id: number;
  details: CreateCompetitorQuoteDetailRequest[];
  doc_type_id?: CreateCompetitorQuoteRequestDocTypeId;
  market_id?: CreateCompetitorQuoteRequestMarketId;
  object_key?: CreateCompetitorQuoteRequestObjectKey;
  salesperson_id?: CreateCompetitorQuoteRequestSalespersonId;
}

export type CreateCompetitorQuoteDetailRequestPricePerWeek = number | string | null;

export type CreateCompetitorQuoteDetailRequestPricePerMonth = number | string | null;

export type CreateCompetitorQuoteDetailRequestPricePerHour = number | string | null;

export type CreateCompetitorQuoteDetailRequestPricePerDay = number | string | null;

export interface CreateCompetitorQuoteDetailRequest {
  competitor_class_id: number;
  price_per_day?: CreateCompetitorQuoteDetailRequestPricePerDay;
  price_per_hour?: CreateCompetitorQuoteDetailRequestPricePerHour;
  price_per_month?: CreateCompetitorQuoteDetailRequestPricePerMonth;
  price_per_week?: CreateCompetitorQuoteDetailRequestPricePerWeek;
  quantity: number;
}

export type CreateCompetitorClassRequestEsClassId = number | null;

export type CreateCompetitorClassRequestClassId = number | null;

export type CreateCompetitorClassRequestCategoryId = number | null;

export interface CreateCompetitorClassRequest {
  category_id?: CreateCompetitorClassRequestCategoryId;
  class_id?: CreateCompetitorClassRequestClassId;
  competitor_id: number;
  description: string;
  es_category_id: number;
  es_class_id?: CreateCompetitorClassRequestEsClassId;
}

export type CreateCommissionOverrideRequestReqRequestRate = number | string;

export interface CreateCommissionOverrideRequestReq {
  company_id: number;
  equipment_class_id: number;
  market_id: number;
  request_note: string;
  request_rate: CreateCommissionOverrideRequestReqRequestRate;
}

export type CreateAssetAssignmentRequestEndDate = string | null;

export interface CreateAssetAssignmentRequest {
  asset_id: number;
  end_date?: CreateAssetAssignmentRequestEndDate;
  start_date: string;
  user_id: number;
}

export type CompetitorQuoteDetailPricePerWeek = string | null;

export type CompetitorQuoteDetailPricePerMonth = string | null;

export type CompetitorQuoteDetailPricePerHour = string | null;

export type CompetitorQuoteDetailPricePerDay = string | null;

export interface CompetitorQuoteDetail {
  competitor_class: CompetitorClass;
  competitor_class_id: number;
  competitor_quote_detail_id: number;
  price_per_day?: CompetitorQuoteDetailPricePerDay;
  price_per_hour?: CompetitorQuoteDetailPricePerHour;
  price_per_month?: CompetitorQuoteDetailPricePerMonth;
  price_per_week?: CompetitorQuoteDetailPricePerWeek;
  quantity: number;
}

export type CompetitorQuoteSalespersonId = number | null;

export type CompetitorQuoteSalesperson = EsdbUser | null;

export type CompetitorQuoteObjectUrl = string | null;

export type CompetitorQuoteMarketId = number | null;

export type CompetitorQuoteCompanyId = number | null;

export interface CompetitorQuote {
  company_id?: CompetitorQuoteCompanyId;
  competitor: Competitor;
  competitor_id: number;
  competitor_quote_id: number;
  date_created: string;
  details: CompetitorQuoteDetail[];
  doc_type: DocType;
  doc_type_id: number;
  es_quotes: ESQuote[];
  market_id?: CompetitorQuoteMarketId;
  object_url?: CompetitorQuoteObjectUrl;
  salesperson?: CompetitorQuoteSalesperson;
  salesperson_id?: CompetitorQuoteSalespersonId;
}

export type CompetitorClassEsClassId = number | null;

export type CompetitorClassClassId = number | null;

export type CompetitorClassCategoryId = number | null;

export interface Competitor {
  competitor_id: number;
  name: string;
}

export interface CompetitorClass {
  category_id?: CompetitorClassCategoryId;
  class_id?: CompetitorClassClassId;
  competitor: Competitor;
  competitor_class_id: number;
  competitor_id: number;
  date_created: string;
  description: string;
  es_category_id: number;
  es_class_id?: CompetitorClassEsClassId;
}

export type CompanyPhoneUserRegionCode = number | RegionCodeHolder;

export interface CompanyPhoneUser {
  admin_url: string;
  company_id: number;
  company_name: string;
  looker_url: string;
  phone_number: string;
  region_code: CompanyPhoneUserRegionCode;
}

export interface Company {
  company_id: number;
  name?: string;
}

export type CommissionOverrideRequestUploadUrl = string | null;

export interface CommissionOverrideRequestUpload {
  commission_override_request_id: number;
  commission_override_request_upload_id: number;
  date_created: string;
  date_updated: string;
  object_id: string;
  url?: CommissionOverrideRequestUploadUrl;
}

export type CommissionOverrideRequestReviewUserName = string | null;

export type CommissionOverrideRequestReviewUserId = number | null;

export type CommissionOverrideRequestReviewNote = string | null;

export type CommissionOverrideRequestReviewDate = string | null;

export type CommissionOverrideRequestRequestNote = string | null;

export interface CommissionOverrideRequest {
  commission_override_request_id: number;
  company_id: number;
  company_name: string;
  date_created: string;
  date_updated: string;
  equipment_class_id: number;
  equipment_class_name: string;
  equipment_rate: string;
  equipment_utilization: string;
  market_id: number;
  market_name: string;
  request_note: CommissionOverrideRequestRequestNote;
  request_rate: string;
  request_uploads?: CommissionOverrideRequestUpload[];
  request_user_id: number;
  request_user_name: string;
  review_date?: CommissionOverrideRequestReviewDate;
  review_note?: CommissionOverrideRequestReviewNote;
  review_status?: RequestStatus;
  review_user_id?: CommissionOverrideRequestReviewUserId;
  review_user_name?: CommissionOverrideRequestReviewUserName;
}

export interface BodyWriteFormDataEtlJotformTokenPost {
  formID: string;
  rawRequest: string;
  submissionID: string;
}

export interface BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost {
  file: Blob;
}

export interface BodyUploadFileCpeOrdersPost {
  file: Blob;
}

export interface BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost {
  files: Blob[];
}

export interface ApproveTripsRequest {
  approvals: TripAndClassification[];
}

export interface AddPhoneNumberRequest {
  company_id: number;
  phone_number: string;
}





/**
 * @summary Get Latest Map Url
 */
export const getLatestMapUrl = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<MapUrlResponse>(
      {url: `/ba-map/`, method: 'get', signal
    },
      );
    }
  

export const getGetLatestMapUrlQueryKey = () => {
    
    return [`/ba-map/`] as const;
    }

    
export const getGetLatestMapUrlQueryOptions = <TData = Awaited<ReturnType<typeof getLatestMapUrl>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLatestMapUrl>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetLatestMapUrlQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLatestMapUrl>>> = ({ signal }) => getLatestMapUrl(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLatestMapUrl>>, TError, TData> & { queryKey: QueryKey }
}

export type GetLatestMapUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getLatestMapUrl>>>
export type GetLatestMapUrlQueryError = ErrorType<_ErrorModel>

/**
 * @summary Get Latest Map Url
 */
export const useGetLatestMapUrl = <TData = Awaited<ReturnType<typeof getLatestMapUrl>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLatestMapUrl>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetLatestMapUrlQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Upload Url
 */
export const createUploadUrl = (
    
 ) => {
      
      
      return customInstance<MapUrlResponse>(
      {url: `/ba-map/`, method: 'post'
    },
      );
    }
  


export const getCreateUploadUrlMutationOptions = <TError = ErrorType<_ErrorModel>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadUrl>>, TError,TVariables, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createUploadUrl>>, TError,TVariables, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUploadUrl>>, TVariables> = () => {
          

          return  createUploadUrl()
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof createUploadUrl>>>
    
    export type CreateUploadUrlMutationError = ErrorType<_ErrorModel>

    /**
 * @summary Create Upload Url
 */
export const useCreateUploadUrl = <TError = ErrorType<_ErrorModel>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUploadUrl>>, TError,TVariables, TContext>, }
) => {

      const mutationOptions = getCreateUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Requests
 */
export const getRequests = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<CommissionOverrideRequest[]>(
      {url: `/commission-override/requests`, method: 'get', signal
    },
      );
    }
  

export const getGetRequestsQueryKey = () => {
    
    return [`/commission-override/requests`] as const;
    }

    
export const getGetRequestsQueryOptions = <TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRequestsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRequests>>> = ({ signal }) => getRequests(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRequestsQueryResult = NonNullable<Awaited<ReturnType<typeof getRequests>>>
export type GetRequestsQueryError = ErrorType<_ErrorModel>

/**
 * @summary Get Requests
 */
export const useGetRequests = <TData = Awaited<ReturnType<typeof getRequests>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRequests>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRequestsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Request
 */
export const createRequest = (
    createCommissionOverrideRequestReq: CreateCommissionOverrideRequestReq,
 ) => {
      
      
      return customInstance<CommissionOverrideRequest>(
      {url: `/commission-override/requests`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCommissionOverrideRequestReq
    },
      );
    }
  


export const getCreateRequestMutationOptions = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequest>>, TError,{data: CreateCommissionOverrideRequestReq}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createRequest>>, TError,{data: CreateCommissionOverrideRequestReq}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRequest>>, {data: CreateCommissionOverrideRequestReq}> = (props) => {
          const {data} = props ?? {};

          return  createRequest(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateRequestMutationResult = NonNullable<Awaited<ReturnType<typeof createRequest>>>
    export type CreateRequestMutationBody = CreateCommissionOverrideRequestReq
    export type CreateRequestMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Request
 */
export const useCreateRequest = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRequest>>, TError,{data: CreateCommissionOverrideRequestReq}, TContext>, }
) => {

      const mutationOptions = getCreateRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Equipment Classes
 */
export const getEquipmentClasses = (
    params: GetEquipmentClassesParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<EquipmentClass[]>(
      {url: `/commission-override/equipment-classes`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetEquipmentClassesQueryKey = (params: GetEquipmentClassesParams,) => {
    
    return [`/commission-override/equipment-classes`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEquipmentClassesQueryOptions = <TData = Awaited<ReturnType<typeof getEquipmentClasses>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params: GetEquipmentClassesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentClasses>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEquipmentClassesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEquipmentClasses>>> = ({ signal }) => getEquipmentClasses(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEquipmentClasses>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEquipmentClassesQueryResult = NonNullable<Awaited<ReturnType<typeof getEquipmentClasses>>>
export type GetEquipmentClassesQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Equipment Classes
 */
export const useGetEquipmentClasses = <TData = Awaited<ReturnType<typeof getEquipmentClasses>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params: GetEquipmentClassesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentClasses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEquipmentClassesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Upload Commission Override Req Files
 */
export const uploadCommissionOverrideReqFiles = (
    commissionOverrideRequestId: number,
    bodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost: BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost,
 ) => {
      
      const formData = new FormData();
bodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost.files.forEach(value => formData.append('files', value));

      return customInstance<CommissionOverrideRequest>(
      {url: `/commission-override/requests/${commissionOverrideRequestId}/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getUploadCommissionOverrideReqFilesMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadCommissionOverrideReqFiles>>, TError,{commissionOverrideRequestId: number;data: BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadCommissionOverrideReqFiles>>, TError,{commissionOverrideRequestId: number;data: BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadCommissionOverrideReqFiles>>, {commissionOverrideRequestId: number;data: BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost}> = (props) => {
          const {commissionOverrideRequestId,data} = props ?? {};

          return  uploadCommissionOverrideReqFiles(commissionOverrideRequestId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadCommissionOverrideReqFilesMutationResult = NonNullable<Awaited<ReturnType<typeof uploadCommissionOverrideReqFiles>>>
    export type UploadCommissionOverrideReqFilesMutationBody = BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost
    export type UploadCommissionOverrideReqFilesMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Upload Commission Override Req Files
 */
export const useUploadCommissionOverrideReqFiles = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadCommissionOverrideReqFiles>>, TError,{commissionOverrideRequestId: number;data: BodyUploadCommissionOverrideReqFilesCommissionOverrideRequestsCommissionOverrideRequestIdUploadPost}, TContext>, }
) => {

      const mutationOptions = getUploadCommissionOverrideReqFilesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Request
 */
export const updateRequest = (
    requestId: number,
    updateCommissionOverrideRequestReq: UpdateCommissionOverrideRequestReq,
 ) => {
      
      
      return customInstance<CommissionOverrideRequest>(
      {url: `/commission-override/requests/${requestId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateCommissionOverrideRequestReq
    },
      );
    }
  


export const getUpdateRequestMutationOptions = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRequest>>, TError,{requestId: number;data: UpdateCommissionOverrideRequestReq}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateRequest>>, TError,{requestId: number;data: UpdateCommissionOverrideRequestReq}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRequest>>, {requestId: number;data: UpdateCommissionOverrideRequestReq}> = (props) => {
          const {requestId,data} = props ?? {};

          return  updateRequest(requestId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateRequestMutationResult = NonNullable<Awaited<ReturnType<typeof updateRequest>>>
    export type UpdateRequestMutationBody = UpdateCommissionOverrideRequestReq
    export type UpdateRequestMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Request
 */
export const useUpdateRequest = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRequest>>, TError,{requestId: number;data: UpdateCommissionOverrideRequestReq}, TContext>, }
) => {

      const mutationOptions = getUpdateRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Request
 */
export const deleteRequest = (
    requestId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/commission-override/requests/${requestId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteRequestMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRequest>>, TError,{requestId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteRequest>>, TError,{requestId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRequest>>, {requestId: number}> = (props) => {
          const {requestId} = props ?? {};

          return  deleteRequest(requestId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteRequestMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRequest>>>
    
    export type DeleteRequestMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Request
 */
export const useDeleteRequest = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRequest>>, TError,{requestId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Competitor Quote
 */
export const createCompetitorQuote = (
    createCompetitorQuoteRequest: CreateCompetitorQuoteRequest,
 ) => {
      
      
      return customInstance<CompetitorQuote>(
      {url: `/competitor-quotes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCompetitorQuoteRequest
    },
      );
    }
  


export const getCreateCompetitorQuoteMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuote>>, TError,{data: CreateCompetitorQuoteRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuote>>, TError,{data: CreateCompetitorQuoteRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompetitorQuote>>, {data: CreateCompetitorQuoteRequest}> = (props) => {
          const {data} = props ?? {};

          return  createCompetitorQuote(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCompetitorQuoteMutationResult = NonNullable<Awaited<ReturnType<typeof createCompetitorQuote>>>
    export type CreateCompetitorQuoteMutationBody = CreateCompetitorQuoteRequest
    export type CreateCompetitorQuoteMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Competitor Quote
 */
export const useCreateCompetitorQuote = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuote>>, TError,{data: CreateCompetitorQuoteRequest}, TContext>, }
) => {

      const mutationOptions = getCreateCompetitorQuoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Competitor Quotes
 */
export const queryCompetitorQuotes = (
    params?: QueryCompetitorQuotesParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompetitorQuote[]>(
      {url: `/competitor-quotes`, method: 'get',
        params, signal
    },
      );
    }
  

export const getQueryCompetitorQuotesQueryKey = (params?: QueryCompetitorQuotesParams,) => {
    
    return [`/competitor-quotes`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryCompetitorQuotesQueryOptions = <TData = Awaited<ReturnType<typeof queryCompetitorQuotes>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: QueryCompetitorQuotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorQuotes>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryCompetitorQuotesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryCompetitorQuotes>>> = ({ signal }) => queryCompetitorQuotes(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorQuotes>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryCompetitorQuotesQueryResult = NonNullable<Awaited<ReturnType<typeof queryCompetitorQuotes>>>
export type QueryCompetitorQuotesQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Query Competitor Quotes
 */
export const useQueryCompetitorQuotes = <TData = Awaited<ReturnType<typeof queryCompetitorQuotes>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: QueryCompetitorQuotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorQuotes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryCompetitorQuotesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Competitor Class
 */
export const createCompetitorClass = (
    createCompetitorClassRequest: CreateCompetitorClassRequest,
 ) => {
      
      
      return customInstance<CompetitorClass>(
      {url: `/competitor-classes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCompetitorClassRequest
    },
      );
    }
  


export const getCreateCompetitorClassMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorClass>>, TError,{data: CreateCompetitorClassRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCompetitorClass>>, TError,{data: CreateCompetitorClassRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompetitorClass>>, {data: CreateCompetitorClassRequest}> = (props) => {
          const {data} = props ?? {};

          return  createCompetitorClass(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCompetitorClassMutationResult = NonNullable<Awaited<ReturnType<typeof createCompetitorClass>>>
    export type CreateCompetitorClassMutationBody = CreateCompetitorClassRequest
    export type CreateCompetitorClassMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Competitor Class
 */
export const useCreateCompetitorClass = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorClass>>, TError,{data: CreateCompetitorClassRequest}, TContext>, }
) => {

      const mutationOptions = getCreateCompetitorClassMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Competitor Class
 */
export const queryCompetitorClass = (
    params: QueryCompetitorClassParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompetitorClass[]>(
      {url: `/competitor-classes`, method: 'get',
        params, signal
    },
      );
    }
  

export const getQueryCompetitorClassQueryKey = (params: QueryCompetitorClassParams,) => {
    
    return [`/competitor-classes`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryCompetitorClassQueryOptions = <TData = Awaited<ReturnType<typeof queryCompetitorClass>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params: QueryCompetitorClassParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorClass>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryCompetitorClassQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryCompetitorClass>>> = ({ signal }) => queryCompetitorClass(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorClass>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryCompetitorClassQueryResult = NonNullable<Awaited<ReturnType<typeof queryCompetitorClass>>>
export type QueryCompetitorClassQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Query Competitor Class
 */
export const useQueryCompetitorClass = <TData = Awaited<ReturnType<typeof queryCompetitorClass>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params: QueryCompetitorClassParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitorClass>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryCompetitorClassQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Competitor Quote
 */
export const deleteCompetitorQuote = (
    competitorQuoteId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/competitor-quotes/${competitorQuoteId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteCompetitorQuoteMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompetitorQuote>>, TError,{competitorQuoteId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteCompetitorQuote>>, TError,{competitorQuoteId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCompetitorQuote>>, {competitorQuoteId: number}> = (props) => {
          const {competitorQuoteId} = props ?? {};

          return  deleteCompetitorQuote(competitorQuoteId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCompetitorQuoteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCompetitorQuote>>>
    
    export type DeleteCompetitorQuoteMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Competitor Quote
 */
export const useDeleteCompetitorQuote = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCompetitorQuote>>, TError,{competitorQuoteId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteCompetitorQuoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Create Competitor
 */
export const createCompetitor = (
    createCompetitorRequest: CreateCompetitorRequest,
 ) => {
      
      
      return customInstance<Competitor>(
      {url: `/competitors`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCompetitorRequest
    },
      );
    }
  


export const getCreateCompetitorMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitor>>, TError,{data: CreateCompetitorRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCompetitor>>, TError,{data: CreateCompetitorRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompetitor>>, {data: CreateCompetitorRequest}> = (props) => {
          const {data} = props ?? {};

          return  createCompetitor(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCompetitorMutationResult = NonNullable<Awaited<ReturnType<typeof createCompetitor>>>
    export type CreateCompetitorMutationBody = CreateCompetitorRequest
    export type CreateCompetitorMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Competitor
 */
export const useCreateCompetitor = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitor>>, TError,{data: CreateCompetitorRequest}, TContext>, }
) => {

      const mutationOptions = getCreateCompetitorMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Competitors
 */
export const queryCompetitors = (
    params: QueryCompetitorsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Competitor[]>(
      {url: `/competitors`, method: 'get',
        params, signal
    },
      );
    }
  

export const getQueryCompetitorsQueryKey = (params: QueryCompetitorsParams,) => {
    
    return [`/competitors`, ...(params ? [params]: [])] as const;
    }

    
export const getQueryCompetitorsQueryOptions = <TData = Awaited<ReturnType<typeof queryCompetitors>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params: QueryCompetitorsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitors>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryCompetitorsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryCompetitors>>> = ({ signal }) => queryCompetitors(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryCompetitors>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryCompetitorsQueryResult = NonNullable<Awaited<ReturnType<typeof queryCompetitors>>>
export type QueryCompetitorsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Query Competitors
 */
export const useQueryCompetitors = <TData = Awaited<ReturnType<typeof queryCompetitors>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params: QueryCompetitorsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryCompetitors>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryCompetitorsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Competitor Quote Upload Url
 */
export const createCompetitorQuoteUploadUrl = (
    createSignedUrlRequest: CreateSignedUrlRequest,
 ) => {
      
      
      return customInstance<SignedUrl>(
      {url: `/competitor-quote-upload-url`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSignedUrlRequest
    },
      );
    }
  


export const getCreateCompetitorQuoteUploadUrlMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuoteUploadUrl>>, TError,{data: CreateSignedUrlRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuoteUploadUrl>>, TError,{data: CreateSignedUrlRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCompetitorQuoteUploadUrl>>, {data: CreateSignedUrlRequest}> = (props) => {
          const {data} = props ?? {};

          return  createCompetitorQuoteUploadUrl(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCompetitorQuoteUploadUrlMutationResult = NonNullable<Awaited<ReturnType<typeof createCompetitorQuoteUploadUrl>>>
    export type CreateCompetitorQuoteUploadUrlMutationBody = CreateSignedUrlRequest
    export type CreateCompetitorQuoteUploadUrlMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Competitor Quote Upload Url
 */
export const useCreateCompetitorQuoteUploadUrl = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCompetitorQuoteUploadUrl>>, TError,{data: CreateSignedUrlRequest}, TContext>, }
) => {

      const mutationOptions = getCreateCompetitorQuoteUploadUrlMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Query Doc Types
 */
export const queryDocTypes = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<DocType[]>(
      {url: `/doc-types`, method: 'get', signal
    },
      );
    }
  

export const getQueryDocTypesQueryKey = () => {
    
    return [`/doc-types`] as const;
    }

    
export const getQueryDocTypesQueryOptions = <TData = Awaited<ReturnType<typeof queryDocTypes>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryDocTypes>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQueryDocTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof queryDocTypes>>> = ({ signal }) => queryDocTypes(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof queryDocTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type QueryDocTypesQueryResult = NonNullable<Awaited<ReturnType<typeof queryDocTypes>>>
export type QueryDocTypesQueryError = ErrorType<_ErrorModel>

/**
 * @summary Query Doc Types
 */
export const useQueryDocTypes = <TData = Awaited<ReturnType<typeof queryDocTypes>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof queryDocTypes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getQueryDocTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Es Quote
 */
export const createEsQuote = (
    createESQuoteRequest: CreateESQuoteRequest,
 ) => {
      
      
      return customInstance<ESQuote>(
      {url: `/es-quotes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createESQuoteRequest
    },
      );
    }
  


export const getCreateEsQuoteMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEsQuote>>, TError,{data: CreateESQuoteRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createEsQuote>>, TError,{data: CreateESQuoteRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEsQuote>>, {data: CreateESQuoteRequest}> = (props) => {
          const {data} = props ?? {};

          return  createEsQuote(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateEsQuoteMutationResult = NonNullable<Awaited<ReturnType<typeof createEsQuote>>>
    export type CreateEsQuoteMutationBody = CreateESQuoteRequest
    export type CreateEsQuoteMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Es Quote
 */
export const useCreateEsQuote = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEsQuote>>, TError,{data: CreateESQuoteRequest}, TContext>, }
) => {

      const mutationOptions = getCreateEsQuoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Es Quotes
 */
export const getEsQuotes = (
    competitorQuoteId: unknown,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ESQuote[]>(
      {url: `/competitor-quotes/${competitorQuoteId}/es-quotes`, method: 'get', signal
    },
      );
    }
  

export const getGetEsQuotesQueryKey = (competitorQuoteId: unknown,) => {
    
    return [`/competitor-quotes/${competitorQuoteId}/es-quotes`] as const;
    }

    
export const getGetEsQuotesQueryOptions = <TData = Awaited<ReturnType<typeof getEsQuotes>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(competitorQuoteId: unknown, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEsQuotes>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEsQuotesQueryKey(competitorQuoteId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEsQuotes>>> = ({ signal }) => getEsQuotes(competitorQuoteId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(competitorQuoteId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEsQuotes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEsQuotesQueryResult = NonNullable<Awaited<ReturnType<typeof getEsQuotes>>>
export type GetEsQuotesQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Es Quotes
 */
export const useGetEsQuotes = <TData = Awaited<ReturnType<typeof getEsQuotes>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 competitorQuoteId: unknown, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEsQuotes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEsQuotesQueryOptions(competitorQuoteId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Upload File
 */
export const uploadFile = (
    bodyUploadFileCpeOrdersPost: BodyUploadFileCpeOrdersPost,
 ) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadFileCpeOrdersPost.file)

      return customInstance<PurchaseOrder>(
      {url: `/cpe-orders`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getUploadFileMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFile>>, TError,{data: BodyUploadFileCpeOrdersPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadFile>>, TError,{data: BodyUploadFileCpeOrdersPost}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadFile>>, {data: BodyUploadFileCpeOrdersPost}> = (props) => {
          const {data} = props ?? {};

          return  uploadFile(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadFileMutationResult = NonNullable<Awaited<ReturnType<typeof uploadFile>>>
    export type UploadFileMutationBody = BodyUploadFileCpeOrdersPost
    export type UploadFileMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Upload File
 */
export const useUploadFile = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadFile>>, TError,{data: BodyUploadFileCpeOrdersPost}, TContext>, }
) => {

      const mutationOptions = getUploadFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * creates a discount
 * @summary Create Discount
 */
export const createDiscount = (
    createDiscountRequest: CreateDiscountRequest,
 ) => {
      
      
      return customInstance<Discount>(
      {url: `/discounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createDiscountRequest
    },
      );
    }
  


export const getCreateDiscountMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDiscount>>, TError,{data: CreateDiscountRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createDiscount>>, TError,{data: CreateDiscountRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDiscount>>, {data: CreateDiscountRequest}> = (props) => {
          const {data} = props ?? {};

          return  createDiscount(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof createDiscount>>>
    export type CreateDiscountMutationBody = CreateDiscountRequest
    export type CreateDiscountMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Discount
 */
export const useCreateDiscount = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDiscount>>, TError,{data: CreateDiscountRequest}, TContext>, }
) => {

      const mutationOptions = getCreateDiscountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Discounts
 */
export const getDiscounts = (
    params: GetDiscountsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Discount[]>(
      {url: `/discounts`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetDiscountsQueryKey = (params: GetDiscountsParams,) => {
    
    return [`/discounts`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDiscountsQueryOptions = <TData = Awaited<ReturnType<typeof getDiscounts>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params: GetDiscountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDiscounts>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDiscountsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDiscounts>>> = ({ signal }) => getDiscounts(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDiscounts>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDiscountsQueryResult = NonNullable<Awaited<ReturnType<typeof getDiscounts>>>
export type GetDiscountsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Discounts
 */
export const useGetDiscounts = <TData = Awaited<ReturnType<typeof getDiscounts>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params: GetDiscountsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDiscounts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDiscountsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * updates a discount
 * @summary Update Discount
 */
export const updateDiscount = (
    discountId: number,
    updateDiscountRequest: UpdateDiscountRequest,
 ) => {
      
      
      return customInstance<Discount>(
      {url: `/discounts/${discountId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateDiscountRequest
    },
      );
    }
  


export const getUpdateDiscountMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDiscount>>, TError,{discountId: number;data: UpdateDiscountRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateDiscount>>, TError,{discountId: number;data: UpdateDiscountRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDiscount>>, {discountId: number;data: UpdateDiscountRequest}> = (props) => {
          const {discountId,data} = props ?? {};

          return  updateDiscount(discountId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateDiscount>>>
    export type UpdateDiscountMutationBody = UpdateDiscountRequest
    export type UpdateDiscountMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Discount
 */
export const useUpdateDiscount = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDiscount>>, TError,{discountId: number;data: UpdateDiscountRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateDiscountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * delete a discount
 * @summary Delete Discount
 */
export const deleteDiscount = (
    discountId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/discounts/${discountId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteDiscountMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDiscount>>, TError,{discountId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteDiscount>>, TError,{discountId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDiscount>>, {discountId: number}> = (props) => {
          const {discountId} = props ?? {};

          return  deleteDiscount(discountId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDiscount>>>
    
    export type DeleteDiscountMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Discount
 */
export const useDeleteDiscount = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDiscount>>, TError,{discountId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteDiscountMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Returns all departments
 * @summary Get Departments
 */
export const getDepartments = (
    params?: GetDepartmentsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Department[]>(
      {url: `/expense-tracker/departments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetDepartmentsQueryKey = (params?: GetDepartmentsParams,) => {
    
    return [`/expense-tracker/departments/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDepartmentsQueryOptions = <TData = Awaited<ReturnType<typeof getDepartments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetDepartmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDepartments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDepartmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepartments>>> = ({ signal }) => getDepartments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDepartments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDepartmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getDepartments>>>
export type GetDepartmentsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Departments
 */
export const useGetDepartments = <TData = Awaited<ReturnType<typeof getDepartments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetDepartmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDepartments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDepartmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Creates a new department and returns it
 * @summary Create Department
 */
export const createDepartment = (
    createDepartmentRequest: CreateDepartmentRequest,
 ) => {
      
      
      return customInstance<Department>(
      {url: `/expense-tracker/departments/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createDepartmentRequest
    },
      );
    }
  


export const getCreateDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDepartment>>, TError,{data: CreateDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createDepartment>>, TError,{data: CreateDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDepartment>>, {data: CreateDepartmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createDepartment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof createDepartment>>>
    export type CreateDepartmentMutationBody = CreateDepartmentRequest
    export type CreateDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Department
 */
export const useCreateDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDepartment>>, TError,{data: CreateDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getCreateDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single department
 * @summary Get Department
 */
export const getDepartment = (
    departmentId: number,
    params?: GetDepartmentParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetDepartment200>(
      {url: `/expense-tracker/departments/${departmentId}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetDepartmentQueryKey = (departmentId: number,
    params?: GetDepartmentParams,) => {
    
    return [`/expense-tracker/departments/${departmentId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetDepartmentQueryOptions = <TData = Awaited<ReturnType<typeof getDepartment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(departmentId: number,
    params?: GetDepartmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDepartment>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDepartmentQueryKey(departmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDepartment>>> = ({ signal }) => getDepartment(departmentId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(departmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDepartment>>, TError, TData> & { queryKey: QueryKey }
}

export type GetDepartmentQueryResult = NonNullable<Awaited<ReturnType<typeof getDepartment>>>
export type GetDepartmentQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Department
 */
export const useGetDepartment = <TData = Awaited<ReturnType<typeof getDepartment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 departmentId: number,
    params?: GetDepartmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDepartment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDepartmentQueryOptions(departmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Updates a department and returns it
 * @summary Update Department
 */
export const updateDepartment = (
    departmentId: number,
    updateDepartmentRequest: UpdateDepartmentRequest,
 ) => {
      
      
      return customInstance<Department>(
      {url: `/expense-tracker/departments/${departmentId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateDepartmentRequest
    },
      );
    }
  


export const getUpdateDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDepartment>>, TError,{departmentId: number;data: UpdateDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateDepartment>>, TError,{departmentId: number;data: UpdateDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDepartment>>, {departmentId: number;data: UpdateDepartmentRequest}> = (props) => {
          const {departmentId,data} = props ?? {};

          return  updateDepartment(departmentId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateDepartment>>>
    export type UpdateDepartmentMutationBody = UpdateDepartmentRequest
    export type UpdateDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Department
 */
export const useUpdateDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDepartment>>, TError,{departmentId: number;data: UpdateDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin Only) Returns all expense lines
 * @summary Get Expense Lines
 */
export const getExpenseLines = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ExpenseLine[]>(
      {url: `/expense-tracker/expense-lines/`, method: 'get', signal
    },
      );
    }
  

export const getGetExpenseLinesQueryKey = () => {
    
    return [`/expense-tracker/expense-lines/`] as const;
    }

    
export const getGetExpenseLinesQueryOptions = <TData = Awaited<ReturnType<typeof getExpenseLines>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseLines>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExpenseLinesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExpenseLines>>> = ({ signal }) => getExpenseLines(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExpenseLines>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExpenseLinesQueryResult = NonNullable<Awaited<ReturnType<typeof getExpenseLines>>>
export type GetExpenseLinesQueryError = ErrorType<_ErrorModel>

/**
 * @summary Get Expense Lines
 */
export const useGetExpenseLines = <TData = Awaited<ReturnType<typeof getExpenseLines>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseLines>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExpenseLinesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Creates a new expense line and returns it
 * @summary Create Expense Line
 */
export const createExpenseLine = (
    createExpenseLineRequest: CreateExpenseLineRequest,
 ) => {
      
      
      return customInstance<ExpenseLine>(
      {url: `/expense-tracker/expense-lines/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createExpenseLineRequest
    },
      );
    }
  


export const getCreateExpenseLineMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExpenseLine>>, TError,{data: CreateExpenseLineRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createExpenseLine>>, TError,{data: CreateExpenseLineRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExpenseLine>>, {data: CreateExpenseLineRequest}> = (props) => {
          const {data} = props ?? {};

          return  createExpenseLine(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateExpenseLineMutationResult = NonNullable<Awaited<ReturnType<typeof createExpenseLine>>>
    export type CreateExpenseLineMutationBody = CreateExpenseLineRequest
    export type CreateExpenseLineMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Expense Line
 */
export const useCreateExpenseLine = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExpenseLine>>, TError,{data: CreateExpenseLineRequest}, TContext>, }
) => {

      const mutationOptions = getCreateExpenseLineMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single expense line
 * @summary Get Expense Line
 */
export const getExpenseLine = (
    expenseLineId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ExpenseLine>(
      {url: `/expense-tracker/expense-lines/${expenseLineId}`, method: 'get', signal
    },
      );
    }
  

export const getGetExpenseLineQueryKey = (expenseLineId: number,) => {
    
    return [`/expense-tracker/expense-lines/${expenseLineId}`] as const;
    }

    
export const getGetExpenseLineQueryOptions = <TData = Awaited<ReturnType<typeof getExpenseLine>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(expenseLineId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseLine>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExpenseLineQueryKey(expenseLineId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExpenseLine>>> = ({ signal }) => getExpenseLine(expenseLineId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(expenseLineId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExpenseLine>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExpenseLineQueryResult = NonNullable<Awaited<ReturnType<typeof getExpenseLine>>>
export type GetExpenseLineQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Expense Line
 */
export const useGetExpenseLine = <TData = Awaited<ReturnType<typeof getExpenseLine>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 expenseLineId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseLine>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExpenseLineQueryOptions(expenseLineId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Updates an expense line and returns it
 * @summary Update Expense Line
 */
export const updateExpenseLine = (
    expenseLineId: number,
    updateExpenseLineRequest: UpdateExpenseLineRequest,
 ) => {
      
      
      return customInstance<ExpenseLine>(
      {url: `/expense-tracker/expense-lines/${expenseLineId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateExpenseLineRequest
    },
      );
    }
  


export const getUpdateExpenseLineMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExpenseLine>>, TError,{expenseLineId: number;data: UpdateExpenseLineRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateExpenseLine>>, TError,{expenseLineId: number;data: UpdateExpenseLineRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateExpenseLine>>, {expenseLineId: number;data: UpdateExpenseLineRequest}> = (props) => {
          const {expenseLineId,data} = props ?? {};

          return  updateExpenseLine(expenseLineId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateExpenseLineMutationResult = NonNullable<Awaited<ReturnType<typeof updateExpenseLine>>>
    export type UpdateExpenseLineMutationBody = UpdateExpenseLineRequest
    export type UpdateExpenseLineMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Expense Line
 */
export const useUpdateExpenseLine = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExpenseLine>>, TError,{expenseLineId: number;data: UpdateExpenseLineRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateExpenseLineMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Returns all sub-departments
 * @summary Get Sub Departments
 */
export const getSubDepartments = (
    params?: GetSubDepartmentsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SubDepartment[]>(
      {url: `/expense-tracker/sub-departments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetSubDepartmentsQueryKey = (params?: GetSubDepartmentsParams,) => {
    
    return [`/expense-tracker/sub-departments/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSubDepartmentsQueryOptions = <TData = Awaited<ReturnType<typeof getSubDepartments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetSubDepartmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubDepartments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubDepartmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubDepartments>>> = ({ signal }) => getSubDepartments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSubDepartments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubDepartmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getSubDepartments>>>
export type GetSubDepartmentsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Sub Departments
 */
export const useGetSubDepartments = <TData = Awaited<ReturnType<typeof getSubDepartments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetSubDepartmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubDepartments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubDepartmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Creates a new sub-department
 * @summary Create Sub Department
 */
export const createSubDepartment = (
    createSubDepartmentRequest: CreateSubDepartmentRequest,
 ) => {
      
      
      return customInstance<SubDepartment>(
      {url: `/expense-tracker/sub-departments/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubDepartmentRequest
    },
      );
    }
  


export const getCreateSubDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubDepartment>>, TError,{data: CreateSubDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createSubDepartment>>, TError,{data: CreateSubDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSubDepartment>>, {data: CreateSubDepartmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createSubDepartment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateSubDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof createSubDepartment>>>
    export type CreateSubDepartmentMutationBody = CreateSubDepartmentRequest
    export type CreateSubDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Sub Department
 */
export const useCreateSubDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubDepartment>>, TError,{data: CreateSubDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getCreateSubDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single sub-department
 * @summary Get Sub Department
 */
export const getSubDepartment = (
    subDepartmentId: number,
    params?: GetSubDepartmentParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SubDepartment>(
      {url: `/expense-tracker/sub-departments/${subDepartmentId}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetSubDepartmentQueryKey = (subDepartmentId: number,
    params?: GetSubDepartmentParams,) => {
    
    return [`/expense-tracker/sub-departments/${subDepartmentId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSubDepartmentQueryOptions = <TData = Awaited<ReturnType<typeof getSubDepartment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(subDepartmentId: number,
    params?: GetSubDepartmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubDepartment>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSubDepartmentQueryKey(subDepartmentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubDepartment>>> = ({ signal }) => getSubDepartment(subDepartmentId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(subDepartmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSubDepartment>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSubDepartmentQueryResult = NonNullable<Awaited<ReturnType<typeof getSubDepartment>>>
export type GetSubDepartmentQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Sub Department
 */
export const useGetSubDepartment = <TData = Awaited<ReturnType<typeof getSubDepartment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 subDepartmentId: number,
    params?: GetSubDepartmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSubDepartment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSubDepartmentQueryOptions(subDepartmentId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Updates a sub-department
 * @summary Update Sub Department
 */
export const updateSubDepartment = (
    subDepartmentId: number,
    updateSubDepartmentRequest: UpdateSubDepartmentRequest,
 ) => {
      
      
      return customInstance<SubDepartment>(
      {url: `/expense-tracker/sub-departments/${subDepartmentId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateSubDepartmentRequest
    },
      );
    }
  


export const getUpdateSubDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSubDepartment>>, TError,{subDepartmentId: number;data: UpdateSubDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateSubDepartment>>, TError,{subDepartmentId: number;data: UpdateSubDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSubDepartment>>, {subDepartmentId: number;data: UpdateSubDepartmentRequest}> = (props) => {
          const {subDepartmentId,data} = props ?? {};

          return  updateSubDepartment(subDepartmentId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateSubDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateSubDepartment>>>
    export type UpdateSubDepartmentMutationBody = UpdateSubDepartmentRequest
    export type UpdateSubDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Sub Department
 */
export const useUpdateSubDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSubDepartment>>, TError,{subDepartmentId: number;data: UpdateSubDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateSubDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Checks if user is an Expense Tracker admin
 * @summary Get Is Expense Admin
 */
export const getIsExpenseAdmin = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<boolean>(
      {url: `/expense-tracker/expense-users/is-admin`, method: 'get', signal
    },
      );
    }
  

export const getGetIsExpenseAdminQueryKey = () => {
    
    return [`/expense-tracker/expense-users/is-admin`] as const;
    }

    
export const getGetIsExpenseAdminQueryOptions = <TData = Awaited<ReturnType<typeof getIsExpenseAdmin>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIsExpenseAdmin>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIsExpenseAdminQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIsExpenseAdmin>>> = ({ signal }) => getIsExpenseAdmin(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getIsExpenseAdmin>>, TError, TData> & { queryKey: QueryKey }
}

export type GetIsExpenseAdminQueryResult = NonNullable<Awaited<ReturnType<typeof getIsExpenseAdmin>>>
export type GetIsExpenseAdminQueryError = ErrorType<unknown>

/**
 * @summary Get Is Expense Admin
 */
export const useGetIsExpenseAdmin = <TData = Awaited<ReturnType<typeof getIsExpenseAdmin>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIsExpenseAdmin>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIsExpenseAdminQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin Only) Returns all expense users
 * @summary Get Expense Users
 */
export const getExpenseUsers = (
    params?: GetExpenseUsersParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ExpenseUser[]>(
      {url: `/expense-tracker/expense-users/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetExpenseUsersQueryKey = (params?: GetExpenseUsersParams,) => {
    
    return [`/expense-tracker/expense-users/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetExpenseUsersQueryOptions = <TData = Awaited<ReturnType<typeof getExpenseUsers>>, TError = ErrorType<HTTPValidationError>>(params?: GetExpenseUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseUsers>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExpenseUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExpenseUsers>>> = ({ signal }) => getExpenseUsers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExpenseUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExpenseUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getExpenseUsers>>>
export type GetExpenseUsersQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Expense Users
 */
export const useGetExpenseUsers = <TData = Awaited<ReturnType<typeof getExpenseUsers>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetExpenseUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExpenseUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Returns a single expense user

Non-admins can only make requests for their own user
 * @summary Get Expense User
 */
export const getExpenseUser = (
    expenseUserId: number,
    params?: GetExpenseUserParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<GetExpenseUser200>(
      {url: `/expense-tracker/expense-users/${expenseUserId}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetExpenseUserQueryKey = (expenseUserId: number,
    params?: GetExpenseUserParams,) => {
    
    return [`/expense-tracker/expense-users/${expenseUserId}`, ...(params ? [params]: [])] as const;
    }

    
export const getGetExpenseUserQueryOptions = <TData = Awaited<ReturnType<typeof getExpenseUser>>, TError = ErrorType<HTTPValidationError>>(expenseUserId: number,
    params?: GetExpenseUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseUser>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExpenseUserQueryKey(expenseUserId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExpenseUser>>> = ({ signal }) => getExpenseUser(expenseUserId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(expenseUserId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExpenseUser>>, TError, TData> & { queryKey: QueryKey }
}

export type GetExpenseUserQueryResult = NonNullable<Awaited<ReturnType<typeof getExpenseUser>>>
export type GetExpenseUserQueryError = ErrorType<HTTPValidationError>

/**
 * @summary Get Expense User
 */
export const useGetExpenseUser = <TData = Awaited<ReturnType<typeof getExpenseUser>>, TError = ErrorType<HTTPValidationError>>(
 expenseUserId: number,
    params?: GetExpenseUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpenseUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExpenseUserQueryOptions(expenseUserId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Changes market access
 * @summary Update Market Access
 */
export const updateMarketAccess = (
    expenseUserId: number,
    updateMarketAccessRequest: UpdateMarketAccessRequest,
 ) => {
      
      
      return customInstance<ExpenseUser>(
      {url: `/expense-tracker/expense-users/${expenseUserId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateMarketAccessRequest
    },
      );
    }
  


export const getUpdateMarketAccessMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMarketAccess>>, TError,{expenseUserId: number;data: UpdateMarketAccessRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateMarketAccess>>, TError,{expenseUserId: number;data: UpdateMarketAccessRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMarketAccess>>, {expenseUserId: number;data: UpdateMarketAccessRequest}> = (props) => {
          const {expenseUserId,data} = props ?? {};

          return  updateMarketAccess(expenseUserId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateMarketAccessMutationResult = NonNullable<Awaited<ReturnType<typeof updateMarketAccess>>>
    export type UpdateMarketAccessMutationBody = UpdateMarketAccessRequest
    export type UpdateMarketAccessMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Market Access
 */
export const useUpdateMarketAccess = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMarketAccess>>, TError,{expenseUserId: number;data: UpdateMarketAccessRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateMarketAccessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Creates a relationship between a sub-department and expense line
 * @summary Create Sub Department Expense Line
 */
export const createSubDepartmentExpenseLine = (
    createSubDepartmentExpenseLineRequest: CreateSubDepartmentExpenseLineRequest,
 ) => {
      
      
      return customInstance<SubDepartmentExpenseLine>(
      {url: `/expense-tracker/sub-department-expense-lines/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSubDepartmentExpenseLineRequest
    },
      );
    }
  


export const getCreateSubDepartmentExpenseLineMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubDepartmentExpenseLine>>, TError,{data: CreateSubDepartmentExpenseLineRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createSubDepartmentExpenseLine>>, TError,{data: CreateSubDepartmentExpenseLineRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSubDepartmentExpenseLine>>, {data: CreateSubDepartmentExpenseLineRequest}> = (props) => {
          const {data} = props ?? {};

          return  createSubDepartmentExpenseLine(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateSubDepartmentExpenseLineMutationResult = NonNullable<Awaited<ReturnType<typeof createSubDepartmentExpenseLine>>>
    export type CreateSubDepartmentExpenseLineMutationBody = CreateSubDepartmentExpenseLineRequest
    export type CreateSubDepartmentExpenseLineMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Sub Department Expense Line
 */
export const useCreateSubDepartmentExpenseLine = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSubDepartmentExpenseLine>>, TError,{data: CreateSubDepartmentExpenseLineRequest}, TContext>, }
) => {

      const mutationOptions = getCreateSubDepartmentExpenseLineMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Removes the relationship between a sub-department and expense line
 * @summary Delete Sub Department Expense Line
 */
export const deleteSubDepartmentExpenseLine = (
    deleteSubDepartmentExpenseLineRequest: DeleteSubDepartmentExpenseLineRequest,
 ) => {
      
      
      return customInstance<void>(
      {url: `/expense-tracker/sub-department-expense-lines/`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteSubDepartmentExpenseLineRequest
    },
      );
    }
  


export const getDeleteSubDepartmentExpenseLineMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubDepartmentExpenseLine>>, TError,{data: DeleteSubDepartmentExpenseLineRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteSubDepartmentExpenseLine>>, TError,{data: DeleteSubDepartmentExpenseLineRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSubDepartmentExpenseLine>>, {data: DeleteSubDepartmentExpenseLineRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteSubDepartmentExpenseLine(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteSubDepartmentExpenseLineMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSubDepartmentExpenseLine>>>
    export type DeleteSubDepartmentExpenseLineMutationBody = DeleteSubDepartmentExpenseLineRequest
    export type DeleteSubDepartmentExpenseLineMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Sub Department Expense Line
 */
export const useDeleteSubDepartmentExpenseLine = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSubDepartmentExpenseLine>>, TError,{data: DeleteSubDepartmentExpenseLineRequest}, TContext>, }
) => {

      const mutationOptions = getDeleteSubDepartmentExpenseLineMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Creates a relationship between an expense user and sub-department
 * @summary Create Expense User Sub Department
 */
export const createExpenseUserSubDepartment = (
    createExpenseUserSubDepartmentRequest: CreateExpenseUserSubDepartmentRequest,
 ) => {
      
      
      return customInstance<ExpenseUserSubDepartment>(
      {url: `/expense-tracker/expense-user-sub-departments/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createExpenseUserSubDepartmentRequest
    },
      );
    }
  


export const getCreateExpenseUserSubDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExpenseUserSubDepartment>>, TError,{data: CreateExpenseUserSubDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createExpenseUserSubDepartment>>, TError,{data: CreateExpenseUserSubDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExpenseUserSubDepartment>>, {data: CreateExpenseUserSubDepartmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createExpenseUserSubDepartment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateExpenseUserSubDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof createExpenseUserSubDepartment>>>
    export type CreateExpenseUserSubDepartmentMutationBody = CreateExpenseUserSubDepartmentRequest
    export type CreateExpenseUserSubDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Expense User Sub Department
 */
export const useCreateExpenseUserSubDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExpenseUserSubDepartment>>, TError,{data: CreateExpenseUserSubDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getCreateExpenseUserSubDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Removes the relationship between an expense user and sub-department
 * @summary Delete Expense User Sub Department
 */
export const deleteExpenseUserSubDepartment = (
    deleteExpenseUserSubDepartmentRequest: DeleteExpenseUserSubDepartmentRequest,
 ) => {
      
      
      return customInstance<void>(
      {url: `/expense-tracker/expense-user-sub-departments/`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteExpenseUserSubDepartmentRequest
    },
      );
    }
  


export const getDeleteExpenseUserSubDepartmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExpenseUserSubDepartment>>, TError,{data: DeleteExpenseUserSubDepartmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteExpenseUserSubDepartment>>, TError,{data: DeleteExpenseUserSubDepartmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExpenseUserSubDepartment>>, {data: DeleteExpenseUserSubDepartmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  deleteExpenseUserSubDepartment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteExpenseUserSubDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExpenseUserSubDepartment>>>
    export type DeleteExpenseUserSubDepartmentMutationBody = DeleteExpenseUserSubDepartmentRequest
    export type DeleteExpenseUserSubDepartmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Expense User Sub Department
 */
export const useDeleteExpenseUserSubDepartment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExpenseUserSubDepartment>>, TError,{data: DeleteExpenseUserSubDepartmentRequest}, TContext>, }
) => {

      const mutationOptions = getDeleteExpenseUserSubDepartmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Gets users from the esdb users table matching the specified filters
 * @summary Get Esdb Users
 */
export const getEsdbUsers = (
    params?: GetEsdbUsersParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<EsdbUser[]>(
      {url: `/expense-tracker/esdb-users/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetEsdbUsersQueryKey = (params?: GetEsdbUsersParams,) => {
    
    return [`/expense-tracker/esdb-users/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEsdbUsersQueryOptions = <TData = Awaited<ReturnType<typeof getEsdbUsers>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetEsdbUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEsdbUsers>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEsdbUsersQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEsdbUsers>>> = ({ signal }) => getEsdbUsers(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEsdbUsers>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEsdbUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getEsdbUsers>>>
export type GetEsdbUsersQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Esdb Users
 */
export const useGetEsdbUsers = <TData = Awaited<ReturnType<typeof getEsdbUsers>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetEsdbUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEsdbUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEsdbUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Request Image
 */
export const requestImage = (
    imageId: string,
    params?: RequestImageParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<RequestInvoicesImageUrlResponse>(
      {url: `/invoices/request-image/${imageId}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getRequestImageQueryKey = (imageId: string,
    params?: RequestImageParams,) => {
    
    return [`/invoices/request-image/${imageId}`, ...(params ? [params]: [])] as const;
    }

    
export const getRequestImageQueryOptions = <TData = Awaited<ReturnType<typeof requestImage>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(imageId: string,
    params?: RequestImageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof requestImage>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRequestImageQueryKey(imageId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof requestImage>>> = ({ signal }) => requestImage(imageId,params, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(imageId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof requestImage>>, TError, TData> & { queryKey: QueryKey }
}

export type RequestImageQueryResult = NonNullable<Awaited<ReturnType<typeof requestImage>>>
export type RequestImageQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Request Image
 */
export const useRequestImage = <TData = Awaited<ReturnType<typeof requestImage>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 imageId: string,
    params?: RequestImageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof requestImage>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRequestImageQueryOptions(imageId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Download
 */
export const download = (
    params?: DownloadParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<number[]>(
      {url: `/invoices/download`, method: 'get',
        params, signal
    },
      );
    }
  

export const getDownloadQueryKey = (params?: DownloadParams,) => {
    
    return [`/invoices/download`, ...(params ? [params]: [])] as const;
    }

    
export const getDownloadQueryOptions = <TData = Awaited<ReturnType<typeof download>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: DownloadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof download>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof download>>> = ({ signal }) => download(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof download>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadQueryResult = NonNullable<Awaited<ReturnType<typeof download>>>
export type DownloadQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Download
 */
export const useDownload = <TData = Awaited<ReturnType<typeof download>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: DownloadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof download>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Batch Download
 */
export const batchDownload = (
    downloadInvoicesRequest: DownloadInvoicesRequest,
 ) => {
      
      
      return customInstance<DownloadInvoicesResponse>(
      {url: `/invoices/download`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: downloadInvoicesRequest
    },
      );
    }
  


export const getBatchDownloadMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDownload>>, TError,{data: DownloadInvoicesRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof batchDownload>>, TError,{data: DownloadInvoicesRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchDownload>>, {data: DownloadInvoicesRequest}> = (props) => {
          const {data} = props ?? {};

          return  batchDownload(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BatchDownloadMutationResult = NonNullable<Awaited<ReturnType<typeof batchDownload>>>
    export type BatchDownloadMutationBody = DownloadInvoicesRequest
    export type BatchDownloadMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Batch Download
 */
export const useBatchDownload = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchDownload>>, TError,{data: DownloadInvoicesRequest}, TContext>, }
) => {

      const mutationOptions = getBatchDownloadMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Write Form Data
 */
export const writeFormData = (
    token: string,
    bodyWriteFormDataEtlJotformTokenPost: BodyWriteFormDataEtlJotformTokenPost,
 ) => {
      
      const formUrlEncoded = new URLSearchParams();
formUrlEncoded.append('rawRequest', bodyWriteFormDataEtlJotformTokenPost.rawRequest)
formUrlEncoded.append('formID', bodyWriteFormDataEtlJotformTokenPost.formID)
formUrlEncoded.append('submissionID', bodyWriteFormDataEtlJotformTokenPost.submissionID)

      return customInstance<CreateJotformRequest>(
      {url: `/etl/jotform/${token}`, method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded', },
       data: formUrlEncoded
    },
      );
    }
  


export const getWriteFormDataMutationOptions = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof writeFormData>>, TError,{token: string;data: BodyWriteFormDataEtlJotformTokenPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof writeFormData>>, TError,{token: string;data: BodyWriteFormDataEtlJotformTokenPost}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof writeFormData>>, {token: string;data: BodyWriteFormDataEtlJotformTokenPost}> = (props) => {
          const {token,data} = props ?? {};

          return  writeFormData(token,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type WriteFormDataMutationResult = NonNullable<Awaited<ReturnType<typeof writeFormData>>>
    export type WriteFormDataMutationBody = BodyWriteFormDataEtlJotformTokenPost
    export type WriteFormDataMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Write Form Data
 */
export const useWriteFormData = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof writeFormData>>, TError,{token: string;data: BodyWriteFormDataEtlJotformTokenPost}, TContext>, }
) => {

      const mutationOptions = getWriteFormDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add Phone Number
 */
export const addPhoneNumber = (
    addPhoneNumberRequest: AddPhoneNumberRequest,
 ) => {
      
      
      return customInstance<PhoneSystemUser>(
      {url: `/phone-system/user`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addPhoneNumberRequest
    },
      );
    }
  


export const getAddPhoneNumberMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPhoneNumber>>, TError,{data: AddPhoneNumberRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addPhoneNumber>>, TError,{data: AddPhoneNumberRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPhoneNumber>>, {data: AddPhoneNumberRequest}> = (props) => {
          const {data} = props ?? {};

          return  addPhoneNumber(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddPhoneNumberMutationResult = NonNullable<Awaited<ReturnType<typeof addPhoneNumber>>>
    export type AddPhoneNumberMutationBody = AddPhoneNumberRequest
    export type AddPhoneNumberMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Add Phone Number
 */
export const useAddPhoneNumber = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPhoneNumber>>, TError,{data: AddPhoneNumberRequest}, TContext>, }
) => {

      const mutationOptions = getAddPhoneNumberMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Phone Number Search
 */
export const phoneNumberSearch = (
    requestPhoneNumber: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<CompanyPhoneUser[]>(
      {url: `/phone-system/${requestPhoneNumber}`, method: 'get', signal
    },
      );
    }
  

export const getPhoneNumberSearchQueryKey = (requestPhoneNumber: string,) => {
    
    return [`/phone-system/${requestPhoneNumber}`] as const;
    }

    
export const getPhoneNumberSearchQueryOptions = <TData = Awaited<ReturnType<typeof phoneNumberSearch>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(requestPhoneNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof phoneNumberSearch>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPhoneNumberSearchQueryKey(requestPhoneNumber);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof phoneNumberSearch>>> = ({ signal }) => phoneNumberSearch(requestPhoneNumber, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(requestPhoneNumber), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof phoneNumberSearch>>, TError, TData> & { queryKey: QueryKey }
}

export type PhoneNumberSearchQueryResult = NonNullable<Awaited<ReturnType<typeof phoneNumberSearch>>>
export type PhoneNumberSearchQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Phone Number Search
 */
export const usePhoneNumberSearch = <TData = Awaited<ReturnType<typeof phoneNumberSearch>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 requestPhoneNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof phoneNumberSearch>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getPhoneNumberSearchQueryOptions(requestPhoneNumber,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Returns all rebate agreements
 * @summary Get Rebate Agreements
 */
export const getRebateAgreements = (
    params?: GetRebateAgreementsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<RebateAgreement[]>(
      {url: `/rebate-agreements/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetRebateAgreementsQueryKey = (params?: GetRebateAgreementsParams,) => {
    
    return [`/rebate-agreements/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRebateAgreementsQueryOptions = <TData = Awaited<ReturnType<typeof getRebateAgreements>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetRebateAgreementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreements>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRebateAgreementsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRebateAgreements>>> = ({ signal }) => getRebateAgreements(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreements>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRebateAgreementsQueryResult = NonNullable<Awaited<ReturnType<typeof getRebateAgreements>>>
export type GetRebateAgreementsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Rebate Agreements
 */
export const useGetRebateAgreements = <TData = Awaited<ReturnType<typeof getRebateAgreements>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetRebateAgreementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRebateAgreementsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Creates a new rebate agreement
 * @summary Create Rebate Agreement
 */
export const createRebateAgreement = (
    createRebateAgreementRequest: CreateRebateAgreementRequest,
 ) => {
      
      
      return customInstance<RebateAgreement>(
      {url: `/rebate-agreements/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createRebateAgreementRequest
    },
      );
    }
  


export const getCreateRebateAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRebateAgreement>>, TError,{data: CreateRebateAgreementRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createRebateAgreement>>, TError,{data: CreateRebateAgreementRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRebateAgreement>>, {data: CreateRebateAgreementRequest}> = (props) => {
          const {data} = props ?? {};

          return  createRebateAgreement(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateRebateAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof createRebateAgreement>>>
    export type CreateRebateAgreementMutationBody = CreateRebateAgreementRequest
    export type CreateRebateAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Rebate Agreement
 */
export const useCreateRebateAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRebateAgreement>>, TError,{data: CreateRebateAgreementRequest}, TContext>, }
) => {

      const mutationOptions = getCreateRebateAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single rebate agreement
 * @summary Get Rebate Agreement
 */
export const getRebateAgreement = (
    rebateAgreementId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<RebateAgreement>(
      {url: `/rebate-agreements/${rebateAgreementId}`, method: 'get', signal
    },
      );
    }
  

export const getGetRebateAgreementQueryKey = (rebateAgreementId: number,) => {
    
    return [`/rebate-agreements/${rebateAgreementId}`] as const;
    }

    
export const getGetRebateAgreementQueryOptions = <TData = Awaited<ReturnType<typeof getRebateAgreement>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(rebateAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreement>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRebateAgreementQueryKey(rebateAgreementId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRebateAgreement>>> = ({ signal }) => getRebateAgreement(rebateAgreementId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(rebateAgreementId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreement>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRebateAgreementQueryResult = NonNullable<Awaited<ReturnType<typeof getRebateAgreement>>>
export type GetRebateAgreementQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Rebate Agreement
 */
export const useGetRebateAgreement = <TData = Awaited<ReturnType<typeof getRebateAgreement>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 rebateAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRebateAgreement>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRebateAgreementQueryOptions(rebateAgreementId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Updates a rebate agreement
 * @summary Update Rebate Agreement
 */
export const updateRebateAgreement = (
    rebateAgreementId: number,
    updateRebateAgreementRequest: UpdateRebateAgreementRequest,
 ) => {
      
      
      return customInstance<RebateAgreement>(
      {url: `/rebate-agreements/${rebateAgreementId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateRebateAgreementRequest
    },
      );
    }
  


export const getUpdateRebateAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRebateAgreement>>, TError,{rebateAgreementId: number;data: UpdateRebateAgreementRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateRebateAgreement>>, TError,{rebateAgreementId: number;data: UpdateRebateAgreementRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRebateAgreement>>, {rebateAgreementId: number;data: UpdateRebateAgreementRequest}> = (props) => {
          const {rebateAgreementId,data} = props ?? {};

          return  updateRebateAgreement(rebateAgreementId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateRebateAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof updateRebateAgreement>>>
    export type UpdateRebateAgreementMutationBody = UpdateRebateAgreementRequest
    export type UpdateRebateAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Rebate Agreement
 */
export const useUpdateRebateAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRebateAgreement>>, TError,{rebateAgreementId: number;data: UpdateRebateAgreementRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateRebateAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Deletes a rebate agreement
 * @summary Delete Rebate Agreement
 */
export const deleteRebateAgreement = (
    rebateAgreementId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/rebate-agreements/${rebateAgreementId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteRebateAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRebateAgreement>>, TError,{rebateAgreementId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteRebateAgreement>>, TError,{rebateAgreementId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRebateAgreement>>, {rebateAgreementId: number}> = (props) => {
          const {rebateAgreementId} = props ?? {};

          return  deleteRebateAgreement(rebateAgreementId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteRebateAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRebateAgreement>>>
    
    export type DeleteRebateAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Rebate Agreement
 */
export const useDeleteRebateAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRebateAgreement>>, TError,{rebateAgreementId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteRebateAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Uploads a file to a rebate agreement
 * @summary Upload Rebate Agreement File
 */
export const uploadRebateAgreementFile = (
    rebateAgreementId: number,
    bodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost: BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost,
 ) => {
      
      const formData = new FormData();
formData.append('file', bodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost.file)

      return customInstance<RebateAgreement>(
      {url: `/rebate-agreements/${rebateAgreementId}/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


export const getUploadRebateAgreementFileMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadRebateAgreementFile>>, TError,{rebateAgreementId: number;data: BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof uploadRebateAgreementFile>>, TError,{rebateAgreementId: number;data: BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadRebateAgreementFile>>, {rebateAgreementId: number;data: BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost}> = (props) => {
          const {rebateAgreementId,data} = props ?? {};

          return  uploadRebateAgreementFile(rebateAgreementId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadRebateAgreementFileMutationResult = NonNullable<Awaited<ReturnType<typeof uploadRebateAgreementFile>>>
    export type UploadRebateAgreementFileMutationBody = BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost
    export type UploadRebateAgreementFileMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Upload Rebate Agreement File
 */
export const useUploadRebateAgreementFile = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadRebateAgreementFile>>, TError,{rebateAgreementId: number;data: BodyUploadRebateAgreementFileRebateAgreementsRebateAgreementIdUploadPost}, TContext>, }
) => {

      const mutationOptions = getUploadRebateAgreementFileMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Downloads a file assigned a rebate agreement
 * @summary Download Rebate Agreement File
 */
export const downloadRebateAgreementFile = (
    rebateAgreementId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<DownloadRebateAgreementFileRequest>(
      {url: `/rebate-agreements/${rebateAgreementId}/download`, method: 'get', signal
    },
      );
    }
  

export const getDownloadRebateAgreementFileQueryKey = (rebateAgreementId: number,) => {
    
    return [`/rebate-agreements/${rebateAgreementId}/download`] as const;
    }

    
export const getDownloadRebateAgreementFileQueryOptions = <TData = Awaited<ReturnType<typeof downloadRebateAgreementFile>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(rebateAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadRebateAgreementFile>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadRebateAgreementFileQueryKey(rebateAgreementId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadRebateAgreementFile>>> = ({ signal }) => downloadRebateAgreementFile(rebateAgreementId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(rebateAgreementId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadRebateAgreementFile>>, TError, TData> & { queryKey: QueryKey }
}

export type DownloadRebateAgreementFileQueryResult = NonNullable<Awaited<ReturnType<typeof downloadRebateAgreementFile>>>
export type DownloadRebateAgreementFileQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Download Rebate Agreement File
 */
export const useDownloadRebateAgreementFile = <TData = Awaited<ReturnType<typeof downloadRebateAgreementFile>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 rebateAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadRebateAgreementFile>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadRebateAgreementFileQueryOptions(rebateAgreementId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Service Manuals
 */
export const getServiceManuals = (
    params?: GetServiceManualsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ServiceManualsAndCount>(
      {url: `/oem-service-manuals/service-manuals/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetServiceManualsQueryKey = (params?: GetServiceManualsParams,) => {
    
    return [`/oem-service-manuals/service-manuals/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetServiceManualsQueryOptions = <TData = Awaited<ReturnType<typeof getServiceManuals>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetServiceManualsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManuals>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServiceManualsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceManuals>>> = ({ signal }) => getServiceManuals(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getServiceManuals>>, TError, TData> & { queryKey: QueryKey }
}

export type GetServiceManualsQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceManuals>>>
export type GetServiceManualsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Service Manuals
 */
export const useGetServiceManuals = <TData = Awaited<ReturnType<typeof getServiceManuals>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetServiceManualsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManuals>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServiceManualsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Service Manuals
 */
export const createServiceManuals = (
    createServiceManualsRequest: CreateServiceManualsRequest,
 ) => {
      
      
      return customInstance<ServiceManual[]>(
      {url: `/oem-service-manuals/service-manuals/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createServiceManualsRequest
    },
      );
    }
  


export const getCreateServiceManualsMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createServiceManuals>>, TError,{data: CreateServiceManualsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createServiceManuals>>, TError,{data: CreateServiceManualsRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createServiceManuals>>, {data: CreateServiceManualsRequest}> = (props) => {
          const {data} = props ?? {};

          return  createServiceManuals(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateServiceManualsMutationResult = NonNullable<Awaited<ReturnType<typeof createServiceManuals>>>
    export type CreateServiceManualsMutationBody = CreateServiceManualsRequest
    export type CreateServiceManualsMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Service Manuals
 */
export const useCreateServiceManuals = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createServiceManuals>>, TError,{data: CreateServiceManualsRequest}, TContext>, }
) => {

      const mutationOptions = getCreateServiceManualsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Service Manual
 */
export const getServiceManual = (
    serviceManualId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ServiceManual>(
      {url: `/oem-service-manuals/service-manuals/${serviceManualId}`, method: 'get', signal
    },
      );
    }
  

export const getGetServiceManualQueryKey = (serviceManualId: number,) => {
    
    return [`/oem-service-manuals/service-manuals/${serviceManualId}`] as const;
    }

    
export const getGetServiceManualQueryOptions = <TData = Awaited<ReturnType<typeof getServiceManual>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(serviceManualId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManual>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServiceManualQueryKey(serviceManualId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceManual>>> = ({ signal }) => getServiceManual(serviceManualId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(serviceManualId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getServiceManual>>, TError, TData> & { queryKey: QueryKey }
}

export type GetServiceManualQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceManual>>>
export type GetServiceManualQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Service Manual
 */
export const useGetServiceManual = <TData = Awaited<ReturnType<typeof getServiceManual>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 serviceManualId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManual>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServiceManualQueryOptions(serviceManualId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Service Manual
 */
export const updateServiceManual = (
    serviceManualId: number,
    updateServiceManualRequest: UpdateServiceManualRequest,
 ) => {
      
      
      return customInstance<ServiceManual>(
      {url: `/oem-service-manuals/service-manuals/${serviceManualId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateServiceManualRequest
    },
      );
    }
  


export const getUpdateServiceManualMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateServiceManual>>, TError,{serviceManualId: number;data: UpdateServiceManualRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateServiceManual>>, TError,{serviceManualId: number;data: UpdateServiceManualRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateServiceManual>>, {serviceManualId: number;data: UpdateServiceManualRequest}> = (props) => {
          const {serviceManualId,data} = props ?? {};

          return  updateServiceManual(serviceManualId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateServiceManualMutationResult = NonNullable<Awaited<ReturnType<typeof updateServiceManual>>>
    export type UpdateServiceManualMutationBody = UpdateServiceManualRequest
    export type UpdateServiceManualMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Service Manual
 */
export const useUpdateServiceManual = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateServiceManual>>, TError,{serviceManualId: number;data: UpdateServiceManualRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateServiceManualMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Service Manual Types
 */
export const getServiceManualTypes = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ServiceManualType[]>(
      {url: `/oem-service-manuals/service-manual-types/`, method: 'get', signal
    },
      );
    }
  

export const getGetServiceManualTypesQueryKey = () => {
    
    return [`/oem-service-manuals/service-manual-types/`] as const;
    }

    
export const getGetServiceManualTypesQueryOptions = <TData = Awaited<ReturnType<typeof getServiceManualTypes>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualTypes>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServiceManualTypesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceManualTypes>>> = ({ signal }) => getServiceManualTypes(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getServiceManualTypes>>, TError, TData> & { queryKey: QueryKey }
}

export type GetServiceManualTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceManualTypes>>>
export type GetServiceManualTypesQueryError = ErrorType<_ErrorModel>

/**
 * @summary Get Service Manual Types
 */
export const useGetServiceManualTypes = <TData = Awaited<ReturnType<typeof getServiceManualTypes>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualTypes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServiceManualTypesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Service Manual Type
 */
export const getServiceManualType = (
    serviceManualTypeId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ServiceManualType>(
      {url: `/oem-service-manuals/service-manual-types/${serviceManualTypeId}`, method: 'get', signal
    },
      );
    }
  

export const getGetServiceManualTypeQueryKey = (serviceManualTypeId: number,) => {
    
    return [`/oem-service-manuals/service-manual-types/${serviceManualTypeId}`] as const;
    }

    
export const getGetServiceManualTypeQueryOptions = <TData = Awaited<ReturnType<typeof getServiceManualType>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(serviceManualTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualType>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServiceManualTypeQueryKey(serviceManualTypeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceManualType>>> = ({ signal }) => getServiceManualType(serviceManualTypeId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(serviceManualTypeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getServiceManualType>>, TError, TData> & { queryKey: QueryKey }
}

export type GetServiceManualTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceManualType>>>
export type GetServiceManualTypeQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Service Manual Type
 */
export const useGetServiceManualType = <TData = Awaited<ReturnType<typeof getServiceManualType>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 serviceManualTypeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualType>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServiceManualTypeQueryOptions(serviceManualTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Equipment Service Manuals
 */
export const getEquipmentServiceManuals = (
    params?: GetEquipmentServiceManualsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<EquipmentServiceManualsAndCount>(
      {url: `/oem-service-manuals/equipment-service-manuals/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetEquipmentServiceManualsQueryKey = (params?: GetEquipmentServiceManualsParams,) => {
    
    return [`/oem-service-manuals/equipment-service-manuals/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetEquipmentServiceManualsQueryOptions = <TData = Awaited<ReturnType<typeof getEquipmentServiceManuals>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetEquipmentServiceManualsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManuals>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEquipmentServiceManualsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEquipmentServiceManuals>>> = ({ signal }) => getEquipmentServiceManuals(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManuals>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEquipmentServiceManualsQueryResult = NonNullable<Awaited<ReturnType<typeof getEquipmentServiceManuals>>>
export type GetEquipmentServiceManualsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Equipment Service Manuals
 */
export const useGetEquipmentServiceManuals = <TData = Awaited<ReturnType<typeof getEquipmentServiceManuals>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetEquipmentServiceManualsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManuals>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEquipmentServiceManualsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Equipment Service Manual
 */
export const createEquipmentServiceManual = (
    createEquipmentServiceManualRequest: CreateEquipmentServiceManualRequest,
 ) => {
      
      
      return customInstance<EquipmentServiceManual[]>(
      {url: `/oem-service-manuals/equipment-service-manuals/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createEquipmentServiceManualRequest
    },
      );
    }
  


export const getCreateEquipmentServiceManualMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEquipmentServiceManual>>, TError,{data: CreateEquipmentServiceManualRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createEquipmentServiceManual>>, TError,{data: CreateEquipmentServiceManualRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEquipmentServiceManual>>, {data: CreateEquipmentServiceManualRequest}> = (props) => {
          const {data} = props ?? {};

          return  createEquipmentServiceManual(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateEquipmentServiceManualMutationResult = NonNullable<Awaited<ReturnType<typeof createEquipmentServiceManual>>>
    export type CreateEquipmentServiceManualMutationBody = CreateEquipmentServiceManualRequest
    export type CreateEquipmentServiceManualMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Equipment Service Manual
 */
export const useCreateEquipmentServiceManual = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEquipmentServiceManual>>, TError,{data: CreateEquipmentServiceManualRequest}, TContext>, }
) => {

      const mutationOptions = getCreateEquipmentServiceManualMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Equipment Service Manual
 */
export const getEquipmentServiceManual = (
    equipmentServiceManualId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<EquipmentServiceManual>(
      {url: `/oem-service-manuals/equipment-service-manuals/${equipmentServiceManualId}`, method: 'get', signal
    },
      );
    }
  

export const getGetEquipmentServiceManualQueryKey = (equipmentServiceManualId: number,) => {
    
    return [`/oem-service-manuals/equipment-service-manuals/${equipmentServiceManualId}`] as const;
    }

    
export const getGetEquipmentServiceManualQueryOptions = <TData = Awaited<ReturnType<typeof getEquipmentServiceManual>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(equipmentServiceManualId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManual>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEquipmentServiceManualQueryKey(equipmentServiceManualId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEquipmentServiceManual>>> = ({ signal }) => getEquipmentServiceManual(equipmentServiceManualId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(equipmentServiceManualId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManual>>, TError, TData> & { queryKey: QueryKey }
}

export type GetEquipmentServiceManualQueryResult = NonNullable<Awaited<ReturnType<typeof getEquipmentServiceManual>>>
export type GetEquipmentServiceManualQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Equipment Service Manual
 */
export const useGetEquipmentServiceManual = <TData = Awaited<ReturnType<typeof getEquipmentServiceManual>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 equipmentServiceManualId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEquipmentServiceManual>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEquipmentServiceManualQueryOptions(equipmentServiceManualId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Equipment Service Manual
 */
export const updateEquipmentServiceManual = (
    equipmentServiceManualId: number,
    updateEquipmentServiceManualRequest: UpdateEquipmentServiceManualRequest,
 ) => {
      
      
      return customInstance<EquipmentServiceManual>(
      {url: `/oem-service-manuals/equipment-service-manuals/${equipmentServiceManualId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateEquipmentServiceManualRequest
    },
      );
    }
  


export const getUpdateEquipmentServiceManualMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentServiceManual>>, TError,{equipmentServiceManualId: number;data: UpdateEquipmentServiceManualRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentServiceManual>>, TError,{equipmentServiceManualId: number;data: UpdateEquipmentServiceManualRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEquipmentServiceManual>>, {equipmentServiceManualId: number;data: UpdateEquipmentServiceManualRequest}> = (props) => {
          const {equipmentServiceManualId,data} = props ?? {};

          return  updateEquipmentServiceManual(equipmentServiceManualId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateEquipmentServiceManualMutationResult = NonNullable<Awaited<ReturnType<typeof updateEquipmentServiceManual>>>
    export type UpdateEquipmentServiceManualMutationBody = UpdateEquipmentServiceManualRequest
    export type UpdateEquipmentServiceManualMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Equipment Service Manual
 */
export const useUpdateEquipmentServiceManual = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEquipmentServiceManual>>, TError,{equipmentServiceManualId: number;data: UpdateEquipmentServiceManualRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateEquipmentServiceManualMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Equipment Service Manual
 */
export const deleteEquipmentServiceManual = (
    equipmentServiceManualId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/oem-service-manuals/equipment-service-manuals/${equipmentServiceManualId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteEquipmentServiceManualMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentServiceManual>>, TError,{equipmentServiceManualId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentServiceManual>>, TError,{equipmentServiceManualId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEquipmentServiceManual>>, {equipmentServiceManualId: number}> = (props) => {
          const {equipmentServiceManualId} = props ?? {};

          return  deleteEquipmentServiceManual(equipmentServiceManualId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteEquipmentServiceManualMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEquipmentServiceManual>>>
    
    export type DeleteEquipmentServiceManualMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Equipment Service Manual
 */
export const useDeleteEquipmentServiceManual = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEquipmentServiceManual>>, TError,{equipmentServiceManualId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteEquipmentServiceManualMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Service Manuals Asset Status
 */
export const getServiceManualsAssetStatus = (
    params?: GetServiceManualsAssetStatusParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<ServiceManualsAssetStatusAndCount>(
      {url: `/oem-service-manuals/service-manuals-asset-status/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetServiceManualsAssetStatusQueryKey = (params?: GetServiceManualsAssetStatusParams,) => {
    
    return [`/oem-service-manuals/service-manuals-asset-status/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetServiceManualsAssetStatusQueryOptions = <TData = Awaited<ReturnType<typeof getServiceManualsAssetStatus>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetServiceManualsAssetStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualsAssetStatus>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetServiceManualsAssetStatusQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getServiceManualsAssetStatus>>> = ({ signal }) => getServiceManualsAssetStatus(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getServiceManualsAssetStatus>>, TError, TData> & { queryKey: QueryKey }
}

export type GetServiceManualsAssetStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getServiceManualsAssetStatus>>>
export type GetServiceManualsAssetStatusQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Service Manuals Asset Status
 */
export const useGetServiceManualsAssetStatus = <TData = Awaited<ReturnType<typeof getServiceManualsAssetStatus>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetServiceManualsAssetStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getServiceManualsAssetStatus>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetServiceManualsAssetStatusQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Determines if this service is up and running.

**200 status indicates that this is ready to service requests.**
 * @summary Status
 */
export const status = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<StatusResponse>(
      {url: `/status`, method: 'get', signal
    },
      );
    }
  

export const getStatusQueryKey = () => {
    
    return [`/status`] as const;
    }

    
export const getStatusQueryOptions = <TData = Awaited<ReturnType<typeof status>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatusQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof status>>> = ({ signal }) => status(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData> & { queryKey: QueryKey }
}

export type StatusQueryResult = NonNullable<Awaited<ReturnType<typeof status>>>
export type StatusQueryError = ErrorType<unknown>

/**
 * @summary Status
 */
export const useStatus = <TData = Awaited<ReturnType<typeof status>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getStatusQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Search Asset Assignments
 */
export const searchAssetAssignments = (
    params?: SearchAssetAssignmentsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<UserAssetAssignment[]>(
      {url: `/vehicle-usage-tracker/asset-assignments`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSearchAssetAssignmentsQueryKey = (params?: SearchAssetAssignmentsParams,) => {
    
    return [`/vehicle-usage-tracker/asset-assignments`, ...(params ? [params]: [])] as const;
    }

    
export const getSearchAssetAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof searchAssetAssignments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: SearchAssetAssignmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchAssetAssignments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSearchAssetAssignmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof searchAssetAssignments>>> = ({ signal }) => searchAssetAssignments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof searchAssetAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type SearchAssetAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof searchAssetAssignments>>>
export type SearchAssetAssignmentsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Search Asset Assignments
 */
export const useSearchAssetAssignments = <TData = Awaited<ReturnType<typeof searchAssetAssignments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: SearchAssetAssignmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchAssetAssignments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getSearchAssetAssignmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Asset Assignment
 */
export const createAssetAssignment = (
    createAssetAssignmentRequest: CreateAssetAssignmentRequest,
 ) => {
      
      
      return customInstance<UserAssetAssignment>(
      {url: `/vehicle-usage-tracker/asset-assignments`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAssetAssignmentRequest
    },
      );
    }
  


export const getCreateAssetAssignmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssetAssignment>>, TError,{data: CreateAssetAssignmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAssetAssignment>>, TError,{data: CreateAssetAssignmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssetAssignment>>, {data: CreateAssetAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createAssetAssignment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAssetAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof createAssetAssignment>>>
    export type CreateAssetAssignmentMutationBody = CreateAssetAssignmentRequest
    export type CreateAssetAssignmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Asset Assignment
 */
export const useCreateAssetAssignment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssetAssignment>>, TError,{data: CreateAssetAssignmentRequest}, TContext>, }
) => {

      const mutationOptions = getCreateAssetAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Asset Assignment
 */
export const deleteAssetAssignment = (
    assetAssignmentId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/vehicle-usage-tracker/asset-assignments/${assetAssignmentId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteAssetAssignmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssetAssignment>>, TError,{assetAssignmentId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssetAssignment>>, TError,{assetAssignmentId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssetAssignment>>, {assetAssignmentId: number}> = (props) => {
          const {assetAssignmentId} = props ?? {};

          return  deleteAssetAssignment(assetAssignmentId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAssetAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssetAssignment>>>
    
    export type DeleteAssetAssignmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Asset Assignment
 */
export const useDeleteAssetAssignment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssetAssignment>>, TError,{assetAssignmentId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteAssetAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Update Asset Assignment
 */
export const updateAssetAssignment = (
    assetAssignmentId: number,
    updateAssetAssignmentRequest: UpdateAssetAssignmentRequest,
 ) => {
      
      
      return customInstance<UserAssetAssignment>(
      {url: `/vehicle-usage-tracker/asset-assignments/${assetAssignmentId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateAssetAssignmentRequest
    },
      );
    }
  


export const getUpdateAssetAssignmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssetAssignment>>, TError,{assetAssignmentId: number;data: UpdateAssetAssignmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAssetAssignment>>, TError,{assetAssignmentId: number;data: UpdateAssetAssignmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssetAssignment>>, {assetAssignmentId: number;data: UpdateAssetAssignmentRequest}> = (props) => {
          const {assetAssignmentId,data} = props ?? {};

          return  updateAssetAssignment(assetAssignmentId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssetAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssetAssignment>>>
    export type UpdateAssetAssignmentMutationBody = UpdateAssetAssignmentRequest
    export type UpdateAssetAssignmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Asset Assignment
 */
export const useUpdateAssetAssignment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssetAssignment>>, TError,{assetAssignmentId: number;data: UpdateAssetAssignmentRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateAssetAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Quarterly Cutoff Date
 */
export const getQuarterlyCutoffDate = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<QuarterlyDeadlineResponse>(
      {url: `/vehicle-usage-tracker/quarter-deadline`, method: 'get', signal
    },
      );
    }
  

export const getGetQuarterlyCutoffDateQueryKey = () => {
    
    return [`/vehicle-usage-tracker/quarter-deadline`] as const;
    }

    
export const getGetQuarterlyCutoffDateQueryOptions = <TData = Awaited<ReturnType<typeof getQuarterlyCutoffDate>>, TError = ErrorType<_ErrorModel>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuarterlyCutoffDate>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetQuarterlyCutoffDateQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuarterlyCutoffDate>>> = ({ signal }) => getQuarterlyCutoffDate(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getQuarterlyCutoffDate>>, TError, TData> & { queryKey: QueryKey }
}

export type GetQuarterlyCutoffDateQueryResult = NonNullable<Awaited<ReturnType<typeof getQuarterlyCutoffDate>>>
export type GetQuarterlyCutoffDateQueryError = ErrorType<_ErrorModel>

/**
 * @summary Get Quarterly Cutoff Date
 */
export const useGetQuarterlyCutoffDate = <TData = Awaited<ReturnType<typeof getQuarterlyCutoffDate>>, TError = ErrorType<_ErrorModel>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuarterlyCutoffDate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetQuarterlyCutoffDateQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Trips
 */
export const getTrips = (
    params: GetTripsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<SuperTripsPaginated>(
      {url: `/vehicle-usage-tracker/trips`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetTripsQueryKey = (params: GetTripsParams,) => {
    
    return [`/vehicle-usage-tracker/trips`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTripsQueryOptions = <TData = Awaited<ReturnType<typeof getTrips>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params: GetTripsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrips>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTripsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrips>>> = ({ signal }) => getTrips(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTrips>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTripsQueryResult = NonNullable<Awaited<ReturnType<typeof getTrips>>>
export type GetTripsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Trips
 */
export const useGetTrips = <TData = Awaited<ReturnType<typeof getTrips>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params: GetTripsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrips>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTripsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Approve Trips
 */
export const approveTrips = (
    approveTripsRequest: ApproveTripsRequest,
 ) => {
      
      
      return customInstance<void>(
      {url: `/vehicle-usage-tracker/approve-trips`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: approveTripsRequest
    },
      );
    }
  


export const getApproveTripsMutationOptions = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approveTrips>>, TError,{data: ApproveTripsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof approveTrips>>, TError,{data: ApproveTripsRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof approveTrips>>, {data: ApproveTripsRequest}> = (props) => {
          const {data} = props ?? {};

          return  approveTrips(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ApproveTripsMutationResult = NonNullable<Awaited<ReturnType<typeof approveTrips>>>
    export type ApproveTripsMutationBody = ApproveTripsRequest
    export type ApproveTripsMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Approve Trips
 */
export const useApproveTrips = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approveTrips>>, TError,{data: ApproveTripsRequest}, TContext>, }
) => {

      const mutationOptions = getApproveTripsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Report Trips
 */
export const reportTrips = (
    reportTripsRequest: ReportTripsRequest,
 ) => {
      
      
      return customInstance<void>(
      {url: `/vehicle-usage-tracker/report-trips`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reportTripsRequest
    },
      );
    }
  


export const getReportTripsMutationOptions = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reportTrips>>, TError,{data: ReportTripsRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof reportTrips>>, TError,{data: ReportTripsRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reportTrips>>, {data: ReportTripsRequest}> = (props) => {
          const {data} = props ?? {};

          return  reportTrips(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ReportTripsMutationResult = NonNullable<Awaited<ReturnType<typeof reportTrips>>>
    export type ReportTripsMutationBody = ReportTripsRequest
    export type ReportTripsMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Report Trips
 */
export const useReportTrips = <TError = ErrorType<HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reportTrips>>, TError,{data: ReportTripsRequest}, TContext>, }
) => {

      const mutationOptions = getReportTripsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
