import { EuiPage, EuiPageBody, EuiPageSection, EuiPageHeader } from "@equipmentshare/ds2"

export type PageProps = {
    title: React.ReactNode
    children?: React.ReactNode
    restrictWidth?: string
}

export default function Page(props: PageProps) {
    return (
        <EuiPage paddingSize="l" restrictWidth={props.restrictWidth}>
            <EuiPageBody>
                <EuiPageHeader paddingSize="s" pageTitle={props.title} />
                <EuiPageSection paddingSize="s" color="transparent">
                    {props.children}
                </EuiPageSection>
            </EuiPageBody>
        </EuiPage>
    )
}
