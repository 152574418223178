import { useQuery } from "react-query"

import { mapListsByIndex } from "../Utils"
import useEsApiService from "./useEsApiService"

function useAllCategoryNames() {
    const esApiService = useEsApiService()
    return useQuery({
        queryKey: "all-category-names",
        queryFn: () =>
            esApiService.getAllCategories().then((all) =>
                mapListsByIndex(
                    all.map((a) => a.category_id),
                    all.map((a) => a.name)
                )
            ),
    })
}

export default useAllCategoryNames
