export default process.env.REACT_APP_CI_COMMIT_REF_SLUG === "main" && process.env.NODE_ENV !== "test"
    ? {
          API_URL: "https://api.equipmentshare.com/",
          COST_CAPTURE_API_URL: "https://api.equipmentshare.com/things/federated/graphql",
          COST_CAPTURE_LINK: `https://costcapture.estrack.com`,
          AUTH0: {
              DOMAIN: "equipmentshare.auth0.com",
              CLIENTID: "akIANx5VDfwEYVo5TXuehBAJXcCzZly7",
              AUDIENCE: "https://api.equipmentshare.com",
          },
          SKUNKWORKS_URL: "https://api.equipmentshare.com/skunkworks",
          QUOTES_URL: "https://quotes.estrack.com/",
          PAYOUT_PROGRAM_URL: "https://api.equipmentshare.com/payout-program",
          DDOG_RUM: {
              APPLICATION_ID: "5208a76a-01d6-423b-a0b5-ac3b13895ca6",
              CLIENT_TOKEN: "pube1328a481cf4a29ad4f5eb17aa3f8629",
          },
      }
    : {
          API_URL: "https://staging-api.equipmentshare.com/",
          COST_CAPTURE_API_URL: "https://staging-api.equipmentshare.com/things/federated/graphql",
          COST_CAPTURE_LINK: `https://staging-costcapture.estrack.com`,
          AUTH0: {
              DOMAIN: "staging-equipmentshare.auth0.com",
              CLIENTID: "Uw0fQTuSFIioPhJOAl5xycdvbo89Lk24",
              AUDIENCE: "https://staging-api.equipmentshare.com",
          },
          SKUNKWORKS_URL: process.env.REACT_APP_SKUNKWORKS_LOCAL
              ? "http://localhost:8400"
              : "https://staging-api.equipmentshare.com/skunkworks",
          QUOTES_URL: "https://quotes.alpha.estrack.com",
          PAYOUT_PROGRAM_URL: process.env.REACT_APP_PAYOUTPROGRAM_LOCAL
              ? "http://localhost:8401"
              : "https://staging-api.equipmentshare.com/payout-program",
          DDOG_RUM: {
              APPLICATION_ID: "c3955df1-1c2d-42d6-bd8b-0e20374b8c11",
              CLIENT_TOKEN: "pubbf2024352b972161e423f13766829ed3",
          },
      }
