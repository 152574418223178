import React from "react"

import { EuiFieldText, EuiSpacer, EuiFormRow } from "@equipmentshare/ds2"

import { PayoutProgramPicker, MasterAgreementPicker } from "../../../../Pickers"

type ScheduleRequest = {
    master_agreement_id?: number | null
    name?: string | null
    payout_percentage?: string | number | null
    payout_program_id?: number | null
}

export type ScheduleFieldsProps = {
    scheduleReq: ScheduleRequest
    setScheduleReq: (value: React.SetStateAction<ScheduleRequest>) => void
    hasErrors?: boolean
}

type ScheduleCreateReq = {
    master_agreement_id: number
    name: string
    payout_percentage: string | number
    payout_program_id: number
}

export type ScheduleFieldsCreateProps = {
    scheduleReq: ScheduleCreateReq
    setScheduleReq: (value: React.SetStateAction<ScheduleCreateReq>) => void
    hasErrors?: boolean
}

export default function ScheduleFields(
    props: ScheduleFieldsProps | ScheduleFieldsCreateProps
) {
    function isScheduleFieldsProps(
        props: ScheduleFieldsProps | ScheduleFieldsCreateProps
    ): props is ScheduleFieldsProps {
        const req = props.scheduleReq
        return (
            req.master_agreement_id === undefined ||
            req.master_agreement_id === null ||
            req.name === undefined ||
            req.name === null ||
            req.payout_percentage === undefined ||
            req.payout_percentage === null ||
            req.payout_program_id === undefined ||
            req.payout_program_id === null
        )
    }
    return (
        <>
            <EuiFormRow
                label="Schedule Name"
                isInvalid={props.hasErrors ? props.hasErrors : false}
            >
                <EuiFieldText
                    placeholder="Enter Schedule Name"
                    onChange={(e) => {
                        isScheduleFieldsProps(props)
                            ? props.setScheduleReq({
                                  ...props.scheduleReq,
                                  name: e.target.value,
                              })
                            : props.setScheduleReq({
                                  ...props.scheduleReq,
                                  name: e.target.value,
                              })
                    }}
                />
            </EuiFormRow>

            <EuiSpacer />
            <PayoutProgramPicker
                onIdSelected={(e) => {
                    isScheduleFieldsProps(props)
                        ? props.setScheduleReq({
                              ...props.scheduleReq,
                              payout_program_id: e,
                          })
                        : props.setScheduleReq({
                              ...props.scheduleReq,
                              payout_program_id: e ?? 0,
                          })
                }}
                hasErrors={props.hasErrors}
            />
            <EuiSpacer />
            <MasterAgreementPicker
                onIdSelected={(e) => {
                    isScheduleFieldsProps(props)
                        ? props.setScheduleReq({
                              ...props.scheduleReq,
                              master_agreement_id: e,
                          })
                        : props.setScheduleReq({
                              ...props.scheduleReq,
                              master_agreement_id: e ?? 0,
                          })
                }}
                hasErrors={props.hasErrors}
            />
        </>
    )
}
