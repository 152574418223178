import axios from "axios"

export interface Provider {
    name: string
    providerId: number
}

export async function getProviders(query: string): Promise<Provider[]> {
    const resp = await axios.post<{ data: { providers: Provider[] } }>(
        "inventory/graphql",
        { query: `{providers(names: "${query}") {name providerId}}` }
    )
    return resp.data.data.providers
}

export async function getProviderById(providerId: number): Promise<Provider> {
    const resp = await axios.post<{ data: { provider: Provider } }>(
        "inventory/graphql",
        {
            query: `{provider(providerId: ${providerId}) {name providerId}}`,
        }
    )
    return resp.data.data.provider
}
