/**
 * Generated by orval v6.19.1 🍺
 * Do not edit manually.
 * Payout Program V2
 * An HTTP REST API for Payout Program V2
 * OpenAPI spec version: 1.1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import { customInstance } from './AxiosInstance';
import type { ErrorType } from './AxiosInstance';
export type GetAssignmentsParams = {
asset_id?: number | null;
payout_program_id?: number | null;
schedule_id?: number | null;
start_date?: string | null;
end_date?: string | null;
replaced_by_asset_id?: number | null;
replaced_or_removed_reason?: string | null;
active?: boolean | null;
};

export type GetSchedulesParams = {
name?: string | null;
payout_program_id?: number | null;
master_agreement_id?: number | null;
};

export type GetPayoutProgramsParams = {
name?: string | null;
payout_program_type_id?: number | null;
};

export type GetPayoutProgramMasterAgreementsParams = {
company_id?: number | null;
};

export interface _ErrorModel {
  detail: string;
}

export type ValidationErrorLocItem = string | number;

export interface ValidationError {
  loc: ValidationErrorLocItem[];
  msg: string;
  type: string;
}

export type UpdateScheduleRequestPayoutProgramId = number | null;

export type UpdateScheduleRequestPayoutPercentage = number | string | null;

export type UpdateScheduleRequestName = string | null;

export type UpdateScheduleRequestMasterAgreementId = number | null;

export interface UpdateScheduleRequest {
  master_agreement_id?: UpdateScheduleRequestMasterAgreementId;
  name?: UpdateScheduleRequestName;
  payout_percentage?: UpdateScheduleRequestPayoutPercentage;
  payout_program_id?: UpdateScheduleRequestPayoutProgramId;
}

export type UpdatePayoutProgramRequestPayoutProgramTypeId = number | null;

export type UpdatePayoutProgramRequestName = string | null;

export interface UpdatePayoutProgramRequest {
  name?: UpdatePayoutProgramRequestName;
  payout_program_type_id?: UpdatePayoutProgramRequestPayoutProgramTypeId;
}

export interface UpdateMasterAgreementRequest {
  master_agreement_no: string;
}

export type UpdateAssignmentRequestStartDate = string | null;

export type UpdateAssignmentRequestScheduleId = number | null;

export type UpdateAssignmentRequestReplacedOrRemovedReason = string | null;

export type UpdateAssignmentRequestReplacedByAssetId = number | null;

export type UpdateAssignmentRequestPayoutProgramId = number | null;

export type UpdateAssignmentRequestEndDate = string | null;

export type UpdateAssignmentRequestBillingType = PayoutProgramBillingEnum | null;

export type UpdateAssignmentRequestAssetId = number | null;

export interface UpdateAssignmentRequest {
  asset_id?: UpdateAssignmentRequestAssetId;
  billing_type?: UpdateAssignmentRequestBillingType;
  end_date?: UpdateAssignmentRequestEndDate;
  payout_program_id?: UpdateAssignmentRequestPayoutProgramId;
  replaced_by_asset_id?: UpdateAssignmentRequestReplacedByAssetId;
  replaced_or_removed_reason?: UpdateAssignmentRequestReplacedOrRemovedReason;
  schedule_id?: UpdateAssignmentRequestScheduleId;
  start_date?: UpdateAssignmentRequestStartDate;
}

/**
 * Status Response Class
 */
export interface StatusResponse {
  database_connection_up: boolean;
  env_type: string;
  healthy: boolean;
  level: string;
}

export interface Schedule {
  master_agreement: ScheduleMasterAgreement;
  master_agreement_id: number;
  name: string;
  payout_percentage: string;
  payout_program: SchedulePayoutProgram;
  payout_program_id: number;
  schedule_id: number;
}

export type PayoutProgramBillingEnum = typeof PayoutProgramBillingEnum[keyof typeof PayoutProgramBillingEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayoutProgramBillingEnum = {
  MSA: 'MSA',
  DIRECT: 'DIRECT',
} as const;

export interface PayoutProgram {
  name: string;
  payout_program_id: number;
  payout_program_type_id: number;
}

export type SchedulePayoutProgram = PayoutProgram | null;

export type MasterAgreementCompany = Company | null;

export interface MasterAgreement {
  company?: MasterAgreementCompany;
  company_id: number;
  master_agreement_id: number;
  master_agreement_no: string;
}

export type ScheduleMasterAgreement = MasterAgreement | null;

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export type CreateScheduleRequestPayoutPercentage = number | string;

export interface CreateScheduleRequest {
  master_agreement_id: number;
  name: string;
  payout_percentage: CreateScheduleRequestPayoutPercentage;
  payout_program_id: number;
}

export interface CreatePayoutProgramRequest {
  name: string;
  payout_program_type_id: number;
}

export interface CreateMasterAgreementRequest {
  company_id: number;
  master_agreement_no: string;
}

export type CreateAssignmentRequestReplacedOrRemovedReason = string | null;

export type CreateAssignmentRequestReplacedByAssetId = number | null;

export type CreateAssignmentRequestEndDate = string | null;

export interface CreateAssignmentRequest {
  asset_id: number;
  billing_type: PayoutProgramBillingEnum;
  end_date?: CreateAssignmentRequestEndDate;
  payout_program_id: number;
  replaced_by_asset_id?: CreateAssignmentRequestReplacedByAssetId;
  replaced_or_removed_reason?: CreateAssignmentRequestReplacedOrRemovedReason;
  schedule_id: number;
  start_date: string;
}

export interface Company {
  company_id: number;
  name: string;
}

export type AssignmentUpdatedByUserId = number | null;

export type AssignmentScheduleId = number | null;

export type AssignmentSchedule = Schedule | null;

export type AssignmentReplacedOrRemovedReason = string | null;

export type AssignmentReplacedByAssetId = number | null;

export type AssignmentReplacedByAsset = Asset | null;

export type AssignmentPayoutProgram = PayoutProgram | null;

export type AssignmentEndDate = string | null;

export type AssignmentDateUpdated = string | null;

export type AssignmentBillingType = PayoutProgramBillingEnum | null;

/**
 * New Fields must be null because of existing table data
 */
export interface Assignment {
  asset: AssignmentAsset;
  asset_id: number;
  assignment_id: number;
  billing_type: AssignmentBillingType;
  date_updated: AssignmentDateUpdated;
  end_date: AssignmentEndDate;
  payout_program: AssignmentPayoutProgram;
  payout_program_id: number;
  replaced_by_asset: AssignmentReplacedByAsset;
  replaced_by_asset_id: AssignmentReplacedByAssetId;
  replaced_or_removed_reason: AssignmentReplacedOrRemovedReason;
  schedule: AssignmentSchedule;
  schedule_id: AssignmentScheduleId;
  start_date: string;
  updated_by_user_id: AssignmentUpdatedByUserId;
}

export type AssetName = string | null;

export interface Asset {
  asset_id: number;
  name: AssetName;
}

export type AssignmentAsset = Asset | null;





/**
 * Returns a single payout program master agreement
 * @summary Get Payout Program Master Agreement
 */
export const getPayoutProgramMasterAgreement = (
    masterAgreementId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<MasterAgreement>(
      {url: `/v2/master-agreements/${masterAgreementId}`, method: 'get', signal
    },
      );
    }
  

export const getGetPayoutProgramMasterAgreementQueryKey = (masterAgreementId: number,) => {
    
    return [`/v2/master-agreements/${masterAgreementId}`] as const;
    }

    
export const getGetPayoutProgramMasterAgreementQueryOptions = <TData = Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(masterAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPayoutProgramMasterAgreementQueryKey(masterAgreementId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>> = ({ signal }) => getPayoutProgramMasterAgreement(masterAgreementId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(masterAgreementId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPayoutProgramMasterAgreementQueryResult = NonNullable<Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>>
export type GetPayoutProgramMasterAgreementQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Payout Program Master Agreement
 */
export const useGetPayoutProgramMasterAgreement = <TData = Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 masterAgreementId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreement>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPayoutProgramMasterAgreementQueryOptions(masterAgreementId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin Only) Updates a payout program master agreement
 * @summary Update Payout Program Master Agreement
 */
export const updatePayoutProgramMasterAgreement = (
    masterAgreementId: number,
    updateMasterAgreementRequest: UpdateMasterAgreementRequest,
 ) => {
      
      
      return customInstance<MasterAgreement>(
      {url: `/v2/master-agreements/${masterAgreementId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateMasterAgreementRequest
    },
      );
    }
  


export const getUpdatePayoutProgramMasterAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number;data: UpdateMasterAgreementRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number;data: UpdateMasterAgreementRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePayoutProgramMasterAgreement>>, {masterAgreementId: number;data: UpdateMasterAgreementRequest}> = (props) => {
          const {masterAgreementId,data} = props ?? {};

          return  updatePayoutProgramMasterAgreement(masterAgreementId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdatePayoutProgramMasterAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof updatePayoutProgramMasterAgreement>>>
    export type UpdatePayoutProgramMasterAgreementMutationBody = UpdateMasterAgreementRequest
    export type UpdatePayoutProgramMasterAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Payout Program Master Agreement
 */
export const useUpdatePayoutProgramMasterAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number;data: UpdateMasterAgreementRequest}, TContext>, }
) => {

      const mutationOptions = getUpdatePayoutProgramMasterAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin Only) Deletes a payout program master agreement
 * @summary Delete Payout Program Master Agreement
 */
export const deletePayoutProgramMasterAgreement = (
    masterAgreementId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/v2/master-agreements/${masterAgreementId}`, method: 'delete'
    },
      );
    }
  


export const getDeletePayoutProgramMasterAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePayoutProgramMasterAgreement>>, {masterAgreementId: number}> = (props) => {
          const {masterAgreementId} = props ?? {};

          return  deletePayoutProgramMasterAgreement(masterAgreementId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePayoutProgramMasterAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof deletePayoutProgramMasterAgreement>>>
    
    export type DeletePayoutProgramMasterAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Payout Program Master Agreement
 */
export const useDeletePayoutProgramMasterAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgramMasterAgreement>>, TError,{masterAgreementId: number}, TContext>, }
) => {

      const mutationOptions = getDeletePayoutProgramMasterAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns all payout program master agreements
 * @summary Get Payout Program Master Agreements
 */
export const getPayoutProgramMasterAgreements = (
    params?: GetPayoutProgramMasterAgreementsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<MasterAgreement[]>(
      {url: `/v2/master-agreements/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetPayoutProgramMasterAgreementsQueryKey = (params?: GetPayoutProgramMasterAgreementsParams,) => {
    
    return [`/v2/master-agreements/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPayoutProgramMasterAgreementsQueryOptions = <TData = Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetPayoutProgramMasterAgreementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPayoutProgramMasterAgreementsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>> = ({ signal }) => getPayoutProgramMasterAgreements(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPayoutProgramMasterAgreementsQueryResult = NonNullable<Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>>
export type GetPayoutProgramMasterAgreementsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Payout Program Master Agreements
 */
export const useGetPayoutProgramMasterAgreements = <TData = Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetPayoutProgramMasterAgreementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgramMasterAgreements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPayoutProgramMasterAgreementsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin Only) Creates a payout program master agreement
 * @summary Create Payout Program Master Agreement
 */
export const createPayoutProgramMasterAgreement = (
    createMasterAgreementRequest: CreateMasterAgreementRequest,
 ) => {
      
      
      return customInstance<MasterAgreement>(
      {url: `/v2/master-agreements/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createMasterAgreementRequest
    },
      );
    }
  


export const getCreatePayoutProgramMasterAgreementMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgramMasterAgreement>>, TError,{data: CreateMasterAgreementRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgramMasterAgreement>>, TError,{data: CreateMasterAgreementRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPayoutProgramMasterAgreement>>, {data: CreateMasterAgreementRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPayoutProgramMasterAgreement(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePayoutProgramMasterAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof createPayoutProgramMasterAgreement>>>
    export type CreatePayoutProgramMasterAgreementMutationBody = CreateMasterAgreementRequest
    export type CreatePayoutProgramMasterAgreementMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Payout Program Master Agreement
 */
export const useCreatePayoutProgramMasterAgreement = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgramMasterAgreement>>, TError,{data: CreateMasterAgreementRequest}, TContext>, }
) => {

      const mutationOptions = getCreatePayoutProgramMasterAgreementMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single payout program
 * @summary Get Payout Program
 */
export const getPayoutProgram = (
    payoutProgramId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PayoutProgram>(
      {url: `/v2/payout-programs/${payoutProgramId}`, method: 'get', signal
    },
      );
    }
  

export const getGetPayoutProgramQueryKey = (payoutProgramId: number,) => {
    
    return [`/v2/payout-programs/${payoutProgramId}`] as const;
    }

    
export const getGetPayoutProgramQueryOptions = <TData = Awaited<ReturnType<typeof getPayoutProgram>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(payoutProgramId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgram>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPayoutProgramQueryKey(payoutProgramId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayoutProgram>>> = ({ signal }) => getPayoutProgram(payoutProgramId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(payoutProgramId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgram>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPayoutProgramQueryResult = NonNullable<Awaited<ReturnType<typeof getPayoutProgram>>>
export type GetPayoutProgramQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Payout Program
 */
export const useGetPayoutProgram = <TData = Awaited<ReturnType<typeof getPayoutProgram>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 payoutProgramId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutProgram>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPayoutProgramQueryOptions(payoutProgramId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Updates a payout program
 * @summary Update Payout Program
 */
export const updatePayoutProgram = (
    payoutProgramId: number,
    updatePayoutProgramRequest: UpdatePayoutProgramRequest,
 ) => {
      
      
      return customInstance<PayoutProgram>(
      {url: `/v2/payout-programs/${payoutProgramId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updatePayoutProgramRequest
    },
      );
    }
  


export const getUpdatePayoutProgramMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgram>>, TError,{payoutProgramId: number;data: UpdatePayoutProgramRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgram>>, TError,{payoutProgramId: number;data: UpdatePayoutProgramRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePayoutProgram>>, {payoutProgramId: number;data: UpdatePayoutProgramRequest}> = (props) => {
          const {payoutProgramId,data} = props ?? {};

          return  updatePayoutProgram(payoutProgramId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdatePayoutProgramMutationResult = NonNullable<Awaited<ReturnType<typeof updatePayoutProgram>>>
    export type UpdatePayoutProgramMutationBody = UpdatePayoutProgramRequest
    export type UpdatePayoutProgramMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Payout Program
 */
export const useUpdatePayoutProgram = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePayoutProgram>>, TError,{payoutProgramId: number;data: UpdatePayoutProgramRequest}, TContext>, }
) => {

      const mutationOptions = getUpdatePayoutProgramMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Deletes a payout program
 * @summary Delete Payout Program
 */
export const deletePayoutProgram = (
    payoutProgramId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/v2/payout-programs/${payoutProgramId}`, method: 'delete'
    },
      );
    }
  


export const getDeletePayoutProgramMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgram>>, TError,{payoutProgramId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgram>>, TError,{payoutProgramId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePayoutProgram>>, {payoutProgramId: number}> = (props) => {
          const {payoutProgramId} = props ?? {};

          return  deletePayoutProgram(payoutProgramId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeletePayoutProgramMutationResult = NonNullable<Awaited<ReturnType<typeof deletePayoutProgram>>>
    
    export type DeletePayoutProgramMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Payout Program
 */
export const useDeletePayoutProgram = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePayoutProgram>>, TError,{payoutProgramId: number}, TContext>, }
) => {

      const mutationOptions = getDeletePayoutProgramMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns all payout programs
 * @summary Get Payout Programs
 */
export const getPayoutPrograms = (
    params?: GetPayoutProgramsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<PayoutProgram[]>(
      {url: `/v2/payout-programs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetPayoutProgramsQueryKey = (params?: GetPayoutProgramsParams,) => {
    
    return [`/v2/payout-programs/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetPayoutProgramsQueryOptions = <TData = Awaited<ReturnType<typeof getPayoutPrograms>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetPayoutProgramsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutPrograms>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPayoutProgramsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayoutPrograms>>> = ({ signal }) => getPayoutPrograms(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPayoutPrograms>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPayoutProgramsQueryResult = NonNullable<Awaited<ReturnType<typeof getPayoutPrograms>>>
export type GetPayoutProgramsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Payout Programs
 */
export const useGetPayoutPrograms = <TData = Awaited<ReturnType<typeof getPayoutPrograms>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetPayoutProgramsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPayoutPrograms>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPayoutProgramsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Creates a payout program
 * @summary Create Payout Program
 */
export const createPayoutProgram = (
    createPayoutProgramRequest: CreatePayoutProgramRequest,
 ) => {
      
      
      return customInstance<PayoutProgram>(
      {url: `/v2/payout-programs/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createPayoutProgramRequest
    },
      );
    }
  


export const getCreatePayoutProgramMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgram>>, TError,{data: CreatePayoutProgramRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgram>>, TError,{data: CreatePayoutProgramRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPayoutProgram>>, {data: CreatePayoutProgramRequest}> = (props) => {
          const {data} = props ?? {};

          return  createPayoutProgram(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreatePayoutProgramMutationResult = NonNullable<Awaited<ReturnType<typeof createPayoutProgram>>>
    export type CreatePayoutProgramMutationBody = CreatePayoutProgramRequest
    export type CreatePayoutProgramMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Payout Program
 */
export const useCreatePayoutProgram = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPayoutProgram>>, TError,{data: CreatePayoutProgramRequest}, TContext>, }
) => {

      const mutationOptions = getCreatePayoutProgramMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single payout program schedule
 * @summary Get Schedule
 */
export const getSchedule = (
    scheduleId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Schedule>(
      {url: `/v2/schedules/${scheduleId}`, method: 'get', signal
    },
      );
    }
  

export const getGetScheduleQueryKey = (scheduleId: number,) => {
    
    return [`/v2/schedules/${scheduleId}`] as const;
    }

    
export const getGetScheduleQueryOptions = <TData = Awaited<ReturnType<typeof getSchedule>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(scheduleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedule>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetScheduleQueryKey(scheduleId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedule>>> = ({ signal }) => getSchedule(scheduleId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(scheduleId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSchedule>>, TError, TData> & { queryKey: QueryKey }
}

export type GetScheduleQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedule>>>
export type GetScheduleQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Schedule
 */
export const useGetSchedule = <TData = Awaited<ReturnType<typeof getSchedule>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 scheduleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedule>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetScheduleQueryOptions(scheduleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Updates an existing payout program schedule
 * @summary Update Schedule
 */
export const updateSchedule = (
    scheduleId: number,
    updateScheduleRequest: UpdateScheduleRequest,
 ) => {
      
      
      return customInstance<Schedule>(
      {url: `/v2/schedules/${scheduleId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateScheduleRequest
    },
      );
    }
  


export const getUpdateScheduleMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSchedule>>, TError,{scheduleId: number;data: UpdateScheduleRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateSchedule>>, TError,{scheduleId: number;data: UpdateScheduleRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSchedule>>, {scheduleId: number;data: UpdateScheduleRequest}> = (props) => {
          const {scheduleId,data} = props ?? {};

          return  updateSchedule(scheduleId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof updateSchedule>>>
    export type UpdateScheduleMutationBody = UpdateScheduleRequest
    export type UpdateScheduleMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Schedule
 */
export const useUpdateSchedule = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSchedule>>, TError,{scheduleId: number;data: UpdateScheduleRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateScheduleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Deletes an existing payout program schedule
 * @summary Delete Schedule
 */
export const deleteSchedule = (
    scheduleId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/v2/schedules/${scheduleId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteScheduleMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSchedule>>, TError,{scheduleId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteSchedule>>, TError,{scheduleId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSchedule>>, {scheduleId: number}> = (props) => {
          const {scheduleId} = props ?? {};

          return  deleteSchedule(scheduleId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSchedule>>>
    
    export type DeleteScheduleMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Schedule
 */
export const useDeleteSchedule = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSchedule>>, TError,{scheduleId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteScheduleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns all payout program schedules
 * @summary Get Schedules
 */
export const getSchedules = (
    params?: GetSchedulesParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Schedule[]>(
      {url: `/v2/schedules/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetSchedulesQueryKey = (params?: GetSchedulesParams,) => {
    
    return [`/v2/schedules/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetSchedulesQueryOptions = <TData = Awaited<ReturnType<typeof getSchedules>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetSchedulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedules>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSchedulesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedules>>> = ({ signal }) => getSchedules(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getSchedules>>, TError, TData> & { queryKey: QueryKey }
}

export type GetSchedulesQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedules>>>
export type GetSchedulesQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Schedules
 */
export const useGetSchedules = <TData = Awaited<ReturnType<typeof getSchedules>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetSchedulesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSchedules>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSchedulesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Creates a new payout program schedule
 * @summary Create Schedule
 */
export const createSchedule = (
    createScheduleRequest: CreateScheduleRequest,
 ) => {
      
      
      return customInstance<Schedule>(
      {url: `/v2/schedules/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createScheduleRequest
    },
      );
    }
  


export const getCreateScheduleMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSchedule>>, TError,{data: CreateScheduleRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createSchedule>>, TError,{data: CreateScheduleRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSchedule>>, {data: CreateScheduleRequest}> = (props) => {
          const {data} = props ?? {};

          return  createSchedule(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateScheduleMutationResult = NonNullable<Awaited<ReturnType<typeof createSchedule>>>
    export type CreateScheduleMutationBody = CreateScheduleRequest
    export type CreateScheduleMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Schedule
 */
export const useCreateSchedule = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSchedule>>, TError,{data: CreateScheduleRequest}, TContext>, }
) => {

      const mutationOptions = getCreateScheduleMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns a single payout program assignment
 * @summary Get Assignment
 */
export const getAssignment = (
    assignmentId: number,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Assignment>(
      {url: `/v2/assignments/${assignmentId}`, method: 'get', signal
    },
      );
    }
  

export const getGetAssignmentQueryKey = (assignmentId: number,) => {
    
    return [`/v2/assignments/${assignmentId}`] as const;
    }

    
export const getGetAssignmentQueryOptions = <TData = Awaited<ReturnType<typeof getAssignment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(assignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssignment>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssignmentQueryKey(assignmentId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssignment>>> = ({ signal }) => getAssignment(assignmentId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(assignmentId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssignment>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssignmentQueryResult = NonNullable<Awaited<ReturnType<typeof getAssignment>>>
export type GetAssignmentQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Assignment
 */
export const useGetAssignment = <TData = Awaited<ReturnType<typeof getAssignment>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 assignmentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssignment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssignmentQueryOptions(assignmentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Updates a payout program assignment
 * @summary Update Assignments
 */
export const updateAssignments = (
    assignmentId: number,
    updateAssignmentRequest: UpdateAssignmentRequest,
 ) => {
      
      
      return customInstance<Assignment>(
      {url: `/v2/assignments/${assignmentId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateAssignmentRequest
    },
      );
    }
  


export const getUpdateAssignmentsMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignments>>, TError,{assignmentId: number;data: UpdateAssignmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateAssignments>>, TError,{assignmentId: number;data: UpdateAssignmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssignments>>, {assignmentId: number;data: UpdateAssignmentRequest}> = (props) => {
          const {assignmentId,data} = props ?? {};

          return  updateAssignments(assignmentId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssignmentsMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssignments>>>
    export type UpdateAssignmentsMutationBody = UpdateAssignmentRequest
    export type UpdateAssignmentsMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Update Assignments
 */
export const useUpdateAssignments = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssignments>>, TError,{assignmentId: number;data: UpdateAssignmentRequest}, TContext>, }
) => {

      const mutationOptions = getUpdateAssignmentsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * (Admin only) Deletes a payout program assignment
 * @summary Delete Assignment
 */
export const deleteAssignment = (
    assignmentId: number,
 ) => {
      
      
      return customInstance<void>(
      {url: `/v2/assignments/${assignmentId}`, method: 'delete'
    },
      );
    }
  


export const getDeleteAssignmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssignment>>, TError,{assignmentId: number}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssignment>>, TError,{assignmentId: number}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssignment>>, {assignmentId: number}> = (props) => {
          const {assignmentId} = props ?? {};

          return  deleteAssignment(assignmentId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssignment>>>
    
    export type DeleteAssignmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Delete Assignment
 */
export const useDeleteAssignment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssignment>>, TError,{assignmentId: number}, TContext>, }
) => {

      const mutationOptions = getDeleteAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * Returns all payout program assignments
 * @summary Get Assignments
 */
export const getAssignments = (
    params?: GetAssignmentsParams,
 signal?: AbortSignal
) => {
      
      
      return customInstance<Assignment[]>(
      {url: `/v2/assignments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAssignmentsQueryKey = (params?: GetAssignmentsParams,) => {
    
    return [`/v2/assignments/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAssignmentsQueryOptions = <TData = Awaited<ReturnType<typeof getAssignments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(params?: GetAssignmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssignments>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAssignmentsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssignments>>> = ({ signal }) => getAssignments(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssignments>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAssignmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssignments>>>
export type GetAssignmentsQueryError = ErrorType<_ErrorModel | HTTPValidationError>

/**
 * @summary Get Assignments
 */
export const useGetAssignments = <TData = Awaited<ReturnType<typeof getAssignments>>, TError = ErrorType<_ErrorModel | HTTPValidationError>>(
 params?: GetAssignmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAssignments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssignmentsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * (Admin only) Creates a payout program assignment
 * @summary Create Assignment
 */
export const createAssignment = (
    createAssignmentRequest: CreateAssignmentRequest,
 ) => {
      
      
      return customInstance<Assignment>(
      {url: `/v2/assignments/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAssignmentRequest
    },
      );
    }
  


export const getCreateAssignmentMutationOptions = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssignment>>, TError,{data: CreateAssignmentRequest}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createAssignment>>, TError,{data: CreateAssignmentRequest}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssignment>>, {data: CreateAssignmentRequest}> = (props) => {
          const {data} = props ?? {};

          return  createAssignment(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAssignmentMutationResult = NonNullable<Awaited<ReturnType<typeof createAssignment>>>
    export type CreateAssignmentMutationBody = CreateAssignmentRequest
    export type CreateAssignmentMutationError = ErrorType<_ErrorModel | HTTPValidationError>

    /**
 * @summary Create Assignment
 */
export const useCreateAssignment = <TError = ErrorType<_ErrorModel | HTTPValidationError>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssignment>>, TError,{data: CreateAssignmentRequest}, TContext>, }
) => {

      const mutationOptions = getCreateAssignmentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Test
 */
export const getTest = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<unknown>(
      {url: `/v2/test`, method: 'get', signal
    },
      );
    }
  

export const getGetTestQueryKey = () => {
    
    return [`/v2/test`] as const;
    }

    
export const getGetTestQueryOptions = <TData = Awaited<ReturnType<typeof getTest>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTestQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTest>>> = ({ signal }) => getTest(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTestQueryResult = NonNullable<Awaited<ReturnType<typeof getTest>>>
export type GetTestQueryError = ErrorType<unknown>

/**
 * @summary Get Test
 */
export const useGetTest = <TData = Awaited<ReturnType<typeof getTest>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTest>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTestQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Determines if this service is up and running.

**200 status indicates that this is ready to service requests.**
 * @summary Status
 */
export const status = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<StatusResponse>(
      {url: `/status`, method: 'get', signal
    },
      );
    }
  

export const getStatusQueryKey = () => {
    
    return [`/status`] as const;
    }

    
export const getStatusQueryOptions = <TData = Awaited<ReturnType<typeof status>>, TError = ErrorType<unknown>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatusQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof status>>> = ({ signal }) => status(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData> & { queryKey: QueryKey }
}

export type StatusQueryResult = NonNullable<Awaited<ReturnType<typeof status>>>
export type StatusQueryError = ErrorType<unknown>

/**
 * @summary Status
 */
export const useStatus = <TData = Awaited<ReturnType<typeof status>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof status>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getStatusQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


