import React, { useState } from "react"

import {
    EuiForm,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiButton,
    EuiText,
    EuiLink,
    EuiSpacer,
    EuiFieldText,
} from "@equipmentshare/ds2"

import { useUpdateEquipmentServiceManual, EquipmentServiceManual } from "../../../services/Skunkworks/Generated"
import { MakePicker, AllModelsPicker } from "../../Pickers"
import { YearMultiSelect, Option } from "../../MultiSelectPickers"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"

export type EditEquipmentReferenceFormProps = {
    onSave?: () => void
    onCancel: () => void
    equipmentReference: EquipmentServiceManual
}

export const EditEquipmentReferenceForm: React.FC<EditEquipmentReferenceFormProps> = ({
    onSave,
    onCancel,
    equipmentReference,
}) => {
    const [makeId, setMakeId] = React.useState<number | null>(equipmentReference.equipment_make_id)
    const [modelId, setModelId] = useState<number | null>(equipmentReference.equipment_model_id ?? null)
    const [serialStart, setSerialStart] = useState<string | null>(equipmentReference.serial_start)
    const [serialEnd, setSerialEnd] = useState<string | null>(equipmentReference.serial_end)
    const [yearOptions, setYearOptions] = useState<Option[]>(
        equipmentReference.years?.sort().map((year) => ({ label: `${year}`, key: `${year}` })) ?? []
    )
    const [versionNumber, setVersionNumber] = React.useState<number>(equipmentReference.version_number)

    const initialMakeOption = {
        label: equipmentReference.equipment_make_name ?? "",
        id: equipmentReference.equipment_make_id.toString(),
    }

    const initialModelOption = !!equipmentReference.equipment_model_id
        ? {
              label: equipmentReference.equipment_model_name ?? "",
              id: equipmentReference.equipment_model_id.toString(),
              data: {
                  equipment_class_id: 0,
                  equipment_make_id: equipmentReference.equipment_make_id,
                  equipment_model_id: equipmentReference.equipment_model_id,
              },
          }
        : undefined

    const { addSuccessToast, addErrorToast } = useToasts()

    const { mutateAsync: updateEquipmentServiceManual, isLoading: isUpdatingEquipmentServiceManual } =
        useUpdateEquipmentServiceManual({
            mutation: {
                onSuccess: () => addSuccessToast({ text: "Equipment Reference Updated Successfully" }),
            },
        })

    const submitUpdate = async (e: React.FormEvent) => {
        e.preventDefault()

        if (!makeId) {
            addErrorToast({ text: "Please complete all required fields" })
            return
        }

        try {
            const { version_number: updatedVersionNumber } = await updateEquipmentServiceManual({
                equipmentServiceManualId: equipmentReference.equipment_service_manual_id,
                data: {
                    equipment_make_id: makeId,
                    equipment_model_id: modelId,
                    serial_start: serialStart || null,
                    serial_end: serialEnd || null,
                    years: yearOptions.map((y) => parseInt(y.key)),
                    version_number: versionNumber,
                    service_manual_id: equipmentReference.service_manual_id,
                },
            })

            setVersionNumber(updatedVersionNumber)
            onSave && onSave()
        } catch (err) {
            addErrorToast({ text: formatException(err) })
        }
    }

    return (
        <EuiForm component="form" onSubmit={submitUpdate}>
            <EuiFormRow label="Document Title">
                <EuiText size="s">{equipmentReference.service_manual?.file_name}</EuiText>
            </EuiFormRow>
            <EuiFormRow label="GDrive Link">
                <EuiLink
                    href={`https://drive.google.com/file/d/${equipmentReference.service_manual?.file_id}/view`}
                    target="_blank"
                    external
                >
                    Document Link
                </EuiLink>
            </EuiFormRow>
            <EuiFormRow data-testid="make-row">
                <MakePicker
                    placeholder="Search for Equipment Make"
                    onIdSelected={(id) => {
                        if (!!id) {
                            setMakeId(id)
                            setModelId(null)
                        }
                    }}
                    onCleared={() => {
                        setMakeId(null)
                        setModelId(null)
                    }}
                    selectedId={makeId}
                    isInvalid={!makeId}
                    initialOptions={[initialMakeOption]}
                    initialSelected={initialMakeOption}
                />
            </EuiFormRow>
            <EuiFormRow
                helpText={!makeId ? "Select a make to enable model selection" : undefined}
                data-testid="model-row"
            >
                <AllModelsPicker
                    placeholder={!makeId ? "Select Make to Enable" : "Search for Equipment Model(s)"}
                    selectedId={modelId}
                    onIdSelected={(id) => {
                        if (!!id) {
                            setModelId(id)
                        }
                    }}
                    onCleared={() => setModelId(null)}
                    isDisabled={!makeId}
                    context={{ makeId: makeId ?? undefined }}
                    initialOptions={!!initialModelOption ? [initialModelOption] : undefined}
                    initialSelected={initialModelOption}
                />
            </EuiFormRow>
            <EuiFormRow label="Serial Start">
                <EuiFieldText
                    placeholder="Enter Serial Start"
                    value={serialStart ?? ""}
                    onChange={(e) => setSerialStart(e.target.value.trim())}
                />
            </EuiFormRow>
            <EuiFormRow label="Serial End">
                <EuiFieldText
                    placeholder="Enter Serial End"
                    value={serialEnd ?? ""}
                    onChange={(e) => setSerialEnd(e.target.value.trim())}
                />
            </EuiFormRow>
            <EuiFormRow data-testid="year-row">
                <YearMultiSelect
                    placeholder="Select Year(s)"
                    selected={yearOptions}
                    onSelected={(selectedYears) => setYearOptions(selectedYears)}
                />
            </EuiFormRow>
            <EuiSpacer size="xxl" />
            <EuiFormRow>
                <EuiFlexGroup justifyContent="flexEnd" gutterSize="s">
                    <EuiFlexItem grow={false}>
                        <EuiButton color="text" onClick={onCancel}>
                            Close
                        </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton fill type="submit" isLoading={isUpdatingEquipmentServiceManual}>
                            Save
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFormRow>
        </EuiForm>
    )
}

export default EditEquipmentReferenceForm
