import React from "react"
import PQueue from "p-queue/dist"

import {
    EuiTitle,
    EuiProgress,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiRange,
    EuiSpacer,
    EuiButtonIcon,
    EuiTextArea,
} from "@equipmentshare/ds2"

import { Task, JobOptions } from "./Job"
import CenteredContent from "../CenteredContent"
import { wait } from "../../Utils"

interface TaskManagerProps {
    tasks: Task[]
    onFinished: () => void
    options?: JobOptions
}

const DEFAULT_CONCURRENCY = 3

const queue = new PQueue({ concurrency: DEFAULT_CONCURRENCY, autoStart: false })

const initialState = { count: 0, rejected: [], resolved: [] }

function reducer(state: any, action: any) {
    switch (action.type) {
        case "resolve":
            return { ...state, count: state.count + 1 }
        case "reject":
            return {
                count: state.count + 1,
                rejected: [...state.rejected, action.payload],
            }
        default:
            throw new Error()
    }
}

function genWait(rateLimit: number | undefined) {
    if (!rateLimit) {
        return () => null
    }

    if (rateLimit < 1 || rateLimit >= 60) {
        throw new Error("Rate limit must be a value between 1-59 for now.")
    }

    const timeout = (60 / rateLimit) * 1000
    return () => wait(timeout)
}

export default function TaskManager(props: TaskManagerProps) {
    const [curr, setCurr] = React.useState("")
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const [concurrency, setConcurrency] = React.useState(
        props.options?.rateLimit ? 1 : DEFAULT_CONCURRENCY
    )

    const isDone = state.count === props.tasks.length

    React.useEffect(() => {
        queue.concurrency = concurrency
        queue.addAll(
            props.tasks.map(
                // attach an additional then/catch to modify internal state of TaskManager
                (task) => () => {
                    setCurr(task.description)
                    return task
                        .fn()
                        .then(() => dispatch({ type: "resolve" }))
                        .catch((error) => {
                            const errorMessage =
                                error?.response?.data?.message ?? `${error}`
                            return dispatch({
                                type: "reject",
                                payload: `${task.description} - ${errorMessage}`,
                            })
                        })
                        .finally(genWait(props.options?.rateLimit))
                }
            )
        )
        queue.on("idle", () => {
            props.onFinished()
        })
        queue.start()

        // on component destroy
        return () => {
            queue.clear()
        }

        // we only want to run this code once...
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        queue.concurrency = concurrency
    }, [concurrency])

    const playPauseControls = (
        <EuiFlexGroup gutterSize="xs">
            <EuiFlexItem grow={false}>
                <EuiButtonIcon
                    display="base"
                    iconType="play"
                    aria-label="Play"
                    onClick={() => queue.start()}
                />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
                <EuiButtonIcon
                    display="base"
                    iconType="pause"
                    aria-label="Pause"
                    onClick={() => queue.pause()}
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )

    const text = isDone
        ? "All Done!"
        : `Completed: ${state.count} / ${props.tasks.length}`

    const progress = (
        <EuiFlexGroup gutterSize="s" alignItems="center">
            <EuiFlexItem grow={false}>
                <EuiText>
                    <p>{text}</p>
                </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiProgress
                    value={state.count}
                    max={props.tasks.length}
                    size="m"
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )

    const concurrencyControls = (
        <EuiFlexGroup gutterSize="s" alignItems="center">
            <EuiFlexItem grow={false}>
                <EuiText>
                    <p>Concurrency:</p>
                </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <EuiRange
                    min={1}
                    max={10}
                    value={concurrency}
                    onChange={(e: any) =>
                        setConcurrency(parseInt(e.target.value))
                    }
                    showLabels
                    showInput="inputWithPopover"
                />
            </EuiFlexItem>
        </EuiFlexGroup>
    )

    return (
        <>
            <EuiFlexGroup
                gutterSize="xl"
                alignItems="center"
                style={{ maxWidth: 800, margin: "0 auto" }}
            >
                <EuiFlexItem grow={false}>{playPauseControls}</EuiFlexItem>
                {props.options?.rateLimit ? null : (
                    <EuiFlexItem grow={false}>
                        {concurrencyControls}
                    </EuiFlexItem>
                )}
                <EuiFlexItem>{progress}</EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size="xs" />
            <CenteredContent>
                {isDone ? "No more tasks to run..." : curr}
            </CenteredContent>
            <EuiSpacer size="xs" />

            <EuiTitle size="m">
                <h2>Failures</h2>
            </EuiTitle>
            <EuiSpacer size="xs" />
            <EuiTextArea
                placeholder="No errors yet!"
                value={state.rejected.join("\n")}
                readOnly={true}
                style={{ minHeight: 400 }}
                fullWidth
            />
        </>
    )
}
