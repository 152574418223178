import { Moment } from "moment"

import { EuiFormRow, EuiDatePicker, EuiSpacer } from "@equipmentshare/ds2"

export type DateProps = {
    showErrors: boolean
    startDate: Moment | undefined
    handleChangeStart: (date: Moment) => void
    endDate: Moment | undefined
    handleChangeEnd: (date: Moment) => void
}

export default function Dates(props: DateProps) {
    return (
        <>
            <EuiFormRow
                label="Start Date"
                isInvalid={props.showErrors && !props.startDate}
            >
                <EuiDatePicker
                    showTimeSelect
                    selected={props.startDate}
                    onChange={props.handleChangeStart}
                    isInvalid={props.showErrors && !props.startDate}
                />
            </EuiFormRow>

            <EuiSpacer size="s" />

            <EuiFormRow
                label="End Date"
                isInvalid={props.showErrors && !props.endDate}
            >
                <EuiDatePicker
                    showTimeSelect
                    selected={props.endDate}
                    onChange={props.handleChangeEnd}
                    isInvalid={props.showErrors && !props.endDate}
                />
            </EuiFormRow>
        </>
    )
}
