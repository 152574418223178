import { useState } from "react"
import Page from "../Page/Page"
import { EuiPageHeader, EuiSpacer } from "@equipmentshare/ds2"
import TabHomeScreen from "./components/TabHomeScreen"
import RenderCreateModal from "./components/RenderCreateModal"
import RenderViewModal from "./components/RenderViewModal"
import RenderUpdateModal from "./components/RenderUpdateModal"
import RenderDeleteModal from "./components/RenderDeleteModal"
import { PromiseDataMult } from "./components/RenderViewModal"

import RenderTable from "./components/Table/RenderTable"

export enum Tab {
    ASSIGNMENTS = "Assignments",
    MASTERAGREEMENTS = "Master Agreements",
    PROGRAMS = "Programs",
    SCHEDULES = "Schedules",
    ADMIN = "Admin",
}

export default function PayoutProgramConsole() {
    const [tab, setTab] = useState<Tab>(Tab.ASSIGNMENTS)
    const [modalInfo, setModalInfo] = useState({
        isVisible: false,
        action: "",
        tab: "",
    })
    const [tableData, setTableData] = useState<PromiseDataMult>([])
    const [showTableBool, setShowTableBool] = useState(false)

    const onTabClicked = (tab: Tab) => {
        setTab(tab)
        setShowTableBool(false)
    }

    const closeModal = () => setModalInfo({ ...modalInfo, isVisible: false })

    function showModal(action: string) {
        setModalInfo({ isVisible: true, action, tab })
    }

    //dynamically returns the correct model based on the tab and the button clicked
    const renderModal = () => {
        if (!modalInfo.isVisible) return null

        switch (modalInfo.action) {
            case "view":
                return (
                    <RenderViewModal
                        tabName={tab}
                        closeModal={closeModal}
                        onSubmit={(e) => {
                            setTableData(e)
                            setShowTableBool(true)
                        }}
                    />
                )
            case "create":
                return (
                    <RenderCreateModal tabName={tab} closeModal={closeModal} />
                )

            case "update":
                return (
                    <RenderUpdateModal tabName={tab} closeModal={closeModal} />
                )
            case "delete":
                return (
                    <RenderDeleteModal tabName={tab} closeModal={closeModal} />
                )
        }
    }

    return (
        <Page title="Payout Program Admin Console">
            <EuiPageHeader
                tabs={[
                    {
                        label: "Assignments",
                        isSelected: tab === Tab.ASSIGNMENTS,
                        onClick: () => onTabClicked(Tab.ASSIGNMENTS),
                    },
                    {
                        label: "Master Agreements",
                        isSelected: tab === Tab.MASTERAGREEMENTS,
                        onClick: () => onTabClicked(Tab.MASTERAGREEMENTS),
                    },
                    {
                        label: "Programs",
                        isSelected: tab === Tab.PROGRAMS,
                        onClick: () => onTabClicked(Tab.PROGRAMS),
                    },
                    {
                        label: "Schedules",
                        isSelected: tab === Tab.SCHEDULES,
                        onClick: () => onTabClicked(Tab.SCHEDULES),
                    },
                    {
                        label: "Admin",
                        isSelected: tab === Tab.ADMIN,
                        onClick: () => onTabClicked(Tab.ADMIN),
                    },
                ]}
            />
            <EuiSpacer size="l" />
            {tab === Tab.ASSIGNMENTS && (
                <TabHomeScreen
                    title="Assignments"
                    onViewClick={() => showModal("view")}
                    onCreateClick={() => showModal("create")}
                    onUpdateClick={() => showModal("update")}
                    onDeleteClick={() => showModal("delete")}
                />
            )}
            {tab === Tab.MASTERAGREEMENTS && (
                <TabHomeScreen
                    title="Master Agreements"
                    onViewClick={() => showModal("view")}
                    onCreateClick={() => showModal("create")}
                    onUpdateClick={() => showModal("update")}
                    onDeleteClick={() => showModal("delete")}
                />
            )}
            {tab === Tab.PROGRAMS && (
                <TabHomeScreen
                    title="Programs"
                    onViewClick={() => showModal("view")}
                    onCreateClick={() => showModal("create")}
                    onUpdateClick={() => showModal("update")}
                    onDeleteClick={() => showModal("delete")}
                />
            )}
            {tab === Tab.SCHEDULES && (
                <TabHomeScreen
                    title="Schedules"
                    onViewClick={() => showModal("view")}
                    onCreateClick={() => showModal("create")}
                    onUpdateClick={() => showModal("update")}
                    onDeleteClick={() => showModal("delete")}
                />
            )}
            <EuiSpacer />
            <RenderTable data={tableData} tab={tab} showTable={showTableBool} />
            {renderModal()}
        </Page>
    )
}
