import {
    EuiButton,
    EuiModal,
    EuiModalHeaderTitle,
    EuiModalHeader,
    EuiModalBody,
    EuiModalFooter,
    EuiButtonEmpty,
    EuiSpacer,
    EuiSwitch,
    EuiSwitchEvent,
} from "@equipmentshare/ds2"

export type ViewModalProps = {
    modalTitle: string
    closeModal: () => void
    onConfirm: () => void
    modalBody: JSX.Element
    checkedBool: boolean
    onChange: (event: EuiSwitchEvent) => void
}

export default function ViewModal(props: ViewModalProps) {
    return (
        <EuiModal onClose={props.closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1>View {props.modalTitle}</h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiSpacer />
                <EuiSwitch
                    label={`Search Singular ${props.modalTitle}`}
                    checked={props.checkedBool}
                    onChange={props.onChange}
                />
                <EuiSpacer />
                {props.modalBody}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={props.closeModal}>
                    Cancel
                </EuiButtonEmpty>
                <EuiButton onClick={props.onConfirm}>Confirm</EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}
