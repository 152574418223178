import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiTitle,
    EuiEmptyPrompt,
    EuiLoadingSpinner,
    EuiText,
} from "@equipmentshare/ds2"

import { useGetEquipmentServiceManual } from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"
import { EditEquipmentReferenceForm } from "./EditEquipmentReferenceForm"

export type EquipmentReferenceActionsFlyoutProps = {
    onClose: () => void
    equipmentServiceManualId: number
}

export const EquipmentReferenceActionsFlyout: React.FC<EquipmentReferenceActionsFlyoutProps> = ({
    onClose,
    equipmentServiceManualId,
}) => {
    const { addErrorToast } = useToasts()

    const { data: equipmentServiceManual, isLoading } = useGetEquipmentServiceManual(equipmentServiceManualId, {
        query: {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onError: (e) => {
                addErrorToast({
                    text: formatException(e),
                })
            },
        },
    })

    return (
        <EuiFlyout onClose={onClose} size="s">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Equipment Data</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                {isLoading ? (
                    <EuiEmptyPrompt title={<h3>Loading</h3>} titleSize="xs" icon={<EuiLoadingSpinner size="xl" />} />
                ) : !!equipmentServiceManual ? (
                    <EditEquipmentReferenceForm
                        equipmentReference={equipmentServiceManual}
                        onSave={onClose}
                        onCancel={onClose}
                    />
                ) : (
                    <EuiText>No Equipment Reference Found</EuiText>
                )}
            </EuiFlyoutBody>
        </EuiFlyout>
    )
}

export default EquipmentReferenceActionsFlyout
