import React from "react"

import { EuiButton, EuiSpacer, EuiText } from "@equipmentshare/ds2"

import { Job } from "./Job"
import CenteredContent from "../CenteredContent"

export interface ExampleProposal {}

const genSingle = () => ({
    description: "Some promise",
    fn: () =>
        new Promise((resolve, reject) =>
            setTimeout(() => {
                if (Math.random() < 0.1) {
                    reject("Something went wrong...")
                } else {
                    resolve(null)
                }
            }, Math.random() * 1000)
        ),
})

const ExampleJob: Job<ExampleProposal> = {
    gen: () => Array(100).fill(0).map(genSingle),
    Configure: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    This is where you'd normally configure the batch job, but
                    for this example you only need to click Next.
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onSubmit({})}>Next</EuiButton>
        </CenteredContent>
    ),
    Confirm: (props) => (
        <CenteredContent>
            <EuiText>
                <p>
                    This is where we'd normally confirm what you are about to
                    do... You can just hit Next again.
                </p>
            </EuiText>
            <EuiSpacer size="s" />
            <EuiButton onClick={() => props.onConfirm()}>Next</EuiButton>
        </CenteredContent>
    ),
}

export default ExampleJob
