import {
    EuiAvatar,
    EuiHeaderSectionItemButton,
    EuiPopover,
    EuiMarkdownFormat,
} from "@equipmentshare/ds2"
import React from "react"

interface HelpProps {
    name: string
}

const Help = ({ name }: HelpProps) => {
    const [isOpen, setIsOpen] = React.useState(false)

    const onMenuButtonClick = () => {
        setIsOpen(!isOpen)
    }

    const closeMenu = () => {
        setIsOpen(false)
    }

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={name}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Help menu"
            onClick={onMenuButtonClick}
        >
            <EuiAvatar
                name="Help"
                size="l"
                iconType="questionInCircle"
                color={null}
            />
        </EuiHeaderSectionItemButton>
    )

    return (
        <EuiPopover
            id={name}
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="s"
        >
            <div style={{ width: 350 }}>
                <EuiMarkdownFormat>{` For any help regarding this application, please refer to the [\`help-sworks\`](https://equipmentshare.slack.com/archives/C03D9J0MYSH) channel on slack  `}</EuiMarkdownFormat>
            </div>
        </EuiPopover>
    )
}

export default Help
