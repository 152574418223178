import { AxiosError } from "axios"
import { render } from "@testing-library/react"

import EsApiService from "./services/EsApiService"
import { AllProvidersExceptForAuth0 } from "./providers"
import { ValidationError } from "./services/Skunkworks/Generated"

export const snakeToCamel = (str: string): string =>
    str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", ""))

export const camelToSnake = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const changeCase =
    (fn: (s: string) => string) =>
    <T>(obj: T): T => {
        const converted = {} as any
        Object.entries(obj).forEach(([key, value]) => {
            converted[fn(key)] = value
        })
        return converted as T
    }

export const makeObjectCamelCase = changeCase(snakeToCamel)
export const makeObjectSnakeCase = changeCase(camelToSnake)

export function makeQueryParams(obj: { [key: string]: string | number | null | undefined }): string {
    const params = []
    for (const [key, value] of Object.entries(obj)) {
        if (value) {
            params.push(`${key}=${value}`)
        }
    }
    const joined = params.join("&")
    return joined ? "?" + joined : ""
}

// Stolen from here: https://stackoverflow.com/questions/6860853/generate-random-string-for-div-id
export function genGuid() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4()
}

export function wait(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms)
    })
}

export const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
})

// Remove any non-digit character except for decimal point
export const cleanUSDString = (value: string) => value.replace(/[^0-9.]/g, "")

export const formatUSD = (value: string) => {
    let cleanedValue = cleanUSDString(value)

    // Remove any extra decimal points
    const firstDecimalIndex = cleanedValue.indexOf(".")
    if (firstDecimalIndex !== -1) {
        cleanedValue =
            cleanedValue.substring(0, firstDecimalIndex + 1) +
            cleanedValue.substring(firstDecimalIndex + 1).replace(/\./g, "")
    }

    const parts = cleanedValue.split(".")

    // Format whole number part with commas
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    if (parts[1]?.length > 2) {
        parts[1] = parts[1].substring(0, 2)
    }

    const formattedValue = "$" + parts.join(".")
    return formattedValue
}

/**
 * @param keys
 * @param values
 * @returns Record where each key is mapped to each value by index
 *
 * Example:
 * mapListsByIndex([1, 2, 3], ["a", "b", "c"]) -> {1: "a", 2: "b", 3: "c"}
 */
export function mapListsByIndex<T extends string | number, K>(keys: T[], values: K[]) {
    if (keys.length !== values.length) {
        throw new Error("Can't map lists because the lengths of keys and values are not the same.")
    }
    const mapping = {} as Record<T, K>
    for (let i = 0; i < keys.length; i++) {
        mapping[keys[i]] = values[i]
    }
    return mapping
}

export function formatException(e: any) {
    // For now all other cases are unhandled
    if (!(e instanceof AxiosError)) return e
    const data: any = e.response?.data

    if (typeof data?.detail == "string") {
        return data.detail
    }

    if (Array.isArray(data?.detail)) {
        // treat as FastAPI HTTPValidationError
        const errors = data.detail as ValidationError[]
        return errors.map((err) => `${err.loc.join(".")}: ${err.msg}`).join("\n")
    }
    return e.message
}

/**
 * @param method EsApiService method
 * @param val resolved response
 *
 * Make it easy to mock out EsApiService methods.
 *
 * Although val is typed as `any`, all the type work in the function
 * declaration makes the external contract to callers extremely clear.
 */
export function mockEsApiServiceMethodResponse<T extends keyof EsApiService>(
    method: T,
    val: Awaited<ReturnType<EsApiService[T]>>
) {
    if (!(EsApiService as any)._isMockFunction) {
        throw new Error(`The test file is missing 'jest.mock("path/to/services/EsApiService")'`)
    }
    jest.spyOn(EsApiService.prototype, method).mockResolvedValue(val as any)
}

export const renderWithProviders = (ui: JSX.Element) => render(ui, { wrapper: AllProvidersExceptForAuth0 })
