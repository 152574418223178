import {
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiTitle,
    EuiTabbedContent,
    EuiTabbedContentTab,
    EuiEmptyPrompt,
    EuiBadge,
    EuiBadgeProps,
    EuiLoadingSpinner,
    EuiSpacer,
    EuiText,
    COLORSLIGHT,
} from "@equipmentshare/ds2"

import { useGetServiceManual } from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"
import { DocumentDataForm } from "./DocumentDataForm"
import { EquipmentDataForm } from "./EquipmentDataForm"

export type DocumentActionsFlyoutProps = {
    onClose: () => void
    serviceManualId: number
}

export const DocumentActionsFlyout: React.FC<DocumentActionsFlyoutProps> = ({ onClose, serviceManualId }) => {
    const { addErrorToast } = useToasts()

    const {
        data: serviceManual,
        isLoading,
        refetch: refetchServiceManual,
    } = useGetServiceManual(serviceManualId, {
        query: {
            refetchOnWindowFocus: false,
            staleTime: 0,
            cacheTime: 0,
            onError: (e) => {
                addErrorToast({
                    text: formatException(e),
                })
            },
        },
    })

    const statusBadgeColor: EuiBadgeProps["color"] = serviceManual?.classified
        ? COLORSLIGHT.success.toString()
        : "default"
    const statusBadgeText = serviceManual?.classified ? "Classified" : "Unclassified"

    const tabs: EuiTabbedContentTab[] = [
        {
            id: "equipment-data",
            name: "Equipment Data",
            content: (
                <>
                    <EuiSpacer size="m" />
                    {serviceManual ? (
                        <EquipmentDataForm document={serviceManual} onCancel={onClose} />
                    ) : (
                        <EuiText>No Service Manual Found</EuiText>
                    )}
                </>
            ),
        },
        {
            id: "document-data",
            name: "Document Data",
            content: (
                <>
                    <EuiSpacer size="m" />
                    {serviceManual ? (
                        <DocumentDataForm document={serviceManual} onSave={refetchServiceManual} onCancel={onClose} />
                    ) : (
                        <EuiText>No Service Manual Found</EuiText>
                    )}
                </>
            ),
        },
    ]

    return (
        <EuiFlyout onClose={onClose} size="s">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Document Information</h2>
                </EuiTitle>
                <EuiSpacer size="s" />
                <EuiBadge color={statusBadgeColor} data-testid="status-badge">
                    {statusBadgeText}
                </EuiBadge>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                {isLoading ? (
                    <EuiEmptyPrompt title={<h3>Loading</h3>} titleSize="xs" icon={<EuiLoadingSpinner size="xl" />} />
                ) : (
                    <EuiTabbedContent tabs={tabs} initialSelectedTab={tabs[1]} autoFocus="selected" />
                )}
            </EuiFlyoutBody>
        </EuiFlyout>
    )
}

export default DocumentActionsFlyout
