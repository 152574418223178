import { EuiConfirmModal, EuiText } from "@equipmentshare/ds2"

import { useGetEquipmentServiceManual, useDeleteEquipmentServiceManual } from "../../../services/Skunkworks/Generated"
import { useToasts } from "../../../hooks/useToasts"
import { formatException } from "../../../Utils"

export type DeleteEquipmentReferenceModalProps = {
    onClose: () => void
    equipmentServiceManualId: number
}

export const DeleteEquipmentReferenceModal: React.FC<DeleteEquipmentReferenceModalProps> = ({
    onClose,
    equipmentServiceManualId,
}) => {
    const { addErrorToast, addSuccessToast } = useToasts()

    const { data: equipmentServiceManual, isLoading: isLoadingEquipmentServiceManual } = useGetEquipmentServiceManual(
        equipmentServiceManualId,
        {
            query: {
                refetchOnWindowFocus: false,
                staleTime: 0,
                cacheTime: 0,
                onError: (e) => {
                    addErrorToast({
                        text: formatException(e),
                    })
                },
            },
        }
    )

    const { mutateAsync: deleteEquipmentServiceManual, isLoading: isDeleting } = useDeleteEquipmentServiceManual({
        mutation: {
            onSuccess: () => addSuccessToast({ text: "Equipment Reference Successfully Deleted" }),
        },
    })

    const handleDelete = async (e: React.MouseEvent) => {
        e.preventDefault()

        try {
            await deleteEquipmentServiceManual({ equipmentServiceManualId })
            onClose()
        } catch (err) {
            addErrorToast({ text: formatException(err) })
        }
    }

    const makeModelText: string[] = [`${equipmentServiceManual?.equipment_make_name}`]

    if (!!equipmentServiceManual?.equipment_model_name) {
        makeModelText.push(`${equipmentServiceManual?.equipment_model_name}`)
    }

    return (
        <EuiConfirmModal
            title="Delete Equipment Reference"
            cancelButtonText="Cancel"
            onCancel={onClose}
            confirmButtonText="Delete"
            onConfirm={handleDelete}
            buttonColor="danger"
            isLoading={isDeleting}
            confirmButtonDisabled={isLoadingEquipmentServiceManual || isDeleting}
        >
            {isLoadingEquipmentServiceManual ? (
                <EuiText>Loading...</EuiText>
            ) : (
                <EuiText>
                    Are you sure you want to delete the <strong>{makeModelText.join("/")}</strong> equipment reference
                    for <strong>{equipmentServiceManual?.service_manual?.file_name}</strong>?
                </EuiText>
            )}
        </EuiConfirmModal>
    )
}

export default DeleteEquipmentReferenceModal
