import { useMemo, useState } from "react"
import {
    CompetitorQuote,
    CompetitorQuoteDetail,
    useCreateEsQuote,
} from "../../services/Skunkworks/Generated"
import {
    createESQuote,
    ESQuoteRequest,
    ESQuoteEquipmentTypeRequest,
} from "../../services/Quotes"
import { Moment } from "moment"
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiFormRow,
    EuiDatePicker,
    EuiCheckbox,
    EuiButtonEmpty,
    EuiButton,
    EuiPanel,
} from "@equipmentshare/ds2"
import UsdInput from "../UsdInput"
import { DeliveryTypePicker, Option } from "../Pickers"
import { UseQueryResult } from "react-query"
import { useToasts } from "../../hooks/useToasts"
import { NewDetailRow } from "./CompetitorQuotesDetailsInput"
import { RentalRate } from "../../services/Misc"
import { genGuid } from "../../Utils"

type Nullable<T> = {
    [P in keyof T]: T[P] extends (infer U)[]
        ? Nullable<U>[] | null
        : T[P] extends object
        ? Nullable<T[P]> | null
        : T[P] | null
}

interface CreateESQuoteFlyoutProps {
    competitorQuote: CompetitorQuote
    classIdToName: Record<number, UseQueryResult<string, unknown>>
    classIdToRate: Record<number, UseQueryResult<RentalRate | null, unknown>>
    onClose: () => void
    onCreated: () => void
}

// IDK why but the quote-web-api has their own tables for rate shift types which use UUID
// Could be refactored if we allow flexing on the these types
export const BENCHMARK_RATE_UUID = "2c42aa80-1644-4963-9ac5-076539de9541"
export const SINGLE_SHIFT_UUID = "607b335e-734b-4825-97b3-3bc96a4741bf"

function convertExistingDetailsToRows(
    details?: CompetitorQuoteDetail[]
): NewDetailRow[] {
    return (
        details?.map((detail) => {
            const id = genGuid()
            return {
                id,
                competitorClass: detail.competitor_class,
                quantity: detail.quantity,
                pricePerDay: detail.price_per_day?.toString() ?? "",
                pricePerWeek: detail.price_per_week?.toString() ?? "",
                pricePerMonth: detail.price_per_month?.toString() ?? "",
            }
        }) ?? []
    )
}

export default function CreateESQuoteInput(props: CreateESQuoteFlyoutProps) {
    const {
        competitorQuote,
        classIdToName,
        classIdToRate,
        onClose,
        onCreated,
    } = props
    const [startDate, setStartDate] = useState<Moment | null>(null)
    const [endDate, setEndDate] = useState<Moment | null>(null)
    const [deliveryFee, setDeliveryFee] = useState<string | null>(null)
    const [pickupFee, setPickupFee] = useState<string | null>(null)
    const [deliveryTypeId, setDeliveryTypeId] = useState<number | null>(null)
    const [deliveryTypeName, setDeliveryTypeName] = useState<string | null>(
        null
    )
    const [isTaxExempt, setIsTaxExempt] = useState<boolean>(false)
    const [isMakingESQuote, setIsMakingESQuote] = useState(false)
    const details = useMemo(
        () => convertExistingDetailsToRows(competitorQuote.details),
        [competitorQuote.details]
    )

    const { addErrorToast, addSuccessToast } = useToasts()
    const { isLoading: isUploadingESQuote, mutateAsync: uploadESQuote } =
        useCreateEsQuote()

    const getRates = async (branch_id: number, equipment_class_id: number) => {
        const rate = classIdToRate[equipment_class_id].data
        return {
            day_rate: rate?.price_per_day ?? null,
            week_rate: rate?.price_per_week ?? null,
            four_week_rate: rate?.price_per_month ?? null,
        }
    }

    const assertESQuotesInput = (
        body: Nullable<ESQuoteRequest>
    ): body is ESQuoteRequest =>
        body.branch_id !== null &&
        body.start_date !== null &&
        body.end_date !== null &&
        body.delivery_fee !== null &&
        !isNaN(body.delivery_fee) &&
        body.pickup_fee !== null &&
        !isNaN(body.pickup_fee) &&
        body.delivery_type_id !== null &&
        body.delivery_type_name !== null &&
        body.is_tax_exempt !== null &&
        body.equipment_types !== null &&
        body.equipment_types.every(assertESQuotesEquipmentTypes)

    const assertESQuotesEquipmentTypes = (
        body: Nullable<ESQuoteEquipmentTypeRequest>
    ): body is ESQuoteEquipmentTypeRequest =>
        body.equipment_class_id !== null &&
        body.equipment_class_name !== null &&
        body.quantity !== null &&
        body.day_rate !== null &&
        !isNaN(body.day_rate) &&
        body.week_rate !== null &&
        !isNaN(body.week_rate) &&
        body.four_week_rate !== null &&
        !isNaN(body.four_week_rate) &&
        body.shift_id !== null &&
        body.selected_rate_type_id !== null

    const setDeliveryType = (option: Option | null) => {
        if (option) {
            setDeliveryTypeId(Number(option.id))
            setDeliveryTypeName(option.label)
        } else {
            setDeliveryTypeId(null)
            setDeliveryTypeName(null)
        }
    }

    const getClassName = (classId?: number) => {
        if (classId) {
            return classIdToName[classId].data ?? null
        }
        return null
    }

    const handleSave = async () => {
        const equipmentTypes: Nullable<ESQuoteEquipmentTypeRequest>[] = []
        for (let detail of details) {
            let rates = await getRates(
                competitorQuote.market_id!,
                detail.competitorClass.es_class_id!
            )
            if (!(rates.day_rate || rates.week_rate || rates.four_week_rate)) {
                rates = {
                    day_rate: parseFloat(detail.pricePerDay),
                    week_rate: parseFloat(detail.pricePerWeek),
                    four_week_rate: parseFloat(detail.pricePerMonth),
                }
            }
            equipmentTypes.push({
                equipment_class_id: detail.competitorClass.es_class_id ?? null,
                equipment_class_name: getClassName(
                    detail.competitorClass.es_class_id ?? undefined
                ),
                quantity: detail.quantity,
                ...rates,
                shift_id: SINGLE_SHIFT_UUID,
                selected_rate_type_id: BENCHMARK_RATE_UUID,
            })
        }
        const body: Nullable<ESQuoteRequest> = {
            branch_id: competitorQuote.market_id ?? null,
            start_date: startDate?.toISOString() ?? null,
            end_date: endDate?.toISOString() ?? null,
            delivery_fee: parseFloat(deliveryFee ?? ""),
            pickup_fee: parseFloat(pickupFee ?? ""),
            delivery_type_id: deliveryTypeId,
            delivery_type_name: deliveryTypeName,
            is_tax_exempt: isTaxExempt,
            equipment_types: equipmentTypes,
        }
        if (!assertESQuotesInput(body)) {
            addErrorToast({
                text: "Could not create quote, required fields empty",
            })
            return
        }
        setIsMakingESQuote(true)
        try {
            const esQuote = await createESQuote(body)
            setIsMakingESQuote(false)
            if (esQuote && esQuote.id) {
                await uploadESQuote({
                    data: {
                        quote: esQuote.id,
                        competitor_quote_id:
                            competitorQuote.competitor_quote_id,
                    },
                })
            }
            addSuccessToast({
                text: "ES Quote Created!",
            })
            onCreated()
        } catch (e) {
            console.error(e)
            addErrorToast({ text: `${e}` })
        }
    }

    const isValid = !!(
        startDate &&
        endDate &&
        deliveryFee &&
        pickupFee &&
        deliveryTypeId &&
        deliveryTypeName
    )

    const resetESQuoteInputs = () => {
        setStartDate(null)
        setEndDate(null)
        setDeliveryFee(null)
        setPickupFee(null)
        setDeliveryTypeId(null)
        setDeliveryTypeName(null)
        setIsTaxExempt(false)
    }

    const handleClose = () => {
        resetESQuoteInputs()
        onClose()
    }

    const isLoading = isMakingESQuote || isUploadingESQuote

    return (
        <EuiPanel hasBorder={true} hasShadow={false}>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Start Date">
                        <EuiDatePicker
                            selected={startDate}
                            onChange={setStartDate}
                            onClear={() => setStartDate(null)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label="End Date">
                        <EuiDatePicker
                            selected={endDate}
                            onChange={setEndDate}
                            onClear={() => setEndDate(null)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiFormRow label="Delivery Fee">
                        <UsdInput
                            value={deliveryFee ?? ""}
                            onChange={(value) => setDeliveryFee(value ?? null)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiFormRow label="Pickup Fee">
                        <UsdInput
                            value={pickupFee ?? ""}
                            onChange={(value) => setPickupFee(value ?? null)}
                        />
                    </EuiFormRow>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <DeliveryTypePicker
                        onSelected={setDeliveryType}
                        onCleared={() => setDeliveryType(null)}
                    />
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiCheckbox
                        id={"is-tax-exempt-checkbox"}
                        label="Is Tax Exempt"
                        checked={!!isTaxExempt}
                        onChange={() => setIsTaxExempt(!isTaxExempt)}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                        iconType="cross"
                        onClick={handleClose}
                        flush="left"
                        isDisabled={isLoading}
                    >
                        Close
                    </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiButton
                        onClick={handleSave}
                        fill
                        disabled={isLoading || !isValid}
                        isLoading={isLoading}
                    >
                        Save
                    </EuiButton>
                </EuiFlexItem>
            </EuiFlexGroup>
        </EuiPanel>
    )
}
