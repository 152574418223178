import { useState } from "react"

import { EuiButtonEmpty, EuiPopover, EuiPopoverTitle } from "@equipmentshare/ds2"

import { RebateTierData } from "../Utils"
import { RebateTierForm } from "./RebateTierForm"

export type EditRebateTierButtonProps = {
    rebateTier: RebateTierData
    handleSave: (rebateTier: RebateTierData) => void
}

export const EditRebateTierButton: React.FC<EditRebateTierButtonProps> = ({ rebateTier, handleSave }) => {
    const [isRebateTierFormOpen, setIsRebateTierFormOpen] = useState<boolean>(false)

    const initialData = {
        ...rebateTier,
        rebate_percentage: parseFloat((rebateTier.rebate_percentage * 100).toFixed(2)),
    }

    const onSave = (rTier: RebateTierData) => {
        handleSave(rTier)
        setIsRebateTierFormOpen(false)
    }

    return (
        <EuiPopover
            button={
                <EuiButtonEmpty onClick={() => setIsRebateTierFormOpen(!isRebateTierFormOpen)}>Edit</EuiButtonEmpty>
            }
            isOpen={isRebateTierFormOpen}
            closePopover={() => setIsRebateTierFormOpen(false)}
            anchorPosition="leftDown"
            initialFocus="#minimumPayment"
            panelStyle={{ width: 300 }}
            repositionOnScroll
        >
            <EuiPopoverTitle>Edit Rebate Tier</EuiPopoverTitle>
            <RebateTierForm initialData={initialData} handleSave={onSave} />
        </EuiPopover>
    )
}

export default EditRebateTierButton
